import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";

const SensoryIntegration = ({ data, next, prev, id,setAction, action }) => {
  const questionInfo = [
    {
      id: 189,
      title: "sensory_int_child_hard_hearing",
      placeholder: "",
      label:
        "Did you ever think that your child might have been deaf/hard of hearing?",
      wrapperClassName: "",
      options: [
        {
          id: 164,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 165,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 190,
      title: "sensory_int_child_hard_hearing_desc",
      placeholder: "Details...",
      label: "If so, was his/her hearing checked? What were the results?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 191,
      title: "sensory_int_child_hypersensitive",
      placeholder: "",
      label:
        "Is your child hypersensitive to certain sounds, textures, and/colors?",
      wrapperClassName: "",
      options: [
        {
          id: 166,
          title: "Yes",
          key: "yes",
        },
        {
          id: 167,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 192,
      title: "sensory_int_child_hypersensitive_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 193,
      title: "sensory_int_child_inital_feeling",
      placeholder: "",
      label:
        "During your child’s first two years, did he/she enjoy being held?",
      wrapperClassName: "",
      options: [
        {
          id: 168,
          title: "Yes",
          key: "yes",
        },
        {
          id: 169,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 194,
      title: "sensory_int_child_affection",
      placeholder: "Details...",
      label:
        "Does your child enjoy affection (ex. hugs, kisses, high fives, etc.) now?",
      wrapperClassName: "",
      inputType: true,

      // options: [
      //   {
      //     id: 170,
      //     title: "Yes",
      //     key: "yes",
      //   },
      //   {
      //     id: 171,
      //     title: "No",
      //     key: "no",
      //   },
      // ],
    },
    {
      id: 195,
      title: "sensory_int_child_rythmic_activity",
      placeholder: "",
      label:
        "Does your child ever engage in rhythmic or rocking activities for long periods of time (like rocking in a chair, jumping, swinging, etc.)?",
      wrapperClassName: "",
      options: [
        {
          id: 172,
          title: "Yes",
          key: "yes",
        },
        {
          id: 173,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 196,
      title: "sensory_int_child_rythmic_activity_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 197,
      title: "sensory_int_child_walkthrough",
      placeholder: "",
      label:
        "Does your child ever look through or walk through people as if they weren’t there?",
      wrapperClassName: "",
      options: [
        {
          id: 174,
          title: "Yes",
          key: "yes",
        },
        {
          id: 175,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 198,
      title: "sensory_int_child_oddities",
      placeholder: "",
      label:
        "Does the child have certain eating oddities such as refusing to drink from a transparent container, eating only hot (or cold) food, eating only one or two foods, etc.?",
      wrapperClassName: "",
      options: [
        {
          id: 176,
          title: "Yes",
          key: "yes",
        },
        {
          id: 177,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 199,
      title: "sensory_int_child_oddities_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 200,
      title: "sensory_int_child_food_craving",
      placeholder: "",
      label: "Does the child have unusual cravings for foods?",
      wrapperClassName: "",
      options: [
        {
          id: 178,
          title: "Yes",
          key: "yes",
        },
        {
          id: 179,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 201,
      title: "sensory_int_child_food_craving_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 202,
      title: "sensory_int_child_food_intake",
      placeholder: "",
      label: "Does the child eat large amounts of food?",
      wrapperClassName: "",
      options: [
        {
          id: 180,
          title: "Yes",
          key: "yes",
        },
        {
          id: 181,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 203,
      title: "sensory_int_child_food_intake_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 204,
      title: "sensory_int_child_playing_skill",
      placeholder: "Details...",
      label:
        "How skillful is the child as doing fine work with his/her fingers or playing with small objects?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 205,
      title: "sensory_int_reaction_on_interuption",
      placeholder: "Details...",
      label:
        "How does the child react to being interrupted during an activity?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 206,
      title: "sensory_int_ritual_engagment",
      placeholder: "",
      label:
        "Does the child engage in complicated rituals that make him/her very upset if not followed (like putting many dolls to bed in a certain order, taking exactly the same routes between two places, etc.)?",
      wrapperClassName: "",
      options: [
        {
          id: 182,
          title: "Yes",
          key: "yes",
        },
        {
          id: 183,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 207,
      title: "sensory_int_ritual_engagment_desc",
      placeholder: "Details...",
      label: "If yes, please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/sensory-integeration/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/sensory-integeration"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/sensory-integeration/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};
export default SensoryIntegration;
