import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";

const { Title } = Typography;

const StopwatchDisplay = ({ setTimer, timer, setDisabled, reset }) => {
  const [running, setRunning] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(timer);
  const [intervalId, setIntervalId] = useState(0); // Store the interval ID
  const handleStartStop = () => {
    if (!running) {
      const id = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(id); // Store the interval ID in state
      setRunning(true);
    } else {
      clearInterval(intervalId); // Clear the interval using the stored ID
      setRunning(false);
    }
  };

  const handleReset = () => {
    clearInterval(intervalId);
    setRunning(false);
    setTimeElapsed(0);
  };
  useEffect(() => {
    setDisabled(running);
  }, [running]);
  useEffect(() => {
    handleReset();
  }, [reset]);
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    setTimer(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    );
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      <Title level={2}>{formatTime(timeElapsed)}</Title>
      <div className="d-flex align-items-center mb-24 gap-12">
        <button className="timer-btn" onClick={handleStartStop}>
          {running ? "Stop" : "Start"}
        </button>
        <button className="timer-btn-2" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default StopwatchDisplay;
