import "./custom-select.scss";
import { useEffect, useState } from "react";
import backarrowicon from "../../assets/svg/downarrow.svg";
import searchicon from "../../assets/svg/searchicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import LoaderSpinner from "../../components/general/loader/loader.spinner";
import { timeSlots } from "../../constants/clientdata";
import { logout } from "../../store/user/userSlice";

const CustomSelect = ({
  label,
  simple,
  role,
  options,
  placeholder,
  handleValue,
  appointmentData,
  endTime,
  fieldValue,
  className,
  time,
  userId,
  objectives,
  existingGoals,
  setUserGoals,
  mainObjective,
  name,
  mands,
  isClickDisabled,
  testingRequired,
  labelClass,
  customValue,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const getValueByKey = (key) => {
    const slot = timeSlots.find((slot) => slot.key === key);
    return slot ? slot.value : null;
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        // setIsLoading(true);
        const getTableData = async ({ role, page, search }) => {
          const queryParams = {
            role,
            q: search ?? "",
            page: page,
            per_page: 20,
            status: "active",
          };
          const queryString = new URLSearchParams(queryParams).toString();

          // setTableData(TableDataClient);
          try {
            const response = await httpClient.get(
              `api/v1/users/?${queryString}`
            );
            setData({ items: response?.data?.users });
            setMetaData(response?.data?.meta);
            setCurrentPage(response?.data?.meta?.current_page);

            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              // setIsLoading(false);
              localStorage.removeItem(`persist:root`);

              logout();
              window.open("/dashboardlogin", "_self");
              return;
            }
            createNotification("error", error?.response?.data?.response.error);
            console.log("Something Went wrong", error);
          }
        };
        const getGoalsData = async ({ userId, search }) => {
          const queryParams = {
            q: search ?? "",
            user_id: userId,
          };
          const queryString = new URLSearchParams(queryParams).toString();
          // setTableData(TableDataClient);
          try {
            // const response = await httpClient.get(
            //   `api/v1/goals/client_goals?${queryString}`
            // );
            const response = await httpClient.get(
              `api/v1/goals/client_active_goals?${queryString}`
            );
            setUserGoals(response?.data?.goals?.length);
            setData({ items: response?.data?.goals });
            setMetaData(response?.data?.meta);
            setCurrentPage(response?.data?.meta?.current_page);
            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              // setIsLoading(false);
              localStorage.removeItem(`persist:root`);

              logout();
              window.open("/dashboardlogin", "_self");
              return;
            }
            createNotification("error", error?.response?.data?.response.error);
            console.log("Something Went wrong", error);
          }
        };
        if (objectives && userId) {
          getGoalsData({ userId, search: searchText });
        } else if (!mands && role)
          // Add role with conditiomn to prevent multiple calls on edit appointment modal
          getTableData({
            page: 1,
            role,
            search: searchText,
          });
      },
      searchText ? 1000 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, userId, role]);

  const fetchMoreData = () => {
    const queryParams = {
      role,
      q: searchText ?? "",
      page: currentPage + 1,
      per_page: 20,
      status: "active",
    };
    const queryString = new URLSearchParams(queryParams).toString();
    const getMoreData = async () => {
      try {
        const response = await httpClient.get(`api/v1/users/?${queryString}`);
        setData({ items: data.items.concat(response?.data?.users) });
        setMetaData(response?.data?.meta);
        setCurrentPage(currentPage + 1);

        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          // setIsLoading(false);
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        // setIsLoading(false);
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error);
      }
    };
    getMoreData();
  };
  const getGoalTitle = (goalId) => {
    let goalTitle;
    if (NewArray?.length) {
      goalTitle =
        NewArray?.find(
          (item) => item?.id == goalId
          // if (item.id == goalId) {
          //   return item;
          // }
        )?.title || "";
    }
    if (goalTitle) {
      return goalTitle;
    }
  };
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest("input")) {
        setActiveDropdown(null);
      }
    });
  }, []);

  const firstUnique =
    mainObjective &&
    mainObjective?.map((obj) => {
      return { id: obj.goal_id, title: obj.goal_title || "" };
    });
  const secondArray = data?.items?.map((obj) => {
    return {
      id: obj.id,
      title: obj.title,
      active: true,
    };
  });
  const NewArray = secondArray &&
    firstUnique && [...secondArray, ...firstUnique];

  const filteredArray = NewArray?.filter((item) => item.id !== "");

  const uniqueArray = filteredArray?.reduce((acc, current) => {
    const existingItem = acc.find((item) => item.id === current.id);

    if (!existingItem) {
      acc.push(current);
    } else if (current.active === true && !existingItem.active) {
      existingItem.active = true;
    }

    return acc;
  }, []);

  useEffect(() => {
    setUserGoals?.(uniqueArray?.length);
  }, [uniqueArray]);

  const handleInputKeyDown = (e) => {
    if (e.key === " ") {
      e.target.value += e.key;
      e.preventDefault();
    }
  };

  const preventActionDone = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <section
      className={`dropdown-wrapper ${className ? className : ""}`}
      style={{ zIndex: 100 }}
    >
      <div className="custom-select-wrapper">
        <label className={`f-15 f-w-600 ${labelClass ? labelClass : "mt-30"}`}>
          {label}
        </label>
        <div
          style={{
            pointerEvents:
              endTime && !!!appointmentData?.appointment?.start_time && "none",
            backgroundColor: testingRequired
              ? "#50C878"
              : isClickDisabled
              ? "#e9ecef"
              : "",
            color: testingRequired && "black",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!isClickDisabled) {
              document
                .querySelectorAll(".custom-dropdown-content")
                ?.forEach((dropdown) => (dropdown.style.display = "none"));
              setActiveDropdown(activeDropdown === label ? null : label);
            }
          }}
          className="custom-dropdown-btn"
        >
          {customValue
            ? name // Use this condition for simple select set customValue to true
            : fieldValue && !time && !objectives
            ? selected || name
            : fieldValue && time
            ? getValueByKey(fieldValue)
            : objectives
            ? getGoalTitle(fieldValue)
            : placeholder}
          {!isClickDisabled && (
            <img src={backarrowicon} alt="Back Arrow Icon" />
          )}
        </div>
        <div
          className="custom-dropdown-content"
          style={{
            display: activeDropdown === label ? "block" : "none",
            height: "min-content",
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {!simple && (
            <div className="custom-search-input-wrapper">
              <div class="custom-search-input">
                <input
                  onPaste={preventActionDone}
                  onCopy={preventActionDone}
                  className="searching-input"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  // values={searchText}
                  onKeyDown={(e) => handleInputKeyDown(e)}
                />
                <div className="search-icon ">
                  <img src={searchicon} alt="Search Icon" />
                </div>
              </div>
            </div>
          )}
          {simple ? (
            options?.map((item, index) => {
              return (
                <div
                  onClick={(e) => {
                    setIsSelected(e.target.textContent);
                    setIsActive(!isActive);
                    handleValue(item.key);
                  }}
                  key={index}
                  className="item"
                >
                  {item.value}
                </div>
              );
            })
          ) : (
            <InfiniteScroll
              dataLength={data?.items?.length ?? 20}
              next={() => fetchMoreData()}
              hasMore={
                metaData?.current_page === metaData?.total_pages ? false : true
              }
              // style={{ position: "relative" }}
              height={objectives ? "200px" : "145px"}
              loader={<LoaderSpinner size="20" left={"40%"} color="#090834" />}
            >
              {objectives && NewArray
                ? NewArray.filter(
                    (item) => !existingGoals?.includes(item.id)
                  )?.map((item, index) => (
                    <div
                      onClick={(e) => {
                        if (item?.active) {
                          setIsSelected(e.target.textContent);
                          setIsActive(!isActive);
                          handleValue(item.id);
                        }
                      }}
                      style={{
                        pointerEvents: !item?.active && "none",
                        cursor: !item?.active && "none",
                      }}
                      key={index}
                      className="item"
                    >
                      {item.full_name || item.title}{" "}
                    </div>
                  ))
                : data?.items?.map((item, index) => (
                    <div
                      onClick={(e) => {
                        setIsSelected(e.target.textContent);
                        setIsActive(!isActive);
                        handleValue(item.slug || item.id);
                      }}
                      key={index}
                      className="item"
                    >
                      {item.full_name || item.title}
                    </div>
                  ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </section>
  );
};
export default CustomSelect;
