import React, { useEffect, useState } from "react";
import Styles from "./Dashboard.module.scss";
import PanelBoard from "../../components/PanelBoard/PanelBoard";
import DashboardPanelCard from "../../components/DashboardPanelCard/DashboardPanelCard";
import TableView from "../../components/TableViews/TableView";
import httpClient from "../../services/axios";
import { Spin } from "antd";
import { createNotification } from "../../helpers/createNotifications";
import { useNavigate } from "react-router-dom";

const DashboardPanelIndex = ({ setPanelViewHandler, setdashboardView }) => {
  const [directorData, setDirectorData] = useState();
  const [lowestUnits, setLowestUnits] = useState();
  const [chartData, setChartData] = useState();
  const [goalsDuration, setGoalsDuration] = useState("this_week");
  const [unitFilter, setUnitFilter] = useState(97153);
  const [unitTypeFilter, setUnitTypeFilter] = useState("direct");

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        httpClient
          .get("/api/v1/users/director_dashboard_details")
          .then((response) => {
            setDirectorData(response?.data?.director_dashboard_data);
          });
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getDashboardData();
  }, []);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        httpClient
          .get(
            `api/v1/users/lowest_client_units?filter=${unitFilter.toString()}&type=${unitTypeFilter}`
          )
          .then((response) => {
            setLowestUnits(response?.data?.units_data);
          });
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getDashboardData();
  }, [unitFilter, unitTypeFilter]);
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        const response = await httpClient.get(
          `/api/v1/goals/total_worked_goals?required_date=${goalsDuration}`
        );
        setChartData(response?.data?.goals_data);

        return response;
        // }
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };

    getQuestionData();
  }, [goalsDuration]);

  const UnitChangeHandler = () => {};
  const DurationChangeHandler = () => {};
  const navigate = useNavigate();
  return (
    <>
      {directorData ? (
        <div className={Styles.Dashboard__Panel__ContentWrapper}>
          <PanelBoard
            RenewelCount={"04"}
            TechniciansScheduled={
              directorData?.client_overall_info?.technician_schedule
            }
            GoalsCount={directorData?.client_overall_info?.goals_worked}
          />
          <section className={Styles.Dashboard__Panel_CardWrapper}>
            <DashboardPanelCard
              title="Goals Worked"
              Goal={true}
              chartData={chartData}
              duration={setGoalsDuration}
              onChange={DurationChangeHandler}
              setPanelViewHandler={setPanelViewHandler}
            />
            <DashboardPanelCard
              onChange={UnitChangeHandler}
              unitsRemaining={lowestUnits}
              title={"Lowest Units Remaining"}
              setUnitFilter={setUnitFilter}
              setUnitTypeFilter={setUnitTypeFilter}
              units={true}
              setPanelViewHandler={setPanelViewHandler}
              onClickViewAll={() => {
                setPanelViewHandler("AllSessions");
              }}
            />
          </section>
          <section className={`d-flex flex-column gap-0`}>
            <div className="d-flex justify-content-between align-items-center mb-30">
              <h3 className={`f-18 lh-25 f-w-700 clr-dark-grey`}>
                Sessions Completed This Week
              </h3>
              {!!directorData?.current_week_completed_sessions?.length && (
                <span
                  onClick={() => {
                    setdashboardView("Sessions");

                    navigate("/dashboard/sessions");
                  }}
                  className={`f-14 lh-30 f-w-600 clr-orange-pri cursor-pointer`}
                >
                  View all
                </span>
              )}
            </div>
            <TableView
              View={"DirectorCompletedSession"}
              setdashboardView={setdashboardView}
              tableData={directorData?.current_week_completed_sessions}
            />
          </section>
        </div>
      ) : (
        <div
          className="overlay-spinner"
          style={{
            position: "fixed",
            top: "0",
            left: "248px",
            bottom: "0",
            right: "0",
            background: "#fff",
            zIndex: 999,
          }}
        >
          <div style={{ position: "relative" }}>
            <Spin
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardPanelIndex;
