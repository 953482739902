import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LMFullCalender from "../../components/LMFullCalender/LMFullCalender";
import TableView from "../../components/TableViews/TableView";
import DatePickerLM from "../../components/form-controls/input/DatePicker/DatePickerLM";
import { timeSlotsEnd, timeSlotsStart } from "../../constants/clientdata";
import {
  CalenderIcon,
  GlobalSettingIcon,
  plusIconWhite,
} from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";
import Sc from "../Dashboard/Dashboard.module.scss";
import styleClasses from "./Appointments.module.scss";
import CustomSelect from "./custom-select";
import GlobalSettingsModal from "../../components/Modals/GlobalSettingsModal";
import dayjs from "dayjs";

const tabNames = [
  {
    key: "UpcomingSessions",
    label: `Sessions`,
  },
  {
    key: "awaiting_approval",
    label: `Awaiting Approval`,
  },
];

const Appointments = ({ setdashboardView }) => {
  const [appointmentData, setAppointmentData] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const [tab, setTab] = useState("UpcomingSessions");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState();
  const [dateSelect, setDateSelect] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [datesForDot, setDatesForDot] = useState();
  const [actionDone, setActionDone] = useState(false);
  const [globalSettingModal, setGlobalSettingModal] = useState(false);
  const [configurationType, setConfigurationType] = useState("Global");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [bulkSelectedRows, setBulkSelectedRows] = useState();
  const [settingOptionsEnabled, setSettingOptionsEnabled] = useState(true);

  const onChange = (key) => {
    setTab(key);
  };

  const locationOption = [
    { value: "In Clinic", key: "in_clinic" },
    { value: "Home", key: "in_home" },
  ];

  const modifierCodeOptions = [
    { value: "HO", key: "ho" },
    { value: "Tech", key: "tech" },
  ];

  const onSelectValue = (key, value) => {
    if (appointmentData?.appointment?.end_time && key === "start_time") {
      if (value < appointmentData?.appointment?.end_time) {
        setAppointmentData({
          appointment: {
            ...appointmentData?.appointment,
            [key]: value,
          },
        });
        setError("");
      } else {
        setAppointmentData({
          appointment: {
            ...appointmentData?.appointment,
            [key]: value,
            end_time: "",
          },
        });
        setError("Start time should be earlier then End time");
      }
    } else {
      setAppointmentData({
        appointment: { ...appointmentData?.appointment, [key]: value },
      });
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [dateSelect]);

  const filterTimeSlots = (time) => {
    const filteredTimeSlots = timeSlotsEnd.filter((slot) => slot.key > time);
    return filteredTimeSlots;
  };

  const addKeyToAppointments = (response) => {
    const appointments = response?.data?.appointments || [];

    const appointmentsWithKeys = appointments.map((appointment) => ({
      key: Math.random(),
      ...appointment,
    }));

    response.data.appointments = appointmentsWithKeys;
    return response;
  };

  const formatedDateTime = fetchFormattedDateTime();
  const now = new Date();
  useEffect(() => {
    setLoading(true);
    const getTableData = async ({ role, tab, page, status }) => {
      const queryParams = {
        page: page,
        per_page: 8,
        current_datetime: formatedDateTime,
        date: dateSelect || now.toISOString().split("T")[0],
        ...(tab === "awaiting_approval" && { awaiting_approval: true }),
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const response = await httpClient.get(
          `api/v1/appointments/?${queryString}`
        );
        const updatedResponse = addKeyToAppointments(response);
        setTableData(updatedResponse?.data);
        setLoading(false);
        // setIsLoading(false);
        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          setLoading(false);

          // setIsLoading(false);
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        setLoading(false);

        // setIsLoading(false);
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error);
      }
      setLoading(false);

      // setIsLoading(false);
    };

    getTableData({
      page: currentPage,
      tab,
    });
  }, [tab, currentPage, dateSelect, actionDone]);

  useEffect(() => {
    if (
      appointmentData?.appointment?.schedule_date &&
      appointmentData?.appointment?.schedule_date != "undefined" &&
      appointmentData?.appointment?.client_id &&
      appointmentData?.appointment?.technician_id &&
      appointmentData?.appointment?.location &&
      appointmentData?.appointment?.start_time &&
      appointmentData?.appointment?.end_time
    ) {
      setError("");
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appointmentData]);

  useEffect(() => {
    const getScheduleDate = async () => {
      try {
        const response = await httpClient.get(
          `api/v1/appointments/appointments_schedule_dates`,
          {
            params: {
              month: month,
              year: year,
            },
          }
        );
        if (response.data) {
          setDatesForDot(response.data);
        }
        // setIsLoading(false);

        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          // setIsLoading(false);
          localStorage.removeItem(`persist:root`);
          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        // setIsLoading(false);
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error);
      }
    };
    if (year && month) {
      getScheduleDate();
    }
  }, [year, month, actionDone]);
  return (
    <>
      <section className={styleClasses.Appointments}>
        <div className={`${styleClasses.Appointments_Wrapper}`}>
          <div className={`d-flex ${styleClasses.Appointments_Heading_Div}`}>
            <h3 className={`f-24 lh-25 f-w-700 clr-dark-grey`}>Appointments</h3>
            <Button
              className={`lm-form-btn lm-form-btn-primary f-16 f-w-600 ${styleClasses.Appointments_Heading_Div_Global_Setting_Btn}`}
              onClick={() => {
                setConfigurationType("Global");
                setModalLoading(true);
                setGlobalSettingModal(true);
              }}
            >
              <span style={{ marginRight: "5px" }}>{GlobalSettingIcon}</span>{" "}
              Global Settings
            </Button>
          </div>
          <div className={styleClasses.Appointments__Container}>
            <div className={styleClasses.Appointments__Details}>
              <div className="h-50 d-flex gap-10 aling-items-center">
                <div className="w-100 position-relative">
                  <h1
                    className={`position-absolute f-21 lh-25 f-w-700 clr-dark-grey ${styleClasses.Appointments__Details__Heading}`}
                  >
                    Appointments Calendar
                  </h1>
                  <LMFullCalender
                    setDateSelect={setDateSelect}
                    setYear={setYear}
                    setMonth={setMonth}
                    datesForDot={datesForDot}
                  ></LMFullCalender>
                </div>
                <div
                  className={`${styleClasses.Appointments__Details__CalendarIcon}`}
                >
                  {CalenderIcon}
                </div>
              </div>
              <div
                className="appointment-listing-tabs"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "2px solid #e4e1e1",
                  margin: "0px",
                }}
              >
                <Tabs
                  className={`lm-tabs ${styleClasses.Appointments__Details__TabWrapper}`}
                  defaultActiveKey="UpcomingSessions"
                  items={tabNames}
                  onChange={onChange}
                  navWrapperClassName={`abc`}
                  // navWrapperClassName={``}
                />
                {bulkSelectedRows?.length > 0 && (
                  <Button
                    className={`lm-form-btn-primary`}
                    style={{ width: "20px" }}
                    onClick={() => {
                      setConfigurationType("BulkUpdate");
                      setModalLoading(true);
                      setGlobalSettingModal(true);
                    }}
                  >
                    Apply Settings
                  </Button>
                )}
              </div>
              {tab === "UpcomingSessions" && (
                <TableView
                  setdashboardView={setdashboardView}
                  setGlobalSettingModal={setGlobalSettingModal}
                  Pagination={true}
                  View={"UpcomingSessions"}
                  perPage={8}
                  tableData={tableData?.appointments}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  metaData={tableData?.meta}
                  setActionDone={setActionDone}
                  actionDone={actionDone}
                  loading={loading}
                  setBulkSelectedRows={setBulkSelectedRows}
                  settingOptionsEnabled={settingOptionsEnabled}
                  setSettingOptionsEnabled={setSettingOptionsEnabled}
                />
              )}
              {tab === "awaiting_approval" && (
                <TableView
                  setdashboardView={setdashboardView}
                  Pagination={true}
                  View={"awaiting_approval"}
                  perPage={8}
                  tableData={tableData?.appointments}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  metaData={tableData?.meta}
                  loading={loading}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styleClasses.Appointments__AddAppointmentSidebar}>
          <h4 className="f-20 f-w-700 clr-side-bar-heading mb-25">
            Add Appointment
          </h4>
          <div className="">
            <div className={styleClasses.Appointments__AddAppointmentdateinput}>
              <DatePickerLM
                label={"Date"}
                onChange={(date) => {
                  const formattedDate = date
                    ? dayjs(date).format("YYYY-MM-DD")
                    : null;
                  setAppointmentData({
                    appointment: {
                      ...appointmentData?.appointment,
                      schedule_date: `${formattedDate}`,
                    },
                  });
                }}
                futureDate={true}
                value={appointmentData?.appointment?.schedule_date || ""}
                allowClear={false}
              ></DatePickerLM>
            </div>
            <CustomSelect
              label={"Select Client"}
              role="client"
              placeholder={"Client name"}
              handleValue={(value) => onSelectValue("client_id", value)}
              field={"client_slug"}
              fieldValue={appointmentData?.appointment?.client_id}
              labelClass={'mt-20'}
            />
            <CustomSelect
              label={"Select Technician"}
              role="technician"
              placeholder={"Technician name"}
              handleValue={(value) => onSelectValue("technician_id", value)}
              field={"technician_slug"}
              fieldValue={appointmentData?.appointment?.technician_id}
              labelClass={'mt-20'}
            />
            <CustomSelect
              label={"Location"}
              simple={true}
              options={locationOption}
              placeholder={"Location"}
              handleValue={(value) => onSelectValue("location", value)}
              field={"location"}
              fieldValue={appointmentData?.appointment?.location}
              labelClass={'mt-20'}
            />

            <CustomSelect
              label={"Start Time"}
              simple={true}
              options={timeSlotsStart}
              placeholder={"00:00 AM"}
              handleValue={(value) => onSelectValue("start_time", value)}
              field={"start_time"}
              fieldValue={appointmentData?.appointment?.start_time}
              time={true}
              labelClass={'mt-20'}
            />
            {error && (
              <p
                style={{
                  color: "#E31616",
                  fontSize: "12px",
                  marginTop: "4px",
                }}
              >
                {error}
              </p>
            )}
            <CustomSelect
              label={"End Time"}
              simple={true}
              options={filterTimeSlots(
                appointmentData?.appointment?.start_time
              )}
              appointmentData={appointmentData}
              endTime={true}
              placeholder={"00:00 PM"}
              handleValue={(value) => onSelectValue("end_time", value)}
              fieldValue={appointmentData?.appointment?.end_time}
              time={true}
              labelClass={'mt-20'}
            />
            <CustomSelect
              label={"Modifier code"}
              placeholder={"Modifier code"}
              handleValue={(value) => onSelectValue("modifier_code", value)}
              options={modifierCodeOptions}
              fieldValue={appointmentData?.appointment?.modifier_code}
              simple={true}
              labelClass={'mt-20'}
            />
          </div>

          <Button
            onClick={() => {
              setConfigurationType("AppointmentSettings");
              setGlobalSettingModal(true);
            }}
            disabled={isDisabled}
            className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button} ${styleClasses.Appointments__AddAppointmentbutton}`}
          >
            Book Appointment
          </Button>
        </div>

        <GlobalSettingsModal
          show={globalSettingModal}
          configurationType={configurationType}
          appointmentData={appointmentData}
          setAppointmentData={setAppointmentData}
          setActionDone={setActionDone}
          actionDone={actionDone}
          onHide={() => {
            setGlobalSettingModal(false);
          }}
          setGlobalSettingModal={setGlobalSettingModal}
          modalLoading={modalLoading}
          setLoadingModal={setModalLoading}
          bulkSelectedRows={bulkSelectedRows}
          setBulkSelectedRows={setBulkSelectedRows}
          setSettingOptionsEnabled={setSettingOptionsEnabled}
        />
      </section>
    </>
  );
};

export default Appointments;
