import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import "./DailyDataSheetTabs.scss";
const ViewObjectives = ({ data }) => {

  if (!data.length) {
    return (
      <div
        className="compilance-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                <ellipse
                  fill="#f5f5f5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fill-rule="nonzero" stroke="#d9d9d9">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="ant-empty-description">No data</div>
        </div>
      </div>
    );
  } else
    return (
      <Accordion
        defaultActiveKey="0"
        flush
        className="custom-accordian-data-sheet"
      >
        {data &&
          data.map &&
          data.map((row, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header className="fs-14 f-w-600 clr-dark-grey">
                {row?.goal_title}
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-table mt-16">
                  <Table>
                    {row?.sub_objective_attributes?.length &&
                      row.sub_objective_attributes?.map((subRow, subIndex) => (
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <p className="fs-16 f-w-600">
                                Objective {subIndex + 1}{" "}
                              </p>
                              <span className="fs-14 f-w-400 clr-span-text">
                                {subRow?.statement || ""}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <p className="fs-14 f-w-600 clr-td-text">
                                Total Outcome{" "}
                              </p>
                              <span className="clr-orange-pri fs-14 f-w-600">
                                {subRow?.positive_outcome +
                                  subRow?.negative_outcome || 0}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <p className="fs-14 f-w-600 clr-td-text">
                                Negative Outcome{" "}
                              </p>
                              <span className="clr-chip-red fs-14 f-w-600">
                                {subRow?.negative_outcome || 0}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <p className="fs-14 f-w-600 clr-td-text">
                                Positive Outcome{" "}
                              </p>
                              <span className="clr-orange-pri fs-14 f-w-600">
                                {subRow.positive_outcome || 0}{" "}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    );
};
export default ViewObjectives;
