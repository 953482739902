import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import "./Modals.scss";

const ViewEditHistoryModal = ({
  onHide,
  show,
  modalLoading,
  setLoadingModal,
  setShowEditHistoryModal,
  billingId,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [invalidCheckinUnlock, setInvalidCheckinUnlock] = useState(false);
  const [billingEditHistory, setBillingEditHistory] = useState([]);

  const columns = [
    {
      title: "Editor",
      dataIndex: "editor",
      key: "editor",
      width: 100,
    },
    {
      title: "Column",
      width: 100,
      dataIndex: "column",
      key: "column",
    },
    {
      title: "Old Value",
      width: 100,
      dataIndex: "oldValue",
      key: "oldValue",
    },
    {
      title: "New Value",
      width: 100,
      dataIndex: "newValue",
      key: "newValue",
    },
    {
      title: "Edit Date",
      width: 100,
      dataIndex: "editDate",
      key: "editDate",
    },
  ];

  const formatAuditData = (data) => {
    const formattedData = data.map((item) => {
      return {
        id: item.id,
        key: item.key,
        editor: item.editor,
        column: item.edited_column,
        oldValue: item.old_value,
        newValue: item.new_value,
        editDate: item.created_at,
      };
    });
    setBillingEditHistory(formattedData);
  };

  useEffect(() => {
    const getBillingEditHistory = async () => {
      try {
        setLoadingModal(true);
        const res = await httpClient.get(
          `/api/v1/appointment_billings/${billingId}/edit_history`
        );
        if (res?.data?.billing_audits) {
          formatAuditData(res?.data?.billing_audits);
        } else {
          createNotification("error", "Unable to fetch billing history");
        }
        setLoadingModal(false);
        return true;
      } catch (error) {
        handleErrorResponse(error);
        setLoadingModal(false);
      }
    };
    if (show) {
      getBillingEditHistory();
    }
  }, [show]);

  return (
    <Modal
      className="billing-edit-history-modal"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Billing Edit History
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-30 pt-30">
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={billingEditHistory}
                pagination={false}
                className="billing-edit-history-table"
                // scroll={{ x: 700 }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  width: "20%",
                }}
                className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 `}
                onClick={() => {
                  setShowEditHistoryModal(false);
                }}
              >
                ok
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default ViewEditHistoryModal;
