import React from "react";
import InnerFooter from "./InnerFooter";
import { useParams } from "react-router-dom";

const IndexPage = ({ prev, next }) => {
  const { id } = useParams();

  return (
    <>
      <p className="max-w-input-of text-normal">
        Please complete this intake questionnaire regarding your child. Feel
        free to add any additional information or attach additional reports that
        you think may helpful for us in getting to know your child. Learn Me LLC
        views all of the information that you provide us with as strictly
        confidential. This information is helpful for us in developing an
        initial understanding of your child’s needs and provides critical
        information for us to discuss with your insurance company to get
        authorization for services.
      </p>
      {!id && <InnerFooter nextFormHandler={next} next={true} start={true} />}
    </>
  );
};

export default IndexPage;
