import React, { useEffect, useRef } from "react";
import "./text.field.formik";
import CurrencyInput from "react-currency-input-field";

import { SearchIconDimmed } from "../../../constants/constants";
export const TextFieldFormik = (props) => {
  const {
    name,
    id,
    values,
    handleBlur,
    handleChange,
    errors,
    err,
    touched,
    disabled,
    placeholder,
    type,
    label,
    labelClassName,
    wrapperClassName,
    className = "form-control lm-input",
    errorClassName = "formik_error",
    autoComplete = "on",
    tabIndex,
    formik,
    required,
    defaultValue,
    pattern,
    batchNo,
    birthDate,
    firstInputRef,
  } = props;
  const textareaProps = type === "textarea" ? { as: "textarea", rows: 4 } : {};
  const pwFieldPlaceholder = "********";
  // console.log(batchNo, "inside");
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();

    // Format month and day to have leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  // const inputRef = useRef();

  const preventActionDone = (e) => {
    e.preventDefault();
    return false;
  };

  const handleBeforeInput = (e) => {
    if (isNaN(e.data)) {
      e.preventDefault();
    }
  };
  return (
    <div className={`${wrapperClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className={`lm-input-form-input-label form-label ${
            required && "required"
          }`}
        >
          {label}
        </label>
      )}

      {type === "text" ||
      type === "password" ||
      type === "email" ||
      type === "search" ||
      type === "tel" ? (
        <div>
          <input
            type={type}
            onPaste={preventActionDone}
            onCopy={preventActionDone}
            className={`${className} ${
              type === "search" && "lm-search-input"
            } ${errors && "error-field"}`}
            id={id}
            style={{ textAlign: "left !important" }}
            placeholder={type === "password" ? pwFieldPlaceholder : placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            tabIndex={tabIndex}
            ref={firstInputRef ? firstInputRef : null}
            name={name}
            value={values && values}
            onChange={handleChange}
            required={required}
            onContextMenu={preventActionDone}
            onKeyDown={(event) => {
              if (
                (event.ctrlKey &&
                  (event.key === "c" ||
                    event.key === "x" ||
                    event.key === "v")) ||
                (event.metaKey &&
                  (event.key === "c" || event.key === "x" || event.key === "v"))
              ) {
                event.preventDefault();
              }
            }}
            defaultValue={defaultValue}
            pattern={pattern}
            max={getCurrentDate()}
          />
          {type === "search" && SearchIconDimmed}
        </div>
      ) : type === "number" ? (
        <input
          type={"text"}
          onPaste={preventActionDone}
          onCopy={preventActionDone}
          className={`${className} ${type === "search" && "lm-search-input"} ${
            errors && "error-field"
          }`}
          id={id}
          placeholder={type === "password" ? pwFieldPlaceholder : placeholder}
          disabled={disabled}
          autoComplete={"off"}
          tabIndex={tabIndex}
          name={name}
          style={{ textAlign: "left !important" }}
          value={values && values}
          ref={firstInputRef ? firstInputRef : null}
          onChange={handleChange}
          required={required}
          defaultValue={defaultValue}
          pattern={pattern}
          onContextMenu={preventActionDone}
          onKeyDown={(e) => {
            const pattern = /[0-9]/;
            const inputChar = e.key;
            if (
              !pattern.test(inputChar) &&
              e.key !== "Backspace" &&
              e.key !== "Tab"
            ) {
              e.preventDefault();
            }
          }}
          onBeforeInput={handleBeforeInput}
          max={getCurrentDate()}
        />
      ) : type === "date" ? (
        <input
          type="date"
          onPaste={preventActionDone}
          onCopy={preventActionDone}
          className={`${className} ${type === "search" && "lm-search-input"} ${
            errors && "error-field"
          }`}
          id={id}
          style={{ textAlign: "left !important" }}
          placeholder={type === "password" ? pwFieldPlaceholder : placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          tabIndex={tabIndex}
          ref={firstInputRef ? firstInputRef : null}
          name={name}
          value={values && values}
          onChange={handleChange}
          onContextMenu={preventActionDone}
          onKeyDown={(event) => {
            if (event.key !== "Tab") event.preventDefault();
            if (
              (event.ctrlKey &&
                (event.key === "c" ||
                  event.key === "x" ||
                  event.key === "v")) ||
              (event.metaKey &&
                (event.key === "c" || event.key === "x" || event.key === "v"))
            ) {
              event.preventDefault();
            }
          }}
          required={required}
          defaultValue={defaultValue}
          pattern={pattern}
          max={birthDate && getCurrentDate()}
        />
      ) : type === "time" ? (
        <>
          {" "}
          <input
            type="time"
            onPaste={preventActionDone}
            onCopy={preventActionDone}
            className={`${className} ${
              type === "search" && "lm-search-input"
            } ${errors && "error-field"}`}
            id={id}
            style={{ textAlign: "left !important" }}
            readonly={true}
            placeholder={type === "password" ? pwFieldPlaceholder : placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            tabIndex={tabIndex}
            onContextMenu={preventActionDone}
            onKeyDown={(event) => {
              if (
                (event.ctrlKey &&
                  (event.key === "c" ||
                    event.key === "x" ||
                    event.key === "v")) ||
                (event.metaKey &&
                  (event.key === "c" || event.key === "x" || event.key === "v"))
              ) {
                event.preventDefault();
              }
            }}
            name={name}
            value={values && values}
            onChange={handleChange}
            // onKeyDown={(event) => event.preventDefault()}
            required={required}
            defaultValue={defaultValue}
            pattern={pattern}
            onFocus={(e) => e.target.removeAttribute("readonly")} // Use onFocus event instead of onfocus
            onBlur={(e) => e.target.setAttribute("readonly", "readonly")}
          />
        </>
      ) : type === "currency" ? (
        <CurrencyInput
          id="input-example"
          name="input-name"
          className={`${className}`}
          prefix="$"
          placeholder={placeholder}
          defaultValue={defaultValue}
          decimalsLimit={2}
          onValueChange={(value) => handleChange(value)}
        />
      ) : (
        <textarea
        onPaste={preventActionDone}
        onCopy={preventActionDone}
          type={type}
          className={`${className} ${errors && "error-field"}`}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={firstInputRef ? firstInputRef : null}
          as="textarea"
          onContextMenu={preventActionDone}
          onKeyDown={(event) => {
            if (
              (event.ctrlKey &&
                (event.key === "c" ||
                  event.key === "x" ||
                  event.key === "v")) ||
              (event.metaKey &&
                (event.key === "c" || event.key === "x" || event.key === "v"))
            ) {
              event.preventDefault();
            }
          }}
          rows="3"
          style={{ textAlign: "left !important" }}
          tabIndex={tabIndex}
          name={name}
          value={values}
          onChange={handleChange}
        ></textarea>
      )}

      {/* {errors && <p className={errorClassName}>{errors}</p>} */}
    </div>
  );
};
