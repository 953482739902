import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../axios";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";


export const login = async (data) => {
  try {
    // we will call our login here and return data accordingly
    const res = await httpClient.post("/api/v1/users/sign_in", { ...data });
    return res;
  } catch (error) {
    console.log("Something Went wrong", error.response);
    return error;
  }
};

export const otpLogin = async (data) => {
  try {
    // we will call our login here and return data accordingly
    data.current_datetime = fetchFormattedDateTime();
    const res = await httpClient.post("/api/v1/users/sign_in", { ...data });
    return res;
  } catch (error) {
    console.log("Something Went wrong", error.response);
    return error;
  }
};

export const resendOtp = async (data) => {
  try {
    data.current_datetime = fetchFormattedDateTime();
    const res = await httpClient.post("/resend_otp", { ...data });
    return res;
  } catch (error) {
    console.log("Something Went wrong", error.response);
    return error;
  }
};

export const resetPassword = async (data) => {
  try {
    // we will call our login here and return data accordingly
    const res = await httpClient.post("/api/v1/users/password ", { ...data });
    return res;
  } catch (error) {
    console.log("Something Went wrong", error.response);
    return error;
  }
};
export const updatePassword = async (data) => {
  try {
    // we will call our login here and return data accordingly
    const res = await httpClient.put("api/v1/users/password ", { ...data });
    return res;
  } catch (error) {
    console.log("Something Went wrong", error.response);
    return error;
  }
};
