import React from "react";
import Styles from "../../Containers/Dashboard/Dashboard.module.scss";
const PanelBoard = ({ RenewelCount, TechniciansScheduled, GoalsCount }) => {
  return (
    <section className={Styles.Dashboard__Panel__Board}>
      <div className="d-flex gap-15 align-items-center">
        <span className="f-w-700 lh-21 f-50 clr-purple-bold">
          {TechniciansScheduled}
        </span>
        <p className="f-18 f-w-600 lh-20 clr-dark-grey">
          Technicians Scheduled
        </p>
      </div>
      <div className="d-flex gap-15 align-items-center">
        <span className="f-w-700 lh-21 f-50 clr-purple-bold">{GoalsCount}</span>
        <p className="f-18 c lh-20 clr-dark-grey f-w-600 text-capitalize">
          Goals being worked on
        </p>
      </div>
    </section>
  );
};

export default PanelBoard;
