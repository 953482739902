import { useEffect, useState } from "react";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import "./DailyDataSheetTabs.scss";
import { useMatch, useParams } from "react-router-dom";
import { Button } from "antd";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { logout } from "../../store/user/userSlice";
import CustomSelect from "../../Containers/Appointment/custom-select";
import { Input, InputNumber } from "antd";
import { greenPostive, redNegetive } from "../../constants/constants";

const AddMands = () => {
  const { id, serviceId } = useParams();
  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const [payload, setPayload] = useState([]);

  const [action, setAction] = useState(false);

  useEffect(() => {
    const getMandsData = async () => {
      try {
        const res = await httpClient.get("api/v1/mands", {
          params: { data_sheet: { id: id } },
        });
        if (res.data?.data.length) {
          setPayload(res.data?.data);
        } else {
          setPayload(payload);
        }
        return true;
      } catch (error) {
        if (
          error?.response?.data?.response?.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );

          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error?.message);

        return false;
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getMandsData();
    }
  }, [id, action]);

  if (!payload?.length) {
    return (
      <div
        className="compilance-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                <ellipse
                  fill="#f5f5f5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fill-rule="nonzero" stroke="#d9d9d9">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="ant-empty-description">No data</div>
        </div>
      </div>
    );
  }
  const mands = [
    { value: "Prompted", key: "promted" },
    { value: "Unprompted", key: "unpromted" },
    { value: "Spontaneous", key: "spontaneous" },
  ];
  return (
    <>
      <div className="compilance-header">
        {payload?.length > 0 &&
          payload?.map((item, index) => {
            return (
              <div className="demand-wrapper" key={index}>
                <ul className="demand-wrapper-ul-mand">
                  <li>
                    <li style={{ minWidth: "120px" }}>
                      <div>
                        <label className="f-15 f-w-600 clr-dark-grey">
                          Mand {index + 1}
                        </label>
                      </div>

                      <TextFieldFormik
                        key={item?.index}
                        placeholder={"Enter Title"}
                        type={"text"}
                        wrapperClassName={"max-w-input-of w-100"}
                        tabIndex={1}
                        name={`title`}
                        disabled={true}
                        values={
                          mands.find((opt) => opt?.key === item?.mand_type)
                            ?.value
                        }
                      />
                    </li>
                  </li>
                  <li style={{ minWidth: "120px" }}>
                    <div>
                      <label className="f-15 f-w-600 clr-dark-grey">
                        Description {index + 1}
                      </label>
                    </div>

                    <TextFieldFormik
                      key={item?.index}
                      placeholder={"Enter Title"}
                      type={"text"}
                      wrapperClassName={"max-w-input-of w-100"}
                      tabIndex={1}
                      name={`title`}
                      handleChange={(e) => {
                        const updatedPayload = [...payload];
                        updatedPayload[index].title = e.target.value;
                        setPayload(updatedPayload);
                      }}
                      disabled={true}
                      values={item?.title || ""}
                    />
                  </li>

                  <li>
                    <Input
                      disabled={true}
                      className="counter-input-abc"
                      style={{ maxWidth: "120px" }}
                      prefix={<span>{redNegetive}</span>}
                      suffix={<span>{greenPostive}</span>}
                      type="number"
                      min={0}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.replace(/^0+/, "");
                        const numericValue = parseInt(trimmedValue);
                        const updatedPayload = [...payload];

                        if (isNaN(numericValue) || numericValue < 0) {
                          setPayload({ ...payload, count: 0 });
                          updatedPayload[index].count = 0;
                          setPayload(updatedPayload);
                        } else {
                          updatedPayload[index].count = numericValue;
                          setPayload(updatedPayload);
                        }
                      }}
                      value={item?.count}
                    />
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
    </>
  );
};
export default AddMands;
