import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import styleClasses from "../../Containers/Appointment/Appointments.module.scss";
import Sc from "../../Containers/Dashboard/Dashboard.module.scss";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import "./Modals.scss";

const AppointmentCancelModal = ({
  singleRowData,
  setAppointEditModal,
  setActionDone,
  actionDone,
  ...props
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(props.modalLoading);
  const [appointmentData, setAppointmentData] = useState();
  const [name, setName] = useState();
  const [error, setError] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");

  const formatTime = (val) => {
    const dateTime = new Date(val);
    const hours = dateTime.getUTCHours();
    const minutes = dateTime.getUTCMinutes();

    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return timeString;
  };

  useEffect(() => {
    const getAppointmentData = async () => {
      setLoading(true);
      try {
        const response = await httpClient.get(
          `api/v1/appointments/${singleRowData?.id}`
        );

        if (response?.data) {
          setAppointmentData({
            appointment: {
              schedule_date: response.data?.schedule_date,
              client_id: response.data?.client_slug || "check-new",
              technician_id: response.data?.technician_slug,
              location: response.data?.location,
              start_time: formatTime(response.data?.start_time),
              end_time: formatTime(response.data?.end_time),
              status: response.data?.status,
            },
          });
          setName({
            client: response.data.client_name,
            technician: response.data.technician_name,
            location: getLocation(response.data.location),
          });
          // setActionDone(!actionDone);
          console.log(response);
          setLoading(false);
        }

        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);
          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        setLoading(false);

        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error);
      }
    };
    if (singleRowData?.id) {
      getAppointmentData();
    }
  }, [props?.show]);

  const handleCancelAppointment = async () => {
    setLoading(true);
    try {
      const response = await httpClient.put(
        `/api/v1/appointments/${singleRowData?.id}/cancel_appointment`,
        { cancellation_reason: cancellationReason }
      );
      if (response.data) {
        createNotification("success", "Apponitment Updated");
        setLoading(false);
        setActionDone(!actionDone);
        setAppointEditModal(false);
      }

      return true;
    } catch (error) {
      if (
        error?.response?.data === "Signature has expired" ||
        error?.response?.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);
        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      setLoading(false);
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error);
    }
  };
  useEffect(() => {
    if (
      appointmentData?.appointment?.schedule_date &&
      appointmentData?.appointment?.schedule_date != "undefined" &&
      appointmentData?.appointment?.client_id &&
      appointmentData?.appointment?.technician_id &&
      appointmentData?.appointment?.location &&
      appointmentData?.appointment?.start_time &&
      appointmentData?.appointment?.end_time
    ) {
      setError("");
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appointmentData]);
  const locationOption = [
    { value: "In Clinic", key: "in_clinic" },
    { value: "Home", key: "in_home" },
  ];
  const getLocation = (val) => {
    const location = locationOption?.find((obj) => obj?.key === val)?.value;
    return location;
  };

  return (
    <Modal
      {...props}
      size={500}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // style={{ width: "362px" }}
    >
      <Spin spinning={loading}>
        <Modal.Body style={{ width: "100%" }}>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Cancel Appointment
            </Modal.Title>
          </Modal.Header>

          <div
            className={styleClasses.Appointments__AddAppointmentSidebar}
            style={{ width: "100%" }}
          >
            <div>
              <TextFieldFormik
                placeholder="Enter cancellation reason"
                label="Description"
                wrapperClassName="w-100 cancellationReasonModal"
                type="textarea"
                handleChange={(e) => setCancellationReason(e.target.value)}
                />
            </div>

            <Button
              onClick={() => {
                handleCancelAppointment();
              }}
              disabled={isDisabled}
              className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button} ${styleClasses.Appointments__AddAppointmentbutton}`}
            >
              Cancel Appointment
            </Button>
          </div>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default AppointmentCancelModal;
