export const questionInfo = [
  {
    id: 505,
    title: "note_recepient_first_name",
    placeholder: "Enter First Name",
    label: "First Name",
    wrapperClassName: "w-32",
    options: [],
    message: "Recipient Info",
    // formater: 4,
    disabled: true,
  },
  {
    id: 506,
    title: "note_recepient_middle_name",
    placeholder: "Enter Middle Name",
    label: "Middle Name",
    wrapperClassName: "w-32",
    options: [],
    formater: 4,
    // disabled: true,
  },
  {
    id: 507,
    title: "note_recepient_last_name",
    placeholder: "Enter Last Name",
    label: "Last Name",
    wrapperClassName: "w-32",
    options: [],
    // formater: 4,
    disabled: true,
  },
  {
    id: 508,
    title: "note_recepient_date_of_birth",
    placeholder: "",
    label: "Date of Birth",
    wrapperClassName: "w-49",
    options: [],
    fieldType: "date",
    birthDate: true,
    disabled: true,
  },
  {
    id: 509,
    title: "note_recepient_insurance_no",
    placeholder: "",
    label: "DOD No. / Insurance No.",
    wrapperClassName: "w-49",
    options: [],
    disabled: true,
  },
  {
    id: 510,
    title: "note_recepient_mailing_address",
    placeholder: "Enter Address",
    label: "Address",
    wrapperClassName: "m-w-100",
    options: [],
    message: "Recipient Mailing Address",
    disabled: true,
  },
  {
    id: 511,
    title: "note_recepient_mailing_city",
    placeholder: "Enter City",
    label: "City",
    disabled: true,

    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },
  {
    id: 512,
    title: "note_recepient_mailing_state",
    placeholder: "Enter State",
    label: "State",
    disabled: true,

    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },
  {
    id: 513,
    title: "note_recepient_mailing_zip_code",
    placeholder: "Enter Zip Code",
    label: "Zip Code",
    wrapperClassName: "w-32",
    disabled: true,
    options: [],
    fieldType: "number",
    disabled: true,
  },
  {
    id: 514,
    title: "note_recepient_mailing_email_address",
    placeholder: "Enter Email Address",
    label: "Email Address",
    wrapperClassName: "m-w-i-half",
    options: [],
    formater: 3,
    disabled: true,
  },
  {
    id: 515,
    title: "note_session_info_start_time",
    placeholder: "Enter Start Time",
    label: "Start Time",
    wrapperClassName: "w-2",
    options: [],
    message: "Session Information",
    fieldType: "text",
    disabled: true,
    formater: 7,
  },
  {
    id: 516,
    title: "note_session_info_end_time",
    placeholder: "Enter End Time",
    label: "End Time",
    wrapperClassName: "w-2",
    options: [],
    fieldType: "text",
    formater: 7,
    disabled: true,
  },
  {
    // id: 517,
    title: "note_session_info_total_time",
    placeholder: "Enter Total Time",
    label: "Total Time",
    wrapperClassName: "w-2",
    options: [],
    disabled: true,
  },
  {
    id: 518,
    title: "note_session_info_date",
    placeholder: "Enter Date",
    label: "Date",
    wrapperClassName: "w-32",
    options: [],
    fieldType: "text",
    disabled: true,
    formater: 10,
  },
  {
    id: 519,
    title: "note_session_info_technician_name",
    placeholder: "Enter Technician",
    label: "Technician",
    wrapperClassName: "m-w-i",
    options: [],
    disabled: true,
  },
  {
    id: 520,
    placeholder: "",
    label: "Location",
    wrapperClassName: "m-w-i",
    title: "note_session_info_location",
    formater: 9,
    disabled: "true",
    options: [],
  },
  // {
  //   id: 521,
  //   title: "note_session_info_participant",
  //   placeholder: "Enter Participant",
  //   label: "Participant",
  //   wrapperClassName: "m-w-i",
  //   options: [],
  //   disabled: true,
  // },
  {
    id: 522,
    placeholder: "",
    label: "Additional Participant relation to patient",
    gridView: true,
    title: "note_session_info_relationship_to_individual",
    options: [
      {
        id: 463,
        tabIndex: 1,
        title: "Parent",
        key: "parent",
      },
      {
        id: 464,
        tabIndex: 2,
        title: "Grand Parent",
        key: "grand_parent",
      },
      {
        id: 465,
        tabIndex: 3,
        title: "Sibling",
        key: "sibling",
      },
      {
        id: 466,
        tabIndex: 4,
        title: "Legal Guardian ,Family Member",
        key: "family_legal_guardian",
      },
      {
        id: 467,
        tabIndex: 5,
        title: "Legal Guardian ,Non Family Member",
        key: "non_family_legal_guardian",
      },
      {
        id: 468,
        tabIndex: 6,
        title: "Other Provider",
        key: "other_provider",
      },
    ],
  },
  {
    id: 523,
    placeholder: "",
    label: "What service was done in this session?",
    title: "note_session_service_unit_code",
    wrapperClassName: "radio-full-width",
    gridView: true,
    options: [
      {
        id: 469,
        tabIndex: 1,
        title: "97151",
        key: "service_97151",
      },
      {
        id: 470,
        tabIndex: 2,
        title: "97152",
        key: "service_97152",
      },
      {
        id: 471,
        tabIndex: 3,
        title: "97153",
        key: "service_97153",
      },
      {
        id: 472,
        tabIndex: 4,
        title: "97154",
        key: "service_97154",
      },
      // {
      //   id: 473,
      //   tabIndex: 5,
      //   title: "97155",
      //   key: "service_97155",
      // },
      {
        id: 474,
        tabIndex: 6,
        title: "97156",
        key: "service_97156",
      },
      {
        id: 475,
        tabIndex: 7,
        title: "97157",
        key: "service_97157",
      },
    ],
  },
  {
    id: 524,
    title: "note_session_service_unit_type",
    placeholder: "Unit Type",
    label: "Unit Type",
    wrapperClassName: "w-100",
    options: [
      {
        id: 473,
        tabIndex: 5,
        title: "Supervision",
        key: "supervision",
      },
      {
        id: 474,
        tabIndex: 6,
        title: "Direct",
        key: "direct",
      },
      {
        id: 475,
        tabIndex: 7,
        title: "Other",
        key: "other",
      },
    ],
  },
  {
    id: 1525,
    title: "session_97155_service_note",
    placeholder: "Session 97155",
    label: "Session 97155",
    wrapperClassName: "w-100",
    options: [
      {
        id: 1473,
        tabIndex: 5,
        title: "Yes",
        key: "yes",
      },
      {
        id: 1474,
        tabIndex: 6,
        title: "No",
        key: "no",
      },
    ],
  },
  {
    id: 525,
    title: "note_session_97155_start_time",
    placeholder: "Enter Start Time",
    label: "Session 97155 Start Time",
    wrapperClassName: "w-32",
    options: [],
    fieldType: "time",
    disabled: false,
    formater: 7,
  },
  {
    id: 525,
    title: "note_session_97155_end_time",
    placeholder: "Enter End Time",
    label: "Session 97155 Start Time",
    wrapperClassName: "w-32",
    options: [],
    fieldType: "time",
    disabled: false,
    formater: 7,
  },
  {
    id: 526,
    title: "note_session_service_supervisor_name",
    placeholder: "Enter Supervisor Name",
    label: "Supervisor Name",
    wrapperClassName: "m-w-i",
    options: [],
    disabled: true,
  },
  {
    id: 527,
    placeholder: "",
    label: "Billing Type",
    title: "note_session_info_billing_type",
    wrapperClassName: "m-w-i",
    disabled: "true",
    gridView: true,
    formater: 11,
    options: [],
  },
  {
    id: 528,
    placeholder: "",
    label: "Service Provider Type",
    title: "note_session_service_provider_type",
    wrapperClassName: "radio-full-width",
    gridView: true,
    options: [
      {
        id: 486,
        tabIndex: 1,
        title: "Behavior Analyst (BCBA)",
        key: "behaviour_analyst",
      },
      {
        id: 487,
        tabIndex: 2,
        title: "Assistant Behavior Analyst (BCaBA)",
        key: "assistant_behaviour_analyst",
      },
      {
        id: 488,
        tabIndex: 3,
        title: "RBT(Registered Behavior Technician)",
        key: "registered_behaviour_technician",
      },

      {
        id: 489,
        tabIndex: 4,
        title: "Behavior Analyst- Doctoral (BCBA-D)",
        key: "behaviour_analyst_doctoral",
      },
    ],
  },
  {
    id: 529,
    placeholder: "",
    label: "Service Phase",
    title: "note_session_service_phase",
    wrapperClassName: "radio-full-width",

    gridView: true,
    options: [
      {
        id: 490,
        tabIndex: 1,
        title: "Assessment",
        key: "assessment",
      },
      {
        id: 491,
        tabIndex: 2,
        title: "Treatment",
        key: "treatment",
      },
      {
        id: 492,
        tabIndex: 3,
        title: "Maintenance",
        key: "maintenance",
      },

      {
        id: 493,
        tabIndex: 4,
        title: "Service Coordination",
        key: "service_coordination",
      },
    ],
  },
  {
    id: 530,
    placeholder: "",
    label: "Location",
    title: "note_session_service_location",
    wrapperClassName: "radio-full-width",

    gridView: true,
    options: [
      {
        id: 494,
        tabIndex: 1,
        title: "Learn Me Clinic",
        key: "learnme_clinic",
      },
      {
        id: 495,
        tabIndex: 2,
        title: "Telehealth",
        key: "telehealth",
      },
      {
        id: 496,
        tabIndex: 3,
        title: "Home",
        key: "home",
      },

      {
        id: 497,
        tabIndex: 4,
        title: "Community",
        key: "community",
      },
      {
        id: 498,
        tabIndex: 5,
        title: "Other",
        key: "other",
      },
    ],
  },
  {
    id: 531,
    placeholder: "",
    label: "Signs / Symbols Observed",
    title: "note_session_symbol_observed",
    wrapperClassName: "radio-full-width",

    gridView: true,
    checkBox: true,
    options: [
      {
        id: 499,
        tabIndex: 1,
        title: "Deficits in Receptive Language",
        key: "deficit_in_receptive_language",
      },
      {
        id: 500,
        tabIndex: 2,
        title: "Deficits in Expressive Language",
        key: "deficit_in_expressive_language",
      },
      {
        id: 501,
        tabIndex: 3,
        title: "Deficit in Community Skills",
        key: "deficit_in_community_skill",
      },

      {
        id: 502,
        tabIndex: 4,
        title: "Deficit in Play Skills",
        key: "deficit_in_play_skill",
      },
      {
        id: 503,
        tabIndex: 5,
        title: "Property Destruction",
        key: "property_destruction",
      },
      {
        id: 504,
        tabIndex: 6,
        title: "Pica",
        key: "pica",
      },
      {
        id: 505,
        tabIndex: 7,
        title: "Deficit in Safety Skills",
        key: "deficit_in_safety_skill",
      },
      {
        id: 506,
        tabIndex: 8,
        title: "Elopement",
        key: "elopment",
      },
      {
        id: 507,
        tabIndex: 9,
        title: "Aggression towards self/others",
        key: "agression_towards_other",
      },
      {
        id: 508,
        tabIndex: 10,
        title: "Deficits in Social Interactions",
        key: "deficit_in_social_interaction",
      },
      {
        id: 509,
        tabIndex: 11,
        title: "Deficit in Motor Skills",
        key: "deficit_in_motor_skills",
      },
      {
        id: 510,
        tabIndex: 12,
        title: "Taking without asking",
        key: "talking_without_asking",
      },
      {
        id: 511,
        tabIndex: 13,
        title: "Restricted Interests",
        key: "restricted_interest",
      },
      {
        id: 512,
        tabIndex: 14,
        title: "Tantrums",
        key: "tantrums",
      },
      {
        id: 513,
        tabIndex: 15,
        title: "Deficit in Adaptive Skills",
        key: "deficit_in_adaptive_skills",
      },
    ],
  },
  {
    id: 532,
    title: "note_session_symbol_observed_other",
    placeholder: "Enter Details",
    label: "Other",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 533,
    placeholder: "",
    label: "Content Of Therapy Session",
    title: "note_session_therapy_content",
    wrapperClassName: "radio-full-width",

    checkBox: true,
    gridView: true,
    options: [
      {
        // id: 514,
        tabIndex: 1,
        title: "Assessment / Observation",
        key: "assessment_observation",
      },
      {
        // id: 515,
        tabIndex: 2,
        title: "Adaptive Skills Training",
        key: "adaptive_skill_training",
      },
      {
        // id: 516,
        tabIndex: 3,
        title: "Parent Training",
        key: "parent_training",
      },
      // {
      //   // id: 517,
      //   tabIndex: 4,
      //   title: "Deficit in Motor Skills",
      //   key: "deficit_in_motor_skills",
      // },
      {
        // id: 518,
        tabIndex: 5,
        title: "Outdoor Time",
        key: "outdoor_time",
      },
      {
        // id: 519,
        tabIndex: 6,
        title: "Potty Training",
        key: "potty_training",
      },
      {
        // id: 520,
        tabIndex: 7,
        title: "Supervison of BT",
        key: "supervison_of_bt",
      },
      {
        // id: 521,
        tabIndex: 8,
        title: "Interaction With Caregivers",
        key: "interaction_with_caregiver",
      },
      {
        // id: 522,
        tabIndex: 9,
        title: "Social Skill Training",
        key: "social_skill_training",
      },
      {
        // id: 523,
        tabIndex: 10,
        title: "Interaction With Peers",
        key: "interaction_with_peer",
      },
      // {
      //   // id: 524,
      //   tabIndex: 11,
      //   title: "Deficit in Motor Skills",
      //   key: "deficit_in_motor_skills",
      // },
      {
        // id: 525,
        tabIndex: 12,
        title: "Community Skill",
        key: "community_skill",
      },
      {
        // id: 526,
        tabIndex: 13,
        title: "Table Time",
        key: "table_time",
      },
      {
        // id: 527,
        tabIndex: 14,
        title: "Record Review",
        key: "record_review",
      },
      {
        // id: 528,
        tabIndex: 15,
        title: "Preference Assessment",
        key: "preference_assessment",
      },
    ],
  },
  {
    id: 534,
    placeholder: "",
    label: "Procedures Used",
    title: "note_session_procedure_used",
    wrapperClassName: "radio-full-width",

    gridView: true,
    checkBox: true,
    options: [
      {
        id: 529,
        tabIndex: 1,
        title: "Skill Aquisition",
        key: "skill_aquisition",
      },
      {
        id: 530,
        tabIndex: 2,
        title: "Behaviour Contract",
        key: "behaviour_contract",
      },
      {
        id: 531,
        tabIndex: 3,
        title: "timer",
        key: "timer",
      },

      {
        id: 532,
        tabIndex: 4,
        title: "FCT",
        key: "fct",
      },
      {
        id: 533,
        tabIndex: 5,
        title: "Visual Aid",
        key: "visual_aid",
      },
      {
        id: 534,
        tabIndex: 6,
        title: "Premack Principal",
        key: "premack_principal",
      },
      {
        id: 535,
        tabIndex: 7,
        title: "Stimulus Prompts",
        key: "stimulus_prompts",
      },
      {
        id: 536,
        tabIndex: 8,
        title: "DTT",
        key: "ddt",
      },
      {
        id: 537,
        tabIndex: 9,
        title: "Antcident Manipulation",
        key: "antcident_manipulation",
      },
      {
        id: 538,
        tabIndex: 10,
        title: "Chaining",
        key: "chaining",
      },
      {
        id: 539,
        tabIndex: 11,
        title: "Differncial Reinforcment",
        key: "differncial_reinforcment",
      },
      {
        id: 540,
        tabIndex: 12,
        title: "Social Skill Acquisition",
        key: "social_skill_acquisition",
      },
      {
        id: 541,
        tabIndex: 13,
        title: "roleplay",
        key: "roleplay",
      },
      {
        id: 542,
        tabIndex: 14,
        title: "Token Board",
        key: "token_board",
      },
      {
        id: 543,
        tabIndex: 15,
        title: "Self Monitor",
        key: "self_monitor",
      },
      {
        id: 544,
        tabIndex: 16,
        title: "Errorless Learning",
        key: "errorless_learning",
      },
      {
        id: 545,
        tabIndex: 17,
        title: "Net",
        key: "net",
      },
      {
        id: 546,
        tabIndex: 18,
        title: "Video Modeling",
        key: "video_modeling",
      },
      {
        id: 547,
        tabIndex: 19,
        title: "Shaping",
        key: "shaping",
      },
      {
        id: 548,
        tabIndex: 20,
        title: "Self Managment",
        key: "self_managment",
      },
    ],
  },
  {
    id: 535,
    placeholder: "",
    label:
      "Beneficiary response to others (for programs involving parent/sibling/peer interaction)",
    title: "note_program_parent_sibbling_interaction",
    wrapperClassName: "radio-full-width",

    gridView: true,
    options: [
      {
        id: 549,
        tabIndex: 1,
        title: "Positive",
        key: "positive",
      },
      {
        id: 550,
        tabIndex: 2,
        title: "Negative",
        key: "negative",
      },
      {
        id: 551,
        tabIndex: 3,
        title: "No Response",
        key: "no_response",
      },
    ],
  },
  {
    id: 536,
    placeholder: "",
    label:
      "Beneficiary response to intervention used throughout the session (check one)",
    title: "note_beneficiary_response_to_intervention",
    wrapperClassName: "radio-full-width",

    gridView: true,
    options: [
      {
        id: 552,
        tabIndex: 1,
        title: "Positive",
        key: "positive",
      },
      {
        id: 553,
        tabIndex: 2,
        title: "Negative",
        key: "negative",
      },
      {
        id: 554,
        tabIndex: 3,
        title: "No Response",
        key: "no_response",
      },
    ],
  },
  {
    id: 537,
    placeholder: "",
    label: "Beneficiary showing progress towards goals in the targeted areas",
    title: "note_beneficiary_goal_progress",
    gridView: true,
    checkBox: true,
    options: [
      {
        id: 555,
        tabIndex: 1,
        title: "Communication",
        key: "communication",
      },
      {
        id: 556,
        tabIndex: 2,
        title: "Behaviour",
        key: "behaviour",
      },
      {
        id: 557,
        tabIndex: 3,
        title: "Social",
        key: "social",
      },
      {
        id: 558,
        tabIndex: 4,
        title: "Adaptive",
        key: "adaptive",
      },
      {
        id: 559,
        tabIndex: 5,
        title: "Cognitive",
        key: "cognitive",
      },
      {
        id: 560,
        tabIndex: 6,
        title: "Academic",
        key: "academic",
      },
      {
        id: 561,
        tabIndex: 7,
        title: "Vocational",
        key: "vocational",
      },
      {
        id: 562,
        tabIndex: 8,
        title: "Motor",
        key: "motor",
      },
    ],
  },
  {
    id: 538,
    title: "note_degree_of_program_toward_tratment_outcome",
    placeholder: "",
    label:
      "DEGREE OF PROGRESS TOWARDS TREATMENT GOALS AND OUTCOME OF TREATMENT",
    options: [],
  },
  {
    id: 539,
    title: "goal_target",
    placeholder: "Enter Goal / Target",
    label: "Goal / Target",
    wrapperClassName: "w-32",
    options: [],
    first: true,
    disabled: true,
  },
  {
    id: 540,
    title: "trials",
    placeholder: "Enter Trials",
    label: "Trials",
    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },
  {
    id: 541,
    title: "data",
    placeholder: "Enter Data",
    label: "% Data",
    wrapperClassName: "w-32",
    options: [],
    last: true,
    disabled: true,
  },
  {
    id: 542,
    title: "note_spont_matts",
    placeholder: "Enter # Spont. Mands",
    label: "# Spont. Mands",
    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },
  {
    id: 543,
    title: "note_compliance_percentage",
    placeholder: "Enter % Compliance",
    label: "% Compliance",
    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },

  {
    id: 544,
    title: "note_accept_no_percentage",
    placeholder: "Enter % Accept No",
    label: "% Accept No",
    wrapperClassName: "w-32",
    options: [],
    disabled: true,
  },
  {
    id: 635,
    title: "abc_behaviours_section",
    options: [],
    answers: [],
  },
  {
    id: 545,
    title: "note_antecedent",
    placeholder: "Enter Antecedent",
    label: "Antecedent",
    wrapperClassName: "w-32",
    options: [],
    first: true,
    formater: 8,
    disabled: true,
  },
  {
    id: 546,
    title: "note_behaviour",
    placeholder: "Enter Behaviour",
    label: "Behaviour",
    wrapperClassName: "w-32",
    options: [],
    formater: 8,
    disabled: true,
  },
  {
    id: 547,
    title: "note_consequence",
    placeholder: "Enter Consequence",
    label: "Consequence",
    wrapperClassName: "w-32",
    formater: 8,
    options: [],
    last: true,
    disabled: true,
  },
  {
    id: 548,
    title: "note_session_summary",
    placeholder: "Enter Details",
    textarea: true,
    label:
      "Session Summary: Using objective language, please describe your session. Are there any areas of concern, or any comments related to behavior reduction or skill acquisition goals?",
    wrapperClassName: "w-100",
    options: [],
    inputType: true,
  },
];
