import { logout } from "../store/user/userSlice";
import { createNotification } from "./createNotifications";

export const handleErrorResponse = (error) => {
  const showErrorNotification = async (message) => {
    createNotification("error", message);
  };

  if (
    error?.response?.data === "Signature has expired" ||
    error?.response?.status === 401
  ) {
    showErrorNotification("Please login again your session has expired");
    localStorage.removeItem("persist:root");
    logout();
    setTimeout(() => {
        window.open("/dashboardlogin", "_self");
    }, 2500);
    return true;
  }
  const ErrorMessage = error?.response?.data?.response?.error || error?.response?.data?.message
  showErrorNotification(ErrorMessage);
  console.log("Something went wrong", error?.message);
  return false;
};
