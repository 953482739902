import React, { useState } from "react";
import sc from "./Dashboard.module.scss";
import DashboardPanel from "./DashboardPanel";
import DashboardSideBar from "./DashboardSideBar";
import { GetCurrentUser } from "../../utils/utilAuth";
import TechnicianDahaboard from "../TechnicianDahaboard/TechnicianDahaboard";
import TechPanel from "./TechPanel";
const Dashboard = () => {
  const [isView, setIsView] = useState("");
  const user = GetCurrentUser();

  return (
    <section className={sc.Dashboard}>
      <DashboardSideBar
        CurrentActive={isView}
        setdashboardView={(view) => {
          setIsView(view);
        }}
        isView={isView}
      />
      {user.role === "director" ? (
        <DashboardPanel dashboardView={isView} setdashboardView={setIsView} />
      ) : (
        <TechPanel dashboardView={isView} setdashboardView={setIsView} />
      )}
    </section>
  );
};

export default Dashboard;
