export const questionInfo = [
  {
    id: 505,
    title: "check_out_patient_name",
    placeholder: "Enter Patient Name",
    label: "Patient Name",
    wrapperClassName: "w-32",
    tabIndex: 1,
    firstInput: true,
    disabled: true,
    options: [],
  },
  {
    id: 506,
    title: "check_out_therapist_name",
    placeholder: "Enter Therapist Name",
    label: "Therapist Name",
    wrapperClassName: "w-32",
    options: [],
    tabIndex: 2,
    disabled: true,

    // disabled: true,
  },
  {
    id: 507,
    title: "check_out_date",
    placeholder: "",
    label: "Check Out Date",
    wrapperClassName: "w-32",
    fieldType: "text",
    tabIndex: 3,
    disabled: true,
    formater: 10,
    options: [],
  },
  // {
  //   id: 508,
  //   title: "check_in_time",
  //   placeholder: "",
  //   label: "Check In Time",
  //   wrapperClassName: "w-32",
  //   options: [],
  //   tabIndex: 4,
  //   fieldType: "time",
  // },
  {
    id: 509,
    title: "check_out_location",
    placeholder: "Enter Location",
    label: "Location",
    tabIndex: 5,
    disabled: true,
    formater: 9,
    wrapperClassName: "w-32",
    options: [],
  },

  {
    id: 520,
    placeholder: "",
    label: "Overall Behaviour",
    title: "check_out_overall_behaviour",
    wrapperClassName: "w-100",
    options: [
      {
        id: 558,
        tabIndex: 6,
        title: "Outstanding",
        key: "outstanding",
      },
      {
        id: 559,
        tabIndex: 7,
        title: "Great",
        key: "great",
      },
      {
        id: 560,
        tabIndex: 8,
        title: "good",
        key: "good",
      },
      {
        id: 560,
        tabIndex: 9,
        title: "Need Help",
        key: "need_help",
      },
    ],
  },
  {
    id: 521,
    title: "check_out_behaviour_addit_text",
    placeholder: "Enter Behaviour Text",
    label: "Additional Behaviour Text",
    tabIndex: 10,
    wrapperClassName: "w-32",
    options: [],
  },
  {
    id: 522,
    placeholder: "",
    label: "Bathroom",
    title: "bathroom",
    wrapperClassName: "w-100",
    options: [
      {
        id: 464,
        tabIndex: 9,
        title: "Went In Toilet",
        key: "went_in_toilet",
      },
      {
        id: 465,
        tabIndex: 10,
        title: "Sat On Toilet",
        key: "sat_on_toilet",
      },
      {
        id: 466,
        tabIndex: 11,
        title: "Was Wet",
        key: "was_wet",
      },
      {
        id: 467,
        tabIndex: 12,
        title: "Had A Bm",
        key: "had_a_bm",
      },
    ],
  },
  {
    id: 522,
    title: "check_out_bathroom_beh_addit_text",
    placeholder: "Enter Bathroom Behaviour Text",
    label: "Additional Bathroom Behaviour Text",
    tabIndex: 10,
    wrapperClassName: "w-32",
    options: [],
  },
  {
    id: 523,
    placeholder: "",
    label: "Eating Behaviour",
    title: "check_out_eating_behaviour",
    wrapperClassName: "w-100",
    options: [
      {
        id: 467,
        tabIndex: 12,
        title: "All Food",
        key: "all_food",
      },
      {
        id: 468,
        tabIndex: 13,
        title: "Most Of Food",
        key: "most_of_food",
      },
      {
        id: 469,
        tabIndex: 14,
        title: "Some Of Food",
        key: "some_of_food",
      },
      {
        id: 470,
        tabIndex: 14,
        title: "Didn't Eat Today",
        key: "didn't eat_today",
      },
    ],
  },
  {
    id: 524,
    title: "check_out_eating_beh_addit_text",
    placeholder: "Enter Eating Behaviour Text",
    label: "Additional Eating Behaviour Text",
    tabIndex: 10,
    wrapperClassName: "w-32",
    options: [],
  },
  {
    id: 525,
    placeholder: "",
    label: "Eating Transitioned",
    title: "check_out_eating_transitioned",
    wrapperClassName: "w-100",
    options: [
      {
        id: 470,
        tabIndex: 15,
        title: "Independently",
        key: "independently",
      },
      {
        id: 471,
        tabIndex: 16,
        title: "With Minimal Support",
        key: "with_minimal_support",
      },
      {
        id: 472,
        tabIndex: 17,
        title: "With Some Guidance",
        key: "with_some_guidance",
      },
      {
        id: 473,
        tabIndex: 17,
        title: "Need Help",
        key: "need_help",
      },
    ],
  },
  {
    id: 526,
    title: "check_out_transitioned_addit_text",
    placeholder: "Enter Transitioned Additional text",
    label: "Transitioned Additional text",
    wrapperClassName: "m-w-i",
    tabIndex: 18,
    options: [],
  },
  {
    id: 527,
    title: "check_out_child_comm_progress",
    placeholder: "",
    label: "Communication",
    wrapperClassName: "w-32",
    firstOption: true,
    firstOptionLabel: "I am Progressing",
    optionalChecked: true,
    // tabIndex: 18,
    options: [],
  },

  {
    id: 528,
    title: "check_out_progress_communication",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 529,
    title: "check_out_child_beh_progress",
    placeholder: "",
    label: "Behaviour",
    wrapperClassName: "w-32",
    optionalChecked: true,
    options: [],
  },
  {
    id: 530,
    title: "check_out_progress_behaviour",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 531,
    title: "check_out_child_social_progress",
    placeholder: "",
    label: "Social",
    wrapperClassName: "w-32",
    optionalChecked: true,
    options: [],
  },
  {
    id: 532,
    title: "check_out_progress_social",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 533,
    title: "check_out_child_adaptive_progress",
    placeholder: "",
    label: "Adaptive",
    wrapperClassName: "w-32",
    optionalChecked: true,
    options: [],
  },
  {
    id: 534,
    title: "check_out_progress_adaptive",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 535,
    title: "check_out_child_motor_progress",
    placeholder: "",
    label: "Motor",
    wrapperClassName: "w-32",
    optionalChecked: true,
    options: [],
  },
  {
    id: 536,
    title: "check_out_progress_motor",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 537,
    title: "check_out_participation_with_peers",
    placeholder: "",
    label: "With Peers",
    wrapperClassName: "w-32",
    optionalChecked: true,
    firstOption: true,
    firstOptionLabel: "Participation Was Positive",
    options: [],
  },
  {
    id: 538,
    title: "participation_with_peers",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 539,
    title: "check_out_participation_with_therapist",
    placeholder: "",
    label: "With Therapist",
    wrapperClassName: "w-32",
    optionalChecked: true,
    options: [],
  },
  {
    id: 540,
    title: "participation_with_therapist",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 541,
    title: "check_out_participation_session_interventions",
    placeholder: "",
    label: "For Interventions Used Throughout The Session",
    wrapperClassName: "m-w-i",
    optionalChecked: true,
    options: [],
  },
  {
    id: 542,
    title: "participation_session_interventions",
    placeholder: "Details...",
    label: "",
    wrapperClassName: "m-w-i",
    options: [],
  },
  {
    id: 543,
    title: "check_out_special_practice_with_therapist",
    placeholder: "Details...",
    label: "Worked/Practice With Therapist",
    wrapperClassName: "w-49",
    options: [],
  },
  {
    id: 544,
    title: "check_out_special_practice_enjoyed",
    placeholder: "Details...",
    label: "Enjoyed / Liked",
    wrapperClassName: "w-49",
    options: [],
  },
  {
    id: 545,
    title: "check_out_addit_comment_notes",
    placeholder: "Enter Details",
    label: "Additional Notes / Comments (BT/RBT)",
    wrapperClassName: "w-100",
    options: [],
  },
  {
    id: 545,
    title: "check_out_to_do_at_home",
    placeholder: "Enter Details",
    label: "Things To Work On At Home (BCBA)",
    wrapperClassName: "w-100",
    options: [],
  },
];
