import React, { useEffect, useState } from "react";
import Styles from "../../Containers/Dashboard/Dashboard.module.scss";
import { CalenderIcon, arrowDownBlack } from "../../constants/constants";
import ImageView from "../../components/Image/ImageView";
import DummyImage from "../../assets/images/user-profile.png";
import UpcomingSessionListing from "./UpcomingSessionListing";

import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
const GoalsDurationOptions = [
  { value: "weekly", label: "7 Days" },
  { value: "monthly", label: "1 Month" },
];
const RightSideBar = ({ setdashboardView }) => {
  const [upComingSessions, setUpComingSessions] = useState();
  const [filter, setFilter] = useState("weekly");
  const [metaData, setMetaData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    const getUpcomingSessions = async () => {
      try {
        httpClient
          .get(`/api/v1/appointments/fetch_upcoming_sessions?filter=${filter}`)
          .then((response) => {
            setUpComingSessions(response?.data?.data);
            setMetaData(response.data.meta);
            setCurrentPage(response?.data.meta.current_page);
          });
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getUpcomingSessions();
  }, [filter]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // minWidth: "200px",
        width: "450px",
        maxWidth: "500px",
        background: "#fff",
        // padding: "0 30px",
      }}
    >
      <div
        className={Styles.Dashboard__Panel__RightSidebar___hideOverflow}
        style={{ overflowY: "none !important", padding: "0 30px" }}
      >
        <div className={Styles.Dashboard__Panel__RightSidebar_Header}>
          <h3 className="f-18 lh-25 f-w-700 clr-dark-grey">Sessions</h3>
          <div className="cursor-pointer d-flex gap-10 align-items-center">
            <span className="f-14 f-w-600 lh-30 clr-grey-blur  d-block">
              
              <Select
                suffixIcon={arrowDownBlack}
                defaultValue={GoalsDurationOptions[0].label}
                style={{ width: 130, height: 38 }}
                onChange={(e) => {
                  setCurrentPage(1);
                  setFilter(e);
                }}
                options={GoalsDurationOptions}
                className={"DashbaordCardSelect"}
                popupClassName="DashbaordCardSelect"
              />
            </span>{" "}
            <span
              onClick={() => {
                navigate("/appointments");
                setdashboardView("Appointments");
              }}
            >
              {CalenderIcon}
            </span>
          </div>
        </div>
      </div>
      <UpcomingSessionListing
        Day="Today"
        setFilter={setFilter}
        filter={filter}
        Sessions={upComingSessions}
        metaData={metaData}
        setMetaData={setMetaData}
        setUpComingSessions={setUpComingSessions}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default RightSideBar;
