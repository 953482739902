import React, { useEffect, useState } from "react";
import RadioButtons from "../../../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import { TextFieldFormik } from "../../../form-controls/input/text.field.formik";
import { basicSelectionOption } from "../../../../constants/constants";
import InnerHeader from "../../InnerHeader";
import { Form, Formik, FormikProvider, useFormik } from "formik";
import InnerFooter from "../../InnerFooter";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import { createNotification } from "../../../../helpers/createNotifications";

const GeneralInformationForm = ({ next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 1,
      placeholder: "Complete Name",
      label: "Your Name",
      title: "general_name",
      formater: 4,
      tabIndex: 1,
      firstInput: true,
    },
    {
      id: 2,
      placeholder: "",
      label: "Relationship to the Child:",
      title: "general_child_relationship",
      options: [
        {
          id: Math.random(),

          title: "Parent",
          key: "parent",
          tabIndex: 2,
        },
        {
          id: Math.random(),

          title: "Legal Guardian, Family Member",
          key: "legal_guardian_family_member",
          tabIndex: 3,
        },
        {
          id: Math.random(),
          tabIndex: 4,
          title: "Grandparent",
          key: "grand_parent",
        },
        {
          id: Math.random(),
          tabIndex: 5,
          title: "Legal Guardian, Non Family Member",
          key: "legal_guardian_non_family_member",
        },
        {
          id: Math.random(),
          tabIndex: 6,
          title: "Other Provider",
          key: "other_provider",
        },
      ],
      gridView: true,
    },
    {
      id: 3,
      placeholder: "",
      label: "How did you hear of ABA Agency:",
      title: "gerneral_agency_source",
      options: [
        {
          id: Math.random(),
          tabIndex: 7,
          title: "Friend",
          key: "friend",
        },
        {
          id: Math.random(),
          tabIndex: 8,
          title: "Digital Ads",
          key: "digital_ads",
        },
        {
          id: Math.random(),
          tabIndex: 9,
          title: "Clinician",
          key: "clinician",
        },
        {
          id: Math.random(),
          tabIndex: 10,
          title: "Other",
          key: "other",
        },
      ],
      gridView: false,
    },
    {
      id: 4,
      placeholder: "Details...",
      label: "What was your reason for referral?",
      title: "general_referal_reason",
      inputType: true,
      tabIndex: 11,
    },
    {
      id: 5,
      placeholder: "",
      label: "Has your child received ABA or behavioural therapy before?",
      title: "general_therapy_recieved",
      options: [
        {
          id: Math.random(),
          tabIndex: 11,
          title: "Yes",
          key: "yes",
        },
        {
          id: Math.random(),
          tabIndex: 12,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 6,
      placeholder: "Details...",
      label: "If yes, when and why?",
      title: "general_therapy_recieved_desc",
      inputType: true,
      childInput: true,
      tabIndex: 13,
    },
    {
      id: 7,
      placeholder: "Details...",
      label: "Initial Parent/Caregiver/LBA Goals for the ISP",
      title: "general_initial_goals",
      inputType: true,
      tabIndex: 14,
    },
    {
      id: 8,
      placeholder: "Details...",
      label: "Requested Services",
      title: "general_requested_services",
      options: [
        {
          id: Math.random(),
          tabIndex: 15,
          title: "ABA in Clinic",
          key: "aba_in_the_clinic",
        },
        {
          id: Math.random(),
          tabIndex: 16,
          title: "ABA in Home - Telehealth Consultation",
          key: "aba_in_home",
        },
      ],
      gridView: false,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/general/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get("api/v1/sections/general");
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();

          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        // console.log("Something Went wrong", error);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/general/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            idCheck={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default GeneralInformationForm;
