import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { createNotification } from "../../helpers/createNotifications";
// import { setCurrentUser } from "../../store/user/userSlice";
// import LoginForm from "../../features/auth/login.form";
// import "./login.css";
// import { login } from "../../services/apis/authService";
// import {
//   signInWithFacebook,
//   signInWithGoogle,
// } from "../../utils/firebase.utils";
// import { ReactComponent as GoogleSvg } from "../../assets/svg/google.svg";
// import { ReactComponent as FacebookSvg } from "../../assets/svg/facebook.svg";
import MultiStepperForm from "../../components/multiStepperForm/multiStepperForm";

const OnBoardingPage = () => {
  return (
    <Fragment>
      <MultiStepperForm />
    </Fragment>
  );
};

export default OnBoardingPage;
