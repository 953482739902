import { useEffect, useState } from "react";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import { createNotification } from "../../../../helpers/createNotifications";

const BirthInformation = ({ data, prev, next, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 142,
      title: "birth_info_mother_parental_care",
      placeholder: "",
      label: "Did the birth mother receive regular prenatal care?",
      wrapperClassName: "",
      options: [
        {
          id: 102,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 103,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 143,
      title: "birth_info_pregnancy_complication",
      placeholder: "",
      label: "Were there any complications with the pregnancy?",
      wrapperClassName: "",
      options: [
        {
          id: 104,
          title: "Yes",
          key: "yes",
        },
        {
          id: 105,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 144,
      title: "birth_info_pregnancy_complication_desc",
      placeholder: "Details...",
      label: "If yes, please explain below.",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 145,
      title: "birth_info_term",
      placeholder: "",
      label: "Was birth at full term?",
      wrapperClassName: "",
      options: [
        {
          id: 106,
          title: "Yes",
          key: "yes",
        },
        {
          id: 107,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 146,
      title: "birth_info_term_desc",
      placeholder: "Details...",
      label: "If no, please provide details:",
      wrapperClassName: "",
      options: [],
      inputType: true,
      descType: "no",
    },
    {
      id: 147,
      title: "birth_info_delivery_question_type",
      placeholder: "",
      label: "What was the type of delivery?",
      wrapperClassName: "",
      options: [
        {
          id: 108,
          title: "Spontaneous",
          key: "spontaneous",
        },
        {
          id: 109,
          title: "Induced Vaginal",
          key: "induced_vaginal",
        },
        {
          id: 110,
          title: "C-Section",
          key: "c_section",
        },
      ],
    },
    {
      id: 148,
      title: "birth_info_delivery_complications",
      placeholder: "",
      label: "Were there any complications during delivery?",
      wrapperClassName: "",
      options: [
        {
          id: 111,
          title: "Yes",
          key: "yes",
        },
        {
          id: 112,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 149,
      title: "birth_info_delivery_complications_desc",
      placeholder: "Details...",
      label:
        "If yes, please describe the complications below and treatment details.",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 150,
      title: "birth_info_child_lbs",
      placeholder: "Weight in lbs.",
      topLabel: "What was your child/adolescent’s birth weight?",
      label: "lbs.",
      wrapperClassName: "w-40 d-flex align-items-center  double-field",
      options: [],
      fieldType: "number",
      formater: 6,
    },
    {
      id: 151,
      title: "birth_info_child_oz",
      placeholder: "Weight in oz.",
      label: "oz.",
      wrapperClassName: "w-40 d-flex align-items-center double-field",
      options: [],
      fieldType: "number",
      formater: 6,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/birth-information/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/birth-information"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/birth-information/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};
export default BirthInformation;
