import React, { useState } from "react";
import ClientDailySessions from "./ClientDailySessions";
import Styles from "./Dashboard.module.scss";
import ProfileSetting from "./ProfileSetting";

import AddObjectives from "../../components/forms/AddObjectives";
import CheckInForm from "../../components/forms/CheckInForm/CheckInForm";
import DailyDataSheet from "../../components/forms/DailyDataSheet";
import DailyDataSheetDetail from "../../components/forms/DailyDataSheetDetail/DailyDataSheetDetail";
import TechnicianDahaboard from "../TechnicianDahaboard/TechnicianDahaboard";
import ClientTechListing from "./ClientTechListing";
// import DailyDataSheet from "../../components/forms/DailyDataSheet";
import CheckOutForm from "../../components/forms/CheckOutForm/CheckOutForm";
import styleClasses from "../../components/forms/DailyDataSheet.module.scss";

const TechPanel = ({ dashboardView, setdashboardView }) => {
  const [panelView, setPanelView] = useState(dashboardView);
  const setPanelViewHandler = (view) => {
    setdashboardView(view);
    setPanelView(view);
  };

  return (
    <section className={Styles.Dashboard__Panel}>
      {dashboardView === "panel" && (
        <TechnicianDahaboard setPanelViewHandler={setPanelViewHandler} />
      )}
      {dashboardView === "ProfileSetting" && (
        <ProfileSetting setdashboardView={setdashboardView} />
      )}

      {dashboardView === "sessionCompleted" && (
        <ClientTechListing
          View={"monthly"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="panel"
          ClientSessions={true}
        />
      )}
      {dashboardView === "pendingsessions" && (
        <ClientTechListing
          View={"pendingsessions"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="panel"
          pendingSession={true}
        />
      )}
      {dashboardView === "goalsObjective" && (
        <section className={styleClasses.MainDataSheetPage}>
          <div className={styleClasses.MainDataSheetPage__Content}>
            <div className={styleClasses.MainDataSheetPage__SheetHeader}>
              <div>
                <h3
                  className={styleClasses.MainDataSheetPage__DailydataHeading}
                >
                  Add Objectives
                </h3>
              </div>
            </div>
            <div className={styleClasses.MainDataSheetPage__MainTabs}>
              <AddObjectives
                // View={"pendingsessions"}
                data={null}
                onlyObjective={true}
                setdashboardView={setdashboardView}
                showAlertModal={true}
              />
            </div>
          </div>
        </section>
      )}
      {dashboardView === "datasheet" && (
        <DailyDataSheet setdashboardView={setdashboardView} />
      )}
      {dashboardView === "checkin" && (
        <CheckInForm setdashboardView={setdashboardView} />
      )}
      {dashboardView === "checkout" && (
        <CheckOutForm setdashboardView={setdashboardView} />
      )}

      {dashboardView === "serviceNote" && (
        <DailyDataSheetDetail setdashboardView={setdashboardView} />
      )}
      {dashboardView === "yourClients" && (
        <ClientTechListing
          View={"yourclients"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          YourClients={true}
        />
      )}
      {dashboardView === "clientGoals" && (
        <ClientDailySessions
          Panel={true}
          View={"ViewGoals"}
          tech={true}
          setdashboardView={setdashboardView}
          assignGoal={true}
          tabOf={"client"}
        />
      )}
      {dashboardView === "yourclientsingle" && (
        <ClientTechListing
          View={"yourclientsingle"}
          setdashboardView={setdashboardView}
          dashboardView={dashboardView}
          //   AllSessions={true}
          tabOf="client"
          clientSessionsTech={true}
        />
      )}

      {/* {dashboardView === "panel" && <RightSideBar />}
      {dashboardView === "Clients" && (
        <ClientsListing
          RightSidebar={true}
          View={
            panelView === "Clients" || dashboardView === "Clients"
              ? "unarchived"
              : panelView
          }
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="client"
          // ClientSessions={true}
        />
      )}
      {dashboardView === "Technicians" && (
        <ClientsListing
          RightSidebar={true}
          View={
            panelView === "Technicians" || dashboardView === "Technicians"
              ? "unarchived"
              : panelView
          }
          setdashboardView={setdashboardView}
          AllSessions={true}
          tabOf="technician"
          // ClientSessions={true}
        />
      )}
      {dashboardView === "AllSessions" && (
        <ClientsListing
          RightSidebar={true}
          View={panelView}
          setdashboardView={setdashboardView}
          AllSessions={true}
        />
      )}
      {dashboardView === "ProfileSetting" && <ProfileSetting />}
      {dashboardView === "Client" && (
        <Client
          setdashboardView={setdashboardView}
          View={dashboardView}
        ></Client>
      )}
      {dashboardView === "Technician" && (
        <Technican
          setdashboardView={setdashboardView}
          View={dashboardView}
        ></Technican>
      )}
      {dashboardView === "ViewGoals" && (
        <ClientDailySessions
          Panel={true}
          View={"ViewGoals"}
          setdashboardView={setdashboardView}
          ClientSessions={true}
        />
      )}
      {dashboardView === "AssignGoal" && (
        <ClientDailySessions
          Panel={true}
          View={"AssignGoal"}
          setdashboardView={setdashboardView}
          assignGoal={true}
        />
      )}
      {dashboardView === "DailySessionView" && (
        <ClientDailySessions
          setdashboardView={setdashboardView}
          Panel={true}
          View={"DailySessionView"}
        />
      )}
      {dashboardView === "DailySessionView" && (
        <ClientDailySessions
          setdashboardView={setdashboardView}
          Panel={true}
          View={"DailySessionView"}
        />
      )}
      {dashboardView === "Appointments" && <Appointments />} */}
      {/* {dashboardView === "form" && */}
      {/* <DailyDataSheet /> */}
      {/* } */}
      {/* {dashboardView === "form" && <TechnicianDahaboard />} */}
      {/* <DailyDataSheet/> */}
    </section>
  );
};

export default TechPanel;
