import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./Modals.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import dayjs from "dayjs";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { Spin } from "antd";
import { logout } from "../../store/user/userSlice";
import { useParams } from "react-router-dom";

const GoalModal = (props) => {
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(props.modalLoading);
  const [data, setData] = useState();
  const handleAssignGoal = async () => {
    // /api/v1/goals/A4/assign_goal?user_id=kamran-khalid'
    try {
      if (data) {
        const res = await httpClient.put(
          `/api/v1/goals/${props?.singleRowData?.RefNo}/assign_goal?user_id=${
            props.userId || id
          }`,
          {
            ...data,
          }
        );
        if (res.data) {
          createNotification("success", "Goal Assigned");
          // setAddClientModalView(false);
          props?.setAction?.(!props?.action);
          setLoading(false);
          props.setAssignGoalModalView(false);
        }
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error?.response?.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );

        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      setLoading(false);
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);

      return false;
    }
  };
  useEffect(() => {
    if (!props?.show) {
      setIsDisabled(true);
    }
  }, [props?.show]);
  useEffect(() => {
    if (
      props.AssignGoal &&
      data?.goal_user_mapping?.start_date?.length &&
      data?.goal_user_mapping?.end_date?.length &&
      data?.goal_user_mapping?.acquisition_criteria?.trim()?.length &&
      data?.goal_user_mapping?.description?.trim()?.length
    ) {
      setIsDisabled(false);
    } else if (
      props.AddGoal &&
      data?.goal?.title?.length &&
      data?.goal?.reference_no?.length
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data]);

  const handleAddGoal = async () => {
    try {
      if (data) {
        const res = await httpClient.post(`/api/v1/goals/`, {
          ...data,
        });
        if (res.data) {
          createNotification("success", "Goal Added");
          setLoading(false);
          props?.setAction?.(!props?.action);
          props.setAddGoalModalView(false);
          return true;
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );

        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      setLoading(false);

      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);

      return false;
    }
  };

  return (
    <Modal
      {...props}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin spinning={loading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              {props.AddGoal ? "Add New" : "Assign"} Goal
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-30 pt-32 w-100">
            {props.AddGoal && (
              <TextFieldFormik
                placeholder="A-10"
                label="Goal Label"
                wrapperClassName="w-100"
                type="text"
                handleChange={(e) => {
                  setData({
                    goal: {
                      ...data?.goal,
                      reference_no: e.target.value,
                    },
                  });
                }}
              />
            )}
            {props.AssignGoal ? (
              <TextFieldFormik
                placeholder="Enter Goal Title"
                label="Goal Title"
                wrapperClassName="w-100"
                type="text"
                disabled
                values={props.AssignGoal && props.singleRowData?.goalTitle}
              />
            ) : (
              <TextFieldFormik
                placeholder="Enter Goal Title"
                label="Goal Title"
                wrapperClassName="w-100"
                type="text"
                handleChange={(e) => {
                  setData({
                    goal: {
                      ...data?.goal,
                      title: e.target.value,
                    },
                  });
                }}
              />
            )}

            {props.AssignGoal && (
              <TextFieldFormik
                placeholder="Self Reliance"
                label="Acquisition Criteria"
                wrapperClassName="w-100"
                type="text"
                handleChange={(e) => {
                  if (e.target.value?.length > 0) {
                    props.AssignGoal
                      ? setData({
                          goal_user_mapping: {
                            ...data?.goal_user_mapping,
                            acquisition_criteria: e.target.value.trim(),
                          },
                        })
                      : setData({
                          goal: {
                            ...data?.goal,
                            acquisition_criteria: e.target.value.trim(),
                          },
                        });
                  }
                }}
              />
            )}
            {props.AssignGoal && (
              <div className="d-flex w-100 gap-20 w-100">
                <DatePickerLM
                  placeholder={"Select Start Date"}
                  WrapperClassName="w-100"
                  label={"Start Date"}
                  // defaultValue={props?.clientProfile?.date_of_birth || ""}
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;
                    setData({
                      goal_user_mapping: {
                        ...data?.goal_user_mapping,
                        start_date: formattedDate,
                      },
                    });
                  }}
                  futureDate={true}
                />

                <DatePickerLM
                  placeholder="Select End Date"
                  WrapperClassName="w-100"
                  label={"End Date"}
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;

                    setData({
                      goal_user_mapping: {
                        ...data?.goal_user_mapping,
                        end_date: formattedDate,
                      },
                    });
                  }}
                  futureDate={true}
                />
              </div>
            )}
            <TextFieldFormik
              placeholder="Enter Details"
              label="Description"
              wrapperClassName="w-100"
              type="textarea"
              handleChange={(e) => {
                props.AssignGoal
                  ? setData({
                      goal_user_mapping: {
                        ...data?.goal_user_mapping,
                        description: e.target.value,
                      },
                    })
                  : setData({
                      goal: {
                        ...data?.goal,
                        description: e.target.value,
                      },
                    });
              }}
            />
            <div className="w-100 d-flex justify-content-end">
              {props.AssignGoal ? (
                <Button
                  disabled={isDisabled}
                  onClick={() => handleAssignGoal()}
                  className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 mt-30 `}
                >
                  Assign Goal
                </Button>
              ) : (
                <Button
                  disabled={isDisabled}
                  onClick={() => handleAddGoal()}
                  className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 mt-30 `}
                >
                  Add Goal
                </Button>
              )}
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default GoalModal;
