import { useState, useRef, useEffect } from "react";
import Styles from "../../Containers/Dashboard/Dashboard.module.scss";
import Notification from "./Notification";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { logout } from "../../store/user/userSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import { GetCurrentUser } from "../../utils/utilAuth";
const NotificationTab = ({
  Notifications,
  refr,
  setActionDone,
  actionDone,
  unreadNotification,
  currentPage,
  setCurrentPage,
  setNotificationData,
  setMetaData,
  metaData,
  handleReadNotification,
}) => {
  const wrapperRef = useRef(refr);
  const user = GetCurrentUser();
  // const handleReadNotification = async () => {
  //   try {
  //     if (unreadNotification) {
  //       const res = await httpClient.get(
  //         `/api/v1/notifications/mark_as_completed?id=${
  //           user.role === "technician" ? user.slug : "learn-me-admin"
  //         }`
  //       );
  //       if (res.data) {
  //         // createNotification("success", "Read");
  //         // setCurrentPage(1);
  //         // setActionDone(!actionDone);
  //       }
  //     }
  //     return true;
  //   } catch (error) {
  //     if (
  //       error?.response?.data?.response.error === "Signature has expired" ||
  //       error?.response?.status === 401
  //     ) {
  //       createNotification(
  //         "error",
  //         "Please login again your session has been expired"
  //       );
  //       localStorage.removeItem(`persist:root`);

  //       logout();
  //       window.open("/dashboardlogin ", "_self");
  //       return;
  //     }
  //     console.log(error);
  //     createNotification("error", error?.response?.data?.response.error);
  //     return false;
  //   }
  // };

  const fetchMoreNotification = () => {
    const getNotificationsData = async () => {
      try {
        const response = await httpClient.get(
          `api/v1/notifications?id=${
            user?.role === "technician" ? user?.slug : "learn-me-admin"
          }&page=${currentPage + 1}&per_page=10`
        );
        setNotificationData([...Notifications, ...response?.data?.data]);
        setMetaData(response.data.meta);
        setCurrentPage(currentPage + 1);
        return response;
      } catch (error) {
        if (
          error.response?.data === "Signature has expired" ||
          error.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error?.message);
      }
    };
    getNotificationsData();
  };

  return (
    <>
      <div
        ref={refr}
        className={`animate-op ${Styles.Dashboard__NotificationsTab}`}
      >
        {!!!Notifications?.length ? (
          <div
            className="compilance-header"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                    <ellipse
                      fill="#f5f5f5"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g fill-rule="nonzero" stroke="#d9d9d9">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        fill="#fafafa"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="ant-empty-description">No data</div>
            </div>
          </div>
        ) : (
          <ul className={Styles.Dashboard__Notifications}>
            <p
              className="f-16 f-w-600 lh-24 clr-purple-sec pe-24 pb-10 cursor-pointer	text-end"
              onClick={() => handleReadNotification()}
            >
              Clear all
            </p>
            <InfiniteScroll
              dataLength={Notifications?.length ?? 10}
              next={() => fetchMoreNotification()}
              hasMore={
                metaData?.current_page === metaData?.total_pages ? false : true
              }
              height={"92vh"}
              width={"100%"}
              loader={
                <Spin
                  size="small"
                  style={{ position: "absolute", left: "50%" }}
                />
              }
            >
              {Notifications?.map((notification) => {
                return (
                  <Notification
                    read={notification?.is_read}
                    id={notification?.id}
                    note={notification?.notification_type}
                    title={notification?.content}
                    timeAgo={notification?.created_at}
                    setActionDone={setActionDone}
                    actionDone={actionDone}
                  />
                );
              })}
            </InfiniteScroll>
          </ul>
        )}
      </div>
      <div className="custom-backdrop"></div>
    </>
  );
};

export default NotificationTab;
