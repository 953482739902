import CreateUser from "../features/user/create.users";
import UserList from "../features/user/user.list";
import Dashboard from "../pages/dashboard/dashboard";
import onBoardingPage from "../pages/onBoardingForm/onboardingForm";

const routeObjects = [
  // {
  //   path: "clientForm/onboardingform",
  //   component: onBoardingPage,
  // },
  // { path: "/dashboard", component: Dashboard },
  // {
  //   path: '/user/create',
  //   component: CreateUser
  // },{
  //   path: '/user/list',
  //   component: UserList
  // }
];

export default routeObjects;
