import React, { useState, useEffect } from "react";
import "./CountDown.scss";

const FormCountDown = ({ onCompletion, submissiontime }) => {
  const [seconds, setSeconds] = useState(submissiontime);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      onCompletion(); // Call the function on completion of 2 minutes
    }
  }, [seconds, onCompletion]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="countdown-container">
      <p className="countdown-text">Time Remaining: <b> {formatTime(seconds)}</b></p>
    </div>
  );
};

export default FormCountDown;
