import React from "react";
import { Button } from "react-bootstrap";
import { arrowRightWhite, arrowleftOrange } from "../../constants/constants";
import LoaderSpinner from "../general/loader/loader.spinner";
import { logout } from "../../store/user/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
const InnerFooter = ({
  nextFormHandler,
  prevFormHandler,
  loading,
  disabled,
  saveDraft,
  next,
  last,
  start,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="onboarding-forms-section-footer"
      style={{ justifyContent: start ? "end" : "space-between" }}
    >
      {!start && (
        <Button
          className="lm-form-btn lm-form-btn-secondary lm-form-btn-secondary-draft"
          onClick={saveDraft}
        >
          Save Draft
        </Button>
      )}
      <div className="d-flex gap-20">
        {prevFormHandler && (
          <Button
            onClick={prevFormHandler}
            className="lm-form-btn lm-form-btn-previous"
          >
            {arrowleftOrange}
          </Button>
        )}
        {loading ? (
          <Button className="lm-form-btn lm-form-btn-primary">
            <LoaderSpinner color="white" />
          </Button>
        ) : (
          <Button
            onClick={() => {
              nextFormHandler();
            }}
            className="lm-form-btn lm-form-btn-primary"
            disabled={disabled}
          >
            {last ? "Submit" : start ? "Start" : "Next"}
            {arrowRightWhite}
          </Button>
        )}
      </div>
    </div>
  );
};

export default InnerFooter;
