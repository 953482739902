import { Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import styleClasses from "../../Containers/Appointment/Appointments.module.scss";
import CustomSelect from "../../Containers/Appointment/custom-select";
import Sc from "../../Containers/Dashboard/Dashboard.module.scss";
import { timeSlotsEnd, timeSlotsStart } from "../../constants/clientdata";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import "./Modals.scss";

const AppointmentEditModal = ({
  singleRowData,
  setAppointEditModal,
  setActionDone,
  actionDone,
  ...props
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(props.modalLoading);
  const [appointmentData, setAppointmentData] = useState();
  const [name, setName] = useState();
  const [error, setError] = useState("");

  const formatTime = (val) => {
    const dateTime = new Date(val);
    const hours = dateTime.getUTCHours();
    const minutes = dateTime.getUTCMinutes();

    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return timeString;
  };

  useEffect(() => {
    const getAppointmentData = async () => {
      setLoading(true);
      try {
        const response = await httpClient.get(
          `api/v1/appointments/${singleRowData?.id}`
        );

        if (response?.data) {
          setAppointmentData({
            appointment: {
              schedule_date: response.data?.schedule_date,
              client_id: response.data?.client_slug || "check-new",
              technician_id: response.data?.technician_slug,
              location: response.data?.location,
              modifier_code: response.data?.modifier_code,
              start_time: formatTime(response.data?.start_time),
              end_time: formatTime(response.data?.end_time),
            },
          });
          setName({
            client: response.data.client_name,
            technician: response.data.technician_name,
            location: getKeyValue(locationOption, response.data.location),
            modifier_code: getKeyValue(modifierCodeOptions,response.data.modifier_code),
          });
          // setActionDone(!actionDone);
          setLoading(false);
        }

        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);
          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        setLoading(false);

        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error);
      }
    };
    if (singleRowData?.id) {
      getAppointmentData();
    }
  }, [props?.show]);

  const formatedDateTime = fetchFormattedDateTime();

  const handleEditAppointment = async () => {
    setLoading(true);
    try {
      const response = await httpClient.put(
        `api/v1/appointments/${singleRowData?.id}?date_time=${formatedDateTime}`,
        {
          appointment: {
            ...appointmentData.appointment,
            start_time: `${appointmentData.appointment.schedule_date}T${appointmentData.appointment.start_time}Z`,
            end_time: `${appointmentData.appointment.schedule_date}T${appointmentData.appointment.end_time}Z`,
          },
        }
      );
      if (response.data) {
        createNotification("success", "Apponitment Updated");
        setLoading(false);
        setActionDone(!actionDone);
        setAppointEditModal(false);
      }

      return true;
    } catch (error) {
      if (
        error?.response?.data === "Signature has expired" ||
        error?.response?.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);
        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      setLoading(false);
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error);
    }
  };
  useEffect(() => {
    if (
      appointmentData?.appointment?.schedule_date &&
      appointmentData?.appointment?.schedule_date != "undefined" &&
      appointmentData?.appointment?.client_id &&
      appointmentData?.appointment?.technician_id &&
      appointmentData?.appointment?.location &&
      appointmentData?.appointment?.start_time &&
      appointmentData?.appointment?.end_time &&
      appointmentData?.appointment?.modifier_code
    ) {
      setError("");
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [appointmentData]);

  const locationOption = [
    { value: "In Clinic", key: "in_clinic" },
    { value: "Home", key: "in_home" },
  ];

  const modifierCodeOptions = [
    { value: "HO", key: "ho" },
    { value: "Tech", key: "tech" },
  ];

  const getKeyValue = (options, val) => {
    const value = options?.find((obj) => obj?.key === val)?.value;
    return value;
  };

  const onSelectValue = (key, value) => {
    if (appointmentData?.appointment?.end_time && key === "start_time") {
      if (value < appointmentData?.appointment?.end_time) {
        setAppointmentData({
          appointment: {
            ...appointmentData?.appointment,
            [key]: value,
          },
        });
        setError("");
      } else {
        setAppointmentData({
          appointment: {
            ...appointmentData?.appointment,
            [key]: value,
            end_time: "",
          },
        });
        // createNotification(
        //   "error",
        //   "Start time should be earlier then End time"
        // );
        setError("Start time should be earlier then End time");
      }
    } else {
      setAppointmentData({
        appointment: { ...appointmentData?.appointment, [key]: value },
      });
    }
  };
  const filterTimeSlots = (time) => {
    const filteredTimeSlots = timeSlotsEnd.filter((slot) => slot.key > time);
    return filteredTimeSlots;
  };
  return (
    <Modal
      {...props}
      size={500}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // style={{ width: "362px" }}
    >
      <Spin spinning={loading}>
        <Modal.Body style={{ width: "100%" }}>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Update Appointment
            </Modal.Title>
          </Modal.Header>

          <div
            className={styleClasses.Appointments__AddAppointmentSidebar}
            style={{ width: "100%" }}
          >
            <div>
              <div
                className={styleClasses.Appointments__AddAppointmentdateinput}
                style={{ width: "280px", margin: "0 auto" }}
              >
                <DatePickerLM
                  label={"Date"}
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;
                    setAppointmentData({
                      appointment: {
                        ...appointmentData?.appointment,
                        schedule_date: `${formattedDate}`,
                      },
                    });
                  }}
                  futureDate={true}
                  value={appointmentData?.appointment?.schedule_date || ""}
                  allowClear={false}
                ></DatePickerLM>
              </div>
              <CustomSelect
                label={"Select Client"}
                role="client"
                placeholder={"Client name"}
                handleValue={(value) => onSelectValue("client_id", value)}
                field={"client_slug"}
                fieldValue={appointmentData?.appointment?.client_id}
                name={name?.client}
              />
              <CustomSelect
                label={"Select Technician"}
                role="technician"
                placeholder={"Technician name"}
                handleValue={(value) => onSelectValue("technician_id", value)}
                field={"technician_slug"}
                fieldValue={appointmentData?.appointment?.technician_id}
                name={name?.technician}
              />
              <CustomSelect
                label={"Location"}
                simple={true}
                options={locationOption}
                placeholder={"Location"}
                handleValue={(value) => onSelectValue("location", value)}
                field={"location"}
                fieldValue={appointmentData?.appointment?.location}
                name={name?.location}
              />

              <CustomSelect
                label={"Start Time"}
                simple={true}
                options={timeSlotsStart}
                placeholder={"00:00 AM"}
                handleValue={(value) => onSelectValue("start_time", value)}
                field={"start_time"}
                fieldValue={appointmentData?.appointment?.start_time}
                time={true}
              />
              {error && (
                <p
                  style={{
                    color: "#E31616",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {error}
                </p>
              )}
              <CustomSelect
                label={"End Time"}
                simple={true}
                options={filterTimeSlots(
                  appointmentData?.appointment?.start_time
                )}
                appointmentData={appointmentData}
                endTime={true}
                placeholder={"00:00 PM"}
                handleValue={(value) => onSelectValue("end_time", value)}
                fieldValue={appointmentData?.appointment?.end_time}
                time={true}
              />

              <CustomSelect
                label={"Modifier code"}
                placeholder={"Modifier code"}
                handleValue={(value) => onSelectValue("modifier_code", value)}
                options={modifierCodeOptions}
                fieldValue={appointmentData?.appointment?.modifier_code}
                simple={true}
                name={name?.modifier_code}
                objectives={false}
                time={false}
              />
            </div>

            <Button
              onClick={() => {
                handleEditAppointment();
              }}
              disabled={isDisabled}
              className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button} ${styleClasses.Appointments__AddAppointmentbutton}`}
            >
              Update Appointment
            </Button>
          </div>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default AppointmentEditModal;
