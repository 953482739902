import { Input } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import React from "react";

const NewPasswordForm = ({ setCreds, creds }) => {
  return (
    <div className="w-100 d-flex flex-column ">
      <h4 className="forgot-password-title mb-12 ">Create a new password</h4>
      <p className="forgot-password-desc mb-24">
        Please choose your new password.
      </p>
      <div className="w-100 mb-24">
        <label className={`lm-input-form-input-label form-label `}>
          Password
        </label>
        <Input.Password
          placeholder="******"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          className="lm-search-input"
          onChange={(e) =>
            setCreds({
              user: {
                ...creds?.user,
                password: e.target.value,
              },
            })
          }
        />
      </div>
      <div className="w-100 mb-24">
        <label className={`lm-input-form-input-label form-label `}>
          Confirm Password
        </label>
        <Input.Password
          placeholder="******"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          className="lm-search-input"
          onChange={(e) =>
            setCreds({
              user: {
                ...creds?.user,
                password_confirmation: e.target.value,
              },
            })
          }
        />
      </div>
      <p style={{ fontSize: "15px", marginBottom: "10px" }}>
        Password Must Contain:
        <ul
          style={{
            fontSize: "10px",
            listStyle: "circle",
            marginLeft: "20px",
          }}
        >
          <li>Atleast 1 capital letter</li>
          <li>Atleast 1 lower letter</li>
          <li>Atleast 1 specail character</li>
          <li>Atleast 1 number digit</li>
        </ul>
      </p>
    </div>
  );
};

export default NewPasswordForm;
