import React, { useEffect, useState } from "react";
import "../../components/CheckEmail/CheckEmail.scss";
import mail from "../../assets/svg/mail-01.svg";
import dummyImage from "../../assets/images/lm-auth-featured.svg";
import dummyImageTop from "../../assets/images/image-4.svg";
import { TableDataArchive } from "./../../constants/clientdata";
import { persistReducer } from "redux-persist";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";

const PasswordSuccess = () => {
  const location = useLocation();
  const propsReceived = location.state;
  const navigate = useNavigate();

  return (
    <section className="checkemail-section">
      <img className="checkemail-image" src={dummyImage} alt="top" />
      <img className="checkemail-image-top" src={dummyImageTop} alt="bottom" />

      <div className="checkemail-wrapper">
        <div className="check-your-email">
          <span className="email-icon">
            <img src={mail} alt="icon" />
          </span>
          <h3 className="check-your-email-heading">
            Password reset successfully
          </h3>
          <p className="check-your-email-reset-link">
            Your password has been successfully reset.
            <br /> Click <span>"Continue"</span> to log in magically
          </p>
          <button
            className="email-btn pointer"
            style={{ cursor: "pointer !important" }}
            onClick={() => navigate("/dashboardlogin")}
          >
            Continue
          </button>
        </div>
      </div>
    </section>
  );
};

export default PasswordSuccess;
