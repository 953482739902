import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";

const InstitudeAndServices = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 87,
      title: "institue_school_list",
      placeholder: "",
      label:
        "List all schools your child has attended in order starting with the most current school.",
      wrapperClassName: "w-100",
      options: [],
    },
    {
      id: 88,
      title: "institue_school_name",
      placeholder: "Complete Name",
      label: "School Name",
      wrapperClassName: "w-4",
      options: [],
      first: true,
      firstInput: true,
      tabIndex: 1,
    },
    {
      id: 89,
      title: "institue_school_system",
      placeholder: "Enter School System",
      label: "School System",
      wrapperClassName: "w-4",
      options: [],
    },
    {
      id: 90,
      title: "institue_school_year",
      placeholder: "0-12",
      label: "Years",
      wrapperClassName: "w-8",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 91,
      title: "institue_school_grade",
      placeholder: "0-12",
      label: "Grade",
      wrapperClassName: "w-8",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 92,
      title: "institue_education_services",
      placeholder: "Complete Name",
      label: "Special Education Services",
      wrapperClassName: "w-4",
      options: [],
      last: true,
    },
    {
      id: 93,
      title: "instiute_special_service_classified",
      placeholder: "",
      label: "Currently classified for special education services",
      wrapperClassName: "w-100",
      options: [
        {
          id: 48,
          title: "Yes",
          key: "yes",
        },
        {
          id: 49,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 94,
      title: "institute_education_plan",
      placeholder: "",
      label: "Does he/she have an Individualized Education Plan (IEP)?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 50,
          title: "Yes",
          key: "yes",
        },
        {
          id: 51,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 95,
      title: "institue_child_aide",
      placeholder: "",
      label: "Does the child have a 1:1 aide at school?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 52,
          title: "Yes",
          key: "yes",
        },
        {
          id: 53,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 96,
      title: "institute_speech_occupational_services",
      placeholder: "",
      label:
        "Is the child receiving Speech and/or Occupational Therapy services at school?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 54,
          title: "Yes",
          key: "yes",
        },
        {
          id: 55,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 97,
      title: "institute_speech_occupational_service_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/institute-services/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/institute-services"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);

        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/institute-services/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default InstitudeAndServices;
