import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";

const SuicideAndSelfHarm = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 179,
      title: "suicide_ideations",
      placeholder: "",
      label: "Does the child have any suicidal ideations?",
      wrapperClassName: "",
      options: [
        {
          id: 154,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 155,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 180,
      title: "suicide_ideations_desc",
      placeholder: "Details...",
      label: "If so, please explain (including frequency):",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 181,
      title: "suicide_attempted",
      placeholder: "",
      label: "Has the child ever attempted suicide before?",
      wrapperClassName: "",
      options: [
        {
          id: 156,
          title: "Yes",
          key: "yes",
        },
        {
          id: 157,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 182,
      title: "suicide_attempted_desc",
      placeholder: "Details...",
      label: "If so, please explain (including frequency):",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 183,
      title: "homocidal_ideation",
      placeholder: "",
      label: "Has the child ever had homicidal ideations?",
      wrapperClassName: "",
      options: [
        {
          id: 158,
          title: "Yes",
          key: "yes",
        },
        {
          id: 159,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 184,
      title: "homocidal_ideation_desc",
      placeholder: "Details...",
      label: "If so, please explain (including frequency):",
      wrapperClassName: "",
      options: [],
      inputType: true,
      message:
        "**If the child has any suicidal or homicidal ideations then he/she needs a crisis/safety plan and/or may need to refer for assessment to the Community Services Board.**",
    },
    {
      id: 185,
      title: "child_mood_range",
      placeholder: "",
      label: "Is the child’s mood in the normal range?",
      wrapperClassName: "",
      options: [
        {
          id: 160,
          title: "Yes",
          key: "yes",
        },
        {
          id: 161,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 186,
      title: "child_mood_range_desc",
      placeholder: "Details...",
      label: "If not, please explain:",
      wrapperClassName: "",
      options: [],
      descType: "no",
      inputType: true,
    },
    {
      id: 187,
      title: "intellectual_impairments",
      placeholder: "",
      label: "Does the child have intellectual impairments?",
      wrapperClassName: "",
      options: [
        {
          id: 162,
          title: "Yes",
          key: "yes",
        },
        {
          id: 163,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 188,
      title: "intellectual_impairments_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/suicide-self-harm/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/suicide-self-harm"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/suicide-self-harm/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};
export default SuicideAndSelfHarm;
