import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import RadioButtons from "../../../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import SwitchCheckbox from "../../../form-controls/input/Switch/SwitchCheckbox";
import { TextFieldFormik } from "../../../form-controls/input/text.field.formik";
import { Button } from "react-bootstrap";
import { plusIconBlack } from "../../../../constants/constants";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import { createNotification } from "../../../../helpers/createNotifications";
import httpClient from "../../../../services/axios";

const InsuranceInformation = ({ next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 9,
      title: "insurance_funding_source",
      placeholder: "",
      label: "What is your Service Funding Source?",
      wrapperClassName: "",
      options: [
        {
          id: 14,
          title: "Insurance",
          key: "insurance",
          firstInput: true,
          tabIndex: 1,
        },
        {
          id: 15,
          title: "Self-Pay",
          key: "self_pay",
          tabIndex: 2,
        },
        {
          id: 16,
          title: "Other",
          key: "other",
          tabIndex: 3,
        },
      ],
    },
    {
      id: 10,
      title: "insurances_names",
      placeholder: "",
      label: "Do you have health Insurance? please list insurances",
      // wrapperClassName: "w-",
      options: [],
      // tabIndex: 4,
    },
    {
      id: 11,
      title: "insurance_name_input",
      placeholder: "Name of Insurance",
      label: "Name of Insurance",
      wrapperClassName: "w-4",
      options: [],
      first: true,
      tabIndex: 4,
    },
    {
      id: 12,
      title: "insurance_policy_number",
      placeholder: "Enter Policy No.",
      label: "Insurance Policy No",
      wrapperClassName: "w-4",
      options: [],
      tabIndex: 5,
    },
    {
      id: 13,
      title: "insurance_subscriber_name",
      placeholder: "Complete Name",
      label: "Subscriber Name",
      wrapperClassName: "w-4",
      options: [],
      tabIndex: 6,
    },
    {
      id: 14,
      title: "insurance_subscriber_dob",
      placeholder: "dd/mm/yyyy",
      label: "Subscriber Date of Birth",
      wrapperClassName: "w-4",
      options: [],
      fieldType: "date",
      birthDate: true,
      tabIndex: 7,
    },
    {
      id: 15,
      title: "medical_insurance_no",
      placeholder: "0000-0000-0000",
      label: "Medicaid/Insurance No.",
      wrapperClassName: "m-w-i-s",
      options: [],
      formater: 1,
      fieldType: "number",
      tabIndex: 8,
      last: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/insurance-information/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/insurance-information"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/insurance-information/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res?.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            setAction={setAction}
            action={action}
            postData={postData}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default InsuranceInformation;
