import { useEffect, useState } from "react";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import { createNotification } from "../../../../helpers/createNotifications";
import httpClient from "../../../../services/axios";

const DrugAndAlcoholProfile = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 230,
      title: "drug_substance_abuse_problem",
      placeholder: "",
      label:
        "Does the child have any substance abuse problems (including drugs, alcohol or prescription drug abuse)?",
      wrapperClassName: "",
      options: [
        {
          id: 208,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 209,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 231,
      title: "drug_substance_abuse_issue",
      placeholder: "",
      label:
        "Are there any substance abuse issues (including drugs, alcohol, or prescription drug abuse) within the child’s family?",
      wrapperClassName: "",
      options: [
        {
          id: 210,
          title: "Yes",
          key: "yes",
        },
        {
          id: 211,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 232,
      title: "drug_substance_abuse_drug_list",
      placeholder: "List here...",
      label: "If so, please list the drugs being used:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 233,
      title: "drug_substance_abuse_issue2_desc",
      placeholder: "Details...",
      label: "If so, please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 234,
      title: "drug_substance_often_used",
      placeholder: "Details...",
      label:
        "Has treatment been sought (when and where). How often are substances being used and for how long?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/drug-alcohol-profile/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/drug-alcohol-profile"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error.response?.data?.error[0]);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/drug-alcohol-profile/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default DrugAndAlcoholProfile;
