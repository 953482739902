import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";
import { filter, get } from "lodash";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import TableView from "../../components/TableViews/TableView";
import DatePickerLM from "../../components/form-controls/input/DatePicker/DatePickerLM";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";
import styleSheet from "./Dashboard.module.scss";
import moment from 'moment'; 

const ClientBillingDetails = ({ tabOf, setdashboardView }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState({});
  const [lastRequestedFilters, setLastRequestedFilters] = useState({});
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [getData, setGetData] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [totalAmount, setTotalAmount] = useState('0');

  const filterDatesSeleted =
    get(filterParams, "start_date") && get(filterParams, "end_date");

  const BillingDataEmty = billingData?.data?.length === 0;

  const getDefaultDates = () => {
    const currentDate = moment();
    
    const startDate = currentDate.clone().subtract(1, 'week').startOf('week');
    const endDate = currentDate.clone().subtract(1, 'week').endOf('week');
    
    const formattedStartDate = startDate.format('YYYY-MM-DD');
    const formattedEndDate = endDate.format('YYYY-MM-DD');
    
    return {
      defaultStartDate: formattedStartDate,
      defaultEndDate: formattedEndDate
    };
  };

  const { defaultStartDate, defaultEndDate } = getDefaultDates();

  useEffect(() => {
    const getBillingData = async ({ page }) => {
      setLoading(true);
      const queryParams = {
        page: page,
        per_page: 10,
        start_date: filterParams.start_date || defaultStartDate,
        end_date: filterParams.end_date || defaultEndDate,
        client_id: filterParams.client_id || "",
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `/api/v1/appointment_billings/?${queryString}`
        );
        if (res?.data) {
          setBillingData(res?.data);
          setTotalAmount(res?.data?.total_amount);
          setLastRequestedFilters(filterParams);
        } else {
          createNotification("error", "Unable to fetch Billings");
        }
        setLoading(false);
        return true;
      } catch (error) {
        setLoading(false);
        handleErrorResponse(error);
      }
    };
    if (filterDatesSeleted || (defaultStartDate &&  defaultEndDate)) {
      getBillingData({
        page: currentPage,
      });
    }
  }, [currentPage, getData, actionDone]);

  useEffect(() => {
    setCurrentPage(1);
  }, [getData]);

  // useEffect(() => {
  // }, [billingData]);

  const generateBilling = async () => {
    setLoading(true);
    const current_date = fetchFormattedDateTime();
    try {
      const res = await httpClient.post(`/api/v1/appointment_billings`, {
        current_date: current_date,
      });
      createNotification("success", res?.data?.message);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (csvData.length !== 0) {
      document.getElementById("csv-link").click();
    }
  }, [dataFetched]);

  const getCsvData = async () => {
    setLoading(true);
    try {
      const res = await httpClient.get(
        `/api/v1/appointment_billings/generate_csv`,
        {
          params: {
            start_date: lastRequestedFilters.start_date || defaultStartDate,
            end_date: lastRequestedFilters.end_date || defaultEndDate,
            client_id: lastRequestedFilters.client_id,
          },
        }
      );
      if (res?.data) {
        setCsvData(res?.data?.data);
        setDataFetched(!dataFetched);
        setLoading(false);
      } else {
        createNotification("error", "Unable to fetch CSV Data");
      }
      return true;
    } catch (error) {
      handleErrorResponse(error);
      setLoading(false);
    }
  };  

  return (
    <section className={`${styleSheet.ClientBillingContainer}`}>
      <div className={`${styleSheet.ClientBillingFilters}`}>
        <div className={`${styleSheet.ClientBillingFilters_filterItem}`}>
          <DatePickerLM
            label={"Start Date"}
            defaultValue={defaultStartDate}
            onChange={(date) => {
              const formattedDate = date
                ? dayjs(date).format("YYYY-MM-DD")
                : null;
              setFilterParams({ ...filterParams, start_date: formattedDate });
            }}
          ></DatePickerLM>
          <span className={`${styleSheet.filterStatement}`}>{"*Required"}</span>
        </div>
        <div className={`${styleSheet.ClientBillingFilters_filterItem}`}>
          <DatePickerLM
            label={"End Date"}
            defaultValue={defaultEndDate}
            onChange={(date) => {
              const formattedDate = date
                ? dayjs(date).format("YYYY-MM-DD")
                : null;
              setFilterParams({ ...filterParams, end_date: formattedDate });
            }}
          ></DatePickerLM>
          <span className={`${styleSheet.filterStatement}`}>{"*Required"}</span>
        </div>
        <div
          className={`${styleSheet.ClientBillingFilters_filterItem_clientId}`}
        >
          <TextFieldFormik
            label="Client ID"
            placeholder="Enter Client ID"
            type="text"
            // values={{ title: "title" }}
            handleChange={(e) =>
              setFilterParams({ ...filterParams, client_id: e.target.value })
            }
          />
        </div>
        <div className={`${styleSheet.clientBillingButtons}`}>
          <Button
            disabled={loading}
            onClick={() => {
              setGetData(!getData);
            }}
            className={`lm-form-btn lm-form-btn-primary f-16 mt-25`}
            style={{ width: "140px" }}
          >
            Fetch Data
          </Button>

          <Button
            disabled={BillingDataEmty || loading}
            onClick={() => {
              getCsvData();
            }}
            className={`lm-form-btn lm-form-btn-primary f-16  mt-25`}
            style={{ width: "150px" }}
          >
            Generate CSV
          </Button>

          <CSVLink
            id="csv-link"
            data={csvData}
            filename={"client-billing-details.csv"}
            style={{ display: "none" }}
          />

          <Button
            style={{
              backgroundColor: "#ffffff",
              borderColor: "#f6891f",
              color: "#f6891f",
              borderRadius: "5px",
              width: "150px",
              height: "44px",
            }}
            onClick={() => {
              generateBilling();
            }}
            className={`lm-form-btn  f-16 mt-25`}
            icon={<DownloadOutlined />}
            size={"large"}
          >
            Today's Sync
          </Button>
        </div>
        <div className={`${styleSheet.ClientBillingFilters_totalAmountDiv}`}>
          <div className={`${styleSheet.ClientBillingFilters_totalAmountHeadingDiv}`}>
            <p>Total Amount</p>
          </div>
          <div className={`${styleSheet.ClientBillingFilters_totalAmountValueDiv}`}>
            <p className={`${styleSheet.ClientBillingFilters_totalAmountValue}`}>{totalAmount} $</p>
          </div>
        </div>
      </div>
      <section className={`${styleSheet.ClientBilling}`}>
        <TableView
          setdashboardView={setdashboardView}
          Pagination={true}
          View={"ClientBilling"}
          tableData={billingData?.data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          tabOf={tabOf}
          loading={loading}
          actionDone={actionDone}
          setActionDone={setActionDone}
          metaData={billingData?.meta}
          setBillingData={setBillingData}
        />
      </section>
    </section>
  );
};

export default ClientBillingDetails;
