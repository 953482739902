import { useNavigate } from "react-router-dom";
import flagicon from "../../../assets/svg/Flagicon.svg";

const PendingApproval = ({ pendingApprovalData, setPanelViewHandler }) => {
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  const getLetter = (name) => {
    const words = name?.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words?.length >= 1) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1]?.[0]?.toUpperCase() || "";
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return extractedLetters;
  };
  return (
    <div className="Pending-Approval-Sessions-outer">
      <div className="Pending-Approval-Sessions-heading">
        <h3 className="Pending-Approval-Sessions-title">
          Sessions Pending Approval{" "}
        </h3>
        <div className="Pending-Approval-Sessions-linkouter">
          {!!pendingApprovalData?.length && (
            <a
              className="Pending-Approval-Sessions-link"
              onClick={() => {
                navigate("/techdashboard/pendingsessions");
                setPanelViewHandler("pendingsessions");
              }}
            >
              View all
            </a>
          )}
        </div>
      </div>
      <div className="Approval-Sessions-card-outer">
        {pendingApprovalData?.map((data, index) => {
          return (
            index < 3 && (
              <div
                className="Approval-session-card"
                key={data?.id || Math.random()}
              >
                <div className="Approval-card-icon-outer">
                  <div className="Approval-card-icon">
                    <p>{getLetter(data?.client_name)}</p>
                  </div>
                  <div className="Approval-name-age">
                    <p className="Approval-name-joe">{data?.client_name}</p>
                    <span className="Approval-age-year">
                      {data?.age || 0} years
                    </span>
                  </div>
                </div>
                <div className="flag-date-outer">
                  <img src={flagicon} />
                  <span className="span-date">
                    {formatDate(data?.schedule_date)}
                  </span>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default PendingApproval;
