import React, { useEffect, useState } from "react";
import Sc from "./Dashboard.module.scss";
import { Breadcrumb, Input, Select, Spin } from "antd";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import RadioButtons from "../../components/form-controls/input/RadioButtonsSection/RadioButtons";
import TableView from "../../components/TableViews/TableView";
import TabsView from "../../components/TabView/TabsView";
import PanelBoard from "../../components/PanelBoard/PanelBoard";
import { Button } from "react-bootstrap";

import {
  plusIconWhite,
  arrowLeftPurple,
  arrowRightBreadcrumb,
  arrowDownBlack,
} from "../../constants/constants";
import PermissionModal from "../../components/Modals/PermissionModal";
import { TableDataClient } from "../../constants/clientdata";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import LoaderSpinner from "../../components/general/loader/loader.spinner";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "./Dashboard.module.scss";
import AddClientModal from "../../components/Modals/AddClientModal";
import { logout } from "../../store/user/userSlice";
import AddUnitsModal from "../../components/Modals/AddUnitsModal";

const UnitListing = ({
  ClientSessions,
  AllSessions,
  View,
  setdashboardView,
  RightSidebar,
  dashboardView,
  Panel = false,
  tabOf,
  units,
  director,
}) => {
  const navigate = useNavigate();
  const [addClientModalView, setAddClientModalView] = useState(false);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [clientCreds, setClientCreds] = useState();
  const [loading, setIsLoading] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [currentView, setCurrentView] = useState(View);
  const [sideBarData, setSideBarData] = useState();
  const { id } = useParams();

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        setIsLoading(true);
        const getTableData = async ({ page, user_id }) => {
          const date = new Date();
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          const queryParams = {
            page,
            per_page: 10,
            user_id,
            filter: "as_of_today",
            current_date: `${year}-${month}-${day}`,
          };
          const queryString = new URLSearchParams(queryParams).toString();
          // /api/v1/units/client_units_info?user_id=humayun-client&filter=as_of_today'
          // setTableData(TableDataClient);
          try {
            const response = await httpClient.get(
              `api/v1/units/client_units_info/?${queryString}`
            );
            if (response.data) {
              setTableData(response?.data);
              setIsLoading(false);
            }

            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              setIsLoading(false);
              localStorage.removeItem(`persist:root`);

              logout();
              window.open("/dashboardlogin", "_self");
              return;
            } else if (
              error.message ===
              "Cannot read properties of null (reading 'user')"
            ) {
              window.open("/dashboardlogin", "_self");
            }
            setIsLoading(false);
            createNotification("error", error?.response?.data?.response?.error);
            console.log("Something Went wrong", error);
          }

          setIsLoading(false);
        };

        if (id) {
          getTableData({
            page: currentPage,
            user_id: id,
          });
        }
      },
      searchText ? 1000 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, actionDone]);

  const getActiveTab = (tab) => {
    setCurrentView(tab);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentView]);
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await httpClient.get(
  //         `/api/v1/users/user_listing_summary`,
  //         { params: { role: tabOf } }
  //       );
  //       // setTableData(response.data);
  //       setSideBarData(response.data);
  //       setIsLoading(false);
  //       return true;
  //     } catch (error) {
  //       if (
  //         error?.response?.data === "Signature has expired" ||
  //         error?.response?.status === 401
  //       ) {
  //         createNotification(
  //           "error",
  //           "Please login again your session has been expired"
  //         );
  //         setIsLoading(false);
  //         localStorage.removeItem(`persist:root`);

  //         logout();
  //         window.open("/dashboardlogin", "_self");
  //         return;
  //       } else if (
  //         error.message === "Cannot read properties of null (reading 'user')"
  //       ) {
  //         window.open("/dashboardlogin", "_self");
  //       }
  //       // console.log(JSON.stringify(error), "data");
  //       setIsLoading(false);
  //       createNotification("error", error?.response?.data?.response?.error);
  //       console.log("Something Went wrong", error.message);
  //     }
  //   };
  //   if (!sideBarData && !director) getData();
  // }, [tabOf]);

  const TabsDataClientSessions = [
    {
      id: Math.random(),
      title: "Original Target",
      key: "original_target",
    },
    {
      id: Math.random(),
      title: "As Of Today",
      key: "today",
    },
    {
      id: Math.random(),
      title: "Updated Target",
      key: "updated_target",
    },
  ];

  const getTableView = (CurrentView) => {
    switch (CurrentView) {
      case "DailySessions":
        return <TableView View={"Panel"} currentPage={currentPage} />;
      case "original_target":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            tableData={tableData?.data}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      case "today":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            tableData={tableData?.data}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
      case "updated_target":
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            setCurrentPage={setCurrentPage}
            tableData={tableData?.data}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );

      default:
        return (
          <TableView
            setdashboardView={setdashboardView}
            Pagination={true}
            View={CurrentView}
            tableData={tableData?.users}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            tabOf={tabOf}
            actionDone={actionDone}
            setActionDone={setActionDone}
            metaData={tableData?.meta}
          />
        );
    }
  };

  const handleAddunits = async () => {
    try {
      const res = await httpClient.post(
        `api/v1/units/update_or_create?user_id=${id}`,
        {
          ...clientCreds,
        }
      );
      if (res?.data) {
        setModalLoading(false);
        createNotification("success", res?.data?.message);
        setAddClientModalView(false);
        setActionDone(!actionDone);
      }
    } catch (error) {
      console.log(error);
      setModalLoading(false);
      createNotification("error", error?.response?.data?.response.error);
    }
  };
  const insuranecOption = [
    { value: "Direct", key: "direct" },
    { value: "Supervision", key: "supervision" },
    { value: "Other", key: "other" },
  ];
  const CodeOption = [
    { value: "97151", key: "97151" },
    { value: "97152", key: "97152" },
    { value: "97153", key: "97153" },
    { value: "97154", key: "97154" },
    { value: "97155", key: "97155" },
    { value: "97156", key: "97156" },
    { value: "97157", key: "97157" },
  ];
  const [insurance, setInsuarance] = useState(insuranecOption[0].key);
  const [code, setCode] = useState(CodeOption[0].key);
  const [unitsStats, setUnitsStats] = useState();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }
  useEffect(() => {
    const getUnitsStats = async ({ code, user_id, insurance }) => {
      const queryParams = {
        unit_code: code,
        unit_type: insurance,
        user_id,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      // /api/v1/units/client_units_info?user_id=humayun-client&filter=as_of_today'
      // setTableData(TableDataClient);
      try {
        const response = await httpClient.get(
          `api/v1/units/client_unit_stats/?${queryString}`
        );
        if (response.data) {
          setUnitsStats(response.data.data);
        }

        return true;
      } catch (error) {
        if (
          error?.response?.data === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          setIsLoading(false);
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        } else if (
          error.message === "Cannot read properties of null (reading 'user')"
        ) {
          window.open("/dashboardlogin", "_self");
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error);
      }
    };

    if (code && insurance && id) {
      getUnitsStats({
        code,
        user_id: id,
        insurance,
      });
    }
  }, [code, insurance]);
  const [modalLoading, setModalLoading] = useState(false);
  return (
    <section className={`${Sc.ClientListingContainer}`}>
      <section
        className={`${Sc.ClientListing}`}
        style={{ position: "relative" }}
      >
        <div
          className={`mb-1 ${Styles.ProfileContainer__LeftSidebar_Header}`}
          style={{ paddingBottom: "8px" }}
        >
          <div
            onClick={() => {
              setdashboardView("Client");
              navigate(-1);
            }}
            className="lm-nav-arrow"
          >
            {" "}
            {arrowLeftPurple}
          </div>
          <Breadcrumb
            separator={arrowRightBreadcrumb}
            items={[
              {
                title: "Clients",
              },
              {
                title: "Client Profile  ",
                // href: "",
              },
              {
                title: "Units",
                // href: "",
              },
            ]}
          />
        </div>

        <div className={`${Sc.ClientListing__Wrapper}`}>
          <div className={Sc.ClientListing__Header}>
            {ClientSessions && (
              <h4 className="f-24 f-w-700 lh-25 clr-dark-grey">
                Sessions completed
              </h4>
            )}
            <div
              className={`${
                Sc.ClientListing__Header_InputSection
              }  d-flex gap-25 ${
                AllSessions && "w-100 justify-content-between"
              } `}
            >
              <TabsView
                activeDefault={TabsDataClientSessions[0].key}
                TabsData={TabsDataClientSessions}
                getActiveTab={getActiveTab}
                Container={true}
                // setIsLoading={setIsLoading}
                setSearchText={setSearchText}
                setCurrentView={setCurrentView}
              />

              {/* <TextFieldFormik
                wrapperClassName={`Search-Input-Wrapper ${
                  ClientSessions ? "w-100" : "w-50"
                }`}
                values={searchText}
                handleChange={(e) => {
                  setCurrentPage(1);
                  setSearchText(e.target.value);
                }}
                type="search"
                placeholder="Search"
              /> */}
            </div>
          </div>
          {loading ? <Spin></Spin> : getTableView(currentView)}
        </div>
      </section>
      {RightSidebar && (
        <section className={Sc.ClientListing__RightSidebar}>
          <>
            <div style={{ display: "flex", gap: "2px" }}>
              <Select
                suffixIcon={arrowDownBlack}
                defaultValue={CodeOption[0].value}
                style={{ width: 130, height: 38 }}
                onChange={(e) => setCode(e)}
                options={CodeOption}
                className={"DashbaordCardSelect"}
                popupClassName="DashbaordCardSelect"
              />
              <Select
                suffixIcon={arrowDownBlack}
                defaultValue={insuranecOption[0].value}
                style={{ width: 130, height: 38 }}
                onChange={(e, key) => {
                  setInsuarance(key.key);
                }}
                options={insuranecOption}
                className={"DashbaordCardSelect"}
                popupClassName="DashbaordCardSelect"
              />
            </div>
            <div className={Sc.ClientListing__RightSidebar_board}>
              <div className="d-flex flex-column gap-2">
                <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                  {unitsStats?.units_remaining || "-"}
                </span>
                <span className="clr-dark-grey f-15 f-w-600 lh-20">
                  Remaining Units
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <span className="f-35 lh-40 f-w-700 clr-purple-bold">
                  {" "}
                  {unitsStats?.authorized_units || "-"}
                </span>
                <span className="clr-dark-grey f-15 f-w-600 lh-20">
                  Authorized Units
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <p className="clr-dark-grey f-15 f-w-600 lh-20">
                  Authorization Start Date
                </p>
                <span className="clr-text-light-gray f-13 lh-20">
                  {formatDate(unitsStats?.authorized_start_date)}
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <p className="clr-dark-grey f-15 f-w-600 lh-20">
                  Authorization End Date
                </p>
                <span className="clr-text-light-gray f-13 lh-20">
                  {formatDate(unitsStats?.authorized_end_date)}
                </span>
              </div>
            </div>
            <Button
              onClick={() => {
                setAddClientModalView(true);
              }}
              className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
            >
              {plusIconWhite} Add Units
            </Button>
          </>
        </section>
      )}

      <AddUnitsModal
        AddingEntity="Client"
        show={addClientModalView}
        onHide={() => {
          setAddClientModalView(false);
        }}
        onHandleAdd={() => {
          handleAddunits();
        }}
        setCreds={setClientCreds}
        creds={clientCreds}
        modalLoading={modalLoading}
        setLoadingModal={setModalLoading}
      />
    </section>
  );
};

export default UnitListing;
