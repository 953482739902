import { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProvider } from "formik";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import InnerFooter from "./InnerFooter";
import { createNotification } from "../../helpers/createNotifications";
import { NumberFormat, validateEmail, validateName } from "../../helpers/utils";
import { useParams } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const CommonFormPage = ({
  data,
  questionInfo,
  prev,
  next,
  halfWidth,
  postData,
  last,
  idCheck,
}) => {
  const [loading, setLoadiing] = useState(false);
  const { id } = useParams();

  const [mappingData, setMappingData] = useState([...data?.questions]);
  const [validateError, setValidateError] = useState([]);
  const [customData, setCustomData] = useState([
    ...data?.questions?.filter(
      (item) => item?.question_type === "custom_with_text"
    ),
  ]);
  let isSubmitting = false;
  let isFormatSubmit = true;
  const formatValue = (value, formatNumber) => {
    switch (formatNumber) {
      case 1:
        return NumberFormat(value, "xxxx-xxxx-xxxx");
      case 2:
        return NumberFormat(value, "(xxx) xxx-xxxx");
      case 5:
        return value;
      default:
        return;
    }
  };

  const firstInputRef = useRef(null);

  useEffect(() => {
    console.log(firstInputRef, "firstInputRef");
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const questions = mappingData;
    if (questions.find((item) => item?.question_type === "custom")) {
      const customWithTextObject = {
        batch_no: 1,
        question_type: "custom_with_text",
        customObjects: customData,
      };
      const newData = questions?.filter(
        (item) => item?.question_type !== "custom_with_text"
      );
      const customWithTextObjects = [];
      const parentIndices = {};
      const parentObjects = [];
      const visitedParents = new Set();

      customData.forEach((customItem) => {
        const parentID = customItem?.meta?.parent_question_id;

        if (!visitedParents.has(parentID)) {
          parentObjects.push(customItem);
          visitedParents.add(parentID);
        }
      });
      const noAnswers = [];
      customData.forEach((customItem) => {
        if (customItem?.answers?.length) {
          parentObjects.forEach((parentItem, parentIndex) => {
            const parentID = customItem?.meta?.parent_question_id;
            parentItem.answers.forEach((answer, index) => {
              const batchNo = answer.meta.batch_no;
              if (!parentIndices.hasOwnProperty(parentID + index)) {
                if (
                  parentItem.answers[index] &&
                  parentID === parentItem?.meta?.parent_question_id
                ) {
                  parentIndices[parentID + index] =
                    customWithTextObjects.length;
                  customWithTextObjects.push({
                    batch_no: batchNo,
                    question_type: "custom_with_text",
                    customObjects: [],
                    parent_id: parentID,
                  });
                }
              }

              if (parentItem.meta?.parent_question_id === parentID) {
                customWithTextObjects[
                  parentIndices[parentID + index]
                ]?.customObjects.push(customItem);
              }
            });
          });
        } else {
          if (!noAnswers.includes(customItem)) {
            noAnswers.push(customItem);
          }
        }
      });
      if (noAnswers?.length) {
        noAnswers.forEach((customItem) => {
          const parentID = customItem?.meta?.parent_question_id;
          if (!parentIndices.hasOwnProperty(parentID)) {
            parentIndices[parentID] = customWithTextObjects.length;
            customWithTextObjects.push({
              batch_no: 1,
              question_type: "custom_with_text",
              customObjects: [],
              parent_id: parentID,
            });
          }
          customWithTextObjects[parentIndices[parentID]].customObjects.push(
            customItem
          );
        });
      }

      const tempData = newData.flatMap((item) => {
        if (
          item?.question_type === "custom" ||
          item?.meta.key === "medical_info_current_medications" ||
          item?.meta.key === "medical_info_current_diagnosis" ||
          item?.meta.key === "home_family_sblings"
        ) {
          if (customData[0]?.answers?.length) {
            return [
              item,
              ...customWithTextObjects.filter(
                (object) => object.parent_id === item.id
              ),
            ];
          } else {
            const parentID = item.id;
            const parentIndex = parentIndices[parentID];
            const customWithTextObject = customWithTextObjects[parentIndex];
            return [item, customWithTextObject];
          }
        } else {
          return item;
        }
      });
      const finalData = tempData.filter((data) => {
        if (data?.customObjects) {
          if (data?.customObjects?.length) {
            return data;
          } else {
            return;
          }
        } else {
          return data;
        }
      });

      finalData.sort((a, b) => {
        if ("parent_id" in a && "parent_id" in b) {
          if (a.parent_id < b.parent_id) {
            return -1;
          }
          if (a.parent_id > b.parent_id) {
            return 1;
          }
        }
        if (a.batch_no < b.batch_no) {
          return -1;
        }
        if (a.batch_no > b.batch_no) {
          return 1;
        }
        return 0;
      });
      setMappingData([...finalData]);
    }
  }, [data]);
  const duplicateCustomObject = (parentID, batch_no) => {
    const customWithParent = mappingData?.reduce((lastMatch, item) => {
      if (item?.parent_id === parentID) {
        return item;
      }
      return lastMatch;
    }, null);
    const newCustomWithParent = { ...customWithParent };
    const index = mappingData.indexOf(customWithParent);
    newCustomWithParent.batch_no = batch_no + 1;
    const newData = [...mappingData];
    newData.splice(index + 1, 0, newCustomWithParent);
    setMappingData([...newData]);
  };
  const removeObjectByBatchNo = (item, values, setValues, errors) => {
    const updatedValues = values.section.questions_attributes.map(
      (question) => {
        const answerAttribute = question?.answers_attributes?.map((answer) => {
          if (
            answer?.meta?.batch_no === item?.batch_no &&
            item.customObjects.some((i) => i?.id === question?.id)
          ) {
            return { ...answer, _destroy: true };
          } else {
            return answer;
          }
        });

        if (question) {
          return {
            ...question,
            answers_attributes: answerAttribute,
          };
        }
      }
    );
    setValues({
      ...values,
      section: {
        ...values.section,
        questions_attributes: updatedValues,
      },
    });
    const toBeRemovedItem = mappingData.filter((object) => {
      if (object !== item) {
        return object;
      }
    });
    setMappingData([...toBeRemovedItem]);
    return toBeRemovedItem;
    // }
  };

  const handleSaveDraft = async (values, errors) => {
    const payload = {
      section: {
        questions_attributes: values.section.questions_attributes?.filter(
          (question) => {
            if (question?.answers_attributes.length) {
              if (question?.answers_attributes[0].option_id) {
                return question;
              } else if (question?.answers_attributes[0].statement) {
                return question;
              }
            }
          }
        ),
      },
    };
    // let errors = {};
    let newError = {};
    values.section.questions_attributes.map((item) => {
      if (item?.answers_attributes?.length === 1) {
        const answer = item.answers_attributes[0];
        if (!answer?.statement && !answer?.option_id) {
          if (
            !data.questions.some(
              (question) =>
                question.id === item.id &&
                (question.question_type === "custom" ||
                  question.question_type === null)
            )
          ) {
            const customWithText = data.questions.some(
              (question) =>
                question.id === item.id &&
                question.question_type === "custom_with_text"
            );
            if (customWithText) {
              errors[item.id + `-${1}`] = "This field is required";
            }
          }
        }
      } else if (item?.answers_attributes?.length > 1) {
        const answer = item.answers_attributes[0];
        if (!answer?.statement && !answer?.option_id) {
          if (
            !data.questions.some(
              (question) =>
                question.id === item.id &&
                (question.question_type === "custom" ||
                  question.question_type === null)
            )
          ) {
            const customWithText = data.questions.some(
              (question) =>
                question.id === item.id &&
                question.question_type === "custom_with_text"
            );
            if (customWithText) {
              errors[item.id + `-${1}`] = "This field is required";
            }
          }
        }
        item?.answers_attributes.map((answer, index) => {
          if (!answer?.statement && !answer?.option_id && !answer?._destroy) {
            index > 0 &&
              (errors[item.id + `-${answer?.meta?.batch_no}`] =
                "This field is required");
          }
        });
      }
      return errors;
    });

    function checkSubArray(subArray) {
      const trueCount = subArray.filter((value) => value === true).length;
      const falseCount = subArray.filter((value) => value === false).length;

      if (falseCount === subArray.length) {
        return true; // All False is equal to true
      } else if (trueCount === subArray.length) {
        return true; // All True is equal to true
      } else if (
        trueCount === subArray.length / 2 &&
        falseCount === subArray.length / 2
      ) {
        return false; // Half true and half false
      } else if (trueCount === 1 && falseCount === subArray.length - 1) {
        return false; // 1 true and rest false
      } else if (trueCount === subArray.length - 1 && falseCount === 1) {
        return false; // 1 false and rest true
      } else {
        return false; // Other cases
      }
    }
    function checkArray(array) {
      const result = array.map((subArray) => checkSubArray(subArray));
      return result;
    }

    const customWithText = mappingData?.filter(
      (data) => data?.question_type === "custom_with_text"
    );

    // console.log(errors)
    const tempArray = customWithText.map((item) =>
      item?.customObjects.map((obj) =>
        Object.keys(errors).includes(obj?.id + "-" + item?.batch_no)
      )
    );

    const checkError = checkArray(tempArray);
    // const question = () => {
    //   console.log("questions_attributes", values.section.questions_attributes);
    //   const toFormate = [...values.section.questions_attributes];
    //   const nload = toFormate?.map((question) => {
    //     if (question?.answers_attributes.length === 1) {
    //       if (question?.answers_attributes[0].option_id) {
    //         return question;
    //       } else if (question?.answers_attributes[0].statement) {
    //         return question;
    //       }
    //     }
    //     if (question?.answers_attributes.length > 1) {
    //       console.log(values);

    //       const { answers_attributes } = question || {};
    //       const transformedAnswers = answers_attributes.filter(
    //         (answer) => !!answer.statement
    //       );

    //       return {
    //         ...question,
    //         answers_attributes: transformedAnswers,
    //       };
    //     }
    //   });
    //   return {
    //     ...toFormate,
    //     section: { ...toFormate.section, questions_attributes: nload },
    //   };
    // };
    // console.log(
    //   checkError.length > 1 ? checkSubArray(checkError) : checkError[0]
    // );
    // question();
    const isOnlyRequired = Object.values(errors).every(
      (value) => value === "This field is required"
    );

    if (
      checkError.length > 1
        ? checkSubArray(checkError)
        : checkError[0] || (!checkError.length && !Object.keys(errors).length)
    ) {
      for (const key in errors) {
        delete errors[key];
      }
      const submitted = await postData(payload);
      if (submitted) {
        createNotification("success", "Your form is saved in draft");
        window.location.reload(true);
      } else return;
    } else if (
      checkError.length > 1
        ? checkSubArray(checkError)
        : checkError[0] && isOnlyRequired
    ) {
      const submitted = await postData(payload);
      if (submitted) {
        createNotification("success", "Your form is saved in draft");
        window.location.reload(true);
      } else return;
    } else {
      createNotification(
        "error",
        "Cannot save, fields are empty or incorrect value or format"
      );
    }
  };
  return (
    <FormikProvider>
      <Formik
        validateOnMount
        initialValues={{
          section: {
            questions_attributes: data?.questions?.map((question) => {
              if (question.question_type !== "custom") {
                const answer = question?.options?.length
                  ? question.answers.length
                    ? question.answers.map((answer) => {
                        return {
                          id: answer?.id,
                          option_id: answer.option_id,
                          meta: {},
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                        };
                      })
                    : [
                        {
                          id: question?.answers[0]?.id,
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                          option_id: question?.answers[0]?.option_id,
                        },
                      ]
                  : question?.answers?.length
                  ? question.answers.map((answer) => {
                      return {
                        id: answer?.id,
                        statement: answer.statement,
                        meta: {
                          batch_no: answer?.meta.batch_no,
                          // parent_id: answer?.meta?.parent_id,
                        },
                        user_section_mapping_id: data?.user_section_mapping_id,
                      };
                    })
                  : [
                      {
                        id: question?.answers[0]?.id,
                        statement: question?.answers[0]?.statement,
                        user_section_mapping_id: data?.user_section_mapping_id,
                      },
                    ];
                return {
                  id: question.id,
                  answers_attributes: answer,
                };
              } else {
                return;
              }
            }),
          },
        }}
        onSubmit={async (values) => {
          setLoadiing(true);
          const submitted = await postData(values);
          try {
            if (submitted) {
              next && next();
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoadiing(false);
          }
        }}
        validate={(values, err) => {
          let errors = {};
          if (validateError) {
            validateError?.map((item) => {
              if (!errors[item.error]) {
                errors[item.error] = "Please Enter correct value";
              }
            });
          }

          values.section.questions_attributes.map((item) => {
            if (item?.answers_attributes?.length === 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    if (!answer?.meta?.containsError)
                      errors[item.id] = "This field is required";
                  }
                }
              }
            } else if (item?.answers_attributes?.length > 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    errors[item.id] = "This field is required";
                  }
                }
              }
              item?.answers_attributes.map((answer, index) => {
                if (
                  !answer?.statement &&
                  !answer?.option_id &&
                  !answer?._destroy
                ) {
                  index > 0 &&
                    (errors[item.id + `-${answer?.meta?.batch_no}`] =
                      "This field is required");
                }
              });
            }
          });
          // if (isSubmitting && isFormatSubmit) {
          // }

          return errors;
        }}
      >
        {({ handleSubmit, errors, values, handleChange, setValues }) => {
          const handleForamtion = (value, formater, id, batch_no) => {
            if (value || value === 0) {
              switch (formater) {
                case 2: {
                  const formatedValue = NumberFormat(value, "(xxx) xxx-xxxx");

                  if (formatedValue && formatedValue.length === 14) {
                    const parsedPhoneNumber = parsePhoneNumberFromString(
                      formatedValue,
                      "US"
                    );
                    const isValid =
                      parsedPhoneNumber && parsedPhoneNumber.isValid();
                    if (!isValid) {
                      errors[id] = "Please enter valid number";
                    } else delete errors[id];
                  } else if (
                    formatedValue &&
                    formatedValue.length > 1 &&
                    formatedValue.length < 14
                  ) {
                    errors[id] = "Please enter complete value";
                  } else {
                    delete errors[id];
                  }
                  return formatedValue;
                }
                case 3: {
                  const formatedValue = validateEmail(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "Please enter email with correct format";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 4: {
                  const formatedValue = validateName(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "You have entered wrong value";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 5: {
                  if (value <= 0 || value > 12) {
                    errors[id] = "Value must be from 0 to 12";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 6: {
                  if (value === "0") {
                    errors[id] = "Enter valid value";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                // console.log("case run");
                default:
                  return value;
              }
            }
          };
          return (
            <Form>
              <div
                className={`d-flex gap-16 flex-wrap ${
                  halfWidth ? "max-w-input-of" : "w-100"
                }`}
              >
                {mappingData?.map((item) => {
                  let currentItem = questionInfo.find(
                    (i) => i?.title === item?.title
                  );
                  switch (item?.question_type) {
                    case "text_input":
                      const index =
                        values.section.questions_attributes.findIndex(
                          (index) => index?.id === item?.id
                        );
                      const isCustom = data?.questions.find(
                        (question) =>
                          question?.id === item?.meta?.parent_question_id
                      );
                      let currentOptionalParent;

                      if (isCustom?.question_type === "optional") {
                        currentOptionalParent =
                          values.section.questions_attributes?.find(
                            (question) => question?.id === isCustom?.id
                          );
                        const index =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id === item?.id
                          );
                        const containsItem =
                          values.section.questions_attributes.find(
                            (question) => question?.id === item?.id
                          );

                        if (
                          containsItem &&
                          currentOptionalParent?.answers_attributes[0]?.meta
                            ?.containsError
                        ) {
                          containsItem?.answers_attributes?.map((answer) => {
                            answer._destroy = true;
                            answer.meta = {
                              containsError: true,
                            };
                          });
                        }
                        {
                          /* if (
                          containsItem &&
                          currentOptionalParent?.answers_attributes[0]?.meta
                            ?.containsError
                        ) {
                          values.section.questions_attributes.splice(index, 1);
                        } */
                        }
                      }

                      if (
                        item?.meta.parent_question_id &&
                        isCustom?.question_type === "radio_with_description"
                      ) {
                        const parentAnswer =
                          values.section.questions_attributes.find(
                            (index) =>
                              index?.id == item?.meta.parent_question_id
                          )?.answers_attributes[0]?.option_id;
                        const descType = currentItem?.descType;
                        const yesOptionId = data.questions
                          .find(
                            (question) =>
                              question.id === item?.meta.parent_question_id
                          )
                          .options.find(
                            (option) =>
                              option.meta.key === (descType ? descType : "yes")
                          )?.id;
                        const compare = parentAnswer == yesOptionId;

                        if (!compare && !!item.meta.parent_question_id) {
                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (containsItem) {
                            values.section.questions_attributes.splice(
                              index,
                              1
                            );
                          }
                        } else if (compare && !!item.meta.parent_question_id) {
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (!containsItem) {
                            values.section.questions_attributes.push({
                              id: item?.id,
                              answers_attributes: [
                                {
                                  statement: "",
                                  user_section_mapping_id:
                                    data?.user_section_mapping_id,
                                },
                              ],
                            });
                          }
                        }

                        return (
                          compare && (
                            <>
                              <TextFieldFormik
                                disabled={id ? true : false}
                                key={currentItem?.id}
                                placeholder={currentItem?.placeholder}
                                label={currentItem?.label}
                                type={
                                  currentItem?.fieldType
                                    ? currentItem?.fieldType
                                    : currentItem?.inputType
                                    ? "textarea"
                                    : "text"
                                }
                                firstInputRef={
                                  currentItem?.firstInput ? firstInputRef : null
                                }
                                pattern={currentItem?.pattern}
                                wrapperClassName={
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-1 00"
                                }
                                tabIndex={currentItem?.tabIndex}
                                name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                handleChange={handleChange}
                                values={
                                  values?.section?.questions_attributes[index]
                                    ?.answers_attributes[0]?.statement
                                }
                                errors={errors[item?.id]}
                                birthDate={
                                  currentItem?.birthDate &&
                                  currentItem?.birthDate
                                }
                              />
                              <span
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#B1B1B1",
                                }}
                              >
                                {currentItem?.message
                                  ? currentItem?.message
                                  : ""}
                              </span>
                            </>
                          )
                        );
                      } else {
                        return (
                          <>
                            {currentItem?.twoCol && (
                              <div className="two-col-wrapper">
                                <span className="two-col-heading">
                                  {currentItem?.heading}
                                </span>
                                <div className="two-col-titles">
                                  <span>{currentItem?.colOneTitle}</span>
                                  <span>{currentItem?.colTwoTitle}</span>
                                </div>
                              </div>
                            )}
                            {currentItem?.topLabel && (
                              <label
                                className={`lm-input-form-input-label form-label }`}
                              >
                                {currentItem?.topLabel}
                              </label>
                            )}
                            {!currentOptionalParent?.answers_attributes[0]?.meta
                              ?.containsError && (
                              <TextFieldFormik
                                disabled={id ? true : false}
                                key={currentItem?.id}
                                placeholder={currentItem?.placeholder}
                                label={currentItem?.label}
                                firstInputRef={
                                  currentItem?.firstInput ? firstInputRef : null
                                }
                                type={
                                  currentItem?.fieldType
                                    ? currentItem?.fieldType
                                    : currentItem?.inputType
                                    ? "textarea"
                                    : "text"
                                }
                                pattern={currentItem?.pattern}
                                wrapperClassName={
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }
                                tabIndex={currentItem?.tabIndex}
                                birthDate={
                                  currentItem?.birthDate &&
                                  currentItem?.birthDate
                                }
                                name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                handleChange={handleChange}
                                values={handleForamtion(
                                  values.section.questions_attributes[index]
                                    ?.answers_attributes[0]?.statement,
                                  currentItem?.formater,
                                  item?.id
                                )}
                                errors={errors[item?.id]}
                              />
                            )}
                          </>
                        );
                      }
                    case "custom_with_text":
                      const lastBatchNoObjects = {};
                      let showAddButton = 0;
                      mappingData.forEach((currentObject) => {
                        if (
                          currentObject.question_type === "custom_with_text" &&
                          currentObject.parent_id &&
                          (!lastBatchNoObjects[currentObject.parent_id] ||
                            currentObject.batch_no >
                              lastBatchNoObjects[currentObject.parent_id]
                                .batch_no)
                        ) {
                          lastBatchNoObjects[currentObject.parent_id] =
                            currentObject;
                        }
                      });

                      mappingData.map((obj) => {
                        if (obj.parent_id === item.parent_id) {
                          showAddButton = showAddButton + 1;
                        }
                      });
                      const currentBatch = item?.batch_no;
                      const renderedCustomObjects = item?.customObjects?.map(
                        (object) => {
                          let currentItem = questionInfo.find(
                            (i) => i?.title === object.title
                          );
                          let customIndex;
                          if (object?.meta.parent_question_id) {
                            customIndex =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === object.id
                              );

                            const parentAnswer =
                              values.section.questions_attributes.find(
                                (index) =>
                                  index?.id == object.meta.parent_question_id
                              )?.answers_attributes[0]?.option_id;
                            const descType = currentItem?.descType;
                            const yesOptionId = data.questions
                              .find(
                                (question) =>
                                  question.id === object.meta.parent_question_id
                              )
                              ?.options.find(
                                (option) =>
                                  option.meta.key ==
                                  (descType ? descType : "yes")
                              )?.id;
                            const compare = parentAnswer == yesOptionId;

                            if (!compare && !!object.meta.parent_question_id) {
                              const index =
                                values.section.questions_attributes.findIndex(
                                  (index) => index?.id === object?.id
                                );
                              const containsItem =
                                values.section.questions_attributes.some(
                                  (question) => question?.id === object?.id
                                );

                              if (containsItem) {
                                values.section.questions_attributes.splice(
                                  index,
                                  1
                                );
                              }
                              const tempData = mappingData;
                              const mappingIndex = tempData.findIndex(
                                (data) =>
                                  data?.batch_no === item?.batch_no &&
                                  data?.parent_id === item?.parent_id
                              );
                              const containsMappingItem = tempData.some(
                                (data) =>
                                  data?.batch_no === item?.batch_no &&
                                  data?.parent_id === item?.parent_id
                              );
                              if (
                                containsMappingItem &&
                                mappingIndex &&
                                item?.batch_no > 1
                              ) {
                                tempData.splice(mappingIndex, 1);
                              }
                            } else if (
                              compare &&
                              !!object.meta.parent_question_id
                            ) {
                              const containsItem =
                                values.section.questions_attributes.some(
                                  (question) => question?.id === object?.id
                                );

                              if (!containsItem) {
                                values.section.questions_attributes.push({
                                  id: object?.id,
                                  answers_attributes: object?.answers?.length
                                    ? object?.answers?.map((answer) => {
                                        return {
                                          statement:
                                            answer?.meta?.batch_no === 1
                                              ? ""
                                              : answer?.statement,
                                          user_section_mapping_id:
                                            data?.user_section_mapping_id,
                                          meta: answer?.meta,
                                          id: answer?.id,
                                          _destroy:
                                            answer?.meta?.batch_no === 1
                                              ? false
                                              : true,
                                        };
                                      })
                                    : [
                                        {
                                          statement: "",
                                          user_section_mapping_id:
                                            data?.user_section_mapping_id,
                                        },
                                      ],
                                });
                              }
                            }

                            return (
                              compare && (
                                <>
                                  {item?.batch_no > 1 &&
                                    currentItem?.first &&
                                    !id && (
                                      <div className="remove-btn-wrapper">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            removeObjectByBatchNo(
                                              item,
                                              values,
                                              setValues,
                                              errors
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    )}
                                  <TextFieldFormik
                                    birthDate={
                                      currentItem?.birthDate &&
                                      currentItem?.birthDate
                                    }
                                    firstInputRef={
                                      currentItem?.firstInput
                                        ? firstInputRef
                                        : null
                                    }
                                    disabled={id ? true : false}
                                    key={object?.id + `-${item?.batch_no}`}
                                    batchNo={item?.batch_no}
                                    placeholder={currentItem?.placeholder}
                                    errors={
                                      errors[object?.id + `-${item?.batch_no}`]
                                    }
                                    label={currentItem?.label}
                                    type={
                                      currentItem?.fieldType
                                        ? currentItem?.fieldType
                                        : currentItem?.inputType
                                        ? "textarea"
                                        : "text"
                                    }
                                    tabIndex={currentItem?.tabIndex}
                                    pattern={currentItem?.pattern}
                                    wrapperClassName={
                                      currentItem?.wrapperClassName
                                        ? currentItem?.wrapperClassName
                                        : "max-w-input-of w-100"
                                    }
                                    handleChange={(e) => {
                                      const thisBatch = mappingData?.filter(
                                        (obj) =>
                                          obj?.parent_id === item?.parent_id
                                      );

                                      const batchIndex = thisBatch?.findIndex(
                                        (index) =>
                                          index?.batch_no === item?.batch_no
                                      );
                                      if (currentItem?.age) {
                                        if (e.target.value < 1) {
                                          const tempError = [];

                                          if (validateError.length) {
                                            if (
                                              !validateError?.some(
                                                (item) =>
                                                  item.error ===
                                                  object?.id +
                                                    `-${item?.batch_no}`
                                              )
                                            ) {
                                              tempError?.push({
                                                error:
                                                  object?.id +
                                                  `-${item?.batch_no}`,
                                              });
                                            }
                                          } else {
                                            tempError?.push({
                                              error:
                                                object?.id +
                                                `-${item?.batch_no}`,
                                            });
                                          }

                                          const updatedErrors =
                                            validateError.length > 0
                                              ? [...tempError, ...validateError]
                                              : tempError;
                                          setValidateError(updatedErrors);
                                        } else {
                                          const updatedErrors =
                                            validateError.filter(
                                              (error) =>
                                                error.error !==
                                                object?.id +
                                                  `-${item?.batch_no}`
                                            );

                                          setValidateError(updatedErrors);
                                          delete errors[
                                            object?.id + `-${item?.batch_no}`
                                          ];
                                          isFormatSubmit = true;
                                        }
                                      }

                                      if (currentItem?.formater) {
                                        e.target.value = formatValue(
                                          e.target.value,
                                          currentItem?.formater
                                        );

                                        if (
                                          currentItem?.formater != 5 &&
                                          currentItem?.formater != 2
                                        ) {
                                          if (e.target.value.length < 14) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Please enter complete value";
                                            const tempError = [];

                                            if (validateError.length) {
                                              if (
                                                !validateError?.some(
                                                  (item) =>
                                                    item.error ===
                                                    object?.id +
                                                      `-${item?.batch_no}`
                                                )
                                              ) {
                                                tempError?.push({
                                                  error:
                                                    object?.id +
                                                    `-${item?.batch_no}`,
                                                });
                                              }
                                            } else {
                                              tempError?.push({
                                                error:
                                                  object?.id +
                                                  `-${item?.batch_no}`,
                                              });
                                            }

                                            const updatedErrors =
                                              validateError.length > 0
                                                ? [
                                                    ...tempError,
                                                    ...validateError,
                                                  ]
                                                : tempError;
                                            setValidateError(updatedErrors);

                                            isFormatSubmit = false;
                                          } else {
                                            const updatedErrors =
                                              validateError.filter(
                                                (error) =>
                                                  error.error !==
                                                  object?.id +
                                                    `-${item?.batch_no}`
                                              );

                                            setValidateError(updatedErrors);
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        } else if (
                                          currentItem?.formater === 2
                                        ) {
                                          if (e.target.value.length < 14) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Please enter complete value";

                                            isFormatSubmit = false;
                                          } else if (
                                            e.target.value.length === 14
                                          ) {
                                            const parsedPhoneNumber =
                                              parsePhoneNumberFromString(
                                                e.target.value,
                                                "US"
                                              );
                                            const isValid =
                                              parsedPhoneNumber &&
                                              parsedPhoneNumber.isValid();
                                            if (!isValid) {
                                              errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ] = "Please enter valid number";
                                              isFormatSubmit = false;
                                            }
                                          } else {
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        } else if (currentItem?.formater == 5) {
                                          if (
                                            e.target.value > 12 ||
                                            e.target.value <= 0
                                          ) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Value must be from 0 to 12";
                                            const tempError = [];

                                            if (validateError.length) {
                                              if (
                                                !validateError?.some(
                                                  (item) =>
                                                    item.error ===
                                                    object?.id +
                                                      `-${item?.batch_no}`
                                                )
                                              ) {
                                                tempError?.push({
                                                  error:
                                                    object?.id +
                                                    `-${item?.batch_no}`,
                                                });
                                              }
                                            } else {
                                              tempError?.push({
                                                error:
                                                  object?.id +
                                                  `-${item?.batch_no}`,
                                              });
                                            }
                                            const updatedErrors =
                                              validateError.length > 0
                                                ? [
                                                    ...tempError,
                                                    ...validateError,
                                                  ]
                                                : tempError;
                                            setValidateError(updatedErrors);
                                            isFormatSubmit = false;
                                          } else {
                                            const updatedErrors =
                                              validateError.filter(
                                                (error) =>
                                                  error.error !==
                                                  object?.id +
                                                    `-${item?.batch_no}`
                                              );

                                            setValidateError(updatedErrors);
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        }
                                      } else {
                                        if (e.target.value) {
                                          delete errors[
                                            object?.id + `-${item?.batch_no}`
                                          ];
                                        } else {
                                          console.log(e.target.value);
                                          errors[
                                            object?.id + `-${item?.batch_no}`
                                          ] = "This field is required";
                                        }
                                      }

                                      const answer =
                                        values.section.questions_attributes[
                                          customIndex
                                        ].answers_attributes;
                                      const valueIndex = answer.findIndex(
                                        (index) =>
                                          index?.meta?.batch_no ===
                                          item?.batch_no
                                      );
                                      const batchAnswer = answer[batchIndex];
                                      if (batchIndex === 0) {
                                        batchAnswer.statement = e.target.value;
                                        batchAnswer.meta = {
                                          batch_no: item?.batch_no,
                                          parent_id: item?.parent_id,
                                        };
                                      } else {
                                        if (valueIndex) {
                                          answer[valueIndex].statement =
                                            e.target.value;
                                          answer[valueIndex].meta = {
                                            batch_no: item?.batch_no,
                                            parent_id: item?.parent_id,
                                          };
                                          answer[
                                            valueIndex
                                          ].user_section_mapping_id =
                                            data?.user_section_mapping_id;
                                        } else {
                                          answer.push({
                                            statement: e.target.value,
                                            meta: {
                                              batch_no: item?.batch_no,
                                              parent_id: item?.parent_id,
                                            },
                                            user_section_mapping_id:
                                              data?.user_section_mapping_id,
                                          });
                                        }
                                      }
                                    }}
                                    defaultValue={
                                      values.section.questions_attributes[
                                        customIndex
                                      ]?.answers_attributes[
                                        values.section.questions_attributes[
                                          customIndex
                                        ]?.answers_attributes?.findIndex(
                                          (index) =>
                                            index?.meta?.batch_no ===
                                            item?.batch_no
                                        )
                                      ]?.statement
                                    }
                                  />

                                  {currentItem?.last &&
                                    item?.batch_no ===
                                      lastBatchNoObjects[item?.parent_id]
                                        ?.batch_no &&
                                    !idCheck &&
                                    !id &&
                                    showAddButton < 5 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          duplicateCustomObject(
                                            item?.parent_id,
                                            item?.batch_no
                                          );

                                          const updatedQuestions =
                                            values.section.questions_attributes.map(
                                              (question) => {
                                                const currentCheck =
                                                  data?.questions?.some(
                                                    (newQuestion) => {
                                                      return (
                                                        question?.id ===
                                                          newQuestion.id &&
                                                        item?.parent_id ===
                                                          newQuestion?.meta
                                                            ?.parent_question_id &&
                                                        newQuestion?.question_type ===
                                                          "custom_with_text"
                                                      );
                                                    }
                                                  );
                                                const answerCheck =
                                                  question?.answers_attributes?.find(
                                                    (answer) =>
                                                      answer?.meta?.batch_no ===
                                                      item?.batch_no + 1
                                                  );
                                                if (answerCheck) {
                                                  answerCheck._destroy = false;
                                                }
                                                return currentCheck &&
                                                  !answerCheck
                                                  ? {
                                                      ...question,
                                                      answers_attributes: [
                                                        ...question.answers_attributes,
                                                        {
                                                          meta: {
                                                            parent_id:
                                                              item?.parent_id,
                                                            batch_no:
                                                              item?.batch_no +
                                                              1,
                                                          },
                                                          statement: "",
                                                        },
                                                      ],
                                                    }
                                                  : question;
                                              }
                                            );
                                          setValues({
                                            ...values,
                                            section: {
                                              ...values.section,
                                              questions_attributes:
                                                updatedQuestions,
                                            },
                                          });
                                        }}
                                        className="add-custom-button"
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9 0V17.0573"
                                            stroke="black"
                                          />
                                          <path
                                            d="M17.5273 8.5293L0.470009 8.5293"
                                            stroke="black"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                </>
                              )
                            );
                          } else {
                            return (
                              <>
                                {currentItem?.twoCol && (
                                  <div className="two-col-wrapper">
                                    <span className="two-col-heading">
                                      {currentItem?.heading}
                                    </span>
                                    <div className="two-col-titles">
                                      <span>{currentItem?.colOneTitle}</span>
                                      <span>{currentItem?.colTwoTitle}</span>
                                    </div>
                                  </div>
                                )}
                                <TextFieldFormik
                                  birthDate={
                                    currentItem?.birthDate &&
                                    currentItem?.birthDate
                                  }
                                  firstInputRef={
                                    currentItem?.firstInput
                                      ? firstInputRef
                                      : null
                                  }
                                  disabled={id ? true : false}
                                  key={item?.batch_no.id}
                                  batchNo={item?.batch_no}
                                  placeholder={currentItem?.placeholder}
                                  errors={errors[object?.id]}
                                  label={currentItem?.label}
                                  tyep={
                                    currentItem?.fieldType
                                      ? currentItem?.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem?.wrapperClassName
                                      ? currentItem?.wrapperClassName
                                      : "max-w-input-of w-100"
                                  }
                                  tabIndex={currentItem?.tabIndex}
                                  name={`section.questions_attributes[${customIndex}].answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  values={
                                    values.section.questions_attributes[
                                      customIndex
                                    ]?.answers_attributes[item?.batch_no - 1]
                                      ? values.section.questions_attributes[
                                          customIndex
                                        ].answers_attributes[item?.batch_no - 1]
                                          .statement
                                      : ""
                                  }
                                />
                              </>
                            );
                          }
                        }
                      );
                      return <>{renderedCustomObjects}</>;

                    case "radio":
                      const indexRadio =
                        values?.section?.questions_attributes?.findIndex(
                          (index) => index?.id == item.id
                        );
                      const isCustomType = data.questions.find(
                        (question) =>
                          question.id == item.meta.parent_question_id
                      );

                      if (
                        item.meta.parent_question_id &&
                        isCustomType.question_type == "radio_with_description"
                      ) {
                        const parentAnswer =
                          values.section.questions_attributes.find(
                            (index) => index.id == item.meta.parent_question_id
                          ).answers_attributes[0]?.option_id;

                        const yesOptionId = data.questions
                          .find(
                            (question) =>
                              question.id === item.meta.parent_question_id
                          )
                          ?.options.find(
                            (option) =>
                              option?.meta?.key ===
                              (currentItem?.descType
                                ? currentItem?.descType
                                : "yes")
                          )?.id;
                        const compare = parentAnswer == yesOptionId;
                        if (!compare && !!item.meta.parent_question_id) {
                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (containsItem) {
                            values.section.questions_attributes.splice(
                              index,
                              1
                            );
                          }
                        } else if (compare && !!item.meta.parent_question_id) {
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (!containsItem) {
                            values.section.questions_attributes.push({
                              id: item?.id,
                              answers_attributes: [
                                {
                                  statement: "",
                                  user_section_mapping_id:
                                    data?.user_section_mapping_id,
                                },
                              ],
                            });
                          }
                        }
                        return (
                          compare && (
                            <RadioButtonsFormSection
                              required
                              errors={errors[item?.id]}
                              RadioButtons={currentItem?.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={id ? true : false}
                                      id={currentOption?.id}
                                      key={currentOption?.id}
                                      firstInputRef={
                                        content?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      name={`section.questions_attributes[${indexRadio}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section.questions_attributes[
                                          indexRadio
                                        ]?.answers_attributes?.length &&
                                        currentOption?.id ==
                                          values?.section.questions_attributes[
                                            indexRadio
                                          ]?.answers_attributes[0]?.option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          )
                        );
                      }

                    case "radio_with_description":
                      const indexRadioWD =
                        values.section.questions_attributes.findIndex(
                          (index) => index?.id == item.id
                        );
                      if (!currentItem?.checkBox) {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            RadioButtons={currentItem?.options?.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                return (
                                  <RadioButtons
                                    disabled={id ? true : false}
                                    id={currentOption?.id}
                                    key={currentOption?.id}
                                    name={`section.questions_attributes[${indexRadioWD}].answers_attributes[0].option_id`}
                                    onChange={handleChange}
                                    values={currentOption?.id}
                                    firstInputRef={
                                      content?.firstInput ? firstInputRef : null
                                    }
                                    Label={content.title}
                                    tabIndex={content.tabIndex}
                                    checked={
                                      values?.section.questions_attributes[
                                        indexRadioWD
                                      ]?.answers_attributes?.length &&
                                      currentOption?.id ==
                                        values?.section.questions_attributes[
                                          indexRadioWD
                                        ]?.answers_attributes[0].option_id
                                    }
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      } else {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            RadioButtons={currentItem?.options.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                const answers =
                                  values.section.questions_attributes[
                                    indexRadioWD
                                  ].answers_attributes;
                                let answer = answers.find(
                                  (answer) =>
                                    answer.option_id == currentOption?.id
                                );
                                const isChecked = answer && !answer._destroy;
                                return (
                                  <RadioButtons
                                    disabled={id ? true : false}
                                    id={currentOption?.id}
                                    key={currentOption?.id}
                                    firstInputRef={
                                      content?.firstInput ? firstInputRef : null
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (
                                          answers[0].option_id === undefined
                                        ) {
                                          answers[0].option_id = parseInt(
                                            e.target.value
                                          );
                                        } else {
                                          if (answer) {
                                            answer._destroy = false;
                                          } else {
                                            answer = {
                                              option_id: parseInt(
                                                e.target.value
                                              ),
                                              user_section_mapping_id:
                                                data.user_section_mapping_id,
                                            };
                                            answers.push(answer);
                                          }
                                        }
                                      } else {
                                        if (answer) {
                                          answer._destroy = true;
                                        }
                                      }
                                    }}
                                    values={currentOption?.id}
                                    Label={content.title}
                                    tabIndex={content.tabIndex}
                                    checkBox={currentItem?.checkBox}
                                    defaultChecked={isChecked}
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      }

                    case "optional":
                      const indexOptional =
                        values.section.questions_attributes.findIndex(
                          (index) => index.id == item.id
                        );
                      const displayOptional = currentItem?.optionalChecked;
                      const handleOptionalChange = (value) => {};
                      if (!item?.options?.length) {
                        delete errors[item?.id];
                      }
                      if (!currentItem?.checkBox) {
                        return (
                          <>
                            {currentItem?.descriptionText
                              ? currentItem?.descriptionText
                              : ""}
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              values={values}
                              optionalError={errors}
                              optionalType={true}
                              item={item}
                              setValues={setValues}
                              onOptionalChange={handleOptionalChange}
                              userMapping={data?.user_section_mapping_id}
                              displayOptional={displayOptional}
                              RadioButtons={currentItem?.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={id ? true : false}
                                      id={currentOption?.id}
                                      key={currentOption?.id}
                                      name={`section.questions_attributes[${indexOptional}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      firstInputRef={
                                        content?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section.questions_attributes[
                                          indexOptional
                                        ].answers_attributes.length &&
                                        currentOption.id ==
                                          values?.section.questions_attributes[
                                            indexOptional
                                          ].answers_attributes.length &&
                                        currentOption.id ==
                                          values?.section.questions_attributes[
                                            indexOptional
                                          ].answers_attributes[0].option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={`${
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }`}
                              GridView={currentItem?.gridView}
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            {currentItem?.descriptionText ? (
                              <span
                                style={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                {currentItem?.descriptionText}
                              </span>
                            ) : (
                              ""
                            )}
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              values={values}
                              optionalError={errors}
                              optionalType={true}
                              item={item}
                              setValues={setValues}
                              onOptionalChange={handleOptionalChange}
                              userMapping={data?.user_section_mapping_id}
                              displayOptional={displayOptional}
                              RadioButtons={currentItem?.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  const answers =
                                    values.section.questions_attributes[
                                      indexOptional
                                    ]?.answers_attributes;
                                  let answer = answers?.find(
                                    (answer) =>
                                      answer.option_id == currentOption?.id
                                  );
                                  const isChecked = answer && !answer._destroy;
                                  return (
                                    <RadioButtons
                                      disabled={id ? true : false}
                                      id={currentOption?.id}
                                      key={currentOption?.id}
                                      firstInputRef={
                                        content?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.offsetParent.classList.contains(
                                            "checked"
                                          )
                                        ) {
                                          e.target.offsetParent.classList.remove(
                                            "checked"
                                          );
                                        } else {
                                          e.target.offsetParent.classList.add(
                                            "checked"
                                          );
                                        }

                                        if (e.target.checked) {
                                          if (
                                            answers[0].option_id === undefined
                                          ) {
                                            answers[0].option_id = parseInt(
                                              e.target.value
                                            );
                                          } else {
                                            if (answer) {
                                              answer._destroy = false;
                                            } else {
                                              answer = {
                                                option_id: parseInt(
                                                  e.target.value
                                                ),
                                                user_section_mapping_id:
                                                  data.user_section_mapping_id,
                                              };
                                              answers.push(answer);
                                            }
                                          }
                                        } else {
                                          if (answer) {
                                            answer._destroy = true;
                                          }
                                        }
                                      }}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tagCheckbox={true}
                                      tabIndex={content.tabIndex}
                                      checkBox={currentItem?.checkBox}
                                      defaultChecked={isChecked}
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={`${
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }`}
                              GridView={currentItem?.gridView}
                            />
                          </>
                        );
                      }
                    case "checkbox":
                      const indexCheckbox =
                        values.section.questions_attributes.findIndex(
                          (index) => index.id == item.id
                        );
                      if (!currentItem?.checkBox) {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            RadioButtons={currentItem?.options.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                return (
                                  <RadioButtons
                                    disabled={id ? true : false}
                                    id={currentOption?.id}
                                    key={currentOption?.id}
                                    name={`section.questions_attributes[${indexCheckbox}].answers_attributes[0].option_id`}
                                    onChange={handleChange}
                                    values={currentOption?.id}
                                    firstInputRef={
                                      content?.firstInput ? firstInputRef : null
                                    }
                                    Label={content.title}
                                    tabIndex={content.tabIndex}
                                    checked={
                                      values?.section.questions_attributes[
                                        indexCheckbox
                                      ].answers_attributes.length &&
                                      currentOption.id ==
                                        values?.section.questions_attributes[
                                          indexCheckbox
                                        ].answers_attributes[0].option_id
                                    }
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      } else {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            RadioButtons={currentItem?.options.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                const answers =
                                  values.section.questions_attributes[
                                    indexCheckbox
                                  ].answers_attributes;
                                let answer = answers.find(
                                  (answer) =>
                                    answer.option_id == currentOption?.id
                                );
                                const isChecked = answer && !answer._destroy;
                                return (
                                  <RadioButtons
                                    disabled={id ? true : false}
                                    id={currentOption?.id}
                                    key={currentOption?.id}
                                    firstInputRef={
                                      content?.firstInput ? firstInputRef : null
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (
                                          answers[0].option_id === undefined ||
                                          answers[0].option_id === null
                                        ) {
                                          answers[0].option_id = parseInt(
                                            e.target.value
                                          );
                                          answers[0]._destroy = false;
                                        } else {
                                          if (answer) {
                                            answer._destroy = false;
                                          } else {
                                            answer = {
                                              option_id: parseInt(
                                                e.target.value
                                              ),
                                              user_section_mapping_id:
                                                data.user_section_mapping_id,
                                              _destroy: false,
                                            };
                                            answers.push(answer);
                                          }
                                        }
                                      } else {
                                        if (answer) {
                                          answer._destroy = true;
                                        }
                                      }
                                      if (
                                        answers.every(
                                          (answer) =>
                                            answer.option_id && answer._destroy
                                        )
                                      ) {
                                        errors[item?.id] =
                                          "This field is required";
                                      } else {
                                        delete errors[item?.id];
                                      }
                                    }}
                                    values={currentOption?.id}
                                    Label={content.title}
                                    // tagCheckbox={true}
                                    tabIndex={content.tabIndex}
                                    checkBox={currentItem?.checkBox}
                                    defaultChecked={isChecked}
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      }

                    case "custom":
                      return (
                        <h4 className="w-100 custom-heading">
                          {currentItem?.label}
                        </h4>
                      );
                    default:
                  }
                })}

                {!id && (
                  <InnerFooter
                    prevFormHandler={prev}
                    loading={loading}
                    next={next}
                    last={last ? last : null}
                    nextFormHandler={() => {
                      isSubmitting = true;
                      handleSubmit(values, errors);
                    }}
                    saveDraft={() => handleSaveDraft(values, errors)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormikProvider>
  );
};

export default CommonFormPage;
