import { useEffect, useState } from "react";
import { createNotification } from "../../../helpers/createNotifications";
import httpClient from "../../../services/axios";
import LoaderSpinner from "../../general/loader/loader.spinner";
import CommonFormPage from "../CommonFormPage";
import { logout } from "../../../store/user/userSlice";

const TherapyProviders = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 75,
      title: "current_therapy_services",
      placeholder: "",
      label:
        "Has your child/adolescent received behavioral services with another provider?",
      wrapperClassName: "",
      options: [
        {
          id: 44,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 45,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 76,
      title: "current_therapy_provider_address",
      placeholder: "Complete Address",
      label: "Address",
      wrapperClassName: "",
      options: [],
    },
    {
      id: 77,
      title: "current_therapy_provider_phone",
      label: "Phone No.",
      wrapperClassName: "w-48",
      options: [],
      placeholder: "(000) 000-0000",
      fieldType: "number",
      formater: 2,
    },
    {
      id: 78,
      title: "current_therapy_provider_email",
      placeholder: "Enter Email",
      label: "Email",
      wrapperClassName: "w-48",
      options: [],
      formater: 3,
    },
    {
      id: 79,
      title: "current_therapy_provider_name",
      placeholder: "Enter Provider Name",
      label: "Provider Name",
      wrapperClassName: "w-48",
      options: [],
      formater: 4,
    },
    {
      id: 80,
      title: "current_therapy_provider_no_of_visits",
      placeholder: "Dates of Service/ No. of Visits",
      label: "Dates of Service/ No. of Visits",
      wrapperClassName: "w-48",
      options: [],
    },
    {
      id: 81,
      title: "prev_therapy_services",
      placeholder: "",
      label:
        "Has your child/adolescent received behavioral services with another provider?",
      wrapperClassName: "",
      options: [
        {
          id: 46,
          title: "Yes",
          key: "yes",
        },
        {
          id: 47,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 82,
      title: "prev_therapy_provider_address",
      placeholder: "Complete Address",
      label: "Address",
      wrapperClassName: "",
      options: [],
    },
    {
      id: 83,
      title: "prev_therapy_provider_phone",
      wrapperClassName: "w-48",
      placeholder: "(000) 000-0000",
      fieldType: "number",
      formater: 2,
      options: [],
      label: "Phone No.",
    },
    {
      id: 84,
      title: "prev_therapy_provider_email",
      placeholder: "Enter Email",
      label: "Email",
      wrapperClassName: "w-48",
      options: [],
      formater: 3,
    },
    {
      id: 85,
      title: "prev_therapy_provider_name",
      placeholder: "Enter Provider Name",
      label: "Provider Name",
      wrapperClassName: "w-48",
      options: [],
      formater: 4,
    },
    {
      id: 86,
      title: "prev_therapy_provider_no_of_visits",
      placeholder: "Dates of Service/ No. of Visits",
      label: "Dates of Service/ No. of Visits",
      wrapperClassName: "w-48",
      options: [],
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/therapy-providers-section/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/therapy-providers-section"
            );
          }
          setQuestionData(response?.data);
          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);

        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/therapy-providers-section/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default TherapyProviders;
