import React, { useState } from "react";
import styleClasses from "./RightSideBar.module.scss";
import { plusIconBlack } from "../../constants/constants";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import dotsicon from "../../assets/svg/dotsicon.svg";
import sendmessage from "../../assets/svg/sendmessage.svg";
import deleteicon from "../../assets/svg/deleteicon (1).svg";
import editicon from "../../assets/svg/editicon.svg";
import { createNotification } from "../../helpers/createNotifications";
import LoaderSpinner from "../general/loader/loader.spinner";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { GetCurrentUser } from "../../utils/utilAuth";

const RightSideBar = ({
  postComments,
  comments,
  setComments,
  putEditComments,
  action,
  setAction,
}) => {
  const { slug } = GetCurrentUser();
  const [comment, setComment] = useState();
  const [loading, setLoadiing] = useState();
  const [editLoading, setEditLoadiing] = useState();
  const [editItem, setEditItem] = useState();
  const [editComment, setEditComment] = useState();
  const handleComment = async () => {
    setLoadiing(true);
    if (comment?.trim().length > 0) {
      try {
        const posttedComment = await postComments({
          comment: {
            content: comment,
          },
        });
        if (posttedComment) {
          setComment("");
          setLoadiing(false);
          // createNotification("success", "Comment Posted");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDeleteComment = async (commentId) => {
    try {
      const res2 = await httpClient.delete(`/api/v1/comments/${commentId} `);
      if (res2?.data) {
        // setComments(res2?.data);
        createNotification("success", "Comment Deleted");
        setAction(!action);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  const handleEditComment = async (id) => {
    // console.log(comment);
    setEditLoadiing(true);
    if (editComment?.length) {
      try {
        const posttedComment = await putEditComments(
          {
            comment: {
              content: editComment,
            },
          },
          id
        );
        // console.log(postComments);
        if (posttedComment) {
          setEditComment("");
          setEditLoadiing(false);
          setEditItem(null);
          // createNotification("success", "Comment Posted");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const preventActionDone = (e) => {
    e.preventDefault();
    return false;
  };

  const img = `${(<img src={dotsicon} />)}`;
  return (
    <div className={styleClasses.MainDataSheetPage__RightSidebar}>
      <div className={styleClasses.MainDataSheetPage__RightSidebarWrapper}>
        <h3 className="fs-24 f-w-700 pt-62 pb-40">Comments</h3>
        <div className={styleClasses.MainDataSheetPage__CommentsWrapper}>
          {comments?.map((item) => {
            const currentTime = new Date(); // Current time
            const previousTime = new Date(item?.created_at); // Provided timestamp

            const timeDifference =
              currentTime.getTime() - previousTime.getTime(); // Time difference in milliseconds

            let timeResult;

            if (timeDifference < 1000 * 60) {
              timeResult = `${
                Math.floor(timeDifference / 1000) > 1
                  ? Math.abs(Math.floor(timeDifference / 1000)) +
                    " " +
                    "seconds ago"
                  : Math.abs(Math.floor(timeDifference / 1000)) +
                    " " +
                    "second ago"
              }`;
            } else if (timeDifference < 1000 * 60 * 60) {
              timeResult = `${
                Math.floor(timeDifference / (1000 * 60)) > 1
                  ? Math.abs(Math.floor(timeDifference / (1000 * 60))) +
                    " " +
                    "minutes ago"
                  : Math.abs(Math.floor(timeDifference / (1000 * 60))) +
                    " " +
                    "minute ago"
              }`;
            } else if (timeDifference < 1000 * 60 * 60 * 24) {
              timeResult = `${
                Math.floor(timeDifference / (1000 * 60 * 60)) > 1
                  ? Math.abs(Math.floor(timeDifference / (1000 * 60 * 60))) +
                    " " +
                    "hours ago"
                  : Math.abs(Math.floor(timeDifference / (1000 * 60 * 60))) +
                    " " +
                    "hour ago"
              }`;
            } else if (timeDifference < 1000 * 60 * 60 * 24 * 7) {
              timeResult = `${Math.abs(
                Math.floor(timeDifference / (1000 * 60 * 60 * 24))
              )} days ago`;
            } else {
              timeResult = `${Math.abs(
                Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7))
              )} weeks ago`;
            }
            return (
              <div key={item?.id} className="mb-40">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="f-18 f-w-600 clr-dark-grey text-capitalize ">
                      {item?.commentor_name}
                    </p>
                    <span className="f-14 f-w-600 pt-8 clr-b-grey text-capitalize">
                      {item?.commentator_role}
                    </span>
                  </div>
                  {editItem !== item?.id ? (
                    <div className="dropstart-wrapper">
                      {item?.commentor_id === slug && (
                        <DropdownButton title="" id="" drop="start">
                          <Dropdown.Item
                            className={`${
                              item?.commentor_id !== slug && "cursor-none-click"
                            }`}
                            style={{
                              pointerEvents: `${
                                item?.commentor_id !== slug && "none"
                              }`,
                            }}
                            onClick={() => {
                              if (item?.commentor_id === slug)
                                setEditItem(item?.id);
                              setEditComment(item?.content);
                            }}
                          >
                            <img src={editicon} />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            className={`${
                              item?.commentor_id !== slug && "cursor-none-click"
                            }`}
                            style={{
                              pointerEvents: `${
                                item?.commentor_id !== slug && "none"
                              }`,
                            }}
                            onClick={() => {
                              if (item?.commentor_id === slug)
                                handleDeleteComment(item?.id);
                            }}
                          >
                            <img src={deleteicon} />
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                      )}
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        setEditItem(null);
                        setEditComment("");
                      }}
                      className="comment-cancel-btn"
                    >
                      Cancel
                    </button>
                  )}
                </div>
                <div className="f-16 f-w- 400 clr-dark-grey mt-12">
                  {editItem === item?.id ? (
                    <div className="comment-input-wrapper custom-input-textarea">
                      <div className="icon-wrapper">
                        <span
                          className="icon-wrapper-span"
                          onClick={() =>
                            editComment?.length && handleEditComment(item?.id)
                          }
                          disabled={editLoading || !editComment?.length}
                        >
                          {editLoading ? (
                            <div
                              style={{
                                position: "relative",
                                width: "24px",
                                height: "24px",
                              }}
                            >
                              <LoaderSpinner color="#bbbbbb" size={24} />
                            </div>
                          ) : (
                            <img src={sendmessage} />
                          )}
                        </span>
                        <textarea
                          type="text"
                          class="custom-comment-input"
                          placeholder="Comment here"
                          // defaultValue={editComment}
                          value={editComment}
                          disabled={editLoading}
                          onChange={(e) => setEditComment(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    <p className="mb-8" style={{ wordBreak: "break-all" }}>
                      {item?.content}
                    </p>
                  )}
                  <span className="f-14 f-w-600  clr-b-grey">{timeResult}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="comment-input-wrapper">
        <div className="icon-wrapper">
          <span
            className="icon-wrapper-span"
            onClick={() => comment?.trim().length > 0 && handleComment()}
            disabled={loading || !comment?.trim().length > 0}
          >
            {loading ? (
              <div
                style={{ position: "relative", width: "24px", height: "24px" }}
              >
                <LoaderSpinner color="#bbbbbb" size={24} />
              </div>
            ) : (
              <img src={sendmessage} />
            )}
          </span>
          <input
            onPaste={preventActionDone}
            onCopy={preventActionDone}
            type="text"
            class="custom-comment-input"
            placeholder="Comment here"
            value={comment}
            disabled={loading}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
