import { Button } from "antd";
import GoalCard from "../../../../components/GoalsCard/GoalCard";
import Styles from "../../Dashboard.module.scss";
import { useNavigate } from "react-router-dom";

const WorkedGoals = ({ clientGoals, setdashboardView, userId, technician }) => {
  const navigate = useNavigate();

  const GoalCards = clientGoals?.map((goal, index) => {
    return (
      <GoalCard
        key={goal?.reference_no}
        avatar={goal?.first_name[0] + goal?.last_name[0]}
        category={goal?.reference_no}
        Description={goal?.title}
        Status={goal?.level}
        last_worked_date={goal?.last_worked_date}
      />
    );
  });

  return (
    <div
      className={`${Styles.ProfileContainer__ClientPanel_Card} ${Styles.lastWorkedGoals}`}
    >
      <div
        className={`${Styles.ProfileContainer__ClientPanel_CardHeader} d-flex align-center justify-center`}
      >
        <h3 className="f-18 f-w-700 lh-25 clr-dark-grey">Last Worked Goals</h3>
        <Button
          className="units-link-btn"
          onClick={() => {
            if (technician) {
              navigate("/technicians/inprogressgoals", {
                state: { technician: true, userId },
              });
              setdashboardView("InProgressGoals");
            } else {
              navigate("/clients/goals", { state: { client: true, userId } });
              setdashboardView("ViewGoals");
            }
          }}
        >
          View All Goals
        </Button>
      </div>
      {clientGoals?.length ? (
        GoalCards
      ) : (
        <div
          class="css-dev-only-do-not-override-w8mnev ant-empty ant-empty-normal"
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                <ellipse
                  fill="#f5f5f5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fill-rule="nonzero" stroke="#d9d9d9">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="ant-empty-description">No data</div>
        </div>
      )}
    </div>
  );
};

export default WorkedGoals;
