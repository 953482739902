import { React, useEffect, useState } from "react";
import Styles from "../Dashboard.module.scss";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../../constants/constants";
import { Breadcrumb } from "antd";
import { Button } from "react-bootstrap";
import ProfileModal from "../../../components/Modals/ProfileModal";
import { useNavigate, useParams } from "react-router-dom";
import WorkedGoals from "./WorkedGoals/WorkedGoals";
import httpClient from "../../../services/axios";
import { createNotification } from "../../../helpers/createNotifications";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import TechnicianDetails from "./TecnicianDetail/TechnicianDetail";
import { logout } from "../../../store/user/userSlice";
import { GetCurrentUser } from "../../../utils/utilAuth";
import DashboardPanelCard from "../../../components/DashboardPanelCard/DashboardPanelCard";
import ViewTechHoursModal from "../../../components/Modals/ViewTechHoursModal";
import { handleErrorResponse } from "../../../helpers/responseHandler";

const Technican = ({ setdashboardView, View }) => {
  const { id } = useParams();
  const user = GetCurrentUser();
  const [PrfoileModalView, setProfileModalView] = useState(false);
  const [clientProfile, setClientProfile] = useState();
  const [clientGoals, setClientGoals] = useState();
  const [chartData, setChartData] = useState();
  const [viewHoursModal, setViewHoursModal] = useState(false);
  const [goalsDuration, setGoalsDuration] = useState("this_week");

  useEffect(() => {
    const getQuestionData = async () => {
      try {
        if (!clientProfile) {
          const response = await httpClient.get(
            `api/v1/users/${id || user.slug}`
          );
          setClientProfile(response?.data);

          return response;
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id || user) {
      getQuestionData();
    }
  }, [clientProfile, id]);

  useEffect(() => {
    const getQuestionData = async () => {
      try {
        if (!clientGoals) {
          const response = await httpClient.get(
            `api/v1/goals/last_worked_goals?user_id=${id}&role=technician`
          );
          setClientGoals(response?.data);

          return response;
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id || user) getQuestionData();
  }, [clientGoals, id]);

  useEffect(() => {
    const getQuestionData = async () => {
      try {
        const response = await httpClient.get(
          `/api/v1/goals/total_worked_goals?technician_id=${id}&required_date=${goalsDuration}`
        );
        setChartData(response?.data?.goals_data);

        return response;
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    getQuestionData();
  }, [goalsDuration]);

  const handleUpdateProfile = async (profileUpdate) => {
    try {
      if (profileUpdate) {
        const res = await httpClient.put(`api/v1/users/${id}`, {
          ...profileUpdate,
        });
        if (res.data) {
          setClientProfile(res?.data);
        }
        return true;
      }
    } catch (error) {
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {clientProfile ? (
        <section className={Styles.ProfileContainer}>
          <div className={Styles.ProfileContainer__LeftSidebar}>
            <div className={Styles.ProfileContainer__LeftSidebar_Header}>
              <div
                onClick={() => {
                  setdashboardView("Technicians");
                  navigate("/technicians");
                }}
                className="lm-nav-arrow"
              >
                {arrowLeftPurple}
              </div>
              <Breadcrumb
                separator={arrowRightBreadcrumb}
                items={[
                  {
                    title: "Technicians",
                  },
                  {
                    title: "Technician Profile  ",
                  },
                ]}
              />
            </div>

            <TechnicianDetails
              setProfileModalView={setProfileModalView}
              clientProfile={clientProfile}
              setdashboardView={setdashboardView}
            />
            <section className={`${Styles.ProfileContainer_Footer} mt-auto`}>
              <div className="d-flex gap-20">
                <Button
                  onClick={() => {
                    setViewHoursModal(true);
                  }}
                  className={`w-50  height-49 lm-form-btn-modalbtn lm-form-btn-modalbtn-cancel f-15 f-w-550 clr-orange-pri border-clr-orange-pri`}
                >
                  Hour Overview
                </Button>
                <Button
                  onClick={() => {
                    setdashboardView("yourTechsingle");
                    navigate(`/technicians/${id}/sessions`, {
                      state: { name: clientProfile?.full_name },
                    });
                  }}
                  className={`w-50 height-49  lm-form-btn lm-form-btn-primary f-16 f-w-600 `}
                >
                  View Daily Sessions
                </Button>
              </div>
            </section>
          </div>

          <div className={Styles.ProfileContainer__ClientPanel}>
            <WorkedGoals
              clientGoals={clientGoals?.goals}
              setdashboardView={setdashboardView}
              userId={id}
              technician={true}
            />
            <DashboardPanelCard
              title={"Progress"}
              Goal={true}
              chartData={chartData}
              duration={setGoalsDuration}
            />
          </div>
          <ProfileModal
            technician={true}
            show={PrfoileModalView}
            handleUpdateProfile={handleUpdateProfile}
            clientProfile={clientProfile}
            setProfileModalView={setProfileModalView}
            onHide={() => {
              setProfileModalView(false);
            }}
          />

          <ViewTechHoursModal
            show={viewHoursModal}
            onHide={() => {
              setViewHoursModal(false);
            }}
            technicianID={id}
          />
        </section>
      ) : (
        <LoaderSpinner
          tip="Loading"
          size="50"
          top={"50%"}
          left={"50%"}
          color="#090834"
        />
      )}
    </>
  );
};

export default Technican;
