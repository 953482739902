const beforeUnloadHandler = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to leave? All unsaved changes will be lost.";
  };
  
  export const enableBeforeUnload = (user) => {
    if (user?.role === "technician") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
  
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  
    // If the condition is not met, return a no-op function to avoid errors
    return () => {};
  };
  