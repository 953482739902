import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./Modals.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import { Spin } from "antd";
import { emailRegex } from "../../helpers/utils";
import dayjs from "dayjs";
import CustomSelect from "../../Containers/Appointment/custom-select";

const AddUnitsModal = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { creds, setCreds, show, onHandleAdd, modalLoading, setLoadingModal } =
    props;

  useEffect(() => {
    if (
      creds?.unit_type?.length &&
      creds?.unit_code?.length &&
      creds?.authorized_units?.length
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [creds]);
  useEffect(() => {
    if (!show) {
      setCreds();
      setIsDisabled(true);
    }
  }, [show]);
  const insuranecOption = [
    { value: "Direct", key: "direct" },
    { value: "Supervision", key: "supervision" },
    { value: "Other", key: "other" },
  ];
  const CodeOption = [
    { value: "97151", key: "97151" },
    { value: "97152", key: "97152" },
    { value: "97153", key: "97153" },
    { value: "97154", key: "97154" },
    { value: "97155", key: "97155" },
    { value: "97156", key: "97156" },
    { value: "97157", key: "97157" },
  ];
  return (
    <Modal
      {...props}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Add Units
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-32 pt-32">
            <div className="d-flex flex-column gap-20">
              <div className="d-flex gap-30 flex-wrap">
                <CustomSelect
                  label={"Service Type"}
                  simple={true}
                  options={insuranecOption}
                  placeholder={"Select"}
                  handleValue={(value) =>
                    setCreds({ ...creds, unit_type: value })
                  }
                  className={"add-units-select"}
                  field={"location"}
                  fieldValue={creds?.unit_type}
                  name={creds?.unit_type}
                />
                <CustomSelect
                  label={"Service Code"}
                  simple={true}
                  options={CodeOption}
                  className={"add-units-select z-index-1"}
                  placeholder={"Select"}
                  handleValue={(value) =>
                    setCreds({ ...creds, unit_code: value })
                  }
                  field={"location"}
                  fieldValue={creds?.unit_code}
                  name={creds?.unit_code}
                />
                <TextFieldFormik
                  placeholder=""
                  label="Authorized Units"
                  wrapperClassName="w-100 mt-30"
                  type="number"
                  handleChange={(e) => {
                    setCreds({ ...creds, authorized_units: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between align-items-center">
              {/* <p className="units-p">Units Remaining: 74%</p> */}
              <Button
                disabled={isDisabled}
                className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 `}
                onClick={() => {
                  setLoadingModal(true);
                  onHandleAdd();
                }}
              >
                Add Units
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default AddUnitsModal;
