import { Spin } from "antd";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styleClasses from "../../Containers/Appointment/Appointments.module.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import "./Modals.scss";

const ViewCancelReasonModal = ({
  cancellationReason,
  ...props
}) => {
  const [loading, setLoading] = useState(props.modalLoading);
  

  return (
    <Modal
      {...props}
      size={500}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // style={{ width: "362px" }}
    >
      <Spin spinning={loading}>
        <Modal.Body style={{ width: "100%" }}>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
            cancellation Reason
            </Modal.Title>
          </Modal.Header>

          <div
            className={styleClasses.Appointments__AddAppointmentSidebar}
            style={{ width: "100%" }}
          >
            <div>
              <TextFieldFormik
                disabled = {true}
                wrapperClassName="w-100 cancellationReasonModal"
                type="textarea"
                values = {cancellationReason}
                />
            </div>

          </div>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default ViewCancelReasonModal;
