import { Breadcrumb, Spin } from "antd";
import React, { useEffect, useState } from "react";
import RightSideBar from "../RightSideBar";
import styleClasses from "./DailyDataSheetDetail.module.scss";
import { FaEraser, FaSave, FaSignature } from "react-icons/fa";
import { useMatch, useNavigate, useParams } from "react-router-dom/dist";
import Signature from "signature_pad";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../../constants/constants";
import { createNotification } from "../../../helpers/createNotifications";
import httpClient from "../../../services/axios";
import httpClientTwo from "../../../services/fetch";
import { logout } from "../../../store/user/userSlice";
import { GetCurrentUser } from "../../../utils/utilAuth";
import CommonDailySheetDetail from "./CommonDailySheetDetail";
import { questionInfo } from "./DailyDataSheetDetailConstant";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import { set } from "lodash";

const DailyDataSheetDetail = ({ setdashboardView }) => {
  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const user = GetCurrentUser();
  const [ques, setQues] = useState([]);
  const { id, serviceId, dataSheetId } = useParams();
  const [loading, setLoading] = useState();
  const [questionData, setQuestionData] = useState();
  const [signaturePad, setSignaturePad] = useState();
  const [savedSignature, setSavedSignature] = useState("");
  const [signaturePadTwo, setSignaturePadTwo] = useState();
  const [savedSignatureTwo, setSavedSignatureTwo] = useState("");
  const [comments, setComments] = useState();
  const [action, setAction] = useState(false);
  const [actionTwo, setActionTwo] = useState(false);
  const navigate = useNavigate();
  const [newQuestion, setNewQuestion] = useState();
  const [preFilledData, setPreFilledData] = useState();
  const [signatureDisable, setSignatureDisable] = useState();
  const [applyCalculations, setApplyCalculations] = useState(false);
  const [utcSavedDates, setUtcSavedDates] = useState(true);

  let signatureRedoArray = [];
  useEffect(() => {
    setLoading(true);
    const getServiceNoteData = async () => {
      try {
        const serviceNotes = await httpClient.get(
          `/api/v1/sections/service-note-section`,
          {
            params: {
              resource: "service_note",
              appointment_id: id,
            },
          }
        );
        if (serviceNotes.data) {
          setQuestionData(serviceNotes.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (
      id &&
      (route.includes("servicenote") || route.includes("serviceNote"))
    ) {
      getServiceNoteData();
    }
  }, [id]);

  useEffect(() => {
    const getFormPrefilledData = async () => {
      try {
        // http://localhost:3000/api/v1/data_sheets/537/service_note_saved_answers
        const serviceNotes = await httpClient.get(
          `/api/v1/data_sheets/${dataSheetId}/form_prefilled_answers?resource_type=service_note`
        );
        if (serviceNotes.data) {
          setPreFilledData(serviceNotes.data);
          setUtcSavedDates(serviceNotes.data?.utc_saved_dates);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (dataSheetId) {
      getFormPrefilledData();
    }
  }, [dataSheetId]);

  useEffect(() => {
    // setLoading(true);
    const getCommentsData = async () => {
      try {
        const allComments = await httpClient.get(
          `/api/v1/comments?service_note=${true}&resource_id=${serviceId} `
        );
        if (allComments?.data) {
          setComments(allComments.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (serviceId) getCommentsData();
  }, [action]);

  const postComments = async (commentsData) => {
    try {
      const res2 = await httpClient.post(
        `/api/v1/comments?service_note=${true}&resource_id=${serviceId} `,
        { ...commentsData }
      );
      if (res2?.data) {
        setComments(res2?.data);
        createNotification("success", "Comment Posted");
      }
      return true;
    } catch (error) {
      setLoading(false);
      handleErrorResponse(error);
    }
  };
  const putEditComments = async (commentsData, commentsId) => {
    try {
      const res2 = await httpClient.put(`/api/v1/comments/${commentsId} `, {
        ...commentsData,
      });
      if (res2?.data) {
        // setComments(res2?.data);
        createNotification("success", "Comment Updated");
        setAction(!action);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  function startsWithHttp(inputString) {
    return typeof inputString === "string" && inputString.startsWith("http");
  }

  const postData = async (postData) => {
    const formData = new FormData();

    if (!startsWithHttp(postData?.consultant_signature_image)) {
      formData.append(
        "consultant_signature_image",
        postData?.consultant_signature_image
      );
    }
    if (!startsWithHttp(postData?.guardian_signature_image)) {
      formData.append(
        "guardian_signature_image",
        postData?.guardian_signature_image
      );
    }
    try {
      if (postData) {
        // if (
        //   formData.get("guardian_signature_image") ||
        //   formData.get("consultant_signature_image")
        // ) {
        //   const res = await httpClientTwo.post(
        //     `/api/v1/sections/service-note-section/update_or_create?resource=${"service_note"}&appointment_id=${id}&image_only=${true}`,
        //     formData
        //   );
        // }
        const res2 = await httpClient.post(
          `/api/v1/sections/service-note-section/update_or_create`,
          { ...postData }
        );
        if (res2.data) {
          if (
            formData.get("guardian_signature_image") ||
            formData.get("consultant_signature_image")
          ) {
            const res = await httpClientTwo.post(
              `/api/v1/sections/service-note-section/update_or_create?resource=${"service_note"}&appointment_id=${id}&image_only=${true}`,
              formData
            );
          }
          setQuestionData(res2.data);
          createNotification("success", "Saved");
          setdashboardView("panel");
          navigate("/techdashboard");
        }
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const readyPad = () => {
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector(".signature-canvas");
    if (wrapper) {
      canvas?.getContext("2d").scale(1, 1);
      let tempSignaturePad = new Signature(canvas, {
        maxWidth: 2,
        backgroundColor: "rgb(255, 255, 255)",
      });
      setSignaturePad(tempSignaturePad);
    }
  };
  const readyPadTwo = () => {
    let wrapper = document.getElementById("signature-pad-2");
    let canvas = wrapper?.querySelector(".signature-canvas-2");
    if (wrapper) {
      canvas?.getContext("2d").scale(1, 1);
      let tempSignaturePad = new Signature(canvas, {
        maxWidth: 2,
        backgroundColor: "rgb(255, 255, 255)",
      });
      setSignaturePadTwo(tempSignaturePad);
    }
  };

  useEffect(() => {
    setSavedSignature(questionData?.consultant_signature_image);
    setSavedSignatureTwo(questionData?.guardian_signature_image);
  }, [questionData]);
  const handleSave = () => {
    // console.log(signaturePad);
    setSavedSignature(signaturePad.toDataURL("image/jpeg"));
  };
  const handleSaveTwo = () => {
    setApplyCalculations(true);
    setSavedSignatureTwo(signaturePadTwo.toDataURL("image/jpg"));
  };

  // const handleUndo = () => {
  //   let signatureRemovedData = [];
  //   let signatureData = signaturePad.toData();
  //   let signatureRedoData = _.cloneDeep(signatureData); //original data

  //   if (signatureData.length > 0) {
  //     signatureData.pop(); // remove the last dot or line
  //     signaturePad.fromData(signatureData);
  //     signatureRemovedData = signatureRedoData[signatureRedoData.length - 1];
  //     signatureRedoArray.push(signatureRemovedData);
  //   }
  // };
  // const handleRedo = () => {
  //   if (signatureRedoArray.length !== 0) {
  //     let values = signaturePad.toData();
  //     let lastValue = signatureRedoArray[signatureRedoArray.length - 1];
  //     values.push(lastValue);
  //     signaturePad.fromData(values);
  //     signatureRedoArray.pop(lastValue); //remove the redo item from array
  //   }
  // };

  const handleClear = () => {
    signaturePad.clear();
    setSavedSignature("");
  };
  const handleClearTwo = () => {
    signaturePadTwo.clear();
    setSavedSignatureTwo("");
  };
  useEffect(() => {
    readyPad();
    readyPadTwo();
  }, []);

  const newData = {
    questions: [
      {
        title: "note_recepient_first_name",
        answers: [
          {
            statement: "CHECK",
          },
        ],
      },
    ],
  };
  useEffect(() => {
    if (preFilledData && questionData) {
      const newQuestionData = { ...questionData };
      preFilledData.questions.forEach((question) => {
        const serviceNoteQuestion = newQuestionData.questions.find(
          ({ title }) => title == question.title
        );
        if (serviceNoteQuestion) {
          let answers = (serviceNoteQuestion.answers || []).map((answer) => {
            answer._destroy = true;
            return answer;
          });
          answers = answers.concat(
            (question.answers || []).map((answer, index) => {
              answer.meta = answer.meta ?? {};
              answer.meta.batch_no = index + 1;
              answer.statement = answer.statement ?? "";
              return answer;
            })
          );
          serviceNoteQuestion.answers = answers;
        }
      });

      setNewQuestion({
        ...newQuestionData,
        questions: [...newQuestionData?.questions],
      });
    }
  }, [preFilledData, questionData]);

  return (
    <section className={styleClasses.MainDataSheetDetailPage}>
      <div className={styleClasses.MainDataSheetDetailPage__Content}>
        <div className={styleClasses.breadcrumbWrapper}>
          <div
            className="lm-nav-arrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            {arrowLeftPurple}
          </div>
          <Breadcrumb
            separator={arrowRightBreadcrumb}
            items={[
              {
                title: "Service Note",
              },
            ]}
          />
        </div>
        <h3 className="mt-20 f-24 f-w-700 ms-96 pb-40">
          Service Contract Note
        </h3>
        <div className={styleClasses.breadcrumbWrapper__outerRecipent}>
          <>
            {newQuestion ? (
              <>
                <CommonDailySheetDetail
                  data={newQuestion}
                  questionInfo={questionInfo}
                  postData={postData}
                  dataSheetId={dataSheetId}
                  savedSignature={savedSignature}
                  savedSignatureTwo={savedSignatureTwo}
                  actionTwo={actionTwo}
                  setActionTwo={setActionTwo}
                  setdashboardView={setdashboardView}
                  setSignatureDisable={setSignatureDisable}
                  applyCalculations={applyCalculations}
                  serviceId={serviceId}
                  utcSavedDates={utcSavedDates}
                  // enableSaveBtn={enableSaveBtn}
                />
              </>
            ) : (
              <Spin
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
            <div className={styleClasses.signaturesWrapper}>
              <div
                className={styleClasses.firstSignature}
                style={{ position: "relative" }}
              >
                {((dataSheetId && user?.role === "director") ||
                  !signatureDisable) && (
                  <div className="signature-overlay"></div>
                )}

                <div
                  id="signature-pad"
                  className={`${!questionData && "signature-hidden"}`}
                >
                  <label>
                    Consultant Signature <FaSignature />
                  </label>
                  {savedSignature && (
                    <div
                      className={`saved-signature ${
                        savedSignature && "saved-signature-visible"
                      }`}
                    >
                      <img
                        className="signature-image"
                        alt="saved-signature"
                        src={savedSignature}
                        style={{ paddingTop: savedSignature ? "24px" : "" }}
                      />
                    </div>
                  )}
                  <canvas className="signature-canvas"></canvas>
                  {!savedSignature && (
                    <div className="signature-btn-wrapper">
                      <button
                        onClick={handleSave}
                        disabled={
                          savedSignature ||
                          (dataSheetId && user?.role === "director")
                        }
                      >
                        <FaSave /> Save
                      </button>
                      {/* <button onClick={handleUndo}>
                  <FaUndo /> Undo
                </button>
                <button onClick={handleRedo}>
                  <FaRedo /> Redo
                </button> */}
                      <button
                        onClick={handleClear}
                        disabled={user?.role === "director"}
                      >
                        <FaEraser /> Clear
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={styleClasses.firstSignature}
                style={{ position: "relative" }}
              >
                {((dataSheetId && user?.role === "director") ||
                  !signatureDisable) && (
                  <div className="signature-overlay"></div>
                )}
                <div
                  id="signature-pad-2"
                  className={`${!questionData && "signature-hidden"}`}
                >
                  <label>
                    Parent/Guardian Signature <FaSignature />
                  </label>
                  {savedSignatureTwo && (
                    <div
                      className={`saved-signature ${
                        savedSignatureTwo && "saved-signature-visible"
                      }`}
                    >
                      <img
                        className="signature-image"
                        style={{ paddingTop: savedSignatureTwo ? "24px" : "" }}
                        alt="saved-signature"
                        src={savedSignatureTwo}
                      />
                    </div>
                  )}
                  <canvas className="signature-canvas-2"></canvas>
                  {!savedSignatureTwo && (
                    <div className="signature-btn-wrapper">
                      <button
                        onClick={handleSaveTwo}
                        disabled={
                          savedSignatureTwo ||
                          (dataSheetId && user?.role === "director")
                        }
                      >
                        <FaSave /> Save
                      </button>
                      {/* <button onClick={handleUndo}>
                  <FaUndo /> Undo
                </button>
                <button onClick={handleRedo}>
                  <FaRedo /> Redo
                </button> */}
                      <button
                        onClick={handleClearTwo}
                        disabled={user?.role === "director"}
                      >
                        <FaEraser /> Clear
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {serviceId && (
        <RightSideBar
          postComments={postComments}
          comments={comments}
          putEditComments={putEditComments}
          setComments={setComments}
          setAction={setAction}
          action={action}
        />
      )}
    </section>
  );
};

export default DailyDataSheetDetail;
