import React, { useEffect, useState } from "react";
import ImageView from "../../components/Image/ImageView";
import Styles from "./Dashboard.module.scss";
import { Form } from "react-bootstrap";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import { LockOrangeIcon, bellOrangeIcon } from "../../constants/constants";
import { Spin, Switch } from "antd";
import UpdatePasswordModal from "../../components/Modals/UpdatePasswordModal";
import httpClientTwo from "../../services/fetch";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import LoaderSpinner from "../../components/general/loader/loader.spinner";
import { GetCurrentUser } from "../../utils/utilAuth";
import { useNavigate } from "react-router-dom";
import { NumberFormat } from "../../helpers/utils";
import { setUserProfile } from "../../store/user/userSlice";
import { useDispatch } from "react-redux";
import userProfile1 from "../../assets/images/profile.png";

const cameraIcon = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11.125C8.9375 11.125 9.73437 10.7969 10.3906 10.1406C11.0469 9.48438 11.375 8.6875 11.375 7.75C11.375 6.8125 11.0469 6.01562 10.3906 5.35938C9.73437 4.70312 8.9375 4.375 8 4.375C7.0625 4.375 6.26562 4.70312 5.60937 5.35938C4.95312 6.01562 4.625 6.8125 4.625 7.75C4.625 8.6875 4.95312 9.48438 5.60937 10.1406C6.26562 10.7969 7.0625 11.125 8 11.125ZM8 10.375L7.175 8.575L5.375 7.75L7.175 6.925L8 5.125L8.825 6.925L10.625 7.75L8.825 8.575L8 10.375ZM2 13.75C1.5875 13.75 1.23438 13.6031 0.940625 13.3094C0.646875 13.0156 0.5 12.6625 0.5 12.25V3.25C0.5 2.8375 0.646875 2.48438 0.940625 2.19063C1.23438 1.89688 1.5875 1.75 2 1.75H4.3625L5.75 0.25H10.25L11.6375 1.75H14C14.4125 1.75 14.7656 1.89688 15.0594 2.19063C15.3531 2.48438 15.5 2.8375 15.5 3.25V12.25C15.5 12.6625 15.3531 13.0156 15.0594 13.3094C14.7656 13.6031 14.4125 13.75 14 13.75H2ZM14 12.25V3.25H10.9625L9.59375 1.75H6.40625L5.0375 3.25H2V12.25H14Z"
      fill="#F6891F"
    />
  </svg>
);
const ProfileSetting = ({ setdashboardView }) => {
  const { slug } = GetCurrentUser();

  const [notficationsAgree, setNotficationsAgree] = useState(false);
  const [prevNoti, setPrevNoti] = useState();
  const [disabled, setDisabled] = useState(false);
  const [UpdateModalView, setUpdateModalView] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imageURL, setImageURL] = useState();
  const [profileImage, setProfileImg] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  // const [lgLoading, setLgLoading] = useState("");
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [updatedPhoneNo, setUpdatedPhoneNo] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const [noti, setNoti] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullname, setFullName] = useState();

  const handleInitialProfile = (data) => {
    const fullName = data?.full_name;
    setFullName(data?.full_name);
    // const nameParts = fullName.split(" ");
    setFirstName(data?.first_name);
    setUpdatedFirstName(data?.first_name);
    setUpdatedLastName(data?.last_name);
    setLastName(data?.last_name);
    setPhoneNo(data?.phone_no);
    setUpdatedPhoneNo(data?.phone_no);
    setEmail(data?.email);
    setProfileImg(data?.profile_image);
    setNotficationsAgree(data.notification_status);
    setPrevNoti(data.notification_status);
  };

  useEffect(() => {
    setLoading(true);
    const getUserDetails = async () => {
      try {
        const response = await httpClient.get(`/api/v1/users/${slug}`);
        if (response?.data) {
          handleInitialProfile(response?.data);
          dispatch(
            setUserProfile({
              profile_image: response?.data?.profile_image,
              full_name: response?.data?.full_name,
            })
          );
        }
      } catch (error) {
        if (
          error?.response?.data?.response?.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          if (!imageFile) {
            setLoading(false);
          }
          localStorage.removeItem(`persist:root`);

          // logout();

          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error?.message);
      } finally {
        if (!imageFile) {
          setLoading(false);
        }
      }
    };
    getUserDetails();
  }, [fetchData]);
  const normalizePhoneNumber = (phoneNumber) => {
    return phoneNumber?.replace(/[^\d]/g, "");
  };
  const [newDis, setNewDis] = useState(false);
  const [newDis2, setNewDis2] = useState(false);

  useEffect(() => {
    // console.log(phoneNo, updatedPhoneNo, "HELO");
    // const normalizedPhoneNumber1 = normalizePhoneNumber(phoneNo);
    // const normalizedPhoneNumber2 = normalizePhoneNumber(updatedPhoneNo);
    // if (
    //   (updatedFirstName?.length ||
    //   updatedLastName?.length ||
    //   imageFile )&&
    //   (phoneNo &&
    //     phoneNo?.length === 14 &&
    //     normalizedPhoneNumber1 != normalizedPhoneNumber2) ||
    //   notficationsAgree != prevNoti
    // ) {
    //   if(phoneNo?.length < 14){
    //   setDisabled(true);

    if (phoneNo && phoneNo?.length === 14) {
      setDisabled(false);
    } else if (phoneNo?.length === 0) {
      setDisabled(false);
    } else if (!phoneNo) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    // if (phoneNo && phoneNo?.length === 14) {
    //   setDisabled(false);
    //   setNoti(false);
    // }
    // if (notficationsAgree != prevNoti) {
    //   setDisabled(false);
    //   setNoti(false);
    // } else {
    //   console.log("idr aya");
    //   setDisabled(true);
    //   setNoti(true);
    // }
    //   setDisabled(false);
    //   //   setNoti(false);
    // } else if(phoneNo?.length < 14) {
    //   setDisabled(true);

    // }

    // else {
    //   setDisabled(true);
    // }
  }, [
    updatedFirstName,
    updatedLastName,
    updatedPhoneNo,
    imageFile,
    phoneNo,
    notficationsAgree,
  ]);

  useEffect(() => {
    if (
      (!updatedLastName.length && !!lastName.length) ||
      updatedLastName.trim().length === 0
    ) {
      setNewDis2(true);
    } else {
      setNewDis2(false);
    }
  }, [updatedLastName]);
  useEffect(() => {
    if (
      (!updatedFirstName.length && !!firstName.length) ||
      updatedFirstName.trim().length === 0
    ) {
      setNewDis(true);
    } else {
      setNewDis(false);
    }
  }, [updatedFirstName, setUpdatedFirstName]);

  const handleFirstNameChange = (e) => {
    setUpdatedFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setUpdatedLastName(e.target.value);
  };

  const handlePhoneNoChange = (e) => {
    const rawValue = e.target.value.slice(0, 14); // Remove non-numeric characters and limit to 10 digits

    setPhoneNo(rawValue);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      createNotification("error", "File size should be less the 5MB");
      return;
    }
    const selectedImage = e.target.files[0];
    setImageFile(selectedImage);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageURL(reader.result);
    };

    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleSave = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (
      updatedFirstName?.length ||
      updatedLastName?.length ||
      phoneNo?.length ||
      notficationsAgree?.toString()?.length
    ) {
      const payload = {
        first_name: updatedFirstName || firstName,
        last_name: updatedLastName || lastName,
        phone_no: phoneNo,
        notification_status: notficationsAgree,
      };
      try {
        const response = await httpClient.post(
          `/api/v1/users/${slug}/update_user_profile`,
          {
            user: payload,
          }
        );
        if (response?.data) {
          // setUpdatedFirstName("");
          // setUpdatedLastName("");
          // setUpdatedPhoneNo("");
          if (!imageFile) {
            setFetchData(!fetchData);
            createNotification(
              "success",
              "Profile has been updated successfully"
            );
            setLoading(false);
          }
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          if (!imageFile) {
            setLoading(false);
          }
          localStorage.removeItem(`persist:root`);

          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error?.message);
        setLoading(false);
        return false;
      } finally {
        if (!imageFile) {
          setLoading(false);
        }
      }
    }
    if (imageFile) {
      const formData = new FormData();
      formData.append("profile_image", imageFile);
      try {
        const response = await httpClientTwo.post(
          `/api/v1/users/${slug}/update_profile_image`,
          formData
        );
        if (response?.data) {
          createNotification(
            "success",
            "Profile has been updated successfully"
          );
          setImageFile(null);
          setFetchData(!fetchData);
          // navigate("/");
          // setdashboardView("panel");
          setLoading(false);
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          setLoading(false);

          return;
        }
        createNotification("error", error?.response?.data?.response?.message);
        setLoading(false);
        console.log("Something Went wrong", error?.message);
        return false;
      }
    }
  };

  return (
    <section className={`${Styles.ProfileSettings}`}>
      {loading ? (
        <Spin
          size="large"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <div className={Styles.ProfileSettings__Container}>
          <h2 className="f-27 f-w-600 clr-dark-grey">
            Hello,{" "}
            {firstName && lastName ? firstName + " " + lastName : fullname}
          </h2>
          <div className={Styles.ProfileSettings__ImageSection}>
            <ImageView
              src={imageURL ? imageURL : profileImage || userProfile1}
            />
            <div
              className={Styles.ProfileSettings__InputSection}
              style={{ cursor: "pointer" }}
            >
              <input
                type={"file"}
                className="input-btn"
                accept="image/x-png,image/jpeg,image/jpg"
                onChange={handleImageChange}
              />
              {cameraIcon}
            </div>
          </div>
          <Form className="d-flex flex-column gap-24">
            <div className="d-flex gap-30">
              <TextFieldFormik
                // required={true}
                label="First Name"
                placeholder="First name"
                values={updatedFirstName}
                type="text"
                handleChange={(e) => handleFirstNameChange(e)}
              />
              <TextFieldFormik
                // required={true}
                label="Last Name"
                placeholder="Last name"
                type="text"
                values={updatedLastName}
                handleChange={(e) => handleLastNameChange(e)}
              />
            </div>
            <TextFieldFormik
              disabled={true}
              label="Email"
              values={email}
              type="email"
            />
            <TextFieldFormik
              label="Contact"
              type="number"
              placeholder=""
              handleChange={(e) => handlePhoneNoChange(e)}
              values={NumberFormat(phoneNo, "(xxx) xxx-xxxx")}
            />
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-4 align-items-center">
                {bellOrangeIcon}
                <span className="f-16 lh-20 f-w-600 clr-orange-pri">
                  Notifications
                </span>
              </div>
              <Switch
                onChange={() => {
                  setDisabled(false);
                  setNotficationsAgree(!notficationsAgree);
                }}
                checked={notficationsAgree}
              />
            </div>
            <div
              onClick={() => {
                setUpdateModalView(true);
              }}
              className="cursor-pointer d-flex gap-4 align-items-center"
            >
              {LockOrangeIcon}
              <span className="f-16 lh-20 f-w-600 clr-orange-pri">
                Change Password
              </span>
            </div>
            <div className="d-flex gap-30 mt-56">
              <button
                className={`w-50 lm-form-btn-modalbtn lm-form-btn-modalbtn-cancel f-16 f-w-600 clr-orange-pri border-clr-orange-pri`}
                onClick={() => {
                  setdashboardView?.("panel");
                  navigate("/techdashboard");
                }}
              >
                Cancel
              </button>
              <button
                className={`w-50 lm-form-btn lm-form-btn-primary f-16 f-w-600 profile-save-btn `}
                disabled={disabled || loading || newDis || newDis2}
                onClick={handleSave}
              >
                {loading ? <LoaderSpinner color="white" /> : "Save"}
              </button>
            </div>
          </Form>
        </div>
      )}

      <UpdatePasswordModal
        show={UpdateModalView}
        setUpdateModalView={setUpdateModalView}
        slug={slug}
        onHide={() => {
          setUpdateModalView(false);
        }}
      ></UpdatePasswordModal>
    </section>
  );
};

export default ProfileSetting;
