import React from "react";
import ImageView from "../../components/Image/ImageView";
// import DummyImage from "../../assets/images/user-profile.png";
import DummyImage from "./../../assets/images/user-profile.png";
import Styles from "../../Containers/Dashboard/Dashboard.module.scss";
import { Select, Spin } from "antd";
import {
  MonthOptions,
  ScaleOptions,
  arrowDownBlack,
} from "../../constants/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";

const UpcomingSessionListing = ({
  Sessions,
  Day,
  setFilter,
  filter,
  metaData,
  setUpComingSessions,
  setMetaData,
  currentPage,
  setCurrentPage,
}) => {
  const getLetter = (name) => {
    const words = name?.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words?.length >= 1) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1]?.[0]?.toUpperCase() || "";
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return extractedLetters;
  };

  const convertToAMPM = (utcDateTime) => {
    const date = new Date(utcDateTime);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return formattedTime;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
      dayWithSuffix = `${day}st`;
    } else if (day === 2 || day === 22) {
      dayWithSuffix = `${day}nd`;
    } else if (day === 3 || day === 23) {
      dayWithSuffix = `${day}rd`;
    } else {
      dayWithSuffix = `${day}th`;
    }

    return `${dayWithSuffix} ${month}`;
  };
  const fetchMoreData = () => {
    const queryParams = {
      page: currentPage + 1,
      per_page: 10,
      filter: filter,
    };
    const queryString = new URLSearchParams(queryParams).toString();
    const getUpcomingSessions = async () => {
      try {
        httpClient
          .get(`/api/v1/appointments/fetch_upcoming_sessions?${queryString}`)
          .then((response) => {
            setUpComingSessions([...Sessions, ...response?.data?.data]);
            setMetaData(response?.data?.meta);
            setCurrentPage(currentPage + 1);
          });
      } catch (error) {
        if (
          error.response.data === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getUpcomingSessions();
  };
  const compareDates = (a, b) => {
    const dateA = new Date(a.start_time).toISOString();
    const dateB = new Date(b.start_time).toISOString();

    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      {" "}
      <ul
        className={Styles.Dashboard__Panel__RightSidebar_Listing}
        style={{ padding: "0 2px" }}
      >
        <InfiniteScroll
          dataLength={Sessions?.length ?? 10}
          next={() => fetchMoreData({ page: metaData?.current_page })}
          className="upcoming-sessions-scroll"
          hasMore={
            metaData?.current_page === metaData?.total_pages ? false : true
          }
          // style={{ position: "relative" }}
          height={"100vh"}
          width={"100%"}
          loader={<Spin size="small" left={"40%"} color="#090834" />}
        >
          {Sessions?.length ? (
            Sessions?.sort(compareDates)?.map((sess) => {
              return (
                <li
                  className={
                    Styles.Dashboard__Panel__RightSidebar_Listing__ListItem
                  }
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <span className="f-15 lh-30 f-w-600 clr-dark-grey">
                        {sess?.client_name}
                      </span>
                      <span className="f-14 lh-30 f-w-400 clr-dark-grey">
                        Technician: {sess?.technician_name}
                      </span>
                    </div>
                    <span
                      data-column-name="name"
                      className="rounded-avatar-letters name-show"
                      alt="client-profile"
                      style={{
                        width: "45px",
                        height: "45px",
                        fontSize: "24px",
                      }}
                      //   style={{ background: "purple" }}
                      // src={row.image || "/src/assets/images/tkxel_logo.png"}
                    >
                      {getLetter(sess?.client_name)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="f-14 lh-18 f-w-400 clr-grey-blur">
                      {convertToAMPM(sess?.start_time) +
                        " - " +
                        convertToAMPM(sess?.end_time)}
                    </span>
                    <span className="f-14 lh-18 f-w-400 clr-grey-blur">
                      {formatDate(sess?.schedule_date)}
                    </span>
                  </div>
                </li>
              );
            })
          ) : (
            <div
              className="compilance-header"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
                <div class="ant-empty-image">
                  <svg
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(0 1)"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <ellipse
                        fill="#f5f5f5"
                        cx="32"
                        cy="33"
                        rx="32"
                        ry="7"
                      ></ellipse>
                      <g fill-rule="nonzero" stroke="#d9d9d9">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                        <path
                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                          fill="#fafafa"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="ant-empty-description">No data</div>
              </div>
            </div>
          )}
        </InfiniteScroll>
      </ul>
    </>
  );
};

export default UpcomingSessionListing;
