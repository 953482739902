import Carousel from "react-bootstrap/Carousel";
import timerred from "../../../assets/svg/timerred.svg";
import calenderimg from "../../../assets/svg/calenderDate.svg";
import flagicon from "../../../assets/svg/Flagicon.svg";
import locationicon from "../../../assets/svg/location pin.svg";
import StartSessionModal from "../../../components/Modals/StartSessionModal";
import { useState } from "react";
import React, { useEffect } from "react";
import fetchFormattedDateTime from "../../../utils/datetimeUtlis";

const TodaysSession = ({
  sessionData,
  handleButtonClick,
  handleStartSession,
}) => {
  const screenWidth = window.innerWidth;
  const itemsPerSlide = screenWidth >= 1000 ? 4 : 3;
  const [appointments, setAppointments] = useState(sessionData); // Initial appointments

  const totalSlides = Math.ceil(appointments?.length / itemsPerSlide);
  const [openModal, setOpenModal] = useState(false);
  const [singleItem, setSingleitem] = useState();
  const [firstVisitTime, setFirstVisitTime] = useState(new Date());
  const [firstIntervalCompleted, setFirstIntervalCompleted] = useState(false);

  const createInterval = (delaySeconds) => {
    const intervalId = setInterval(() => {
      const currentDateTime = new Date(fetchFormattedDateTime());
      if (currentDateTime.getMinutes() % 5 === 0) {
        const updatedAppointments = appointments.map((appointment) => {
          const startTime = new Date(appointment.schedule_datetime);
          const timeDifference = startTime - currentDateTime;
          if (timeDifference <= appointment?.checkin_unlock_time * 60 * 1000) {
            return { ...appointment, is_checkin_locked: false };
          } else if (timeDifference <= appointment?.appointment_unlock_time * 60 * 1000) {
            return { ...appointment, is_locked: false };
          } else {
            return appointment;
          }
        });
        setAppointments(updatedAppointments);
      }
      setFirstIntervalCompleted(true);
    }, delaySeconds * 1000);
    return () => clearInterval(intervalId);
  };

  const calculateDelay = () => {
    if (!firstIntervalCompleted) {
      return 60 - firstVisitTime?.getSeconds();
    } else {
      return 60;
    }
  };

  useEffect(() => {
    const delayTime = calculateDelay();
    const cleanup = createInterval(delayTime);
    return cleanup;
  }, [firstIntervalCompleted]);

  const renderCarouselItems = () => {
    const carouselItems = [];

    for (let i = 0; i < totalSlides; i++) {
      const startIndex = i * itemsPerSlide;
      const endIndex = startIndex + itemsPerSlide;
      const slideItems = appointments.slice(startIndex, endIndex);

      const carouselItem = (
        <Carousel.Item className="carousel-outer-main-item" key={i}>
          <div className="session-card-outer">
            {slideItems.map((item) => {
              const clientName = item?.client || "";
              const hyphenIndex = clientName.indexOf("-");
              const firstTwoCharacters = clientName.substring(0, 1);
              const lastTwoCharacters = clientName.substring(
                hyphenIndex + 1,
                hyphenIndex + 2
              );
              return (
                <div className="first-session-card" key={item?.id}>
                  <div className="card-icon">
                    <p>
                      {firstTwoCharacters}
                      {lastTwoCharacters}
                    </p>
                  </div>
                  <div className="name-age">
                    <p className="name-joe">{item?.client}</p>
                    <span className="age-year">
                      Age: {item?.age || 0} Years
                    </span>
                  </div>
                  <div className="time-location">
                    <div className="starttime-endtime">
                      <img src={timerred} alt="Timer" />
                      <span className="timer">
                        {item?.start_time} - {item?.end_time}
                      </span>
                    </div>
                    <div className="starttime-endtime">
                      <div>
                        <img src={calenderimg} alt="Calendar" />
                        <span className="timer">{item?.schedule_date}</span>
                      </div>
                    </div>

                    <div className="in-location">
                      <img src={locationicon} alt="Location" />
                      <span className="in-clinic">
                        {item?.location?.replace("_", " ")}
                      </span>
                    </div>
                  </div>
                  {item.objectives_present ? (
                    <button
                      className="card-button"
                      // style={{ cursor: "pointer" }}
                      disabled={item.is_checkin_locked}
                      onClick={() => {
                        setSingleitem(item);
                        setOpenModal(true);
                      }}
                    >
                      <a
                        className="start-session-btn"
                        style={{ fontSize: "12px" }}
                      >
                        {item.is_checkin_locked
                          ? "Checkin Locked"
                          : "Start Appointment"}
                      </a>
                    </button>
                  ) : (
                    <button
                      className="card-button"
                      // style={{ cursor: "pointer" }}
                      disabled={item.is_locked}
                      onClick={() => {
                        setSingleitem(item);
                        handleStartSession(item);
                      }}
                    >
                      <a className="start-session-btn">
                        {item.is_locked ? "Locked" : "Start Session"}
                      </a>
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </Carousel.Item>
      );

      carouselItems.push(carouselItem);
    }

    return carouselItems;
  };

  return (
    <div className="carousel-outer">
      <Carousel className="carousel-outer-main">
        {renderCarouselItems()}
      </Carousel>
      <StartSessionModal
        show={openModal}
        // clientname={singleRowData?.Name}
        // archive={true}
        // modalLoading={modalLoading}
        onHide={() => {
          setOpenModal(false);
        }}
        setOpenModal={setOpenModal}
        item={singleItem}
        onHandleClick={handleButtonClick}
      />
    </div>
  );
};

export default TodaysSession;
