import React, { useEffect, useState } from "react";
import "./multiStepperForm.scss";
import logo from "../../assets/images/lm-logo-white.svg";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import crossicon from "../../assets/images/cross-icon-dim.svg";
import { Steps } from "antd";
import GeneralInformationForm from "./basicInformation/General/GeneralInformationForm";
import InnerFooter from "./InnerFooter";
import InnerHeader from "./InnerHeader";
import InsuranceInformation from "./basicInformation/insuranceInformation/insuranceInformation";
import IndexPage from "./IndexPage";
import PatientInformation from "./basicInformation/patientInformation/patientInformation";
import ParentAndGuardianInformation from "./basicInformation/ParentAndGuardianInformation/ParentAndGuardianInformation";
import StepCompletionModal from "../Modals/StepCompletionModal";
import { formData, learnnMeForm } from "../../constants/questions";
import { logoutIcon } from "../../constants/constants";
import HealthCareProviders from "./medicalHistory/healthCareProviders/healthCareProviders";
import PatientMedicalInformation from "./medicalHistory/patientMedicalInformation/patientMedicalInformation";
import TherapyProviders from "./therapyProviders/therapyProviders";
import CurrentPerformance from "./education/currentPerformance/currentPerformance";
import InstitudeAndServices from "./education/instituteAndServices/instituteAndServices";
import CaregiverInvolement from "./familyBackground/caregiverInvolvement/caregiverInvolvement";
import HomeAndFamily from "./familyBackground/homeAndFamily/homeAndFamily";
import PsychologicalHistory from "./psychologicalHistory/psychologicalHistory";
import BirthInformation from "./birthAndDevelopment/birthInformation/birthInformation";
import GeneralBehaviour from "./currrentBehaviours/generalBehaviour/generalBehaviour";
import SuicideAndSelfHarm from "./currrentBehaviours/suicideAndSelfHarm/suicideAndSelfHarm";
import SensoryIntegration from "./currrentBehaviours/sensoryIntegration/sensoryIntegration";
import Communication from "./currrentBehaviours/communication/communication";
import SelfCare from "./currrentBehaviours/selfCare/selfCare";
import SocialSkills from "./currrentBehaviours/socialSkills/socialSkills";
import DisciplineInformation from "./disciplineInformation/disciplineInformation";
import DrugAndAlcoholProfile from "./currrentBehaviours/drugAndAlcoholProfile/drugAndAlcoholProfile";
import LegalStatus from "./currrentBehaviours/legalStatus/legalStatus";
import { useDispatch, useSelector } from "react-redux";
import { stepsMapping } from "./stepsMapping";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout, setCurrentUser } from "../../store/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import DevelopmentBackground from "./birthAndDevelopment/developmentBackground/developmentBackground";
import InnerFooterReview from "./innerFooterReview";

export default function MultiStepperForm() {
  const [stepCompletionModal, setStepCompletionModal] = useState(false);
  const [progress, setProgress] = useState();
  const [progressForm, setProgressForm] = useState();
  const [progressSection, setProgressSection] = useState();
  const [action, setAction] = useState(false);

  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { Step } = Steps;

  const steps = [
    { step: 0, title: "Confidential Information", subSteps: [], slug: "" },
    {
      step: 1,
      title: "Basic Information",
      slug: "basic-information",
      subSteps: [
        { subStep: 1, title: "General", slug: "general" },
        {
          subStep: 2,
          title: "Insurance Information",
          slug: "insurance-information",
        },
        {
          subStep: 3,
          title: "Patient Information",
          slug: "patient-information",
        },
        {
          subStep: 4,
          title: "Parent/Guardian Information",
          slug: "parent-guardian-information",
        },
      ],
    },
    {
      step: 2,
      title: "Medical History",
      slug: "medical-history",
      subSteps: [
        {
          subStep: 1,
          title: "Health Care Providers",
          slug: "health-care-providers",
        },
        {
          subStep: 2,
          title: "Patient Medical Information",
          slug: "patient-medical-information",
        },
      ],
    },
    {
      step: 3,
      title: "Therapy Providers",
      slug: "therapy-providers",
      subSteps: [],
    },
    {
      step: 4,
      title: "Education",
      slug: "education",
      subSteps: [
        {
          subStep: 1,
          title: "Institute & Services",
          slug: "institute-services",
        },
        {
          subStep: 2,
          title: "Current Performance",
          slug: "current-performance",
        },
      ],
    },
    {
      step: 5,
      title: "Family Background",
      slug: "family-background",
      subSteps: [
        {
          subStep: 1,
          title: "Caregiver Involvement",
          slug: "caregiver-involvement",
        },
        { subStep: 2, title: "Home & Family", slug: "home-family" },
      ],
    },
    {
      step: 6,
      title: "Psychological history",
      slug: "psychological-history",
      subSteps: [],
    },
    {
      step: 7,
      title: "Birth & development",
      slug: "birth-development",
      subSteps: [
        { subStep: 1, title: "Birth Information", slug: "birth-information" },
        {
          subStep: 2,
          title: "Development Background",
          slug: "development-background",
        },
      ],
    },
    {
      step: 8,
      title: "Current Behaviours",
      slug: "current-behaviours",
      subSteps: [
        { subStep: 1, title: "General Behaviour", slug: "general-behaviour" },
        { subStep: 2, title: "Suicide & Self Harm", slug: "suicide-self-harm" },
        {
          subStep: 3,
          title: "Sensory Integration",
          slug: "sensory-integeration",
        },
        { subStep: 4, title: "Communication", slug: "communication" },
        { subStep: 5, title: "Self Care", slug: "self-care" },
        { subStep: 6, title: "Social Skills", slug: "social-skills" },
        {
          subStep: 7,
          title: "Drug & Alcohol Profile",
          slug: "drug-alcohol-profile",
        },
        { subStep: 8, title: "Legal Status", slug: "legal-status" },
      ],
    },
    {
      step: 9,
      title: "Discipline Information",
      slug: "discipline-information",
      subSteps: [],
    },
  ];

  const renderStep = (step, subStep, setAction, action) => {
    switch (step) {
      case 0:
        return <IndexPage prev={prev} next={next} />;
      case 1:
        switch (subStep) {
          case 0:
            return (
              <GeneralInformationForm
                data={learnnMeForm.sections[0].sub_sections[0]}
                prev={prev}
                setAction={setAction}
                action={action}
                next={next}
                id={id}
              />
            );

          case 1:
            return (
              <InsuranceInformation
                data={learnnMeForm.sections[0].sub_sections[1]}
                prev={prev}
                setAction={setAction}
                action={action}
                next={next}
                id={id}
              />
            );
          case 2:
            return (
              <PatientInformation
                data={learnnMeForm.sections[0].sub_sections[2]}
                prev={prev}
                next={next}
                id={id}
                setAction={setAction}
                action={action}
              />
            );
          case 3:
            return (
              <ParentAndGuardianInformation
                data={learnnMeForm.sections[0].sub_sections[3]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
        }

      case 2:
        switch (subStep) {
          case 0:
            return (
              <HealthCareProviders
                data={learnnMeForm.sections[1].sub_sections[0]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
          case 1:
            return (
              <PatientMedicalInformation
                data={learnnMeForm.sections[1].sub_sections[1]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
        }
      case 3:
        return (
          <>
            <p
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24.51px",
                maxWidth: "475px",
                width: "100%",
              }}
            >
              Please provide us with information regarding the following types
              of current or previous therapy providers and copies of any recent
              evaluations that indicate dates of previous treatment and
              therapeutic interventions and responses.
            </p>
            <TherapyProviders
              data={learnnMeForm.sections[2].sub_sections[0]}
              prev={prev}
              next={next}
              setAction={setAction}
              action={action}
              id={id}
            />
          </>
        );
      case 4:
        switch (subStep) {
          case 0:
            return (
              <InstitudeAndServices
                data={learnnMeForm.sections[3].sub_sections[0]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
          case 1:
            return (
              <CurrentPerformance
                data={learnnMeForm.sections[3].sub_sections[1]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
        }
      case 5:
        switch (subStep) {
          case 0:
            return (
              <CaregiverInvolement
                data={learnnMeForm.sections[4].sub_sections[0]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
          case 1:
            return (
              <HomeAndFamily
                data={learnnMeForm.sections[4].sub_sections[1]}
                prev={prev}
                next={next}
                setAction={setAction}
                action={action}
                id={id}
              />
            );
        }
      case 6:
        return (
          <PsychologicalHistory
            data={learnnMeForm.sections[5].sub_sections[0]}
            prev={prev}
            setAction={setAction}
            action={action}
            next={next}
            id={id}
          />
        );
      case 7:
        switch (subStep) {
          case 0:
            return (
              <BirthInformation
                data={learnnMeForm.sections[6].sub_sections[0]}
                prev={prev}
                setAction={setAction}
                action={action}
                next={next}
                id={id}
              />
            );
          case 1:
            return (
              <DevelopmentBackground
                data={learnnMeForm.sections[6].sub_sections[1]}
                prev={prev}
                setAction={setAction}
                action={action}
                next={next}
                id={id}
              />
            );
        }
      case 8:
        switch (subStep) {
          case 0:
            return (
              <GeneralBehaviour
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                id={id}
                data={learnnMeForm.sections[7].sub_sections[0]}
              />
            );
          case 1:
            return (
              <SuicideAndSelfHarm
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[1]}
                id={id}
              />
            );
          case 2:
            return (
              <SensoryIntegration
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[2]}
                id={id}
              />
            );
          case 3:
            return (
              <Communication
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[3]}
                id={id}
              />
            );
          case 4:
            return (
              <SelfCare
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[4]}
                id={id}
              />
            );
          case 5:
            return (
              <SocialSkills
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[5]}
                id={id}
              />
            );
          case 6:
            return (
              <DrugAndAlcoholProfile
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[6]}
                id={id}
              />
            );
          case 7:
            return (
              <LegalStatus
                next={next}
                setAction={setAction}
                action={action}
                prev={prev}
                data={learnnMeForm.sections[7].sub_sections[7]}
                id={id}
              />
            );
        }
      case 9:
        return (
          <DisciplineInformation
            prev={prev}
            setAction={setAction}
            action={action}
            data={learnnMeForm.sections[8].sub_sections[0]}
            id={id}
            next={next}
          />
        );
      default:
        return null;
    }
  };
  const dispatch = useDispatch();
  const currentUser = useSelector((state) =>
    state.user ? state.user.currentUser : null
  );
  const handleProgress = (progress) => {
    const currentForm = steps.find(
      (step) => step.slug == progress?.progress?.current_form
    );
    if (currentForm) {
      setProgressForm(currentForm?.step);
      const currentSection = currentForm?.subSteps?.find(
        (subStep) => subStep.slug == progress?.progress?.current_section
      );
      setProgressSection(currentSection?.subStep);
    }
  };
  useEffect(() => {
    const getCurrentPorgress = async () => {
      try {
        const response = id
          ? await httpClient.get(
              `api/v1/sections/client_section_progress/${id}`
            )
          : await httpClient.get(`api/v1/sections/user_section_progress`);
        if (response.data.progress) {
          handleProgress(response.data);
          setProgress(response.data);
          // setParentuStep(stepsMapping[response.data.progress.current_form]);
          // setChildStep(stepsMapping[response.data.progress.current_section]);
          // if (!currentUser.form && !currentUser.section) {
          dispatch(
            setCurrentUser({
              token: currentUser.token,
              form: response.data.progress.current_form,
              section: response.data.progress.current_section,
              role: currentUser.role,
              slug: currentUser.slug,
            })
          );
          // }
        }
      } catch (error) {
        createNotification("error", error);
      }
    };
    getCurrentPorgress();
  }, []);
  const getCurrentPorgress = async () => {
    try {
      const response = id
        ? await httpClient.get(`api/v1/sections/client_section_progress/${id}`)
        : await httpClient.get(`api/v1/sections/user_section_progress`);
      if (response.data.progress) {
        handleProgress(response.data);
        setProgress(response.data);
        // setParentuStep(stepsMapping[response.data.progress.current_form]);
        // setChildStep(stepsMapping[response.data.progress.current_section]);
        // if (!currentUser.form && !currentUser.section) {
        dispatch(
          setCurrentUser({
            token: currentUser.token,
            form: response.data.progress.current_form,
            section: response.data.progress.current_section,
            role: currentUser.role,
          })
        );
        return true;
        // }
      }
    } catch (error) {
      createNotification("error", error);
    }
  };
  const parentStep = stepsMapping[currentUser.form];
  const childStep = stepsMapping[currentUser.section];
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  useEffect(() => {
    if (id) {
      setCurrentStep(1);
      setCurrentSubStep(0);
    } else {
      setCurrentStep(parentStep || 0);
      setCurrentSubStep(childStep || 0);
    }
  }, [childStep, parentStep]);

  const next = async () => {
    if (
      currentStep + " " + (currentSubStep + 1) !==
        progressForm + " " + progressSection &&
      (steps[currentStep].subSteps
        ? currentStep <= progressForm
        : currentStep < progressForm) &&
      id
    ) {
      if (currentSubStep === steps[currentStep].subSteps.length - 1) {
        // eslint-disable-next-line no-unused-expressions
        currentStep > 0 ? setStepCompletionModal(true) : null;
        setCurrentStep(currentStep + 1);
        setCurrentSubStep(0);
      } else if (!steps[currentStep].subSteps.length) {
        // eslint-disable-next-line no-unused-expressions
        currentStep > 0 ? setStepCompletionModal(true) : null;
        setCurrentStep(currentStep + 1);

        setCurrentStep(currentStep + 1);
        setCurrentSubStep(0);
      } else {
        setCurrentSubStep(currentSubStep + 1);
      }
    } else if (!id) {
      const progress = await getCurrentPorgress();
      // eslint-disable-next-line no-unused-expressions
      if (progress) {
        if (currentSubStep === steps[currentStep].subSteps.length - 1) {
          // eslint-disable-next-line no-unused-expressions
          currentStep > 0 ? setStepCompletionModal(true) : null;
          setCurrentStep(currentStep + 1);
          setCurrentSubStep(0);
        } else if (!steps[currentStep].subSteps.length) {
          // eslint-disable-next-line no-unused-expressions
          currentStep > 0 ? setStepCompletionModal(true) : null;
          setCurrentStep(currentStep + 1);
          setCurrentSubStep(0);
        } else {
          setCurrentSubStep(currentSubStep + 1);
        }
      }
    }
  };
  const prev = () => {
    if (currentSubStep === 0 && steps[currentStep - 1]) {
      setCurrentStep(currentStep - 1);
      setCurrentSubStep(steps[currentStep - 1].subSteps.length - 1);
    } else if (!steps[currentStep].subSteps.length) {
      setCurrentStep(currentStep - 1);
      setCurrentSubStep(steps[currentStep - 1].subSteps.length - 1);
    } else {
      setCurrentSubStep(currentSubStep - 1);
    }
  };
  return (
    <section className="onboarding-wrapper animate-op">
      <div className="onboarding-sidebar">
        <img src={logo} className="logo" alt="logo"></img>
        <div className="form-sidebar-overflow">
          <Steps current={currentStep - 1}>
            {steps.map((step, stepIndex) => {
              if (stepIndex > 0) {
                return (
                  <Step
                    key={stepIndex}
                    className={`${
                      step.subSteps.length ? "with-substeps" : ""
                    } ${
                      (
                        step.step === 9 && id
                          ? step.step <= progressForm
                          : step.step < progressForm
                          ? true
                          : progressSection === step.subSteps.length &&
                            step.step < progressForm
                      )
                        ? "ant-steps-item-filled"
                        : ""
                    }`}
                    onClick={() => {
                      // if (!id) {
                      if (step?.step <= progressForm) {
                        setCurrentStep(step?.step);
                        if (step?.step !== currentStep)
                          setCurrentSubStep(step?.subSteps[0]?.subStep - 1);
                      }
                      // } else {
                      //   setCurrentStep(step.step);
                      //   if (step.step !== currentStep)
                      //     setCurrentSubStep(step.subSteps[0].subStep - 1);
                      // }
                    }}
                    title={
                      !step.subSteps.length ? (
                        step.title
                      ) : (
                        <div>
                          {step.title}
                          <div
                            className={`sub-step-wrapper ${
                              step.step === currentStep ? "active" : "notActive"
                            }`}
                          >
                            <Steps current={currentSubStep}>
                              {step.subSteps.map((item, itemIndex) => (
                                <Step
                                  title={item.title}
                                  key={itemIndex}
                                  className={
                                    step.step < progressForm ||
                                    item.subStep <= progressSection
                                      ? step.step < progressForm
                                        ? "filled"
                                        : "filled-not-finished"
                                      : ""
                                  }
                                  onClick={() => {
                                    // if (!id) {
                                    if (step.step < progressForm) {
                                      setCurrentSubStep(item.subStep - 1);
                                    } else if (
                                      step.step === progressForm &&
                                      item.subStep <= progressSection
                                    ) {
                                      if (step.step !== currentStep)
                                        setCurrentSubStep(
                                          step.subSteps[0].subStep - 1
                                        );
                                      else setCurrentSubStep(item.subStep - 1);
                                    }
                                    // } else {
                                    //   if (step.step !== currentStep)
                                    //     setCurrentSubStep(
                                    //       step.subSteps[0].subStep - 1
                                    //     );
                                    //   else setCurrentSubStep(item.subStep - 1);
                                    // }
                                  }}
                                />
                              ))}
                            </Steps>
                          </div>
                        </div>
                      )
                    }
                  />
                );
              }
            })}
          </Steps>
        </div>
        {!id && (
          <div
            className="logout-button"
            onClick={() => {
              localStorage.removeItem(`persist:root`);

              setTimeout(() => {
                logout();
                window.open("/clientFormlogin ", "_self");
              }, 500);
            }}
          >
            {logoutIcon} Logout
          </div>
        )}
      </div>
      <div className="text-editor-outer">
        <div className="onboarding-forms-section">
          <div className="onboarding-forms-section-header">
            <h2 className="onboarding-forms-section-header__heading">
              Find The Right Therapist For Your Child
            </h2>
            {id && (
              <img
                onClick={() => {
                  navigate(-1);
                }}
                className="img-fluid onboarding-forms-section-header__crossIcon"
                src={crossicon}
              ></img>
            )}
          </div>

          <div className="onboarding-forms-section-wrapper">
            <div className="d-flex flex-column gap-24 animate-op hide-textarea">
              <InnerHeader
                headerTitle={
                  currentStep === 0
                    ? "Introduction"
                    : currentStep < 10
                    ? steps[currentStep]?.title
                    : "Submited"
                }
                StepNo={currentStep}
                totalSteps={steps.length - 1}
                subStep={
                  currentStep === 0
                    ? "Confidential Information"
                    : steps[currentStep]?.subSteps.length
                    ? steps[currentStep]?.subSteps[currentSubStep]?.title
                    : steps[currentStep]?.title === "Therapy Providers"
                    ? "Contact Information"
                    : [steps[currentStep]?.title]
                    ? [steps[currentStep]?.title]
                    : "You will logged out soon"
                }
              />
              {renderStep(currentStep, currentSubStep, setAction, action)}
              {id && (
                <InnerFooterReview
                  next={next}
                  prev={prev}
                  prevFormHandler={
                    currentStep === 1 && currentSubStep === 0 ? false : true
                  }
                  hasNextBtn={
                    currentStep + " " + (currentSubStep + 1) !==
                      progressForm + " " + progressSection &&
                    id &&
                    (steps[currentStep].subSteps
                      ? currentStep <= progressForm
                      : currentStep < progressForm)
                  }
                  setNotes={setNotes}
                  notes={notes}
                  id={id ? id : false}
                  currentStep={currentStep}
                />
              )}
            </div>

            {/* <InnerFooter prevFormHandler={prev} nextFormHandler={next} /> */}
          </div>
        </div>
      </div>
      {!id && (
        <StepCompletionModal
          show={stepCompletionModal}
          onHide={() => setStepCompletionModal(false)}
          stepCompleted={currentStep - 1}
          nextStepTitle={steps[currentStep]?.title}
        />
      )}
    </section>
  );
}
