import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import InnerFooter from "../../InnerFooter";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import httpClient from "../../../../services/axios";

const GeneralBehaviour = ({ next, prev, id, data, setAction, action }) => {
  const questionInfo = [
    {
      id: 176,
      title: "gen_behaviour_fall_risk",
      placeholder: "",
      label:
        "Is the patient at risk for falls? If so, please complete a falls risk assessment.",
      wrapperClassName: "w-100",
      options: [
        {
          id: 150,
          title: "Yes",
          key: "yes",
        },
        {
          id: 151,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 164,
      title: "gen_beh_agression",
      placeholder: "",
      label: "Aggression (specify below)",
      wrapperClassName: "radio-half",
      descriptionText:
        "Please indicate if your child/adolescent engages in any of the following behaviors (check all that apply):",
      checkBox: true,
      optionalChecked: true,
      options: [
        {
          id: 119,
          title: "Hitting (e.g. punch, slap, etc.)",
          key: "gen_beh_agression_hitting",
          firstInput: true,
        },
        {
          id: 120,
          title: "Spitting",
          key: "gen_beh_agression_spitting",
        },
        {
          id: 121,
          title: "Kicking",
          key: "gen_beh_agression_kicking",
        },
        {
          id: 122,
          title: "Head-butting",
          key: "gen_beh_agression_head_butting",
        },
        {
          id: 123,
          title: "Pulling Teeth",
          key: "gen_beh_agression_pulling_teath",
        },
        {
          id: 124,
          title: "Biting",
          key: "gen_beh_agression_biting",
        },
        {
          id: 125,
          title: "Cutting/Burning",
          key: "gen_beh_agression_cutting_buring",
        },
        {
          id: 126,
          title: "Pinching",
          key: "gen_beh_agression_pinching",
        },
        {
          id: 127,
          title: "Scratching",
          key: "gen_beh_agression_scratching",
        },
        {
          id: 128,
          title: "Other",
          key: "gen_beh_agression_other",
        },
      ],
    },
    {
      id: 165,
      title: "gen_beh_agression_desc",
      placeholder: "Details...",
      label: "Please specify other aggressive behaviours:",
      wrapperClassName: "w-100",
      options: [],
      inputType: true,
    },
    {
      id: 166,
      title: "gen_beh_self_injorious",
      placeholder: "",
      label: "Self-Injurious Behavior (specify below)",
      wrapperClassName: "radio-half",
      checkBox: true,
      optionalChecked: true,

      options: [
        {
          id: 129,
          title: "Hitting self with hands or fists",
          key: "gen_beh_self_injorious_hitting",
        },
        {
          id: 130,
          title: "Kicking self",
          key: "gen_beh_self_injorious_kicking",
        },
        {
          id: 131,
          title: "Biting self",
          key: "gen_beh_self_injorious_biting",
        },
      ],
    },
    {
      id: 167,
      title: "gen_beh_self_injorious_desc",
      placeholder: "Details...",
      label: "Please specify where on the body:",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 168,
      title: "gen_beh_property_destruction",
      placeholder: "",
      label: "Property Destruction (describe below)",
      wrapperClassName: "w-100",
      optionalChecked: true,
      options: [],
    },
    {
      id: 169,
      title: "gen_beh_property_destruction_desc",
      placeholder: "Details...",
      label: "",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 170,
      title: "gen_beh_sexualize_behaviour",
      placeholder: "",
      label: "Sexualized behaviors (describe below)",
      wrapperClassName: "w-100",
      optionalChecked: true,
      options: [],
    },
    {
      id: 171,
      title: "gen_beh_sexualize_behaviour_desc",
      placeholder: "Details...",
      label: "",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 172,
      title: "gen_beh_multi_problems_list",
      placeholder: "",
      label:
        "Additionally, please indicate if your child is experiencing any of the following (check all that apply)?",
      wrapperClassName: "radio-half",
      checkBox: true,
      optionalChecked: true,
      options: [
        {
          id: 132,
          title: "Self-urinating/defecating",
          key: "gen_beh_problems_self_urinating",
        },
        {
          id: 133,
          title: "Fecal smearing",
          key: "gen_beh_problems_fecal_smearing",
        },
        {
          id: 134,
          title: "Bedwetting",
          key: "gen_beh_problems_bedwetting",
        },
        {
          id: 135,
          title: "Difficulty with toileting",
          key: "gen_beh_problems_difficulty_in_toileting",
        },
        {
          id: 136,
          title: "Vocalizations",
          key: "gen_beh_problems_vocalization",
        },
        {
          id: 137,
          title: "Problems with eating",
          key: "gen_beh_problems_with_eating",
        },
        {
          id: 138,
          title: "Tantrums",
          key: "gen_beh_problems_tantrums",
        },
        {
          id: 139,
          title: "Screaming/yelling",
          key: "gen_beh_problems_screaming",
        },
        {
          id: 140,
          title: "Repetitive behaviors",
          key: "gen_beh_problems_repetative_behaviour",
        },
        {
          id: 141,
          title: "Rectal digging",
          key: "gen_beh_problems_rectal_digging",
        },
        {
          id: 142,
          title: "Setting Fire",
          key: "gen_beh_problems_setting_fire",
        },
        {
          id: 143,
          title: "Sadness or Depression ",
          key: "gen_beh_problems_sadness_depression",
        },
        {
          id: 144,
          title: "Difficulty Concentrating",
          key: "gen_beh_problems_difficulty_concentrating",
        },
        {
          id: 145,
          title: "Hallucinartions",
          key: "gen_beh_problems_hallucinations",
        },
        {
          id: 146,
          title: "Delusions",
          key: "gen_beh_problems_dellusions",
        },
        {
          id: 147,
          title: "Other",
          key: "gen_beh_problems_other",
        },
      ],
    },
    {
      id: 173,
      title: "gen_beh_multi_problems_list_desc",
      placeholder: "Details...",
      label: "Please specify what other behaviours the child is experiencing:",
      wrapperClassName: "w-100",
      options: [],
      inputType: true,
    },
    {
      id: 174,
      title: "gen_behaviour_full_day_supervision",
      placeholder: "",
      label:
        "Are there any concerns that require the patient to receive 24/7 supervision (ex. elopement, lack of appropriate safety skills, etc.)?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 148,
          title: "Yes",
          key: "yes",
        },
        {
          id: 149,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 175,
      title: "gen_behaviour_full_day_supervision_desc",
      placeholder: "Details...",
      label: "If so, what are the specific concrens?",
      wrapperClassName: "w-100",
      options: [],
      inputType: true,
    },
    {
      id: 177,
      title: "gen_behaviour_patient_night_awake",
      placeholder: "",
      label: "Does he/she wake up during the night?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 152,
          title: "Yes",
          key: "yes",
        },
        {
          id: 153,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 178,
      title: "gen_behaviour_patient_night_awake_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "w-100",
      options: [],
      inputType: true,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/general-behaviour/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/general-behaviour"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/general-behaviour/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};
export default GeneralBehaviour;
