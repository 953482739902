import { Spin } from "antd";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CustomSelect from "../../Containers/Appointment/custom-select";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import "./Modals.scss";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import dayjs from "dayjs";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";

const ViewTechHoursModal = (props) => {
  const {
    show,
    checkSpentHrs,
    technicianID
  } = props;

  const [modalLoading, setModalLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [workedHours, setWorkedHours] = useState({
    hours: "- -",
    minutes: "- -",
  });

  const getSpentHrs = async () => {
    setModalLoading(true);
    const queryParams = {
      start_date: startDate,
      end_date: endDate
    };
    const queryString = new URLSearchParams(queryParams).toString();
    try {
      const response = await httpClient.get(
        `/api/v1/users/${technicianID}/technician_spent_hrs?${queryString}`
      );
      if (response?.data) {
        setWorkedHours(response?.data?.technician_spent_hrs);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      handleErrorResponse(error);
      setModalLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={props.onHide}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
              Worked Hours Overview
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-32 pt-32">
              <div className="d-flex justify-content-between tech-hrs-datepickers-div">
                <DatePickerLM
                  label={"Start Date"}
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;
                      setStartDate(formattedDate);
                  }}
                  value={startDate}
                  allowClear={false}
                  WrapperClassName="w-100 tech-hrs-start-date"
                ></DatePickerLM>

                <DatePickerLM
                  label={"End Date"}
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;
                    setEndDate(formattedDate);
                  }}
                  value={endDate}
                  allowClear={false}
                  WrapperClassName="w-100 tech-hrs-end-date"
                ></DatePickerLM>
              </div>
            <div className="worked-hours">
              <span className="worked-hrs-label">Total Worked Hours </span>
              <span className="hours">{workedHours.hours}</span>
              <span className="unit">Hours</span>
              <span className="minutes">{workedHours.minutes}</span>
              <span className="unit">Minutes</span>
            </div>

            <div className="check-hrs-btn-div d-flex">
              <Button
                disabled={!startDate || !endDate}
                className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 ms-auto`}
                onClick={() => {
                  getSpentHrs();
                }}
              >
                check
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default ViewTechHoursModal;
