import React, { useEffect, useState } from "react";
import Styles from "../../Containers/Dashboard Login//DashboardLogin.module.scss";
import lmauthfeatured from "../../assets/images/lm-auth-featured.svg";
import lmLogo from "../../assets/images/learn-me-logo-colored.svg";
import ImageView from "../../components/Image/ImageView";

import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import UserTypeTabs from "../../Containers/Dashboard Login/UserTypeTabs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";
import { emailRegex } from "../../helpers/utils";
const ForgotPassword = () => {
  const location = useLocation();
  const propsReceived = location.state;
  const [tab, setTab] = useState(propsReceived?.tab);
  const [disbale, setIsDisable] = useState();
  const [creds, setCreds] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleReset = async (data) => {
    const emailSend = await resetPassword({ ...data });
    if (emailSend?.data?.message) {
      createNotification(
        "success",
        "You will receive an email if you have a account"
      );
      navigate("/checkmail", { state: { email: creds.user.email } });
    } else {
      createNotification("error", "Invalid credentials");
    }
  };

  useEffect(() => {
    setCreds({
      user: {
        email: "",
      },
    });
  }, [tab]);
  useEffect(() => {
    if (creds?.user?.email?.length && emailRegex.test(creds?.user?.email)) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [creds]);
  return (
    <section className={Styles.DashboardLogin}>
      <div className={Styles.DashboardLogin__FormSection}>
        <div className={Styles.DashboardLogin__FormSectionWrapper}>
          <a href="#">
            <ImageView src={lmLogo} className="img-fluid" />
          </a>
          <Form className="w-100 d-flex flex-column">
            <div className="w-100 d-flex flex-column gap-70">
              <UserTypeTabs
                setTab={setTab}
                setCreds={setCreds}
                creds={creds}
                forgotPassword={true}
                tab={tab}
              ></UserTypeTabs>
            </div>
            <Button
              disabled={disbale}
              className="w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600"
              onClick={() => handleReset(creds)}
            >
              Reset Password
            </Button>
            <span
              className="w-100 d-flex justify-content-center align-items-center gap-8 mt-32"
              style={{
                cursor: "pointer",
                fontSize: "14px",
                lineHeight: "20px",
              }}
              onClick={() => navigate("/dashboardlogin")}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8332 9.99935H4.1665M4.1665 9.99935L9.99984 15.8327M4.1665 9.99935L9.99984 4.16602"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back to login
            </span>
          </Form>
        </div>
      </div>
      <div className={Styles.DashboardLogin__ImageSection}>
        <section className={Styles.DashboardLogin__ImageSection_Wrapper}>
          <ImageView src={lmauthfeatured} className="img-fluid" />
        </section>
      </div>
    </section>
  );
};

export default ForgotPassword;
