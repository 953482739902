import { useEffect, useState } from "react";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import "./DailyDataSheetTabs.scss";
import { useMatch, useParams } from "react-router-dom";
import { Button } from "antd";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { logout } from "../../store/user/userSlice";

const ViewCompliance = () => {
  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const { id } = useParams();
  const newObject = {
    difficulty: "",
    title: "",
    complied: "",
  };
  const [payload, setPayload] = useState();
  const [trials, setTrials] = useState();
  const radioOption = [
    { value: "Hard", key: "hard" },
    { value: "Medium", key: "medium" },
    { value: "Easy", key: "easy" },
  ];
  useEffect(() => {
    const getComplianceData = async () => {
      try {
        const res = await httpClient.get("api/v1/compliances", {
          params: { data_sheet: { id: id } },
        });
        if (res?.data?.length) {
          setPayload(res.data);
        }
        return true;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );

          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);

        return false;
      }
    };
    const getTrialsData = async () => {
      try {
        const res = await httpClient.get(
          "api/v1/compliances/successfull_trials",
          {
            params: { data_sheet: { id: id } },
          }
        );
        if (res?.data) {
          setTrials(res.data);
        }
        return true;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );

          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin", "_self");
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);

        return false;
      }
    };
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getComplianceData();
      getTrialsData();
    }
  }, [id]);
  if (!payload?.length) {
    return (
      <div
        className="compilance-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                <ellipse
                  fill="#f5f5f5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fill-rule="nonzero" stroke="#d9d9d9">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="ant-empty-description">No data</div>
        </div>
      </div>
    );
  }
  return (
    <div className="compilance-header">
      <div className="compilance-header-wrapper">
        <p className="f-14 f-w-600">
          Total Trials:{" "}
          <span>
            {trials?.sucessful_trials}/{trials?.total_compliances}
          </span>{" "}
        </p>
        <p className="f-14 f-w-600">
          Difficult Trials:{" "}
          <span>
            {" "}
            {trials?.difficulty_trials}/{trials?.total_compliances}
          </span>{" "}
        </p>
      </div>

      {payload?.length > 0 &&
        payload?.map((item, index) => {
          return (
            <div className="demand-wrapper" key={index}>
              <ul className="demand-wrapper-ul">
                <li>
                  <div>
                    <label className="f-15 f-w-600 clr-dark-grey">
                      Demand {index + 1}
                    </label>
                  </div>

                  <TextFieldFormik
                    disabled={true}
                    key={item?.index}
                    placeholder={"Enter Title"}
                    type={"text"}
                    wrapperClassName={"max-w-input-of w-100"}
                    tabIndex={1}
                    name={`title`}
                    handleChange={(e) => {
                      const updatedPayload = [...payload];
                      updatedPayload[index].title = e.target.value;
                      setPayload(updatedPayload);
                    }}
                    values={item?.title || ""}
                  />
                </li>

                <li>
                  <div>
                    <label className="f-15 f-w-600 clr-dark-grey mb-8">
                      Complied
                    </label>
                  </div>
                  <div class="switch_box box_1">
                    <div className="d-flex align-items-center gap-8">
                      <label className="f-15 f-w-600">No</label>
                      <input
                        disabled={true}
                        type="checkbox"
                        class="switch_1"
                        onChange={(e) => {
                          const updatedPayload = [...payload];
                          updatedPayload[index].complied = e.target.checked;
                          setPayload(updatedPayload);
                        }}
                        checked={item?.complied}
                      />
                      <label className="f-15 f-w-600">Yes</label>
                    </div>
                  </div>
                </li>

                <li>
                  <RadioButtonsFormSection
                    RadioButtons={radioOption.map((content) => {
                      return (
                        <RadioButtons
                          disabled={true}
                          id={content?.key}
                          key={content?.key}
                          name={`payload[${index}].difficulty`}
                          onChange={(e) => {
                            const updatedPayload = [...payload];
                            updatedPayload[index].difficulty = e.target.value;
                            setPayload(updatedPayload);
                          }}
                          //   value={item?.difficulty}
                          Label={content.value}
                          defaultChecked={item.difficulty === content.key}
                        />
                      );
                    })}
                    label={"Difficulty"}
                  />
                </li>
              </ul>
            </div>
          );
        })}
    </div>
  );
};
export default ViewCompliance;
