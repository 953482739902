import { Route, Routes, Navigate } from "react-router-dom";
import { IsLogin, GetCurrentUser } from "../utils/utilAuth";

export default function ProtectedRoute({ children, client }) {
  const isLogin = IsLogin();
  const currentUser = GetCurrentUser();

  // console.log(" Login Status and Current User is---> ", isLogin, currentUser);

  if (!isLogin || !currentUser) {
    return (
        <Navigate to={client ? "/clientFormlogin" : "/dashboardlogin"} replace />
    );
  } else {
    return children;
  }
}
