import React, { useEffect, useState } from "react";
import sc from "./RadioButtons.module.scss";
import RadioButtons from "./RadioButtons";
const RadioButtonsFormSection = (props) => {
  const [optional, setOptional] = useState(props?.displayOptional);
  const [optionalTwo, setOptionalTwo] = useState();

  useEffect(() => {
    if (props?.optionalTypeOut) {
      const temp = props?.values?.section?.questions_attributes?.find(
        (question) => question?.id === props?.item?.id + 1
      );
      const parent = props?.values?.section?.questions_attributes?.find(
        (question) => question?.id === props?.item?.id
      );

      if (!!temp?.answers_attributes[0]?.statement) {
        parent.answers_attributes[0].meta = {
          optionalOut: true,
          checked: true,
        };
        setOptionalTwo(true);
      }

      props?.setValues?.(props?.values);
    }
  }, []);
  return (
    <section
      className={`${sc.RadioButtonsFormSection} ${
        props.wrapperClassName
      } d-flex flex-column gap-15 ${props.errors && sc.errorRadio} `}
      key={props?.id}
    >
      {props?.optionalType && !props.optionalTypeOut && (
        <div className={`d-flex align-items-center gap-12 ${sc.simpleCheck}  `}>
          <RadioButtons
            id={props?.item?.id}
            values={props?.item?.id}
            checkBox={true}
            defaultChecked={optional}
            onChange={(e) => {
              setOptional(e.target.checked);
              if (!e.target.checked) {
                const index =
                  props?.values.section.questions_attributes.findIndex(
                    (index) => index?.id === props?.item?.id
                  );
                const containsItem =
                  props?.values.section.questions_attributes.find(
                    (question) => question?.id === props?.item?.id
                  );

                if (containsItem) {
                  containsItem?.answers_attributes?.map((answer) => {
                    answer._destroy = true;
                    answer.meta = {
                      containsError: true,
                    };
                  });
                }
              } else {
                const containsItem =
                  props?.values.section.questions_attributes.find(
                    (question) => question?.id === props?.item?.id
                  );

                if (containsItem) {
                  props?.values.section.questions_attributes.push({
                    id: props?.item?.id,
                    answers_attributes: containsItem?.answers_attributes?.length
                      ? containsItem?.answers_attributes?.map((answer) => {
                          answer._destroy = false;
                          answer.meta = {
                            containsError: false,
                          };
                        })
                      : [
                          {
                            option_id: undefined,
                            user_section_mapping_id: props?.userMapping,
                          },
                        ],
                  });
                }
              }
              props.setValues(props?.values);
            }}
          />
          <span className="lm-input-form-input-label">{props.label}</span>
        </div>
      )}

      {props?.optionalTypeOut && (
        <div className={`d-flex align-items-center gap-12 ${sc.simpleCheck}  `}>
          <RadioButtons
            id={props?.item?.id}
            values={props?.item?.id}
            checkBox={true}
            checked={optionalTwo}
            onChange={(e) => {
              console.log(e.target.checked);
              setOptionalTwo(e.target.checked);

              const containsItem =
                props?.values.section.questions_attributes.find(
                  (question) => question?.id === props?.item?.id
                );
              if (e.target.checked) {
                containsItem.answers_attributes.map((item) => {
                  item.meta = {
                    optionalOut: true,
                    checked: true,
                  };
                });
              } else {
                containsItem.answers_attributes.map((item) => {
                  item.meta = {
                    optionalOut: true,
                    checked: false,
                  };
                });
              }
              // if (!e.target.checked) {
              //   const index =
              //     props?.values.section.questions_attributes.findIndex(
              //       (index) => index?.id === props?.item?.id
              //     );
              //   const containsItem =
              //     props?.values.section.questions_attributes.find(
              //       (question) => question?.id === props?.item?.id
              //     );

              //   if (containsItem) {
              //     containsItem?.answers_attributes?.map((answer) => {
              //       answer._destroy = true;
              //       answer.meta = {
              //         containsError: true,
              //       };
              //     });
              //   }
              // } else {
              //   const containsItem =
              //     props?.values.section.questions_attributes.find(
              //       (question) => question?.id === props?.item?.id
              //     );

              //   if (containsItem) {
              //     props?.values.section.questions_attributes.push({
              //       id: props?.item?.id,
              //       answers_attributes: containsItem?.answers_attributes?.length
              //         ? containsItem?.answers_attributes?.map((answer) => {
              //             answer._destroy = false;
              //             answer.meta = {
              //               containsError: false,
              //             };
              //           })
              //         : [
              //             {
              //               option_id: undefined,
              //               user_section_mapping_id: props?.userMapping,
              //             },
              //           ],
              //     });
              //   }
              // }
              props.setValues(props?.values);
            }}
          />
          <span className="lm-input-form-input-label">{props.label}</span>
        </div>
      )}
      {!props?.optionalType && (
        <span className="lm-input-form-input-label">{props.label}</span>
      )}
      <div
        className={`dont-use ${
          props.GridView
            ? sc.RadioButtonsFormSection__Wrapper
            : sc.RadioButtonsFormSection__Wrapper_Inline
        } ${props?.displayOptional ? sc.optionalPills : ""}`}
      >
        {props?.optionalType
          ? optional && props.RadioButtons
          : props.RadioButtons}
      </div>
      {/* {props.errors && <p className="formik_error">{props.errors}</p>} */}
    </section>
  );
};

export default RadioButtonsFormSection;
