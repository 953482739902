import React, { useState } from "react";
import "./LMCalender.scss";
import Calendar from "react-calendar";
import {
  CalenderLeftArrowIcon,
  CalenderIcon,
  CalenderRightArrowIcon,
} from "../../constants/constants";

const LMFullCalender = ({ setDateSelect, setMonth, setYear, datesForDot }) => {
  const [value, onChange] = useState(new Date());

  return (
    <Calendar
      className={"lm-appointment-calender"}
      nextLabel={CalenderRightArrowIcon}
      prevLabel={CalenderLeftArrowIcon}
      prev2Label={false}
      next2Label={false}
      tileClassName={"tile-lm-calender"}
      onChange={(e) => {
        onChange();
        const date = new Date(e);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        setDateSelect(formattedDate);
      }}
      value={value}
      minDetail="month"
      maxDetail="month"
      showDoubleView={false}
      showWeekNumbers={false}
      weekStartDay={0}
      showNavigation={true}
      headerRender={(e) => {}}
      view={"month"}
      defaultView={"month"}
      onClick={(e) => {
        // return false;
      }}
      tileContent={({ activeStartDate, date, view }) => {
        const Dotdate = new Date(activeStartDate);
        const year = Dotdate.getFullYear();
        const month = String(Dotdate.getMonth() + 1).padStart(2, "0");
        setMonth(month);
        setYear(year);
        return datesForDot?.map((value) => {
          const newdate = new Date(value);
          const comingMonth = newdate.getMonth() + 1;
          const monthIncalender = new Date(date).getMonth() + 1;
          if (
            newdate.getDate() === new Date(date).getDate() &&
            comingMonth === monthIncalender
          ) {
            return <span className="appointment-date"></span>;
          }
        });
      }}
      onClickMonth={() => {}}
      onClickYear={() => {}}
      formatShortWeekday={(locale, value) =>
        ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
      }
      //   firstDayOfWeek={weekstart}
    />
  );
};

export default LMFullCalender;
