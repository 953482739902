import React from "react";
import cardStyles from "./DashboardPanelCard.module.scss";
import { Select, Space } from "antd";
import {
  MonthOptions,
  ScaleOptions,
  arrowDownBlack,
} from "../../constants/constants";
import Chart from "react-apexcharts";
import ProgressBarComponent from "../ProgressBar/ProgressBarComponent";
import { useNavigate } from "react-router-dom";

var options = {
  series: [
    {
      name: "Goals Achieved",
      type: "column",
      data: [440, 105, 614, 71, 327, 413],
    },
    {
      name: "Sessions Done",
      type: "line",
      data: [440, 105, 614, 71, 327, 413],
    },
  ],
  chart: {
    fontFamily: "OpenSans Regular",
    height: 240,
    width: 240,
    type: "line",
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: [1, 2],
  },
  title: {
    // text:"Goals Achieved",
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [1],
    style: {
      colors: ["#0000"],
    },
  },
  labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN"],
  xaxis: {
    type: "month",
  },
  colors: ["rgba(102, 45, 145, 0.15)", "rgba(246, 137, 31, 1)"],
  yaxis: [
    {
      title: {
        // text:"Goals Achieved",
      },
    },
  ],
  legend: {
    show: false,
  },
};
const ClientsProgress = [
  {
    id: Math.random(),
    clientname: "Mary Sue",
    Progress: 10,
  },
  {
    id: Math.random(),
    clientname: "Emily Joe",
    Progress: 15,
  },
  {
    id: Math.random(),
    clientname: "James Doe",
    Progress: 7,
  },
  {
    id: Math.random(),
    clientname: "Sam Jay",
    Progress: 11,
  },
];
const GoalsDurationOptions = [
  { value: "this_week", label: "This Week" },
  { value: "last_month", label: "Last Month" },
  { value: "six_month", label: "Last 6 Months" },
];
const serviceCode = [
  { value: "97151", label: "97151" },
  { value: "97152", label: "97152" },
  { value: "97153", label: "97153" },
  { value: "97154", label: "97154" },
  { value: "97155", label: "97155" },
  { value: "97156", label: "97156" },
  { value: "97157", label: "97157" },
];
const insuranecOption = [
  { label: "Direct", value: "direct" },
  { label: "Supervision", value: "supervision" },
  { label: "Other", value: "other" },
];
const DashboardPanelCard = ({
  Goal,
  units,
  title,
  onChange,
  setPanelViewHandler,
  unitsRemaining,
  onClickViewAll,
  setUnitFilter,
  chartData,
  setUnitTypeFilter,
  duration,
  setdashboardView,
}) => {
  const chartInfo = {
    series: [
      {
        name: "Goals Achieved",
        type: "column",
        // data: chartData && chartData?.map((month) => Object.values(month)[0]),
        data: chartData && Object.values(chartData).map((month) => month),
      },
      {
        name: "Sessions Done",
        type: "line",
        data: chartData && Object.values(chartData).map((month) => month),
      },
    ],
    chart: {
      fontFamily: "OpenSans Regular",
      height: 240,
      width: 240,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1, 2],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
      style: {
        colors: ["#0000"],
      },
    },
    labels: chartData && Object.keys(chartData).map((month) => month),
    xaxis: {
      type: "month",
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    ],
    colors: ["rgba(102, 45, 145, 0.15)", "rgba(246, 137, 31, 1)"],

    legend: {
      show: false,
    },
  };
  const navigate = useNavigate();
  return (
    <div className={`panel-card ${cardStyles.PanelCard}`}>
      <div className="d-flex justify-content-between align-items-baseline ">
        <h3 className="f-16 lh-25 f-w-700 clr-dark-grey">{`${
          title
        }`}</h3>
        {chartData && (
          <Select
            suffixIcon={arrowDownBlack}
            defaultValue={GoalsDurationOptions[0].label}
            style={{ width: 130, height: 38 }}
            onChange={(e) => duration(e)}
            options={GoalsDurationOptions}
            className={"DashbaordCardSelect"}
            popupClassName="DashbaordCardSelect"
          />
        )}
        {!!units && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "5px",
            }}
          >
            <span className="f-14 f-w-600 lh-30 clr-grey-blur d-flex gap-5">
              <Select
                suffixIcon={arrowDownBlack}
                defaultValue={insuranecOption[0]?.label}
                style={{ width: 115, height: 38 }}
                onChange={(e) => {
                  setUnitTypeFilter(e);
                }}
                options={insuranecOption}
                className={"DashbaordCardSelect"}
                popupClassName="DashbaordCardSelect"
              />
              <Select
                suffixIcon={arrowDownBlack}
                defaultValue={serviceCode[2]?.label}
                style={{ width: 75, height: 38 }}
                onChange={(e) => {
                  setUnitFilter(e);
                }}
                options={serviceCode}
                className={"DashbaordCardSelect"}
                popupClassName="DashbaordCardSelect"
              />
            </span>{" "}
            {!!unitsRemaining?.length && (
              <span
                onClick={() => {
                  navigate("/clients");
                  setdashboardView?.("Clients");
                }}
                className={`f-14 lh-30 f-w-600 clr-orange-pri cursor-pointer`}
              >
                View all
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={`w-100 ${Goal && cardStyles.PanelCard_Chart_Container}`}
        style={{ minHeight: "fit-content", minWidth: "100%" }}
      >
        {chartData && (
          <div style={{ minHeight: "244px", minWidth: "379px", width: "100%" }}>
            {Object.keys(chartData)?.length ? (
              <Chart
                options={chartInfo}
                series={chartInfo.series}
                type="bar"
                width="100%"
                height={"100%"}
                style={{ minWidth: "100%" }}
                menu={false}
                legends={false}
              />
            ) : (
              <div
                class="css-dev-only-do-not-override-w8mnev ant-empty ant-empty-normal"
                style={{
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div class="ant-empty-image">
                  <svg
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(0 1)"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <ellipse
                        fill="#f5f5f5"
                        cx="32"
                        cy="33"
                        rx="32"
                        ry="7"
                      ></ellipse>
                      <g fill-rule="nonzero" stroke="#d9d9d9">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                        <path
                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                          fill="#fafafa"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="ant-empty-description">No data</div>
              </div>
            )}
          </div>
        )}
        {unitsRemaining?.length ? (
          <div className="d-flex flex-column gap-19 mt-16">
            {unitsRemaining.map((cp) => {
              return (
                <ProgressBarComponent
                  progressLabel={true}
                  reverse={true}
                  progress={cp.units}
                  label={cp.full_name}
                />
              );
            })}
          </div>
        ) : (
          <div
            class="css-dev-only-do-not-override-w8mnev ant-empty ant-empty-normal"
            style={{
              height: "240px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div class="ant-empty-image">
              <svg
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse
                    fill="#f5f5f5"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  ></ellipse>
                  <g fill-rule="nonzero" stroke="#d9d9d9">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#fafafa"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <div class="ant-empty-description">No data</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPanelCard;
