import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import OtpModalInput from "./OtpModalInput";
import { otpLogin } from "../../services/apis/authService";
import { useDispatch } from "react-redux";
import { createNotification } from "../../helpers/createNotifications";
import { resendOtp } from "../../services/apis/authService";
import "./OtpModal.scss";
import {loginSuccess} from "../../utils/utilAuth";

function OtpModal({
  isModalOpen,
  setIsModalOpen,
  CurrentUserDetails,
  handleToggle,
  shouldShowEmail,
}) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [isOtpRecent, setIsOtpRecent] = useState(false);
  const [counter, setCounter] = useState(60);


  const verifyOtp = (value) => {
    if (value.trim().length === 6) {
      return true;
    }
  };

  const onChange = async (value) => {
    setOtp(value);
    const isValidOtp = await verifyOtp(value);
    if (isValidOtp) {
      setError(false);
      CurrentUserDetails.user.verification_code = value;
      handleSignIn(CurrentUserDetails);
    } else {
      value.length === 6 && setError(true);
    }
  };

  const handleSignIn = async (data) => {
    const loggedUser = await otpLogin({ ...data });
    if (loggedUser?.data?.token) {
      loginSuccess(dispatch, loggedUser.data);
      setIsModalOpen(false);
    } else {
      delete CurrentUserDetails.user.verification_code;
      setError(true);
    }
  };

  const send_security_code = async (data) => {
    setIsOtpRecent(true);
    setCounter(60);
    const response = await resendOtp({ ...data });
    const OtpSent = response?.status == 200;
    const authenticated = response?.data?.authenticated;
    if (OtpSent && authenticated) {
      createNotification("success", response?.data?.message);
    } else {
      createNotification("error", response?.data?.message || "Invalid credentials");
    }
    setTimeout(() => {
      setIsOtpRecent(false);
    }, 60000); 
  };

  useEffect(() => {
    let timer;
    if (isOtpRecent) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }
    // Cleanup interval when component unmounts or button is enabled
    return () => {
      clearInterval(timer);
    };
  }, [isOtpRecent]);

  return (
    <>
      <Modal
        show={isModalOpen}
        size="lg"
        onHide={() => {
          setIsModalOpen(false);
          // handleCancelCallback();
        }}
        centered
        id="otp-modal"
      >
        <Modal.Body id="modal-type" style={{ alignItems: "center" }}>
          <div id="m-content">
            <div className="modal-body" id="body-otp">
              <h5 className="modal-title" id="otp-title">
                {" "}
                Two Factor Authentication
              </h5>
              <div id="otp-container">
                <OtpModalInput
                  value={otp}
                  onChange={onChange}
                  valueLength={6}
                />
                {/* <TimerComp timer={123} /> */}
                <br />
                {error && <div className="error-div">Incorrect code</div>}
                <div id="message-sent">
                  {`A verification code has been delivered to LearnMe Admin`}
                </div>
                <br />
                {shouldShowEmail && (
                  <div id="resend-code">
                    Didn't receive verification code?
                    <span onClick={handleToggle} style={{ cursor: "pointer" }}>
                      {`Send code via email?`}
                    </span>
                  </div>
                )}
              </div>

              <div className="otp-footer">
                <button
                  className="otp-cancel"
                  onClick={() => {
                    setIsModalOpen(false);
                    setOtp("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="otp-submit"
                  onClick={() => send_security_code(CurrentUserDetails)}
                  disabled={isOtpRecent}
                >
                 {isOtpRecent ? `Resend in ${counter} seconds` : 'Resend Code' }
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <button onClick={() => setIsModalOpen(true)}>Open Modal</button> */}
    </>
  );
}

export default OtpModal;
