import { Table } from "antd";
import React, { useEffect, useState } from "react";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";
import "./TableStyles.scss";

import { useNavigate, useParams } from "react-router-dom";
import {
  AllClientsSessionsTableData,
  AwaitingTableData,
  ClientBillingData,
  DailySessionsClientsTD,
  DirectorDailySessionsClientsTD,
  PanelSessionsTableData,
  TableDataAllSessions,
  TableDataAllSessionsDirector,
  TableDataAllSessionsTech,
  TableDataArchive,
  TableDataArchiveTech,
  TableDataArchivedGoals,
  TableDataAssignedGoals,
  TableDataAssignedGoalsSecond,
  TableDataAssignedInProgressGoals,
  TableDataClientDocuments,
  TableDataOriginalTargets,
  TableDataPendingRequests,
  TableDataPendingRequestsDir,
  TableDataPendingRequestsTech,
  TableDataTechPendingSession,
  TableDataTechYourClients,
  TableHeadClientDocuments,
  TableHeadsAllSessions,
  TableHeadsAllSessionsDirector,
  TableHeadsAllSessionsDirectorArchived,
  TableHeadsAllSessionsTech,
  TableHeadsArchive,
  TableHeadsArchiveTech,
  TableHeadsArchivedGoals,
  TableHeadsAssignedGoals,
  TableHeadsAssignedGoalsSecond,
  TableHeadsAssignedGoalsTech,
  TableHeadsAwaiting,
  TableHeadsClientBillingDetails,
  TableHeadsClientSession,
  TableHeadsDailySessionsClients,
  TableHeadsDirectorCompletedSession,
  TableHeadsInProgressGoals,
  TableHeadsOriginalTargets,
  TableHeadsPendingRequestsTech,
  TableHeadsTechCompletedSession,
  TableHeadsTechCompletedSessionDir,
  TableHeadsTechPendingSession,
  TableHeadsTechSingle,
  TableHeadsTechSingleTech,
  TableHeadsToday,
  TableHeadsUpcomingSession,
  TableHeadsYourClients,
  TechDailySessionsClientsSingle,
  TechDailySessionsClientsSingleTech,
  TechDailySessionsClientsTD,
  TechDailySessionsClientsTDSession,
  TechDailySessionsClientsTDSessionDir,
  UpcomingSessionsTableData,
} from "../../constants/TableDatas";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { GetCurrentUser } from "../../utils/utilAuth";
import AppointmentCancelModal from "../Modals/AppointmentCancelModal";
import AppointmentEditModal from "../Modals/AppointmentEditModal";
import GlobalSettingsModal from "../Modals/GlobalSettingsModal";
import GoalModal from "../Modals/GoalModal";
import PermissionModal from "../Modals/PermissionModal";
import ArchiveGoalModal from "../Modals/archiveGoalModal";
import ArchiveModal from "../Modals/archiveModal";
import DeleteModal from "../Modals/deleteModal";
import ResendMailModal from "../Modals/resendMailModal";
import TextEditorModal from "../Modals/textEditorModal";
import ViewCancelReasonModal from "../Modals/viewCancelReasonModal";
import ViewEditHistoryModal from "../Modals/ViewEditHistoryModal";
import BillingEditConfirmationModal from "../Modals/BillingEditConfirmationModal";

const TableView = ({
  View,
  Pagination = false,
  setdashboardView,
  tableData,
  setTableData,
  setCurrentPage,
  currentPage,
  tabOf,
  metaData,
  setActionDone,
  actionDone,
  userId,
  handleDeleteDoc,
  perPage,
  loading,
  archived,
  setBulkSelectedRows,
  settingOptionsEnabled,
  setSettingOptionsEnabled,
  setBillingData,
}) => {
  const { id } = useParams();
  const user = GetCurrentUser();
  const [showPermisionModalView, setShowPermisionModalView] = useState(false);
  const [showRejectionModalView, setShowRejectionModalView] = useState(false);
  const [assignGoalModalView, setAssignGoalModalView] = useState(false);
  const [archive, setArchive] = useState(false);
  const [archiveGoal, setArchiveGoal] = useState(false);
  const [goalUserMappingId, setGoalUserMappingId] = useState();
  const [unAcrhive, setUnArchive] = useState(false);
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [showMailSent, setShowMailSent] = useState(false);
  const [content, setContent] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const [singleRowData, setSingleRowData] = useState();
  const [modalLoading, setModalLoading] = useState(false);
  const [appointEditModal, setAppointEditModal] = useState(false);
  const [appointmentCancelModal, setAppointmentCancelModal] = useState(false);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [globalSettingModal, setGlobalSettingModal] = useState(false);
  const [selectedCheckBoxList, setSelectedCheckBoxList] = useState([]);
  const [appointmentID, setAppointmentID] = useState(null);
  const [showEditHistoryModal, setShowEditHistoryModal] = useState(false);
  const [billingId, setBillingId] = useState(null);
  const [payload, setPayload] = useState({});
  const [openEditConfirmationModal, setOpenEditConfirmationModal] =
    useState(false);

  const TableHeadsPendingRequests = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      render: (age) => (
        <span className="f-w-600 clr-grey-charchoal">{age} </span>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "DateJoined",
      key: "DateJoined",
    },
    {
      title: "On Boarding Form",
      dataIndex: "OnBoardingForm",
      key: "OnBoardingForm",
    },
    {
      title: "Action",
      key: "Actions",
      dataIndex: "Actions",
    },
  ];
  const TableHeadsPendingRequestsDir = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Date Joined",
      dataIndex: "DateJoined",
      key: "DateJoined",
    },
    {
      title: "Action",
      key: "Actions",
      dataIndex: "Actions",
    },
  ];

  const PaginationControls = Pagination && {
    total: metaData?.total_count,
    position: ["bottomRight"],
    prevIcon: "Prev",
    nextIcon: "Next",
    defaultPageSize: metaData?.per_page || perPage || 10,
    hideOnSinglePage: true,
    current: currentPage,

    onChange: (number) => setCurrentPage(number),
  };

  useEffect(() => {
    console.log("goal mapping id", goalUserMappingId);
  }, [goalUserMappingId]);

  const navigate = useNavigate();
  const getTableData = (view) => {
    switch (view) {
      case "DailySessionView":
        return DailySessionsClientsTD;
      case "ClientBilling":
        return ClientBillingData({
          data: tableData,
          setBillingId: setBillingId,
          setPayload: setPayload,
          setShowEditHistoryModal: setShowEditHistoryModal,
          setOpenEditConfirmationModal: setOpenEditConfirmationModal,
          setBillingData: setBillingData,
        });
      case "sessionCompleted":
        return tabOf === "director"
          ? TechDailySessionsClientsTDSessionDir({ data: tableData })
          : TechDailySessionsClientsTDSession({ data: tableData });
      case "TechCompletedSession":
        return TechDailySessionsClientsTD({ data: tableData });
      case "DirectorCompletedSession":
        return DirectorDailySessionsClientsTD({ data: tableData });
      case "UpcomingSessions":
        return UpcomingSessionsTableData({
          data: tableData,
          setDeleteModal: setDeleteModal,
          setAppointEditModal: setAppointEditModal,
          setAppointmentCancelModal: setAppointmentCancelModal,
          setCancelReasonModal: setCancelReasonModal,
          setCancellationReason: setCancellationReason,
          settingOptionsEnabled: settingOptionsEnabled,
          setGlobalSettingModal: setGlobalSettingModal,
          setAppointmentID: setAppointmentID,
        });
      case "pendingsessions": {
        return TableDataTechPendingSession({
          data: tableData,
          setdashboardView,
        });
      }
      case "yourclientsingle": {
        return tabOf === "client"
          ? TechDailySessionsClientsSingle({
              data: tableData,
              setdashboardView,
            })
          : tabOf === "technician"
          ? TechDailySessionsClientsSingleTech({
              data: tableData,
              setdashboardView: setdashboardView,
            })
          : "";
      }
      case "yourClients": {
        return TableDataTechYourClients({
          data: tableData,
          setdashboardView,
          single: true,
        });
      }
      case "awaiting_approval":
        return AwaitingTableData({
          data: tableData,
          navigate,
          setdashboardView,
        });
      case "ViewGoals":
        return TableDataAssignedGoals({
          data: tableData,
          handleChangeStatus: handleChangeStatus,
          setArchiveGoal: setArchiveGoal,
          setGoalUserMappingId: setGoalUserMappingId,
        });
      case "ArchivedGoals":
        return TableDataArchivedGoals({
          data: tableData,
          updateGoalUserMapping: updateGoalUserMapping,
        });
      case "ViewGoalsTech":
        return TableDataAssignedGoals({
          data: tableData,
          handleChangeStatus: handleChangeStatus,
          setArchiveGoal: setArchiveGoal,
        });
      case "ClientDocuments":
        return TableDataClientDocuments({
          data: tableData,
          handleDeleteDoc: handleDeleteDoc,
          // handleChangeStatus: handleChangeStatus,
        });

      case "AssignGoal":
        return TableDataAssignedGoalsSecond({
          data: tableData,
          setAssignGoalModalView,
        });
      case "InProgressGoals":
        return TableDataAssignedInProgressGoals({
          data: tableData,
          setAssignGoalModalView,
        });
      case "Panel":
        return PanelSessionsTableData;
      case "unarchived":
        return tabOf === "client"
          ? TableDataAllSessions({
              data: tableData,
              setArchive: setArchive,
            })
          : tabOf === "director"
          ? TableDataAllSessionsDirector({
              data: tableData,
              setArchive: setArchive,
              user,
            })
          : TableDataAllSessionsTech({
              data: tableData,
              setArchive: setArchive,
            });
      case "AllSessionListing":
        return AllClientsSessionsTableData;
      case "pending_request":
        return tabOf === "client"
          ? TableDataPendingRequests({
              data: tableData,
              setShowApproveModal: setShowPermisionModalView,
              setDeleteModal: setShowRejectionModalView,
              setShowTextEditor,
              setShowMailSent,
            })
          : tabOf === "director"
          ? TableDataPendingRequestsDir({
              data: tableData,

              setShowMailSent,
            })
          : TableDataPendingRequestsTech({
              data: tableData,
              setShowMailSent,
            });
      case "archived":
        return tabOf === "client"
          ? TableDataArchive({
              data: tableData,
              setUnArchive: setUnArchive,
            })
          : tabOf === "director"
          ? TableDataAllSessionsDirector({
              data: tableData,
              setUnArchive: setUnArchive,
            })
          : TableDataArchiveTech({
              data: tableData,
              setUnArchive: setUnArchive,
            });
      case "original_target":
        return TableDataOriginalTargets({ data: tableData });
      case "today":
        return TableDataOriginalTargets({ data: tableData });
      case "updated_target":
        return TableDataOriginalTargets({
          data: tableData,
          updatedTarget: true,
        });
      default:
        return;
    }
  };

  const getTableHeads = (view) => {
    switch (view) {
      case "DailySessionView":
        return TableHeadsDailySessionsClients;
      case "ClientBilling":
        return TableHeadsClientBillingDetails;
      case "sessionCompleted": {
        return tabOf === "director"
          ? TableHeadsTechCompletedSessionDir
          : TableHeadsTechCompletedSession;
      }
      case "pendingsessions": {
        return TableHeadsTechPendingSession;
      }
      case "yourclientsingle": {
        return tabOf === "client"
          ? TableHeadsTechSingle
          : tabOf === "technician"
          ? TableHeadsTechSingleTech
          : "";
      }

      case "UpcomingSessions":
        return TableHeadsUpcomingSession;
      case "ClientDocuments":
        return TableHeadClientDocuments;
      case "DirectorCompletedSession":
        return TableHeadsDirectorCompletedSession;
      case "awaiting_approval":
        return TableHeadsAwaiting;
      case "ViewGoals":
        return TableHeadsAssignedGoals;
      case "ArchivedGoals":
        return TableHeadsArchivedGoals;
      case "ViewGoalsTech":
        return TableHeadsAssignedGoalsTech;
      case "yourClients":
        return TableHeadsYourClients;
      case "AssignGoal":
        return TableHeadsAssignedGoalsSecond;
      case "InProgressGoals":
        return TableHeadsInProgressGoals;
      case "Panel":
        return TableHeadsClientSession;
      case "TechCompletedSession":
        return TableHeadsTechCompletedSession;
      case "AllSessionListing":
        return TableHeadsClientSession;
      case "unarchived":
        return tabOf === "client"
          ? TableHeadsAllSessions({ data: tableData })
          : tabOf === "director"
          ? TableHeadsAllSessionsDirector
          : TableHeadsAllSessionsTech;
      case "pending_request":
        return tabOf === "client"
          ? TableHeadsPendingRequests
          : tabOf === "director"
          ? TableHeadsPendingRequestsDir
          : TableHeadsPendingRequestsTech;
      case "archived":
        return tabOf === "client"
          ? TableHeadsArchive
          : tabOf === "director"
          ? TableHeadsAllSessionsDirectorArchived
          : TableHeadsArchiveTech;
      case "original_target":
        return TableHeadsOriginalTargets;
      case "today":
        return TableHeadsToday;
      case "updated_target":
        return TableHeadsOriginalTargets;
      default:
        return;
    }
  };
  const handleChangeStatus = async (goalsRefNo, status) => {
    try {
      const res = await httpClient.put(
        `/api/v1/goals/${goalsRefNo}/change_goal_status?user_id=${
          userId || id
        }&status=${status ? "on_hold" : "active"}`
      );
      if (res.data) {
        res.data.goal_user_mappings[0].status === "active"
          ? createNotification("success", "Goal Active")
          : createNotification("success", "Goal Paused");
        setActionDone(!actionDone);
      }
      return true;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const updateGoalUserMapping = async (params, mapping_id) => {
    const goal_mapping_id = goalUserMappingId || mapping_id;
    try {
      const res = await httpClient.put(
        `/api/v1/goal_user_mappings/${goal_mapping_id}`,
        {
          goal_user_mapping: {
            ...params,
          },
          user_id: userId || id,
        }
      );
      if (res.data) {
        createNotification("success", "Goal State has been changed");
        setArchiveGoal(false);
        setActionDone(!actionDone);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      setArchiveGoal(false);
      setModalLoading(false);
      handleErrorResponse(error);
    }
  };

  const handleAcceptRequest = async () => {
    try {
      const res = await httpClient.post(
        `/api/v1/users/${singleRowData.slug}/assign_status?status=active`
      );
      if (res.data) {
        if (tableData.length === 1) {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          } else {
            setCurrentPage(currentPage);
          }
        }
        createNotification("success", "Accepted");
        setShowPermisionModalView(false);
        setActionDone(!actionDone);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      setModalLoading(false);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };

  const handleRejectRequest = async () => {
    try {
      const res = await httpClient.delete(`api/v1/users/${singleRowData.slug}`);
      if (res.data) {
        if (tableData.length === 1) {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          } else {
            setCurrentPage(currentPage);
          }
        }
        createNotification("success", "User Deleted");
        setShowRejectionModalView(false);
        setActionDone(!actionDone);
        setModalLoading(false);
      }
      return res.data;
    } catch (error) {
      if (
        error?.response?.data?.response?.error === "Signature has expired" ||
        error?.response?.status == 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        window.open("/dashboardlogin ", "_self");
        localStorage.removeItem(`persist:root`);

        logout();
        return;
      }
      console.log(error);
      setShowRejectionModalView(false);
      setModalLoading(false);

      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };
  const handleArchiveRequest = async () => {
    try {
      const res = await httpClient.post(
        `/api/v1/users/${singleRowData.slug}/assign_status?status=archive`
      );
      if (res.data) {
        if (tableData.length === 1) {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          } else {
            setCurrentPage(currentPage);
          }
        }
        createNotification("success", "User Archived");
        setArchive(false);
        setActionDone(!actionDone);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      setModalLoading(false);

      console.log(error);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };

  const handleUnArchiveRequest = async () => {
    try {
      const res = await httpClient.post(
        `/api/v1/users/${singleRowData.slug}/assign_status?status=active`
      );
      if (res.data) {
        if (tableData.length === 1) {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          } else {
            setCurrentPage(currentPage);
          }
        }
        createNotification("success", "User Unarchived");
        setUnArchive(false);
        setActionDone(!actionDone);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      setModalLoading(false);

      console.log(error);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };

  const handleResendEmail = async () => {
    try {
      let res;
      if (tabOf === "client") {
        res = await httpClient.post(`users/send_client_login_creds`, {
          user: { email: singleRowData.email, role: tabOf },
        });
      } else if (tabOf === "technician" || tabOf === "director") {
        res = await httpClient.post(`users/send_password_email`, {
          user: { email: singleRowData.email, role: tabOf },
        });
      }
      if (res.data) {
        createNotification("success", "Email Resent");
        setShowMailSent(false);
        setModalLoading(false);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        setShowMailSent(false);

        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      setModalLoading(false);

      setShowMailSent(false);

      return false;
    }
  };

  const handleSendNotes = async () => {
    try {
      const res = await httpClient.get(`/api/v1/users/send_feedback`, {
        params: {
          id: singleRowData.slug,
          description: content,
        },
      });
      if (res.data) {
        setContent("");
        setModalLoading(false);
        setShowTextEditor(false);
        createNotification("success", "Mail sent");
      }
      return res.data;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      setModalLoading(false);
      setShowTextEditor(false);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };

  const handleDeleteSession = async () => {
    const currentDateTime = fetchFormattedDateTime();
    try {
      const res = await httpClient.delete(
        `api/v1/appointments/${singleRowData.id}`,
        {
          params: {
            date_time: currentDateTime,
          },
        }
      );
      if (res.data) {
        createNotification("success", "Appointment Deleted");
        setDeleteModal(false);
        setActionDone(!actionDone);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };
  const toProfile = (id) => {
    if (tabOf === "client") {
      navigate(`/clients/${id}`);
      setdashboardView("Client");
    }
    if (tabOf === "technician") {
      navigate(`/technicians/${id}`);
      setdashboardView("Technician");
    }
  };

  const navigateToForm = (id) => {
    if (tabOf === "client") {
      navigate(`/clients/reviewForm/${id}`);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCheckBoxList(selectedRowKeys);
      setSettingOptionsEnabled(selectedRowKeys.length === 0 ? true : false);
      setBulkSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedCheckBoxList,
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const modalHeight = isSafari ? "calc(100vh - 350px)" : "calc(100vh - 320px)";

  return (
    <>
      <Table
        {...(View === "UpcomingSessions" && {
          rowSelection: {
            type: "checkbox",
            ...rowSelection,
          },
        })}
        className={`${
          View === "Panel" || View === "DailySessionView"
            ? "ant-custom-table "
            : View === "UpcomingSessions"
            ? "lm-listing lm-listing-row-bordered-bottom"
            : "lm-listing"
        }  ${
          View == "sessionCompleted" && tabOf == "director"
            ? "director-table"
            : View === "ClientBilling"
            ? "client-billing-table"
            : View === "UpcomingSessions"
            ? "upcoming-session-table"
            : ""
        }`}
        columns={getTableHeads(View)}
        dataSource={getTableData(View)}
        loading={loading}
        pagination={PaginationControls}
        rowClassName={(record) => record.disabled && "disabled-row"}
        onRow={
          View !== "ClientBilling"
            ? (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    const columnName =
                      event.target.getAttribute("data-column-name");
                    if (columnName === "name") {
                      if (
                        tabOf === "client" &&
                        View !== "pending_request" &&
                        record?.slug &&
                        View !== "archived"
                      ) {
                        toProfile(record.slug);
                      } else if (
                        tabOf === "technician" &&
                        View !== "archived"
                      ) {
                        toProfile(record.slug);
                      }
                    }
                    setSingleRowData(record);
                  },
                };
              }
            : undefined
        }
        scroll={View === "ClientBilling" ? { x: 1600, y: modalHeight } : {}}
      />

      <PermissionModal
        show={showPermisionModalView}
        clientname={singleRowData?.Name}
        accept={true}
        onHide={() => {
          setModalLoading(false);
          setShowPermisionModalView(false);
        }}
        modalLoading={modalLoading}
        onHandleClick={() => {
          setModalLoading(true);
          handleAcceptRequest();
        }}
      />
      <PermissionModal
        show={showRejectionModalView}
        clientname={singleRowData?.Name}
        reject={true}
        modalLoading={modalLoading}
        onHide={() => {
          setModalLoading(false);
          setShowRejectionModalView(false);
        }}
        onHandleClick={() => {
          setModalLoading(true);
          handleRejectRequest();
        }}
      />

      <ArchiveGoalModal
        show={archiveGoal}
        archive={true}
        modalLoading={modalLoading}
        onHide={() => {
          setArchiveGoal(false);
          setModalLoading(false);
        }}
        onHandleClick={() => {
          setModalLoading(true);
          updateGoalUserMapping({ state: "archived" });
        }}
      />

      <ArchiveModal
        show={archive}
        clientname={singleRowData?.Name}
        archive={true}
        modalLoading={modalLoading}
        onHide={() => {
          setArchive(false);
          setModalLoading(false);
        }}
        onHandleClick={() => {
          setModalLoading(true);
          handleArchiveRequest();
        }}
      />
      <ArchiveModal
        show={unAcrhive}
        modalLoading={modalLoading}
        clientname={singleRowData?.Name}
        archive={false}
        onHide={() => {
          setModalLoading(false);
          setUnArchive(false);
        }}
        onHandleClick={() => {
          setModalLoading(true);
          handleUnArchiveRequest();
        }}
      />
      <ResendMailModal
        show={showMailSent}
        modalLoading={modalLoading}
        clientname={singleRowData?.Name}
        onHide={() => {
          setModalLoading(false);
          setShowMailSent(false);
        }}
        onHandleClick={() => {
          setModalLoading(true);
          handleResendEmail();
        }}
      />
      <TextEditorModal
        show={showTextEditor}
        modalLoading={modalLoading}
        clientname={singleRowData?.Name}
        onHide={() => {
          setModalLoading(false);
          setShowTextEditor(false);
        }}
        content={content}
        id={singleRowData?.slug}
        setcontent={setContent}
        onCross={setShowTextEditor}
        onSend={() => {
          setModalLoading(true);
          handleSendNotes();
        }}
      />

      <GoalModal
        AddGoal={false}
        AssignGoal={true}
        show={assignGoalModalView}
        modalLoading={modalLoading}
        singleRowData={singleRowData}
        userId={userId}
        setAssignGoalModalView={() => {
          setAssignGoalModalView();
        }}
        onHide={() => {
          setModalLoading(false);
          setAssignGoalModalView(false);
        }}
      />

      <AppointmentEditModal
        show={appointEditModal}
        modalLoading={modalLoading}
        singleRowData={singleRowData}
        userId={userId}
        setAppointEditModal={setAppointEditModal}
        onHide={() => {
          setAppointEditModal(false);
        }}
        setActionDone={setActionDone}
        actionDone={actionDone}
      />

      <AppointmentCancelModal
        show={appointmentCancelModal}
        modalLoading={modalLoading}
        singleRowData={singleRowData}
        userId={userId}
        setAppointEditModal={setAppointmentCancelModal}
        onHide={() => {
          setAppointmentCancelModal(false);
        }}
        setActionDone={setActionDone}
        actionDone={actionDone}
      />

      <ViewCancelReasonModal
        show={cancelReasonModal}
        modalLoading={modalLoading}
        cancellationReason={cancellationReason}
        onHide={() => {
          setCancelReasonModal(false);
        }}
      />

      <DeleteModal
        show={deleteModal}
        clientname={singleRowData?.Name}
        archive={true}
        onHide={() => {
          setDeleteModal(false);
        }}
        onHandleClick={() => handleDeleteSession()}
      />

      <GlobalSettingsModal
        show={globalSettingModal}
        configurationType={"SpecificAppointment"}
        onHide={() => {
          setGlobalSettingModal(false);
        }}
        modalLoading={modalLoading}
        setLoadingModal={setModalLoading}
        appointmentId={appointmentID}
        setGlobalSettingModal={setGlobalSettingModal}
      />

      <ViewEditHistoryModal
        show={showEditHistoryModal}
        onHide={() => {
          setShowEditHistoryModal(false);
        }}
        modalLoading={modalLoading}
        setLoadingModal={setModalLoading}
        setShowEditHistoryModal={setShowEditHistoryModal}
        billingId={billingId}
      />

      <BillingEditConfirmationModal
        show={openEditConfirmationModal}
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        onHide={() => {
          setOpenEditConfirmationModal(false);
          setActionDone(!actionDone);
        }}
        billingId={billingId}
        payload={payload}
        actionDone={actionDone}
        setActionDone={setActionDone}
        setOpenEditConfirmationModal={setOpenEditConfirmationModal}
      />
    </>
  );
};

export default TableView;
