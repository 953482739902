import React, { useState, useEffect } from "react";

import { Button } from "react-bootstrap";
import "./Modals.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import { createNotification } from "../../helpers/createNotifications";
import { NumberFormat } from "../../helpers/utils";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import parsePhoneNumberFromString from "libphonenumber-js";
import dayjs from "dayjs";
import { Modal, Radio } from "antd";
import SearchDropdown from "../SearchDropDown/SearchDropdown";
import httpClient from "../../services/axios";
import { Select } from "antd";

const ProfileModal = (props) => {
  const nameParts = props?.clientProfile?.full_name?.split(" ");
  const [isDisabled, setIsDisabled] = useState();
  const [phoneNo, setPhoneNo] = useState(props?.clientProfile?.phone_no);
  const [dateOfBirth, setDateOfBirth] = useState(
    props?.clientProfile?.date_of_birth
  );

  const [authorizedStartDate, setAuthorizedStartDate] = useState(
    props?.clientProfile?.authorized_start_date
  );
  const [authorizedEndDate, setAuthorizedEndDate] = useState(
    props?.clientProfile?.authorized_end_date
  );
  const [firstName, setFirstName] = useState(props?.clientProfile?.first_name);
  const [lastName, setLastName] = useState(props?.clientProfile?.last_name);
  const [status, setStatus] = useState(props?.clientProfile?.insurance_status);
  const [address, setAddress] = useState(props?.clientProfile?.address);
  const [city, setCity] = useState(props?.clientProfile?.city);
  const [state, setState] = useState(props?.clientProfile?.state);
  const [directorId, setDirectorId] = useState();
  const [directorsList, setDirectorsList] = useState();
  const [clientId, setClientId] = useState(props?.clientProfile?.client_identifier);
  const [hoRate, setHoRate] = useState(props?.clientProfile?.ho_rate);
  const [techRate, setTechRate] = useState(props?.clientProfile?.tech_rate);

  const [zipCode, setZipCode] = useState(props?.clientProfile?.zip_code);
  const [insuranceId, setInsuranceId] = useState(
    props?.clientProfile?.insurance_id
  );
  const [dropdownList, setDropdownList] = useState();
  const [insuranceProvider, setInsuranceProvider] = useState(
    props?.clientProfile?.insurance_provider
  );
  const [newInsuaranceArray, setNewInsuaranceArray] = useState([]);
  const [existingInsuranceArray, setExistingArray] = useState([
    ...(!!props?.clientProfile?.insurance_providers?.length
      ? props?.clientProfile?.insurance_providers
      : []),
  ]);
  useEffect(() => {
    if (props?.clientProfile?.insurance_providers?.length) {
      setExistingArray([...props?.clientProfile?.insurance_providers]);
    }
  }, [props?.clientProfile]);
  useEffect(() => {
    setNewInsuaranceArray([]);
  }, [props.show]);
  const setProfileUpdate = async () => {
    const newArray = newInsuaranceArray.filter(
      (obj) => obj?.insurance_provider_id
    );
    const newArrayName = newInsuaranceArray.filter(
      (obj) => !obj.insurance_provider_id
    );
    const profileUpdate = {
      first_name: firstName || "",
      last_name: lastName || "",
      phone_no: phoneNo,
      date_of_birth: dateOfBirth,
      address: address,
      insurance_status: status,
      authorized_start_date: authorizedStartDate,
      authorized_end_date: authorizedEndDate,
      city: city,
      ho_rate: hoRate || "",
      tech_rate: techRate || "",
      client_identifier: clientId,
      user_insurance_provider_mappings_attributes: [
        ...(existingInsuranceArray ?? []),
        ...newArray,
      ],
      new_insurance_providers_attributes: [...newArrayName],
      state: state,
      zip_code: zipCode,
      director_id: directorId,
      // insurance_id: insuranceId,
      // insurance_provider: insuranceProvider.toLowerCase(),
    };
    console.log(profileUpdate, "profileUpdate");
    const updated = await props.handleUpdateProfile(profileUpdate);
    if (updated) {
      createNotification("success", "Profile Updated");
    } else return;
  };
  useEffect(() => {
    if (phoneNo) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNo, "US");
      const isValid = parsedPhoneNumber && parsedPhoneNumber.isValid();
      if (isValid) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }
  }, [phoneNo]);
  useEffect(() => {
    console.log(
      [...newInsuaranceArray],
      "new",
      existingInsuranceArray,
      "exist"
    );
  }, [newInsuaranceArray, existingInsuranceArray]);
  const [buttondis2, setbuttonDis2] = useState(false);
  useEffect(() => {
    if (
      !firstName.length ||
      !lastName.length ||
      !hoRate ||
      !techRate
    ) {
      setbuttonDis2(true);
    } else {
      setbuttonDis2(false);
    }
  }, [firstName, lastName, hoRate, techRate]);
  const [buttonDis, setbuttonDis] = useState(true);

  useEffect(() => {
    if (new Date(authorizedStartDate) > new Date(authorizedEndDate)) {
      setbuttonDis(true);
    } else if (new Date(authorizedStartDate) < new Date(authorizedEndDate)) {
      setbuttonDis(false);
    }
  }, [authorizedStartDate, authorizedEndDate]);
  useEffect(() => {
    if (!status) {
      setInsuranceProvider("");
      setInsuranceId("");
    }
  }, [status]);

  useEffect(() => {
    const getDropdownlist = async () => {
      try {
        const providersResponse = await httpClient.get(
          `api/v1/users/fetch_provider_name`
        );
        const response = await httpClient.get(`api/v1/users/fetch_directors`);
        if (providersResponse.data) {
          setDropdownList(providersResponse.data.provider_names);
        }
        if (response.data) {
          setDirectorsList(response.data.directors_list);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDropdownlist();
  }, []);
  const [selectedInsuranceIndex, setSelectedInsuranceIndex] = useState(null);

  const handleRadioChange = (index) => {
    if (index < existingInsuranceArray.length) {
      const updatedArray = existingInsuranceArray.map((item, i) => ({
        ...item,
        primary: i === index,
      }));
      setExistingArray(updatedArray);

      const updatedNewArray = newInsuaranceArray.map((item) => ({
        ...item,
        primary: false,
      }));
      setNewInsuaranceArray(updatedNewArray);
    } else {
      const updatedArray = newInsuaranceArray.map((item, i) => ({
        ...item,
        primary: i === index - existingInsuranceArray.length,
      }));
      setNewInsuaranceArray(updatedArray);

      const updatedExistingArray = existingInsuranceArray.map((item) => ({
        ...item,
        primary: false,
      }));
      setExistingArray(updatedExistingArray);
    }
  };
  return (
    <Modal
      title="Edit Profile"
      centered
      width={650}
      open={props.show}
      onCancel={() => props.setProfileModalView(false)}
      onOk={() => props.setProfileModalView(false)}
      footer={false}
    >
      <section className="d-flex flex-column gap-30  w-100 profile-modal-section">
        <div className="d-flex w-100 gap-20 w-100">
          <TextFieldFormik
            placeholder="First name"
            label="First Name"
            wrapperClassName="w-100"
            type="text"
            // disabled={true}
            handleChange={(e) => setFirstName(e.target.value)}
            values={firstName}
          />

          <TextFieldFormik
            placeholder="Last Name"
            label="Last Name"
            wrapperClassName="w-100"
            type="text"
            // disabled={true}
            handleChange={(e) => setLastName(e.target.value)}
            values={lastName}
          />
        </div>
        <div className="d-flex w-100 gap-20 w-100">
          <TextFieldFormik
            placeholder="Email"
            label="Email"
            wrapperClassName="w-100"
            type="text"
            values={props?.clientProfile?.email}
            disabled
          />
          {!props.technician && (
            <TextFieldFormik
              placeholder="Enter Client ID"
              label="Client ID"
              wrapperClassName="w-100"
              handleChange={(e) => setClientId(e.target.value)}
              type="number"
              values={clientId}
            />
          )}
        </div>
        {!props.technician && (
          <div className="d-flex w-100 gap-20 w-100">
            <TextFieldFormik
              placeholder="Enter HO Rate"
              label="HO Rate (per unit)"
              wrapperClassName="w-100"
              type="currency"
              defaultValue={hoRate}
              handleChange={(value) => setHoRate(value)}
            />
            <TextFieldFormik
              placeholder="Enter Tech Rate"
              label="Tech Rate (per unit)"
              wrapperClassName="w-100"
              handleChange={(value) => setTechRate(value)}
              type="currency"
              defaultValue={techRate}
            />
          </div>
        )}

        <div className="d-flex w-100 gap-20 w-100">
          <TextFieldFormik
            placeholder="Phone number"
            label="Phone number"
            wrapperClassName={`w-100`}
            errors={isDisabled && true}
            type="number"
            values={NumberFormat(phoneNo, "(xxx) xxx-xxxx")}
            handleChange={(e) => {
              const rawValue = e.target.value.slice(0, 14);
              setPhoneNo(rawValue);
            }}
            defaultValue={NumberFormat(
              phoneNo ? phoneNo : props?.clientProfile?.phone_no,
              "(xxx) xxx-xxxx"
            )}
          />

          <DatePickerLM
            placeholder="Date of birth"
            WrapperClassName="w-100"
            label={"Date of birth"}
            defaultValue={props?.clientProfile?.date_of_birth || ""}
            onChange={(date) => {
              const formattedDate = date
                ? dayjs(date).format("YYYY-MM-DD")
                : null;
              setDateOfBirth(formattedDate);
            }}
            pastDate={true}
          />
        </div>
        {!props.technician && (
          <div className="d-flex w-100 gap-20 w-100">
            <DatePickerLM
              placeholder="Authorize Start Date"
              WrapperClassName="w-100"
              label={"Authorize Start Date"}
              defaultValue={
                authorizedStartDate
                  ? authorizedStartDate
                  : props?.clientProfile?.authorized_start_date || ""
              }
              onChange={(date) => {
                const formattedDate = date
                  ? dayjs(date).format("YYYY-MM-DD")
                  : null;
                setAuthorizedStartDate(formattedDate);
              }}
            />
            <DatePickerLM
              placeholder="Authorize End Date"
              WrapperClassName="w-100"
              disabled={
                authorizedStartDate ||
                props.clientProfile?.authorized_start_date
                  ? true
                  : false
              }
              label={"Authorize End Date"}
              dateAfter={
                authorizedStartDate ??
                props.clientProfile?.authorized_start_date
              }
              defaultValue={
                authorizedEndDate
                  ? authorizedEndDate
                  : props?.clientProfile?.authorized_end_date || ""
              }
              onChange={(date) => {
                const formattedDate = date
                  ? dayjs(date).format("YYYY-MM-DD")
                  : null;
                setAuthorizedEndDate(formattedDate);
              }}
            />
          </div>
        )}
        {buttonDis && !props.technician && (
          <span
            style={{
              color: "#E31616",
              fontSize: "12px",
              marginTop: "-15px",
            }}
          >
            {" "}
            Authorized end date should be after authorized start date
          </span>
        )}
        <div className="d-flex w-100 gap-20 w-100">
          <TextFieldFormik
            placeholder="Home address"
            label="Home address"
            wrapperClassName="w-100"
            type="text"
            handleChange={(e) => setAddress(e.target.value)}
            defaultValue={address ? address : props?.clientProfile?.address}
          />
          <TextFieldFormik
            placeholder="City"
            label="City"
            wrapperClassName="w-100"
            type="text"
            handleChange={(e) => setCity(e.target.value)}
            defaultValue={city ? city : props?.clientProfile?.city}
          />
        </div>
        <div className="d-flex w-100 gap-20 w-100">
          <TextFieldFormik
            placeholder="State"
            label="State"
            wrapperClassName="w-100"
            type="text"
            handleChange={(e) => setState(e.target.value)}
            defaultValue={state ? state : props?.clientProfile?.state}
          />
          <TextFieldFormik
            placeholder="Zip Code"
            label="Zip Code"
            wrapperClassName="w-100"
            type="number"
            handleChange={(e) => setZipCode(e.target.value)}
            defaultValue={zipCode ? zipCode : props?.clientProfile?.zip_code}
          />
        </div>
        {!props.technician && (
          <div className="d-flex w-100 gap-20 w-100">
            <div className="w-48">
              <label className={`lm-input-form-input-label form-label`}>
                {"Director"}
              </label>
              <div>
                <Select
                  className="director-dropdown"
                  defaultValue={props?.clientProfile?.director_name}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setDirectorId(e);
                  }}
                  options={directorsList}
                  // className={"DashbaordCardSelect"}
                  // popupClassName="DashbaordCardSelect"
                />
              </div>
            </div>
          </div>
        )}
        {!props.technician && (
          <RadioButtonsFormSection
            required
            RadioButtons={[
              { option: "Yes", id: 1 },
              { option: "No", id: 2 },
            ].map((content) => {
              const isSelected = props?.clientProfile?.insurance_status
                ? content.id === 1
                : content.id === 2;
              return (
                <RadioButtons
                  // disabled={content?.id ? true : false}
                  id={content?.id}
                  key={content?.id}
                  name={"insuranceStatus"}
                  onChange={(e) => {
                    if (e.target.value == 1) {
                      setStatus(true);
                      setNewInsuaranceArray([
                        {
                          insurance_provider_id: "",
                          insurance_id: "",
                          primary: "",
                        },
                      ]);
                    } else {
                      setExistingArray([]);
                      setNewInsuaranceArray([]);
                      setStatus(false);
                    }
                  }}
                  defaultChecked={isSelected}
                  Label={content?.option}
                />
              );
            })}
            label={"Insurance"}
          />
        )}
        {!props.technician && status && (
          <>
            {existingInsuranceArray.map((obj, index) => {
              return (
                <div className="d-flex w-100 gap-20 w-100" key={index}>
                  <Radio
                    name="insurance"
                    checked={obj.primary}
                    onChange={() => handleRadioChange(index)}
                    className="w-49 insurance-radio"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Primary
                  </Radio>
                  <div className="w-100">
                    <TextFieldFormik
                      placeholder="Insurance Id"
                      label=" Insurance Provider"
                      wrapperClassName="w-100 textCap"
                      type="text"
                      disabled={true}
                      defaultValue={obj?.title}
                    />
                  </div>
                  <TextFieldFormik
                    placeholder="Insurance Id"
                    label="Insurance Id"
                    wrapperClassName="w-49"
                    disabled={true}
                    type="number"
                    defaultValue={obj?.insurance_id}
                  />
                </div>
              );
            })}

            {newInsuaranceArray.map((obj, index) => {
              return (
                <div className="d-flex w-100 gap-20 w-100" key={index}>
                  <Radio
                    name="insurance"
                    checked={obj?.primary}
                    className="w-49 insurance-radio"
                    style={{ display: "flex", alignItems: "center" }}
                    onChange={() =>
                      handleRadioChange(index + existingInsuranceArray.length)
                    }
                  >
                    Primary
                  </Radio>
                  <div className="w-100">
                    <label className={`lm-input-form-input-label form-label `}>
                      Insurance Provider
                    </label>
                    <SearchDropdown
                      setInsuranceProvider={(value, obj) => {
                        const updatedInsuranceArray = [...newInsuaranceArray];
                        if (obj.value) {
                          delete updatedInsuranceArray[index].title;
                          updatedInsuranceArray[index] = {
                            ...updatedInsuranceArray[index],
                            insurance_provider_id: obj.value,
                          };
                        } else {
                          delete updatedInsuranceArray[index]
                            .insurance_provider_id;
                          updatedInsuranceArray[index] = {
                            ...updatedInsuranceArray[index],
                            title: obj.label,
                          };
                        }
                        setNewInsuaranceArray(updatedInsuranceArray);
                      }}
                      dropdownList={dropdownList}
                      insuranceProvider={
                        dropdownList.find(
                          (ob) => ob?.id === obj?.insurance_provider_id
                        )?.title || obj?.title
                      }
                    />
                  </div>
                  <TextFieldFormik
                    placeholder="Insurance Id"
                    label="Insurance Id"
                    wrapperClassName="w-49"
                    type="number"
                    handleChange={(e) => {
                      const updatedInsuranceArray = [...newInsuaranceArray];
                      updatedInsuranceArray[index] = {
                        ...updatedInsuranceArray[index],
                        insurance_id: e.target.value,
                      };
                      setNewInsuaranceArray(updatedInsuranceArray);
                    }}
                    value={obj?.insurance_id}
                    defaultValue={obj?.insurance_id}
                  />
                </div>
              );
            })}
          </>
        )}
        {!props.technician && status && (
          <button
            onClick={() => {
              const newMiniObjectiveIn = {
                insurance_id: "",
                insurance_provider_id: "",
                primary: false,
              };

              setNewInsuaranceArray([
                ...newInsuaranceArray,
                newMiniObjectiveIn,
              ]);
            }}
            className="add-main-btn"
            style={{ pointer: "cursor" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path d="M6 0V12" stroke="#ABABAB" />
              <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
            </svg>
          </button>
        )}

        <div className="w-100 d-flex justify-content-end">
          <Button
            onClick={() => {
              setProfileUpdate();
              props.setProfileModalView(false);
            }}
            disabled={
              isDisabled || (buttonDis && !props.technician) || buttondis2
            }
            className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 mt-30 `}
          >
            Update Profile
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default ProfileModal;
