import { useEffect, useState } from "react";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import { createNotification } from "../../../../helpers/createNotifications";
import LoaderSpinner from "../../../general/loader/loader.spinner";

const DevelopmentBackground = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 152,
      title: "dev_background_birth_condition",
      placeholder: "",
      label:
        "Unusual Conditions at Birth (such as blindness, high fever, jaundice, birth injury, cerebral palsy, blue baby or other)",
      wrapperClassName: "",
      options: [
        {
          id: 113,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 114,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 153,
      title: "dev_background_birth_condition_desc",
      placeholder: "Details...",
      label: "If yes, please describe the concerns and treatment details.",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 154,
      title: "dev_background_birth_milestone",
      placeholder: "",
      label:
        "Were there any developmental milestones that your child was delayed in or did not achieve?",
      wrapperClassName: "",
      options: [
        {
          id: 115,
          title: "Yes",
          key: "yes",
        },
        {
          id: 116,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 155,
      title: "dev_background_birth_milestone_desc",
      placeholder: "Details...",
      label: "If yes, please describe the concerns and treatment details.",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 156,
      title: "dev_background_development_disability",
      placeholder: "",
      label: "Is there a family history of developmental disabilities/delays?",
      wrapperClassName: "",
      options: [
        {
          id: 117,
          title: "Yes",
          key: "yes",
        },
        {
          id: 118,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 157,
      title: "dev_background_development_disability_desc",
      placeholder: "Details...",
      label: "If so, what familial relation and what diagnosis(es)?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 158,
      title: "dev_background_crawling_age",
      placeholder: "0-12",
      label: "Crawling",
      wrapperClassName: "style-between",
      options: [],
      heading:
        "List the ages that the following developmental milestones were achieved:",
      twoCol: true,
      colOneTitle: "Developmental Milestone:",
      colTwoTitle: "Age",
      fieldType: "number",
      formater: 5,
    },
    {
      id: 159,
      title: "dev_background_sitting_age",
      placeholder: "0-12",
      label: "Sitting",
      wrapperClassName: "style-between",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 160,
      title: "dev_background_walking_age",
      placeholder: "0-12",
      label: "Walking",
      wrapperClassName: "style-between",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 161,
      title: "dev_background_sleeping_age",
      placeholder: "0-12",
      label: "Sleeping through the night",
      wrapperClassName: "style-between",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 162,
      title: "dev_background_eating_age",
      placeholder: "0-12",
      label: "Eating solid food",
      wrapperClassName: "style-between",
      options: [],
      fieldType: "number",
      formater: 5,
    },
    {
      id: 163,
      title: "dev_background_talking_age",
      placeholder: "0-12",
      label: "Talking",
      wrapperClassName: "style-between",
      options: [],
      fieldType: "number",
      formater: 5,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/development-background/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/development-background"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/development-background/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default DevelopmentBackground;
