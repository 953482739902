import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import minimize from "../../assets/svg/minimizeicon.svg";
import {
  TinyMceApiKey,
  addNotesIconWhite,
  arrowRightOrange,
  arrowleftOrange
} from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import LoaderSpinner from "../general/loader/loader.spinner";

const InnerFooterReview = ({
  nextFormHandler,
  prevFormHandler,
  loading,
  disabled,
  saveDraft,
  next,
  prev,
  last,
  id,
  notes,
  setNotes,
  currentStep,
  currentSubStep,
  hasNextBtn,
}) => {
  const [showTextEditor, setShowTextEditor] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [initialNotes, setInitialNotes] = useState("");
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    const getNotes = async () => {
      try {
        const res = await httpClient.get(`/api/v1/notes?user_id=${id}`);
        if (res.data) {
          setInitialNotes(res.data.notes);
        }
        return res.data;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        // createNotification("error", error?.response?.data?.response.error);
        return false;
      }
    };
    if (id) getNotes();
  }, [id]);
  useEffect(() => {
    if (notes) setInitialNotes(notes);
  }, [showTextEditor]);
  const handleSaveNotes = async () => {
    try {
      if (notes) {
        const res = await httpClient.post(`/api/v1/notes/create_or_update`, {
          description: notes,
          user_id: id,
        });
        setInitialNotes(res.data.notes);
        createNotification("success", "Notes Saved");
        setSaving(false);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        setSaving(false);

        if (id) {
          window.open("/dashboardlogin", "_self");
        } else {
          window.open("/clientFormlogin ", "_self");
        }
        return;
      }
      setSaving(false);

      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  return (
    <>
      <div
        className="onboarding-forms-section-footer"
        style={{ justifyContent: "right" }}
      >
        <div
          className="d-flex gap-20"
          style={{
            justifyContent: "right",
          }}
        >
          {prevFormHandler && (
            <Button onClick={prev} className="lm-form-btn lm-form-btn-previous">
              {arrowleftOrange}
            </Button>
          )}

          {currentStep !== 9 && (
            <Button
              onClick={next}
              className="lm-form-btn disabled-next-btn lm-form-btn-previous"
              disabled={disabled || !hasNextBtn}
            >
              {arrowRightOrange}
            </Button>
          )}
          {showTextEditor ? (
            <Button
              onClick={() => {
                setSaving(true);
                handleSaveNotes();
              }}
              className="lm-form-btn lm-form-btn-primary"
            >
              {saving ? <LoaderSpinner /> : "Save notes"}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setShowTextEditor(true);
              }}
              className="lm-form-btn lm-form-btn-primary"
              disabled={disabled}
            >
              {addNotesIconWhite}
              Add Emai Notes
            </Button>
          )}
        </div>
      </div>
      {showTextEditor && (
        <div class="modal-content modal-content custom-modal-content notes-modal hide-textarea">
          <div class="modal-header mb-16">
            <h5 className="f-16 f-w-700 ps-4 ">Email Notes</h5>
            <button
              type="button"
              class="btn"
              aria-label="Close"
              onClick={() => setShowTextEditor(false)}
            >
              <img src={minimize} />
            </button>
          </div>
          {isLoading && (
            <LoaderSpinner
              tip="Loading"
              size="40"
              top={"50%"}
              left={"50%"}
              color="#090834"
            />
          )}

          <Editor
            //  onInit={(evt, editor) => editorRef.current = editor}
            apiKey= {TinyMceApiKey}
            initialValue={!isLoading ? initialNotes : ""}
            init={{
              height: "100%",
              menubar: false,
              statusbar: false,
              toolbar_location: "bottom",
              plugins: "advlist autolink lists link",
              toolbar: "bold italic underline bullist numlist",
              browser_spellcheck: true,
            }}
            onInit={() => {
              setIsLoading(false);
            }}
            onEditorChange={(content) => setNotes(content)}
          />
        </div>
      )}
    </>
  );
};

export default InnerFooterReview;
