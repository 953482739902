import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import "./Modals.scss";
import { set } from "lodash";

const GlobalSettingsModal = ({
  onHide,
  show,
  modalLoading,
  setLoadingModal,
  setGlobalSettingModal,
  configurationType,
  appointmentData,
  setAppointmentData,
  setActionDone,
  actionDone,
  appointmentId,
  bulkSelectedRows,
  setBulkSelectedRows,
  setSettingOptionsEnabled,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [invalidCheckinUnlock, setInvalidCheckinUnlock] = useState(false);
  const [invalidCheckoutUnlock, setInvalidCheckoutUnlock] = useState(false);
  const [inValidMands, setInValidMands] = useState(false);
  const [inValidAccepNos, setInValidAccepNos] = useState(false);
  const [inValidCompliances, setInValidCompliances] = useState(false);
  const [inValidAbcBehaviours, setInValidAbcBehaviours] = useState(false);
  const [inValidGoals, setInValidGoals] = useState(false);
  const [inValidObjectives, setInValidObjectives] = useState(false);
  const [customizedSettings, setcustomizedSettings] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  const [invalidAppointmentUnlock, setInvalidAppointmentUnlock] =
    useState(false);
  const [inValidCheckinSubmissionTime, setInValidCheckinSubmissionTime] =
    useState(false);
  const [inValidCheckoutSubmissionTime, setInValidCheckoutSubmissionTime] =
    useState(false);
  const [inValidDataSheetSubTime, setInValidDataSheetSubTime] = useState(false);
  const [globalConfigurations, setGlobalConfigurations] = useState({});

  useEffect(() => {
    setcustomizedSettings(false);
    setIsButtonDisabled(false);
    setInvalidCheckinUnlock(false);
    setInvalidCheckoutUnlock(false);
    setInValidCheckinSubmissionTime(false);
    setInValidCheckoutSubmissionTime(false);
    setInValidMands(false);
    setInValidAccepNos(false);
    setInValidCompliances(false);
    setInValidAbcBehaviours(false);
    setInValidGoals(false);
    setInValidObjectives(false);
    setInvalidAppointmentUnlock(false);
  }, [onHide]);

  useEffect(() => {
    setDisableFields(
      (configurationType === "AppointmentSettings" ||
        configurationType === "BulkUpdate") &&
        !customizedSettings
    );
  }, [configurationType, customizedSettings]);

  const get_filtered_params = () => {
    return {
      appointment_unlock_time: globalConfigurations?.appointment_unlock_time,
      checkin_unlock_time: globalConfigurations?.checkin_unlock_time,
      checkout_unlock_time: globalConfigurations?.checkout_unlock_time,
      checkin_form_submission_time:
        globalConfigurations?.checkin_form_submission_time,
      checkout_form_submission_time:
        globalConfigurations?.checkout_form_submission_time,
      data_sheet_submission_time:
        globalConfigurations?.data_sheet_submission_time,
      number_of_mands: globalConfigurations?.number_of_mands,
      no_of_accepting_nos: globalConfigurations?.no_of_accepting_nos,
      number_of_compliances: globalConfigurations?.number_of_compliances,
      number_of_abc_behaviours: globalConfigurations?.number_of_abc_behaviours,
      number_of_goals: globalConfigurations?.number_of_goals,
      number_of_objectives: globalConfigurations?.number_of_objectives,
    };
  };

  useEffect(() => {
    const getConfigurations = async () => {
      const filtered_params = get_filtered_params();
      try {
        setLoadingModal(true);
        const res = await httpClient.get(
          `/api/v1/appointment_configurations/${1}`,
          {
            params: {
              configuration_type: configurationType,
              appointment_id: appointmentId,
            },
          }
        );
        if (res?.data?.appointment_configuration) {
          setGlobalConfigurations(res?.data?.appointment_configuration);
        } else {
          setGlobalConfigurations(filtered_params);
          createNotification("error", "Unable to fetch global settings");
        }
        setLoadingModal(false);
        return true;
      } catch (error) {
        handleErrorResponse(error);
        setLoadingModal(false);
      }
    };
    if (show) {
      getConfigurations();
    }
  }, [show]);

  const handleBookAppointment = async () => {
    const filtered_params = get_filtered_params();
    try {
      const response = await httpClient.post(`api/v1/appointments/`, {
        appointment: {
          ...appointmentData.appointment,
          start_time: `${appointmentData.appointment.schedule_date}T${appointmentData.appointment.start_time}Z`,
          end_time: `${appointmentData.appointment.schedule_date}T${appointmentData.appointment.end_time}Z`,
        },

        appointment_configuration: filtered_params,
      });
      if (response.data) {
        createNotification("success", "Apponitment Booked");
        setAppointmentData({
          appointment: {
            schedule_date: "",
            client_id: "",
            technician_id: "",
            location: "",
            start_time: "",
            end_time: "",
          },
        });
        setActionDone(!actionDone);
      }
      setGlobalSettingModal(false);
      return true;
    } catch (error) {
      setGlobalSettingModal(false);
      handleErrorResponse(error);
    }
  };

  const handleUpdate = async () => {
    const filtered_params = get_filtered_params();
    try {
      setLoadingModal(true);
      const res = await httpClient.put(
        `/api/v1/appointment_configurations/${1}`,
        {
          appointment_configuration: filtered_params,
        },
        {
          params: {
            appointment_id: appointmentId,
            configuration_type: configurationType,
          },
        }
      );
      if (res?.data?.appointment_configuration) {
        setGlobalSettingModal(false);
        createNotification("success", res?.data?.message);
      } else {
        createNotification("error", "Unable to fetch settings");
      }
      setLoadingModal(false);
      return true;
    } catch (error) {
      handleErrorResponse(error);
      setLoadingModal(false);
    }
  };

  const handleBulkUpdate = async () => {
    const filtered_rows = bulkSelectedRows.map((record) => record.id);
    const filtered_params = get_filtered_params();
    try {
      setLoadingModal(true);
      const res = await httpClient.put(
        `/api/v1/appointment_configurations/bulk_update`,
        {
          appointment_configuration: filtered_params,
        },
        {
          params: {
            appointment_ids: filtered_rows,
          },
        }
      );
      if (res?.status === 200) {
        setGlobalSettingModal(false);
        createNotification("success", res?.data?.response?.message);
      } else {
        createNotification("error", res?.data?.response?.message);
      }
      setLoadingModal(false);
      setBulkSelectedRows([]);
      setSettingOptionsEnabled(true);
      setActionDone(!actionDone);
      return true;
    } catch (error) {
      handleErrorResponse(error);
      setLoadingModal(false);
    }
  };

  const not_in_multiples_of_5 = (value, filedName) => {
    if (
      [
        "invalidAppointmentUnlock",
        "invalidCheckinUnlock",
        "invalidCheckoutUnlock",
      ].includes(filedName) &&
      value % 5 !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateValueRestriction = (
    value,
    restrictedValue,
    setterMethod,
    filedName
  ) => {
    const isInvalid =
      !value ||
      parseFloat(value) === 0 ||
      parseFloat(value) > restrictedValue ||
      not_in_multiples_of_5(value, filedName);
    setIsButtonDisabled(isInvalid);
    setterMethod(isInvalid);
  };

  const handleChange = (props) => (e) => {
    const newValue = e.target.value;
    validateValueRestriction(
      newValue,
      props.restrictedValue,
      props.errorSetterMethod,
      props.filedName
    );
    setGlobalConfigurations({
      ...globalConfigurations,
      [props.valueKey]: newValue,
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <div className="scrollable-global-setting-modal">
          <Modal.Body>
            <Modal.Header className="border-0 p-0 pb-12" closeButton>
              <Modal.Title className="f-24 lh-25 f-w-700 clr-dark-grey">
                {configurationType === "Global"
                  ? "Global Settings"
                  : "Appointment Settings"}
              </Modal.Title>
            </Modal.Header>
            <section className="d-flex flex-column gap-30 pt-30">
              <div className="d-flex flex-column gap-20">
                <div className="d-flex flex-wrap">
                  <TextFieldFormik
                    placeholder="1-60 Mintues"
                    label="Appointment unlock time"
                    wrapperClassName="w-100 mt-30"
                    type="number"
                    disabled={disableFields}
                    values={globalConfigurations?.appointment_unlock_time}
                    errors={invalidAppointmentUnlock}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 60,
                        errorSetterMethod: setInvalidAppointmentUnlock,
                        valueKey: "appointment_unlock_time",
                        filedName: "invalidAppointmentUnlock",
                      })(e)
                    }
                  />
                  <span className="">{"Should be in multiples of 5"}</span>
                </div>
                <div
                  className="d-flex flex-wrap fw-bold"
                  style={{ fontSize: "17px" }}
                >
                  Unlock Time
                </div>
                <div className="d-flex gap-30">
                  <div className="w-100 flex-grow-1 fs-10">
                    <TextFieldFormik
                      placeholder="1-60 Minutes"
                      label="check in"
                      type="number"
                      disabled={disableFields}
                      errors={invalidCheckinUnlock}
                      values={globalConfigurations?.checkin_unlock_time}
                      handleChange={(e) =>
                        handleChange({
                          restrictedValue: 60,
                          errorSetterMethod: setInvalidCheckinUnlock,
                          valueKey: "checkin_unlock_time",
                          filedName: "invalidCheckinUnlock",
                        })(e)
                      }
                    />
                    <span className="">{"Should be in multiples of 5"}</span>
                  </div>

                  <div className="w-100 flex-grow-1 fs-10">
                    <TextFieldFormik
                      placeholder="1-60 Minutes"
                      label="Check out"
                      wrapperClassName="w-100 flex-grow-1"
                      type="number"
                      disabled={disableFields}
                      errors={invalidCheckoutUnlock}
                      values={globalConfigurations?.checkout_unlock_time}
                      handleChange={(e) =>
                        handleChange({
                          restrictedValue: 60,
                          errorSetterMethod: setInvalidCheckoutUnlock,
                          valueKey: "checkout_unlock_time",
                          filedName: "invalidCheckoutUnlock",
                        })(e)
                      }
                    />
                    <span className="">{"Should be in multiples of 5"}</span>
                  </div>
                </div>
                <div
                  className="d-flex flex-wrap fw-bold"
                  style={{ fontSize: "17px" }}
                >
                  Submission Time
                </div>
                <div className="d-flex gap-30 align-items-start">
                  <TextFieldFormik
                    placeholder="1-60 Minutes"
                    label="check in"
                    wrapperClassName="w-100 flex-grow-1 fs-10"
                    type="number"
                    disabled={disableFields}
                    errors={inValidCheckinSubmissionTime}
                    values={globalConfigurations?.checkin_form_submission_time}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 60,
                        errorSetterMethod: setInValidCheckinSubmissionTime,
                        valueKey: "checkin_form_submission_time",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-60 Minutes"
                    label="Check out"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidCheckoutSubmissionTime}
                    values={globalConfigurations?.checkout_form_submission_time}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 60,
                        errorSetterMethod: setInValidCheckoutSubmissionTime,
                        valueKey: "checkout_form_submission_time",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-60 Minutes"
                    label="Data sheet"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidDataSheetSubTime}
                    values={globalConfigurations?.data_sheet_submission_time}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 60,
                        errorSetterMethod: setInValidDataSheetSubTime,
                        valueKey: "data_sheet_submission_time",
                      })(e)
                    }
                  />
                </div>
                <hr class="my-4" />
                <div
                  className="d-flex flex-wrap fw-bold"
                  style={{ fontSize: "17px" }}
                >
                  Data sheet fields (Per Hour)
                </div>
                <div className="d-flex gap-30 align-items-start">
                  <TextFieldFormik
                    placeholder="1-50"
                    label="mands"
                    wrapperClassName="w-100 flex-grow-1 fs-10"
                    type="number"
                    disabled={disableFields}
                    errors={inValidMands}
                    values={globalConfigurations?.number_of_mands}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidMands,
                        valueKey: "number_of_mands",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-50"
                    label="accepting no's"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidAccepNos}
                    values={globalConfigurations?.no_of_accepting_nos}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidAccepNos,
                        valueKey: "no_of_accepting_nos",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-50"
                    label="compliances"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidCompliances}
                    values={globalConfigurations?.number_of_compliances}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidCompliances,
                        valueKey: "number_of_compliances",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-50"
                    label="abc behaviors"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidAbcBehaviours}
                    values={globalConfigurations?.number_of_abc_behaviours}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidAbcBehaviours,
                        valueKey: "number_of_abc_behaviours",
                      })(e)
                    }
                  />
                </div>

                <div
                  className="d-flex flex-wrap fw-bold"
                  style={{ fontSize: "17px" }}
                >
                  Goals & Objectives
                </div>
                <div className="d-flex gap-30 align-items-start">
                  <TextFieldFormik
                    placeholder="1-50"
                    label="no of goals"
                    wrapperClassName="w-100 flex-grow-1 fs-10"
                    type="number"
                    disabled={disableFields}
                    errors={inValidGoals}
                    values={globalConfigurations?.number_of_goals}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidGoals,
                        valueKey: "number_of_goals",
                      })(e)
                    }
                  />
                  <TextFieldFormik
                    placeholder="1-50"
                    label="no of objectives (per goal)"
                    wrapperClassName="w-100 flex-grow-1"
                    type="number"
                    disabled={disableFields}
                    errors={inValidObjectives}
                    values={globalConfigurations?.number_of_objectives}
                    handleChange={(e) =>
                      handleChange({
                        restrictedValue: 50,
                        errorSetterMethod: setInValidObjectives,
                        valueKey: "number_of_objectives",
                      })(e)
                    }
                  />
                </div>
                <hr class="my-4" style={{ minHeight: "2px" }} />
              </div>

              <div className="w-100 d-flex justify-content-end align-items-center m-0">
                {configurationType === "Global" ||
                configurationType === "SpecificAppointment" ? (
                  <Button
                    disabled={isButtonDisabled}
                    className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 `}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <div>
                    {customizedSettings ? (
                      <Button
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#ffffff",
                          borderColor: "#f6891f",
                          color: "#f6891f",
                          borderRadius: "5px",
                        }}
                        className={`lm-form-btn-modalbtn`}
                        onClick={() => {
                          setcustomizedSettings(false);
                        }}
                      >
                        Restore Default
                      </Button>
                    ) : (
                      <Button
                        style={{
                          marginRight: "10px",
                          backgroundColor: "#ffffff",
                          borderColor: "#f6891f",
                          color: "#f6891f",
                          borderRadius: "5px",
                        }}
                        className={`lm-form-btn-modalbtn customize-settings-button`}
                        onClick={() => {
                          setcustomizedSettings(true);
                        }}
                      >
                        Customize
                      </Button>
                    )}
                    <Button
                      disabled={isButtonDisabled}
                      className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary f-16 f-w-600 `}
                      onClick={() => {
                        if (configurationType === "BulkUpdate") {
                          handleBulkUpdate();
                        } else {
                          handleBookAppointment();
                        }
                      }}
                    >
                      {configurationType === "BulkUpdate"
                        ? "Apply"
                        : "Create Appointment"}
                    </Button>
                  </div>
                )}
              </div>
            </section>
          </Modal.Body>
        </div>
      </Spin>
    </Modal>
  );
};

export default GlobalSettingsModal;
