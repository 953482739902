import React, { useEffect, useState } from "react";
import "./CheckEmail.scss";
import mail from "../../assets/svg/mail-01.svg";
import dummyImage from "../../assets/images/lm-auth-featured.svg";
import dummyImageTop from "../../assets/images/image-4.svg";
import backarrow from "../../assets/svg/backloginicon.svg";
import CurrentPerformance from "./../multiStepperForm/education/currentPerformance/currentPerformance";
import { TableDataArchive } from "./../../constants/clientdata";
import { persistReducer } from "redux-persist";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";

const CheckEmail = () => {
  const location = useLocation();
  const propsReceived = location.state;
  const navigate = useNavigate();
  const handleReset = async () => {
    const emailSend = await resetPassword({
      user: {
        email: propsReceived?.email,
      },
    });
    if (emailSend?.data?.message) {
      createNotification(
        "success",
        "You will receive an email if you have a account"
      );
      // navigate("/checkmail", { state: { email: propsReceived.user.email } });
    } else {
      createNotification("error", emailSend?.data?.response?.error.message);
    }
  };
  return (
    <section className="checkemail-section">
      <img className="checkemail-image" src={dummyImage} alt="top" />
      <img className="checkemail-image-top" src={dummyImageTop} alt="bottom" />

      <div className="checkemail-wrapper">
        <div className="check-your-email">
          <span className="email-icon">
            <img src={mail} alt="icon" />
          </span>
          <h3 className="check-your-email-heading">Check your email</h3>
          <p className="check-your-email-reset-link">
            We sent a password reset link to
          </p>
          <p>{propsReceived.email}</p>

          <p className="receive-email">
            Didn’t receive the email?{" "}
            <p
              style={{ cursor: "pointer", display: "inline" }}
              onClick={() => {
                handleReset();
              }}
            >
              Click to resend
            </p>{" "}
          </p>

          <p
            className="back-to-login"
            onClick={() => navigate("/dashboardlogin")}
          >
            {" "}
            <img src={backarrow} alt="icon" /> Back to log in
          </p>
        </div>
      </div>
    </section>
  );
};

export default CheckEmail;
