import React, { useEffect, useState } from "react";
import { TextFieldFormik } from "../../../form-controls/input/text.field.formik";
import { GenderOptons } from "../../../../constants/constants";
import RadioButtons from "../../../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import { createNotification } from "../../../../helpers/createNotifications";
import httpClient from "../../../../services/axios";

const PatientInformation = ({ next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 16,
      title: "patient_info_child_name",
      placeholder: "",
      label: "Name of Child",
      wrapperClassName: "",
      options: [],
      gridView: true,
    },
    {
      id: 17,
      title: "patient_info_child_first_name",
      placeholder: "First Name",
      label: "",
      wrapperClassName: "w-3",
      options: [],
      gridView: true,
      formater: 4,
      firstInput: true,
      tabIndex: 1,
    },
    {
      id: 18,
      title: "patient_info_child_middle_name",
      placeholder: "Middle Name",
      label: "",
      wrapperClassName: "w-3",
      options: [],
      gridView: true,
      formater: 4,
      tabIndex: 2,
    },
    {
      id: 19,
      title: "patient_info_child_last_name",
      placeholder: "Last Name",
      label: "",
      wrapperClassName: "w-3",
      options: [],
      gridView: true,
      formater: 4,
      tabIndex: 3,
    },
    {
      id: 20,
      title: "patient_info_gender",
      placeholder: "",
      label: "Gender",
      wrapperClassName: "",
      options: [
        {
          id: 17,
          title: "Male",
          tabIndex: 4,

          key: "male",
        },
        {
          id: 18,
          title: "Female",
          tabIndex: 5,

          key: "female",
        },
      ],
      // gridView: true,
    },
    {
      id: 21,
      title: "patient_info_dob",
      placeholder: "dd/mm/yyyy",
      label: "Date of Birth",
      wrapperClassName: "w-48",
      options: [],
      gridView: true,
      fieldType: "date",
      birthDate: true,
      tabIndex: 6,
    },
    {
      id: 22,
      title: "patient_info_phone",
      placeholder: "(000) 000-0000",
      label: "Patient Phone Number",
      wrapperClassName: "w-48",
      options: [],
      gridView: true,
      formater: 2,
      fieldType: "number",
      tabIndex: 7,
    },
    {
      id: 23,
      title: "patient_info_address",
      placeholder: "Complete Address",
      label: "Address",
      options: [],
      gridView: true,
      tabIndex: 8,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/patient-information/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/patient-information"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response?.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/patient-information/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response?.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default PatientInformation;
