export const stepsMapping = {
  "basic-information": 1,
  general: 0,
  "insurance-information": 1,
  "patient-information": 2,
  "parent-guardian-information": 3,
  "medical-history": 2,
  "health-care-providers": 0,
  "patient-medical-information": 1,
  "therapy-providers": 3,
  education: 4,
  "institute-services": 0,
  "current-performance": 1,
  "family-background": 5,
  "caregiver-involvement": 0,
  "home-family": 1,
  "psychological-history": 6,
  "birth-development": 7,
  "birth-information": 0,
  "development-background": 1,
  "current-behaviours": 8,
  "general-behaviour": 0,
  "suicide-self-harm": 1,
  "sensory-integeration": 2,
  communication: 3,
  "self-care": 4,
  "social-skills": 5,
  "drug-alcohol-profile": 6,
  "legal-status": 7,
  "discipline-information": 9,
};
