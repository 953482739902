import { Editor } from "@tinymce/tinymce-react";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import paperairplane from "../../assets/svg/paper-airplane.svg";
import { TinyMceApiKey } from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import LoaderSpinner from "../general/loader/loader.spinner";
import styles from "./ModalStyles.module.scss";
import "./Modals.scss";

const TextEditorModal = (props) => {
  const { clientname, onCross, onSend, setcontent, id, content, show } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [initialValue, setInitialValue] = useState("");
  useEffect(() => {
    const getNotes = async () => {
      try {
        const res = await httpClient.get(`/api/v1/notes?user_id=${id}`);
        if (res.data) {
          setInitialValue(res.data.notes);
        }
        return res.data;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        setInitialValue("");
        console.log(error);
        // createNotification("error", error?.response?.data?.response.error);
        return false;
      }
    };
    if (id && show) getNotes();
  }, [id, show]);

  return (
    <div className="custom-modal-wrapper">
      <Modal
        {...props}
        size={800}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.emailEditorModal}
      >
        <Spin spinning={props.modalLoading}>
          <Modal.Body class="custom-modal-body">
            <div
              class="modal-content custom-modal-content hide-textarea"
              style={{ position: "relative" }}
            >
              <div class="modal-header">
                <h5 className="f-22 f-w-700 ps-4">Client Email</h5>
                <button
                  type="button"
                  class="btn-close"
               
                  aria-label="Close"
                  onClick={() => onCross(false)}
                ></button>
              </div>
              <div className="modal-sub-heading">
                <span className="f-w-400 f-14 clr-d-color">Subject:</span>
                <p className="f-w-600 f-14">Updates in Sign up form</p>
              </div>
              {isLoading && (
                <LoaderSpinner
                  tip="Loading"
                  size="40"
                  top={"50%"}
                  left={"50%"}
                  color="#090834"
                />
              )}

              <Editor
                //  onInit={(evt, editor) => editorRef.current = editor}
                apiKey= {TinyMceApiKey}
                initialValue={
                  !isLoading && `<p>Dear ${clientname}</p> ${initialValue}`
                }
                init={{
                  height: 500,
                  menubar: false,
                  statusbar: false,
                  toolbar_location: "bottom",
                  plugins: "advlist autolink lists link",
                  toolbar: "bold italic underline bullist numlist",
                  //    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onInit={() => {
                  setIsLoading(false);
                }}
                onEditorChange={(content) => setcontent(content)}
              />

              <div class="custom-modal-footer">
                <div className="modal-footer-btn" onClick={() => onSend()}>
                  <img src={paperairplane} />
                  <button>Send</button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Spin>
      </Modal>
    </div>
  );
};

export default TextEditorModal;
