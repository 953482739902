import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfoIconRed } from "../../constants/constants";
import "./Modals.scss";

const TestingGoalsAlertModal = ({ showModal, setIsModalOpen }) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size={655}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="scrollable-global-setting-modal">
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton>
            {InfoIconRed}
            <Modal.Title
              style={{ marginLeft: "20px" }}
              className="f-21 lh-25 f-w-800 ml-10"
            >
              ALERT
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-20 pt-20">
            <div className="testing-require-modal-content">
              <p>PLEASE TEST GOALS IN GREEN</p>
            </div>
            <div className="w-100 d-flex justify-content-end align-items-center">
              <Button
                className={`f-16 f-w-600 testing-goals-alert-modal-btn`}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                ok
              </Button>
            </div>
          </section>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default TestingGoalsAlertModal;
