import React from "react";
const LoaderSpinner = ({ color, size = 32, top, left }) => {
  return (
    <div
      className="spinner-border"
      role="status"
      style={{
        fontSize: size / 2,
        color,
        width: size + "px",
        height: size + "px",
        position: "absolute",
        left: left,
        top: top,
        zIndex: 1,
      }}
    ></div>
  );
};
export default LoaderSpinner;
