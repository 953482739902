const fetchFormattedDateTime = () => {
  const currentDatetime = new Date();

  let currentDatetimeString =
    currentDatetime.toDateString() + " " + currentDatetime.toTimeString();

  // Remove the "GMT" part using a regular expression
  currentDatetimeString = currentDatetimeString.replace(
    / GMT[+-]\d{4}.*$/,
    ""
  );
  return currentDatetimeString;
};

export default fetchFormattedDateTime;
