import { useEffect, useState } from "react";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import { createNotification } from "../../../../helpers/createNotifications";

const Communication = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 208,
      title: "comm_talk_pattern",
      placeholder: "",
      label: "Did the child start to talk and then stop talking again?",
      wrapperClassName: "",
      options: [
        {
          id: 184,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 185,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 209,
      title: "comm_talk_pattern_desc",
      placeholder: "Details...",
      label: "If so, please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 210,
      title: "comm_immitate_phrase",
      placeholder: "",
      label:
        "Does your child imitate phrases that he/she hears? Parroting/Echolalia?",
      wrapperClassName: "",
      options: [
        {
          id: 186,
          title: "Yes",
          key: "yes",
        },
        {
          id: 187,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 211,
      title: "comm_vocabulary_proportion",
      placeholder: "",
      label:
        "Is the child’s vocabulary (the # of things he can point to or name accurately) greatly out of proportion to his ability to “communicate” (to answer questions or tell you something?)",
      wrapperClassName: "",
      options: [
        {
          id: 188,
          title: "Yes",
          key: "yes",
        },
        {
          id: 189,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 212,
      title: "comm_use_of_yes",
      placeholder: "",
      label: "Does your child use the word “YES”?",
      wrapperClassName: "",
      options: [
        {
          id: 190,
          title: "Yes",
          key: "yes",
        },
        {
          id: 191,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 213,
      title: "comm_use_of_yes_desc",
      placeholder: "Details...",
      label: "If not, what does indicating “YES” look like?",
      wrapperClassName: "",
      options: [],
      descType: "no",
      inputType: true,
    },
    {
      id: 214,
      title: "comm_use_of_no",
      placeholder: "",
      label: "Does your child use the word “NO”?",
      wrapperClassName: "",
      options: [
        {
          id: 192,
          title: "Yes",
          key: "yes",
        },
        {
          id: 193,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 215,
      title: "comm_use_of_no_desc",
      placeholder: "Details...",
      label: "If not, what does indicating “NO” look like?",
      wrapperClassName: "",
      options: [],
      descType: "no",
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/communication/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get("api/v1/sections/communication");
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/communication/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default Communication;
