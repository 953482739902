import React from 'react';

const ChangePassword = () => {
    return (
        <div className="login-box-wrapper">
            <div className='login-box box-wrapper'>
                <h2>Change Passwors</h2>
            </div>
        </div>

    )
}
export default ChangePassword;