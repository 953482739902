import { Form, Formik, FormikProvider, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { createNotification } from "../../../helpers/createNotifications";
import RadioButtons from "../../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import { TextFieldFormik } from "../../form-controls/input/text.field.formik";

import dayjs from "dayjs";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import {
  NumberFormat,
  validateEmail,
  validateName,
} from "../../../helpers/utils";
import { GetCurrentUser } from "../../../utils/utilAuth";
import LoaderSpinner from "../../general/loader/loader.spinner";

const CommonCheckInForm = ({
  data,
  questionInfo,
  prev,
  next,
  halfWidth,
  postData,
  last,
  idCheck,
  savedSignature,
  savedSignatureTwo,
  setdashboardView,
  setSignatureDisable,
  timeOver,
  formStatus,
  changeFormStatus,
  utcSavedDates,
}) => {
  const { params } = useMatch("*");
  const formSubmitButtonRef = useRef();
  const route = Object.values(params)[0];
  const { id, dataSheetId, userId, serviceId } = useParams();
  const user = GetCurrentUser();
  const [mappingData, setMappingData] = useState([...data?.questions]);
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [errorsLength, setErrorsLength] = useState(null);
  const [customData, setCustomData] = useState([
    ...data?.questions?.filter(
      (item) => item?.question_type === "custom_with_text"
    ),
  ]);
  const serviceNoteApproved = data?.service_note_approved;
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const ABC_BEHAVIOUR = [
    { value: "Cries", key: "cries" },
    { value: "Screams", key: "scream" },
    { value: "Hits", key: "hits" },
    { value: "Throw object", key: "throw_object" },
    { value: "Other", key: "behaviour_other" },
    { value: "Told no", key: "asked_no" },
    { value: "Ignored", key: "ignored" },
    { value: "Given what they wanted", key: "given_wanted" },
    { value: "Redirect to alternate behaviour", key: "redirect_alt_behaviour" },
    { value: "Other", key: "consequence_other" },
    { value: "Was Told 'no'", key: "told_no" },
    { value: "Was asked to do something", key: "ask_something" },
    { value: "Attention given to others", key: "attention_to_other" },
    { value: "Transition", key: "transition" },
    { value: "Other", key: "antecedent_other" },
  ];

  let isSubmitting = false;
  let isFormatSubmit = true;
  const formatValue = (value, formatNumber = null) => {
    switch (formatNumber) {
      case 1:
        return NumberFormat(value, "xxxx-xxxx-xxxx");
      case 2:
        return NumberFormat(value, "(xxx) xxx-xxxx");
      case 5:
        return value;
      case 8:
        if (value) {
          const val = ABC_BEHAVIOUR.find((obj) => obj?.key === value)?.value;
          if (val) {
            return val;
          } else {
            return value;
          }
        }
      default:
        return value;
    }
  };

  const convertToFormData = (base64String, contentType, filename) => {
    const encodedData = base64String?.split(",")[1];
    const byteCharacters = atob(encodedData);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);

    const blob = new Blob([byteArray], { type: contentType });

    const file = new File([blob], filename, { type: contentType });

    return file;
  };

  useEffect(() => {
    const questions = mappingData;
    if (questions.find((item) => item?.question_type === "custom")) {
      const customWithTextObject = {
        batch_no: 1,
        question_type: "custom_with_text",
        customObjects: customData,
      };
      const newData = questions?.filter(
        (item) => item?.question_type !== "custom_with_text"
      );
      const customWithTextObjects = [];
      const parentIndices = {};
      const parentObjects = [];
      const visitedParents = new Set();

      customData.forEach((customItem) => {
        const parentID = customItem?.meta?.parent_question_id;

        if (!visitedParents.has(parentID)) {
          parentObjects.push(customItem);
          visitedParents.add(parentID);
        }
      });
      const noAnswers = [];
      customData.forEach((customItem) => {
        if (customItem?.answers?.length) {
          parentObjects.forEach((parentItem, parentIndex) => {
            const parentID = customItem?.meta?.parent_question_id;
            parentItem.answers.forEach((answer, index) => {
              const batchNo = answer?.meta?.batch_no;
              if (!parentIndices.hasOwnProperty(parentID + index)) {
                if (
                  parentItem.answers[index] &&
                  parentID === parentItem?.meta?.parent_question_id
                ) {
                  parentIndices[parentID + index] =
                    customWithTextObjects.length;
                  customWithTextObjects.push({
                    batch_no: batchNo,
                    question_type: "custom_with_text",
                    customObjects: [],
                    parent_id: parentID,
                  });
                }
              }

              if (parentItem.meta?.parent_question_id === parentID) {
                customWithTextObjects[
                  parentIndices[parentID + index]
                ]?.customObjects.push(customItem);
              }
            });
          });
        } else {
          if (!noAnswers.includes(customItem)) {
            noAnswers.push(customItem);
          }
        }
      });
      if (noAnswers?.length) {
        noAnswers.forEach((customItem) => {
          const parentID = customItem?.meta?.parent_question_id;
          if (!parentIndices.hasOwnProperty(parentID)) {
            parentIndices[parentID] = customWithTextObjects.length;
            customWithTextObjects.push({
              batch_no: 1,
              question_type: "custom_with_text",
              customObjects: [],
              parent_id: parentID,
            });
          }
          customWithTextObjects[parentIndices[parentID]].customObjects.push(
            customItem
          );
        });
      }

      const tempData = newData.flatMap((item) => {
        if (item?.question_type === "custom") {
          if (customData[0]?.answers?.length) {
            return [
              item,
              ...customWithTextObjects.filter(
                (object) => object.parent_id === item.id
              ),
            ];
          } else {
            const parentID = item.id;
            const parentIndex = parentIndices[parentID];
            const customWithTextObject = customWithTextObjects[parentIndex];
            return [item, customWithTextObject];
          }
        } else {
          return item;
        }
      });
      const finalData = tempData.filter((data) => {
        if (data?.customObjects) {
          if (data?.customObjects?.length) {
            return data;
          } else {
            return;
          }
        } else {
          return data;
        }
      });

      finalData.sort((a, b) => {
        if ("parent_id" in a && "parent_id" in b) {
          if (a.parent_id < b.parent_id) {
            return -1;
          }
          if (a.parent_id > b.parent_id) {
            return 1;
          }
        }
        if (a.batch_no < b.batch_no) {
          return -1;
        }
        if (a.batch_no > b.batch_no) {
          return 1;
        }
        return 0;
      });
      setMappingData([...finalData]);
    }
  }, [data]);
  const duplicateCustomObject = (parentID, batch_no) => {
    const customWithParent = mappingData?.reduce((lastMatch, item) => {
      if (item?.parent_id === parentID) {
        return item;
      }
      return lastMatch;
    }, null);
    const newCustomWithParent = { ...customWithParent };
    const index = mappingData.indexOf(customWithParent);
    newCustomWithParent.batch_no = batch_no + 1;
    const newData = [...mappingData];
    newData.splice(index + 1, 0, newCustomWithParent);
    setMappingData([...newData]);
  };

  const generateSubmitRequest = async (values) => {
    setLoading(true);
    if (savedSignature) {
      try {
        const submitted = await postData({
          resource: "check_in_form",
          appointment_id: id,
          form_status: "completely_filled",
          therapist_signature_image: startsWithHttp(savedSignature)
            ? savedSignature
            : convertToFormData(
                savedSignature,
                "image/png",
                "therapist-signature"
              ),

          section: {
            questions_attributes: values.section.questions_attributes,
          },
        });
        if (submitted) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      return;
    }
  };

  const generatePartiallyFilledRequest = async (values) => {
    setLoading(true);
    // if (savedSignature) {
    try {
      const submitted = await postData({
        resource: "check_in_form",
        appointment_id: id,
        form_status: "partially_filled",

        section: {
          questions_attributes: values.section.questions_attributes,
        },
      });
      if (submitted) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // } else {
    //   setLoading(false);
    //   return;
    // }
  };

  const navigate = useNavigate();
  function startsWithHttp(inputString) {
    return typeof inputString === "string" && inputString.startsWith("http");
  }
  const handleSendBack = async () => {
    if (dataSheetId) {
      try {
        setdashboardView("dataSheet");
        if (route?.includes("dashboardT")) {
          navigate(
            `/dashboardT/datasheet/${dataSheetId}/${userId}/${id}/${serviceId}`
          );
        } else {
          navigate(
            `/dashboard/datasheet/${dataSheetId}/${userId}/${id}/${serviceId}`
          );
        }
        return true;
      } catch (error) {
        createNotification("error", error?.response?.data?.response?.message);
        console.log("Something Went wrong", error.message);
        return false;
      }
    }
  };

  const handlenext = async () => {
    if (dataSheetId) {
      try {
        setdashboardView("dataSheet");
        if (route?.includes("techdashboard")) {
          navigate(
            `/techdashboard/dataSheet/${dataSheetId}/${userId}/${id}/${serviceId}`
          );
        } else {
          navigate(`/techdashboard`);
        }
        return true;
      } catch (error) {
        createNotification("error", error?.response?.data?.response?.message);
        console.log("Something Went wrong", error.message);
        return false;
      }
    }
  };

  useEffect(() => {
    const submitBtn = formSubmitButtonRef.current;
    if (submitBtn && timeOver) {
      console.log("Doing submission");
      submitBtn.disabled = false;
      submitBtn.click();
      submitBtn.disabled = true;
    }
  }, [timeOver]);

  const submitResults = async (values) => {
    if (timeOver && !savedSignature) {
      generatePartiallyFilledRequest(values);
    } else {
      generateSubmitRequest(values);
    }
  };

  useEffect(() => {
    setSignatureDisable(dirty && errorsLength === 0);
  }, [errorsLength, dirty]);
  return (
    <FormikProvider>
      <Formik
        validateOnMount
        initialValues={{
          section: {
            questions_attributes: data?.questions?.map((question) => {
              if (question.question_type !== "custom") {
                const answer = question?.options?.length
                  ? question.answers.length
                    ? question.answers.map((answer) => {
                        return {
                          id: answer?.id,
                          option_id: answer.option_id,
                          meta: {},
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                        };
                      })
                    : [
                        {
                          id: question?.answers[0]?.id,
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                          option_id: question?.answers[0]?.option_id,
                        },
                      ]
                  : question?.answers?.length
                  ? question.answers.map((answer) => {
                      return {
                        id: answer?.id,
                        statement: answer.statement,
                        meta: {
                          batch_no: answer?.meta?.batch_no,
                          // parent_id: answer?.meta?.parent_id,
                        },
                        user_section_mapping_id: data?.user_section_mapping_id,
                        _destroy: answer._destroy || false,
                      };
                    })
                  : [
                      {
                        id: question?.answers[0]?.id,
                        statement: question?.answers[0]?.statement,
                        user_section_mapping_id: data?.user_section_mapping_id,
                      },
                    ];
                return {
                  id: question.id,
                  answers_attributes: answer,
                };
              } else {
                return;
              }
            }),
          },
        }}
        onSubmit={submitResults}
        validate={(values) => {
          const errors = {};
          // if (isSubmitting) {
          values.section.questions_attributes.map((item, index) => {
            if (item?.answers_attributes?.length === 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    if (!answer?.meta?.containsError) {
                      if (timeOver) {
                        if ([9, 10, 11, 12, 13, 14].includes(index)) {
                          answer.statement = "-";
                        } else if ([5, 6, 7, 8].includes(index)) {
                          delete errors[item.id];
                          values.section.questions_attributes =
                            values.section.questions_attributes.filter(
                              (question) => question.id !== item.id
                            );
                        }
                      } else {
                        console.log("Errors ADD in", item);
                        errors[item.id] = "This field is required";
                      }
                    }
                  }
                }
              }
            } else if (item?.answers_attributes?.length > 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    errors[item.id] = "This field is required";
                  }
                }
              }
              item?.answers_attributes.map((answer, index) => {
                if (
                  !answer?.statement &&
                  !answer?.option_id &&
                  !answer?._destroy
                ) {
                  index > 0 &&
                    (errors[item.id + `-${answer?.meta?.batch_no}`] =
                      "This field is required");
                }
              });
            }
            return errors;
          });
          // if (isSubmitting && isFormatSubmit) {
          //   console.log(errors)
          // }

          setErrorsLength(Object.keys(errors).length);
          return errors;

          // }
        }}
      >
        {({
          handleSubmit,
          errors,
          values,
          handleChange,
          setValues,
          isValid,
          dirty,
        }) => {
          setDirty(dirty);
          const handleForamtion = (value, formater, id, batch_no) => {
            if (value || value === 0) {
              switch (formater) {
                case 2: {
                  const formatedValue = NumberFormat(value, "(xxx) xxx-xxxx");
                  if (formatedValue && formatedValue.length === 14) {
                    const parsedPhoneNumber = parsePhoneNumberFromString(
                      formatedValue,
                      "US"
                    );
                    const isValid =
                      parsedPhoneNumber && parsedPhoneNumber.isValid();
                    if (!isValid) {
                      errors[id] = "Please enter valid number";
                    } else delete errors[id];
                  } else if (
                    formatedValue &&
                    formatedValue.length > 1 &&
                    formatedValue.length < 14
                  ) {
                    errors[id] = "Please enter complete value";
                  } else {
                    delete errors[id];
                  }
                  return formatedValue;
                }
                case 3: {
                  const formatedValue = validateEmail(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "Please enter email with correct format";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 4: {
                  const formatedValue = validateName(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "You have entered wrong value";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 5: {
                  if (value <= 0 || value > 12) {
                    errors[id] = "Value must be from 0 to 12";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 6: {
                  if (value === 0) {
                    errors[id] = "Enter valid value";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 7: {
                  if (value) {
                    function convertToAMPM(utcDateString) {
                      const utcDate = new Date(utcDateString);
                      let hours, minutes;
                      console.log(utcSavedDates, "UTC SAVED DATES");
                      if (utcSavedDates) {
                        hours = utcDate.getUTCHours();
                        minutes = utcDate.getUTCMinutes();
                      } else {
                        hours = utcDate.getHours();
                        minutes = utcDate.getMinutes();
                      }

                      let hours12 = hours % 12;
                      hours12 = hours12 === 0 ? 12 : hours12;
                      const period = hours < 12 ? "AM" : "PM";
                      const formattedTime = `${hours12}:${minutes
                        .toString()
                        .padStart(2, "0")} ${period}`;

                      return formattedTime;
                    }
                    return convertToAMPM(value);
                  }

                  return null;
                }
                case 9: {
                  if (value) {
                    const val = [
                      { key: "in_home", value: "Home" },
                      { key: "in_clinic", value: "In Clinic" },
                    ].find((obj) => obj?.key === value)?.value;
                    if (val) {
                      return val;
                    } else {
                      return value;
                    }
                  }
                }
                case 10: {
                  if (value) {
                    const formatDate = (date) => {
                      const data = new Date(date);
                      if (data) {
                        const formattedDate = dayjs(data).format("MM-DD-YYYY");
                        return formattedDate;
                      }
                    };
                    return formatDate(value);
                  }
                }
                default:
                  return value;
              }
            }
          };

          const transformedMappingData = mappingData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.batch_no === value.batch_no &&
                  t.id === value.id &&
                  t.parent_id === value.parent_id
              )
          );
          return (
            <Form>
              <fieldset
                disabled={
                  user?.role == "technician" &&
                  ["partially_filled", "completely_filled"].includes(formStatus)
                }
              >
                <div
                  className={`d-flex gap-16 flex-wrap ${
                    halfWidth ? "max-w-input-of" : "w-100"
                  }`}
                >
                  {transformedMappingData?.map((item) => {
                    let currentItem = questionInfo.find(
                      (i) => i?.title === item?.title
                    );
                    switch (item?.question_type) {
                      case "text_input":
                        const index =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id === item?.id
                          );
                        const isCustom = data?.questions.find(
                          (question) =>
                            question?.id === item?.meta?.parent_question_id
                        );
                        let currentOptionalParent;

                        if (isCustom?.question_type === "optional") {
                          currentOptionalParent =
                            values.section.questions_attributes?.find(
                              (question) => question?.id === isCustom?.id
                            );
                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.find(
                              (question) => question?.id === item?.id
                            );
                          if (
                            containsItem &&
                            currentOptionalParent?.answers_attributes[0]?.meta
                              ?.containsError
                          ) {
                            containsItem?.answers_attributes?.map((answer) => {
                              answer._destroy = true;
                              answer.meta = {
                                containsError: true,
                              };
                            });
                          }
                        }

                        if (
                          item?.meta.parent_question_id &&
                          isCustom?.question_type === "radio_with_description"
                        ) {
                          const parentAnswer =
                            values.section.questions_attributes.find(
                              (index) =>
                                index?.id == item?.meta.parent_question_id
                            )?.answers_attributes[0]?.option_id;
                          const descType = currentItem?.descType;
                          const yesOptionId = data.questions
                            .find(
                              (question) =>
                                question.id === item?.meta.parent_question_id
                            )
                            .options.find(
                              (option) =>
                                option.meta.key ===
                                (descType ? descType : "yes")
                            )?.id;
                          const compare = parentAnswer == yesOptionId;

                          if (!compare && !!item.meta.parent_question_id) {
                            const index =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === item?.id
                              );
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (containsItem) {
                              values.section.questions_attributes.splice(
                                index,
                                1
                              );
                            }
                          } else if (
                            compare &&
                            !!item.meta.parent_question_id
                          ) {
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (!containsItem) {
                              values.section.questions_attributes.push({
                                id: item?.id,
                                answers_attributes: [
                                  {
                                    statement: "",
                                    t_section_mapping_id:
                                      data?.user_section_mapping_id,
                                  },
                                ],
                              });
                            }
                          }

                          return (
                            compare && (
                              <>
                                {currentItem?.message && (
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "#000",
                                      width: "100%",
                                    }}
                                  >
                                    {currentItem?.message}
                                  </span>
                                )}
                                <TextFieldFormik
                                  disabled={
                                    user.role === "director" ||
                                    currentItem?.disabled
                                      ? true
                                      : false
                                  }
                                  firstInputRef={
                                    currentItem?.firstInput
                                      ? firstInputRef
                                      : null
                                  }
                                  key={currentItem.id}
                                  placeholder={currentItem.placeholder}
                                  label={currentItem.label}
                                  type={
                                    currentItem?.fieldType
                                      ? currentItem.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem.wrapperClassName
                                      ? currentItem.wrapperClassName
                                      : "max-w-input-of w-1 00"
                                  }
                                  tabIndex={1}
                                  name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  values={
                                    values?.section?.questions_attributes[index]
                                      ?.answers_attributes[0]?.statement
                                  }
                                  errors={errors[item?.id]}
                                  birthDate={
                                    currentItem.birthDate &&
                                    currentItem.birthDate
                                  }
                                />
                              </>
                            )
                          );
                        } else {
                          return (
                            <>
                              {currentItem?.message && (
                                <div
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#000",
                                    width: "100%",
                                  }}
                                >
                                  {currentItem?.message}
                                </div>
                              )}
                              {currentItem?.twoCol && (
                                <div className="two-col-wrapper">
                                  <span className="two-col-heading">
                                    {currentItem?.heading}
                                  </span>
                                  <div className="two-col-titles">
                                    <span>{currentItem?.colOneTitle}</span>
                                    <span>{currentItem?.colTwoTitle}</span>
                                  </div>
                                </div>
                              )}
                              {currentItem?.topLabel && (
                                <label
                                  className={`lm-input-form-input-label form-label }`}
                                >
                                  {currentItem?.topLabel}
                                </label>
                              )}

                              {!currentOptionalParent?.answers_attributes[0]
                                ?.meta?.containsError && (
                                <TextFieldFormik
                                  disabled={
                                    user.role === "director" ||
                                    currentItem?.disabled
                                      ? true
                                      : false || serviceNoteApproved
                                  }
                                  firstInputRef={
                                    currentItem?.firstInput
                                      ? firstInputRef
                                      : null
                                  }
                                  key={currentItem?.id}
                                  placeholder={currentItem?.placeholder}
                                  label={currentItem?.label}
                                  type={
                                    currentItem?.fieldType
                                      ? currentItem.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem?.wrapperClassName
                                      ? currentItem?.wrapperClassName
                                      : "max-w-input-of w-100"
                                  }
                                  tabIndex={1}
                                  birthDate={
                                    currentItem?.birthDate &&
                                    currentItem?.birthDate
                                  }
                                  name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  values={
                                    handleForamtion(
                                      values.section.questions_attributes[
                                        index
                                      ]?.answers_attributes.find(
                                        ({ _destroy }) => !_destroy
                                      )?.statement,
                                      currentItem?.formater,
                                      item?.id
                                    ) || ""
                                  }
                                  errors={errors[item?.id]}
                                />
                              )}
                            </>
                          );
                        }
                      case "custom_with_text":
                        const lastBatchNoObjects = {};
                        let showAddButton = 0;
                        mappingData.forEach((currentObject) => {
                          if (
                            currentObject.question_type ===
                              "custom_with_text" &&
                            currentObject.parent_id &&
                            (!lastBatchNoObjects[currentObject.parent_id] ||
                              currentObject.batch_no >
                                lastBatchNoObjects[currentObject.parent_id]
                                  .batch_no)
                          ) {
                            lastBatchNoObjects[currentObject.parent_id] =
                              currentObject;
                          }
                        });

                        mappingData.map((obj) => {
                          if (obj.parent_id === item.parent_id) {
                            showAddButton = showAddButton + 1;
                          }
                        });
                        const currentBatch = item?.batch_no;
                        const renderedCustomObjects = item?.customObjects?.map(
                          (object) => {
                            let currentItem = questionInfo.find(
                              (i) => i?.title === object.title
                            );
                            let customIndex;
                            if (object?.meta.parent_question_id) {
                              customIndex =
                                values.section.questions_attributes.findIndex(
                                  (index) => index?.id === object.id
                                );

                              const parentAnswer =
                                values.section.questions_attributes.find(
                                  (index) =>
                                    index?.id == object.meta.parent_question_id
                                )?.answers_attributes[0]?.option_id;
                              const descType = currentItem?.descType;
                              const yesOptionId = data.questions
                                .find(
                                  (question) =>
                                    question.id ===
                                    object.meta.parent_question_id
                                )
                                ?.options.find(
                                  (option) =>
                                    option.meta.key ==
                                    (descType ? descType : "yes")
                                )?.id;
                              const compare = parentAnswer == yesOptionId;

                              if (
                                !compare &&
                                !!object.meta.parent_question_id
                              ) {
                                const index =
                                  values.section.questions_attributes.findIndex(
                                    (index) => index?.id === object?.id
                                  );
                                const containsItem =
                                  values.section.questions_attributes.some(
                                    (question) => question?.id === object?.id
                                  );

                                if (containsItem) {
                                  values.section.questions_attributes.splice(
                                    index,
                                    1
                                  );
                                }
                                const tempData = mappingData;
                                const mappingIndex = tempData.findIndex(
                                  (data) =>
                                    data?.batch_no === item?.batch_no &&
                                    data?.parent_id === item?.parent_id
                                );
                                const containsMappingItem = tempData.some(
                                  (data) =>
                                    data?.batch_no === item?.batch_no &&
                                    data?.parent_id === item?.parent_id
                                );
                                if (
                                  containsMappingItem &&
                                  mappingIndex &&
                                  item?.batch_no > 1
                                ) {
                                  tempData.splice(mappingIndex, 1);
                                }
                              } else if (
                                compare &&
                                !!object.meta.parent_question_id
                              ) {
                                const containsItem =
                                  values.section.questions_attributes.some(
                                    (question) => question?.id === object?.id
                                  );

                                if (!containsItem) {
                                  values.section.questions_attributes.push({
                                    id: object?.id,
                                    answers_attributes: object?.answers?.length
                                      ? object?.answers?.map((answer) => {
                                          return {
                                            statement: answer?.statement,
                                            user_section_mapping_id:
                                              data?.user_section_mapping_id,
                                            meta: answer?.meta,
                                            id: answer?.id,
                                            _destroy:
                                              answer?.meta?.batch_no === 1
                                                ? false
                                                : true,
                                          };
                                        })
                                      : [
                                          {
                                            statement: "",
                                            user_section_mapping_id:
                                              data?.user_section_mapping_id,
                                          },
                                        ],
                                  });
                                }
                              }

                              return (
                                compare && (
                                  <>
                                    <TextFieldFormik
                                      birthDate={
                                        currentItem.birthDate &&
                                        currentItem.birthDate
                                      }
                                      disabled={
                                        user.role === "director" ||
                                        currentItem.disabled
                                          ? true
                                          : false
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      key={object?.id + `-${item?.batch_no}`}
                                      batchNo={item?.batch_no}
                                      placeholder={currentItem?.placeholder}
                                      errors={
                                        errors[
                                          object?.id + `-${item?.batch_no}`
                                        ]
                                      }
                                      label={currentItem?.label}
                                      type={
                                        currentItem?.fieldType
                                          ? currentItem.fieldType
                                          : currentItem?.inputType
                                          ? "textarea"
                                          : "text"
                                      }
                                      pattern={currentItem?.pattern}
                                      wrapperClassName={
                                        currentItem?.wrapperClassName
                                          ? currentItem?.wrapperClassName
                                          : "max-w-input-of w-100"
                                      }
                                      handleChange={(e) => {
                                        const thisBatch = mappingData?.filter(
                                          (obj) =>
                                            obj?.parent_id === item?.parent_id
                                        );

                                        const batchIndex = thisBatch?.findIndex(
                                          (index) =>
                                            index?.batch_no === item?.batch_no
                                        );
                                        if (currentItem.formater) {
                                          e.target.value = formatValue(
                                            e.target.value,
                                            currentItem.formater
                                          );

                                          if (
                                            currentItem.formater != 5 &&
                                            currentItem.formater != 2
                                          ) {
                                            if (e.target.value.length < 14) {
                                              errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ] = "Please enter complete value";
                                              isFormatSubmit = false;
                                            } else {
                                              delete errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ];
                                              isFormatSubmit = true;
                                            }
                                          } else if (
                                            currentItem.formater === 2
                                          ) {
                                            if (e.target.value.length < 14) {
                                              errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ] = "Please enter complete value";
                                              isFormatSubmit = false;
                                            } else if (
                                              e.target.value.length === 14
                                            ) {
                                              const parsedPhoneNumber =
                                                parsePhoneNumberFromString(
                                                  e.target.value,
                                                  "US"
                                                );
                                              const isValid =
                                                parsedPhoneNumber &&
                                                parsedPhoneNumber.isValid();
                                              if (!isValid) {
                                                errors[
                                                  object?.id +
                                                    `-${item?.batch_no}`
                                                ] = "Please enter valid number";
                                                isFormatSubmit = false;
                                              }
                                            } else {
                                              delete errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ];
                                              isFormatSubmit = true;
                                            }
                                          } else if (
                                            currentItem.formater == 5
                                          ) {
                                            if (
                                              e.target.value > 12 ||
                                              e.target.value <= 0
                                            ) {
                                              errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ] = "Value must be from 0 to 12";
                                              isFormatSubmit = false;
                                            } else {
                                              delete errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ];
                                              isFormatSubmit = true;
                                            }
                                          }
                                        } else {
                                          if (e.target.value) {
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                          } else {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "This field is required";
                                          }
                                        }

                                        const answer =
                                          values.section.questions_attributes[
                                            customIndex
                                          ].answers_attributes;
                                        const valueIndex = answer.findIndex(
                                          (index) =>
                                            index?.meta?.batch_no ===
                                            item?.batch_no
                                        );
                                        const batchAnswer = answer[batchIndex];
                                        if (batchIndex === 0) {
                                          batchAnswer.statement =
                                            e.target.value;
                                          batchAnswer.meta = {
                                            batch_no: item?.batch_no,
                                            parent_id: item?.parent_id,
                                          };
                                        } else {
                                          if (valueIndex) {
                                            answer[valueIndex].statement =
                                              e.target.value;
                                            answer[valueIndex].meta = {
                                              batch_no: item?.batch_no,
                                              parent_id: item?.parent_id,
                                            };
                                            answer[
                                              valueIndex
                                            ].user_section_mapping_id =
                                              data?.user_section_mapping_id;
                                          } else {
                                            answer.push({
                                              statement: e.target.value,
                                              meta: {
                                                batch_no: item?.batch_no,
                                                parent_id: item?.parent_id,
                                              },
                                              user_section_mapping_id:
                                                data?.user_section_mapping_id,
                                            });
                                          }
                                        }
                                      }}
                                      defaultValue={formatValue(
                                        values.section.questions_attributes[
                                          customIndex
                                        ]?.answers_attributes[
                                          values.section.questions_attributes[
                                            customIndex
                                          ]?.answers_attributes?.findIndex(
                                            (index) =>
                                              index?.meta?.batch_no ===
                                                item?.batch_no &&
                                              !index._destroy
                                          )
                                        ]?.statement,
                                        currentItem.formater
                                      )}
                                    />

                                    {currentItem?.last &&
                                      item?.batch_no ===
                                        lastBatchNoObjects[item?.parent_id]
                                          ?.batch_no &&
                                      !idCheck &&
                                      !dataSheetId &&
                                      showAddButton < 5 && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            duplicateCustomObject(
                                              item?.parent_id,
                                              item?.batch_no
                                            );

                                            const updatedQuestions =
                                              values.section.questions_attributes.map(
                                                (question) => {
                                                  const currentCheck =
                                                    data?.questions?.some(
                                                      (newQuestion) => {
                                                        return (
                                                          question?.id ===
                                                            newQuestion.id &&
                                                          item?.parent_id ===
                                                            newQuestion?.meta
                                                              ?.parent_question_id &&
                                                          newQuestion?.question_type ===
                                                            "custom_with_text"
                                                        );
                                                      }
                                                    );
                                                  const answerCheck =
                                                    question?.answers_attributes?.find(
                                                      (answer) =>
                                                        answer?.meta
                                                          ?.batch_no ===
                                                        item?.batch_no + 1
                                                    );
                                                  if (answerCheck) {
                                                    answerCheck._destroy = false;
                                                  }
                                                  return currentCheck &&
                                                    !answerCheck
                                                    ? {
                                                        ...question,
                                                        answers_attributes: [
                                                          ...question.answers_attributes,
                                                          {
                                                            meta: {
                                                              parent_id:
                                                                item?.parent_id,
                                                              batch_no:
                                                                item?.batch_no +
                                                                1,
                                                            },
                                                            statement: "",
                                                          },
                                                        ],
                                                      }
                                                    : question;
                                                }
                                              );
                                            setValues({
                                              ...values,
                                              section: {
                                                ...values.section,
                                                questions_attributes:
                                                  updatedQuestions,
                                              },
                                            });
                                          }}
                                          className="add-custom-button"
                                        >
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M9 0V17.0573"
                                              stroke="black"
                                            />
                                            <path
                                              d="M17.5273 8.5293L0.470009 8.5293"
                                              stroke="black"
                                            />
                                          </svg>
                                        </button>
                                      )}
                                  </>
                                )
                              );
                            } else {
                              return (
                                <>
                                  {currentItem?.twoCol && (
                                    <div className="two-col-wrapper">
                                      <span className="two-col-heading">
                                        {currentItem?.heading}
                                      </span>
                                      <div className="two-col-titles">
                                        <span>{currentItem?.colOneTitle}</span>
                                        <span>{currentItem?.colTwoTitle}</span>
                                      </div>
                                    </div>
                                  )}
                                  <TextFieldFormik
                                    birthDate={
                                      currentItem.birthDate &&
                                      currentItem.birthDate
                                    }
                                    disabled={
                                      user.role === "director" ? true : false
                                    }
                                    firstInputRef={
                                      currentItem?.firstInput
                                        ? firstInputRef
                                        : null
                                    }
                                    // eslint-disable-next-line no-unused-expressions

                                    key={item?.batch_no.id}
                                    batchNo={item?.batch_no}
                                    // eslint-disable-next-line no-unused-expressions

                                    placeholder={currentItem?.placeholder}
                                    errors={errors[object?.id]}
                                    label={currentItem?.label}
                                    // eslint-disable-next-line no-unused-expressions

                                    tyep={
                                      currentItem?.fieldType
                                        ? currentItem.fieldType
                                        : currentItem?.inputType
                                        ? "textarea"
                                        : "text"
                                    }
                                    pattern={currentItem?.pattern}
                                    wrapperClassName={
                                      currentItem?.wrapperClassName
                                        ? currentItem?.wrapperClassName
                                        : "max-w-input-of w-100"
                                    }
                                    tabIndex={1}
                                    // eslint-disable-next-line no-unused-expressions
                                    name={`section.questions_attributes[${customIndex}]?.answers_attributes[0].statement`}
                                    handleChange={handleChange}
                                    // eslint-disable-next-line no-unused-expressions
                                    values={
                                      values?.section.questions_attributes[
                                        customIndex
                                      ]?.answers_attributes[item?.batch_no - 1]
                                        ? values.section.questions_attributes[
                                            customIndex
                                          ].answers_attributes[
                                            item?.batch_no - 1
                                          ].statement
                                        : ""
                                    }
                                  />
                                </>
                              );
                            }
                          }
                        );
                        return <>{renderedCustomObjects}</>;

                      case "radio":
                        const indexRadio =
                          values?.section?.questions_attributes?.findIndex(
                            (index) => index?.id == item.id
                          );
                        const isCustomType = data.questions.find(
                          (question) =>
                            question.id == item.meta.parent_question_id
                        );

                        if (
                          item.meta.parent_question_id &&
                          isCustomType.question_type == "radio_with_description"
                        ) {
                          const parentAnswer =
                            values.section.questions_attributes.find(
                              (index) =>
                                index.id == item.meta.parent_question_id
                            ).answers_attributes[0]?.option_id;

                          const yesOptionId = data.questions
                            .find(
                              (question) =>
                                question.id === item.meta.parent_question_id
                            )
                            ?.options.find(
                              (option) =>
                                option?.meta?.key ===
                                (currentItem?.descType
                                  ? currentItem?.descType
                                  : "yes")
                            )?.id;
                          const compare = parentAnswer == yesOptionId;
                          if (!compare && !!item.meta.parent_question_id) {
                            const index =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === item?.id
                              );
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (containsItem) {
                              values.section.questions_attributes.splice(
                                index,
                                1
                              );
                            }
                          } else if (
                            compare &&
                            !!item.meta.parent_question_id
                          ) {
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (!containsItem) {
                              values.section.questions_attributes.push({
                                id: item?.id,
                                answers_attributes: [
                                  {
                                    statement: "",
                                    user_section_mapping_id:
                                      data?.user_section_mapping_id,
                                  },
                                ],
                              });
                            }
                          }
                          return (
                            compare && (
                              <RadioButtonsFormSection
                                required
                                errors={errors[item?.id]}
                                key={item?.id}
                                RadioButtons={currentItem?.options.map(
                                  (content) => {
                                    const currentOption = item.options.find(
                                      (option) => option.meta.key == content.key
                                    );
                                    return (
                                      <RadioButtons
                                        disabled={
                                          user.role === "director"
                                            ? true
                                            : false
                                        }
                                        firstInputRef={
                                          currentItem?.firstInput
                                            ? firstInputRef
                                            : null
                                        }
                                        id={currentOption?.id}
                                        name={`section.questions_attributes[${indexRadio}].answers_attributes[0].option_id`}
                                        onChange={handleChange}
                                        values={currentOption?.id}
                                        Label={content.title}
                                        tabIndex={content.tabIndex}
                                        checked={
                                          values?.section.questions_attributes[
                                            indexRadio
                                          ]?.answers_attributes?.length &&
                                          currentOption?.id ==
                                            values?.section
                                              .questions_attributes[indexRadio]
                                              ?.answers_attributes[0]?.option_id
                                        }
                                      />
                                    );
                                  }
                                )}
                                label={currentItem?.label}
                                wrapperClassName={
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }
                                GridView={currentItem?.gridView}
                              />
                            )
                          );
                        }

                      case "radio_with_description":
                        const indexRadioWD =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id == item.id
                          );
                        if (!currentItem?.checkBox) {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem?.options?.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director"
                                          ? true
                                          : false || serviceNoteApproved
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      name={`section.questions_attributes[${indexRadioWD}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section.questions_attributes[
                                          indexRadioWD
                                        ]?.answers_attributes?.length &&
                                        currentOption?.id ==
                                          values?.section.questions_attributes[
                                            indexRadioWD
                                          ]?.answers_attributes[0].option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        } else {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem?.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  const answers =
                                    values.section.questions_attributes[
                                      indexRadioWD
                                    ].answers_attributes;
                                  let answer = answers.find(
                                    (answer) =>
                                      answer.option_id == currentOption?.id
                                  );
                                  const isChecked = answer && !answer._destroy;
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director" ? true : false
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          if (
                                            answers[0].option_id === undefined
                                          ) {
                                            answers[0].option_id = parseInt(
                                              e.target.value
                                            );
                                          } else {
                                            if (answer) {
                                              answer._destroy = false;
                                            } else {
                                              answer = {
                                                option_id: parseInt(
                                                  e.target.value
                                                ),
                                                user_section_mapping_id:
                                                  data.user_section_mapping_id,
                                              };
                                              answers.push(answer);
                                            }
                                          }
                                        } else {
                                          if (answer) {
                                            answer._destroy = true;
                                          }
                                        }
                                      }}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checkBox={currentItem.checkBox}
                                      defaultChecked={isChecked}
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem.wrapperClassName
                                  ? currentItem.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        }

                      case "checkbox":
                        const indexCheckbox =
                          values.section.questions_attributes.findIndex(
                            (index) => index.id == item.id
                          );
                        if (!currentItem.checkBox) {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director" ? true : false
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      name={`section.questions_attributes[${indexCheckbox}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section.questions_attributes[
                                          indexCheckbox
                                        ].answers_attributes.length &&
                                        currentOption.id ==
                                          values?.section.questions_attributes[
                                            indexCheckbox
                                          ].answers_attributes[0].option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem.wrapperClassName
                                  ? currentItem.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        } else {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  const answers =
                                    values.section.questions_attributes[
                                      indexCheckbox
                                    ].answers_attributes;
                                  let answer = answers.find(
                                    (answer) =>
                                      answer.option_id == currentOption?.id
                                  );
                                  const isChecked = answer && !answer._destroy;
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director" ? true : false
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          if (
                                            answers[0].option_id ===
                                              undefined ||
                                            answers[0].option_id === null
                                          ) {
                                            answers[0].option_id = parseInt(
                                              e.target.value
                                            );
                                            answers[0]._destroy = false;
                                          } else {
                                            if (answer) {
                                              answer._destroy = false;
                                            } else {
                                              answer = {
                                                option_id: parseInt(
                                                  e.target.value
                                                ),
                                                user_section_mapping_id:
                                                  data.user_section_mapping_id,
                                                _destroy: false,
                                              };
                                              answers.push(answer);
                                            }
                                          }
                                        } else {
                                          if (answer) {
                                            answer._destroy = true;
                                          }
                                        }
                                        if (
                                          answers.every(
                                            (answer) =>
                                              answer.option_id &&
                                              answer._destroy
                                          )
                                        ) {
                                          errors[item?.id] =
                                            "This field is required";
                                        } else {
                                          delete errors[item?.id];
                                        }
                                      }}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      // tagCheckbox={true}
                                      tabIndex={content.tabIndex}
                                      checkBox={currentItem?.checkBox}
                                      defaultChecked={isChecked}
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        }

                      case "custom":
                        return (
                          <h4 className="w-100 custom-heading">
                            {currentItem?.label}
                          </h4>
                        );
                      default:
                        return true;
                    }
                  })}
                  {user.role === "director" ? (
                    <div className="dashboard-footer w-100">
                      {formStatus === "partially_filled" && (
                        <button
                          type="button"
                          className="send-back-btn"
                          onClick={() => {
                            changeFormStatus();
                          }}
                        >
                          {loading ? (
                            <LoaderSpinner color="white" />
                          ) : (
                            `Send back`
                          )}
                        </button>
                      )}
                      <button
                        type="button"
                        className="send-back-btn"
                        onClick={() => handleSendBack()}
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    <div className="dashboard-footer w-100">
                      <button
                        ref={formSubmitButtonRef}
                        type="submit"
                        className="approve-btn"
                        onClick={() => {
                          isSubmitting = true;
                          if (savedSignature) {
                            handleSubmit(values);
                          } else {
                            createNotification("error", "Signature Required");
                          }
                        }}
                        disabled={
                          loading || !savedSignature || serviceNoteApproved
                        }
                      >
                        {loading ? (
                          <LoaderSpinner color="white" />
                        ) : (
                          saveBtnText
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </fieldset>
              {user.role === "technician" &&
                ["partially_filled", "completely_filled"].includes(
                  formStatus
                ) && (
                  <div className="dashboard-footer w-100">
                    <button
                      type="button"
                      className="send-back-btn"
                      onClick={() => handlenext()}
                    >
                      Next
                    </button>
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </FormikProvider>
  );
};

export default CommonCheckInForm;
