import { Spin } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import "./Modals.scss";
import { set } from "lodash";

const BillingEditConfirmationModal = ({
  show,
  onHide,
  modalLoading,
  setModalLoading,
  billingId,
  payload,
  setOpenEditConfirmationModal,
  setActionDone,
  actionDone,
}) => {
  const handleBillingUpdates = async (billingId, billingParams) => {
    setModalLoading(true);
    try {
      const res = await httpClient.put(
        `/api/v1/appointment_billings/${billingId}`,
        {
          appointment_billing: billingParams,
        }
      );
      if (res?.data) {
        createNotification("success", "Appointment Billing Updated");
      }
      setModalLoading(false);
      setOpenEditConfirmationModal(false);
      setActionDone(!actionDone);
      return true;
    } catch (error) {
      setOpenEditConfirmationModal(false);
      handleErrorResponse(error);
      setModalLoading(false);
      setActionDone(!actionDone);
    }
  };

  return (
    <Modal
      className="billing-edit-confirmation-modal"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spin size="large" spinning={modalLoading}>
        <Modal.Body>
          <Modal.Header className="border-0 p-0 pb-12" closeButton={false}>
            <Modal.Title className="f-20 lh-22 f-w-600 clr-dark-grey">
              Confirm Changes Before Update
            </Modal.Title>
          </Modal.Header>
          <section className="d-flex flex-column gap-30 pt-30">
            <div class="values-container d-flex justify-content-center mb-15">
              <div class="values d-flex gap-90">
                <div class="original-value-heading">
                  <h6 className="f-15 f-w-600 mb-25">Original Value</h6>

                  <span className="original-value">
                    {payload.originalValue}
                  </span>
                </div>
                <div class="updated-value-heading">
                  <h6 className="f-15 f-w-600 mb-25">Updated Value</h6>
                  <span className="updated-value">{payload.updatedValue}</span>
                </div>
              </div>
            </div>
            <div class="billing-conf-modal-footer modal-footer">
              <Button
                className={`f-16 f-w-600 confirmation-modal-cancel-btn`}
                onClick={() => {
                  setOpenEditConfirmationModal(false);
                  setActionDone(!actionDone);
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  width: "5%",
                }}
                className={`modal-add-btn lm-form-btn-modalbtn lm-form-btn-primary`}
                onClick={() => {
                  handleBillingUpdates(billingId, payload.params);
                }}
              >
                confirm
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default BillingEditConfirmationModal;
