import { useEffect, useState } from "react";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import { createNotification } from "../../../../helpers/createNotifications";

const PatientMedicalInformation = ({
  data,
  next,
  prev,
  id,
  setAction,
  action,
}) => {
  const questionInfo = [
    {
      id: 44,
      title: "medical_info_child_height",
      placeholder: "Height in cm",
      label: "Current Height",
      wrapperClassName: "w-3",
      options: [],
      fieldType: "number",
      formater: 6,
      firstInput: true,
    },
    {
      id: 45,
      title: "medical_info_child_weight",
      placeholder: "Weight in lbs",
      label: "Current Weight",
      wrapperClassName: "w-3",
      options: [],
      fieldType: "number",
      formater: 6,
    },
    {
      id: 46,
      title: "medical_info_dominant_hand",
      placeholder: "",
      label: "Dominant Hand",
      wrapperClassName: "w-100",
      options: [
        {
          id: 19,
          title: "Left",
          key: "left",
        },
        {
          id: 20,
          title: "Right",
          key: "right",
        },
        {
          id: 21,
          title: "No Preference",
          key: "no_preference",
        },
      ],
      //   gridView: true,
    },
    {
      id: 47,
      title: "medical_info_infectious_disease",
      placeholder: "",
      label: "Any current health conditions, including infectious diseases?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 22,
          title: "Yes",
          key: "yes",
        },
        {
          id: 23,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 48,
      title: "medical_info_infectious_disease_desc",
      placeholder: "Details...",
      label: "If yes, Please Explain below",
      wrapperClassName: "",
      options: [],
      inputType: true,

      //   gridView: true,
    },
    {
      id: 49,
      title: "medical_conditions",
      placeholder: "Details...",
      label: "Please provide the following Details:",
      wrapperClassName: "w-100",
      options: [],
    },
    {
      id: 50,
      title: "medical_conditions_name",
      placeholder: "",
      label: "Medical Condition",
      wrapperClassName: "w-4",
      options: [],
      first: true,
    },
    {
      id: 51,
      title: "medical_conditions_treating_clinician",
      placeholder: "",
      label: "Current Treating Clinicians",
      wrapperClassName: "w-4",
      options: [],
    },
    {
      id: 52,
      title: "medical_conditions_therapeutic_interventions",
      placeholder: "",
      label: "Therapeutic Interventions",
      wrapperClassName: "w-4",
      options: [],
    },
    {
      id: 53,
      title: "medical_conditions_date",
      placeholder: "dd/mm/yyyy",
      label: "Dates",
      wrapperClassName: "w-4",
      options: [],
      fieldType: "date",
      last: true,
    },
    {
      id: 54,
      title: "medical_info_illness",
      placeholder: "",
      label:
        "Have there been significant past and/or present medical problems/illnesses or injuries?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 24,
          title: "Yes",
          key: "yes",
        },
        {
          id: 25,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 55,
      title: "medical_info_illness_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 56,
      title: "medical_info_previous_hospitalizations",
      placeholder: "",
      label: "Any previous hospitalizations or surgeries?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 26,
          title: "Yes",
          key: "yes",
        },
        {
          id: 27,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 57,
      title: "medical_info_previous_hospitalizations_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 58,
      title: "medical_info_physical_complaints",
      placeholder: "",
      label:
        "Are there any current physical complaints or communicable diseases?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 28,
          title: "Yes",
          key: "yes",
        },
        {
          id: 29,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 59,
      title: "medical_info_physical_complaints_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 60,
      title: "medical_info_vision_problem",
      placeholder: "",
      label: "Does your child/adolescent have any Vision problems?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 30,
          title: "Yes",
          key: "yes",
        },
        {
          id: 31,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 61,
      title: "medical_info_hearing_problem",
      placeholder: "",
      label: "Does your child/adolescent have any Hearing problems?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 32,
          title: "Yes",
          key: "yes",
        },
        {
          id: 33,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 62,
      title: "medical_info_history_seizures",
      placeholder: "",
      label: "Does your child/adolescent have a history of seizures?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 34,
          title: "Yes",
          key: "yes",
        },
        {
          id: 35,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 63,
      title: "medical_info_physical_activ_restriction",
      placeholder: "",
      label: "Does the patient have any restrictions on physical activities?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 36,
          title: "Yes",
          key: "yes",
        },
        {
          id: 37,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 64,
      title: "medical_info_allergies",
      placeholder: "",
      label: "Any allergies (Seasonal, Food, Medications etc)?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 38,
          title: "Yes",
          key: "yes",
        },
        {
          id: 39,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 65,
      title: "medical_info_current_medications",
      placeholder: "",
      label: "Currently taking any medications?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 40,
          title: "Yes",
          key: "yes",
        },
        {
          id: 41,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 66,
      title: "medical_info_medication_name",
      placeholder: "",
      label: "Medication Name",
      wrapperClassName: "w-4",
      options: [],
      first: true,
    },
    {
      id: 67,
      title: "medical_info_medication_amount",
      placeholder: "",
      label: "Amount",
      wrapperClassName: "w-4",
      fieldType: "number",
      options: [],
    },
    {
      id: 68,
      title: "medical_info_medication_how_often",
      placeholder: "",
      label: "How often?",
      wrapperClassName: "w-4",
      options: [],
    },
    {
      id: 69,
      title: "medical_info_medication_when_taken",
      placeholder: "",
      label: "When is it taken?",
      wrapperClassName: "w-4",
      options: [],
      last: true,
    },
    {
      id: 70,
      title: "medical_info_current_diagnosis",
      placeholder: "",
      label: "Does the patient Currently have a diagnosis?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 42,
          title: "Yes",
          key: "yes",
        },
        {
          id: 43,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 71,
      title: "medical_info_current_diag",
      placeholder: "",
      label: "Diagnosis",
      wrapperClassName: "w-4",
      options: [],
      gridView: true,
      first: true,
    },
    {
      id: 72,
      title: "medical_info_current_diag_physician",
      placeholder: "",
      label: "Diagnosis Physician",
      wrapperClassName: "w-4",
      options: [],
      gridView: true,
    },
    {
      id: 73,
      title: "medical_info_current_diag_date",
      placeholder: "dd/mm/yyyy",
      label: "Date Diagnosis",
      wrapperClassName: "w-4",
      options: [],
      fieldType: "date",
      gridView: true,
    },
    {
      id: 74,
      title: "medical_info_current_diag_code",
      placeholder: "",
      label: "Diagnosis Code",
      wrapperClassName: "w-4",
      options: [],
      last: true,
      gridView: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/patient-medical-information/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/patient-medical-information"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/patient-medical-information/update_or_create",
          {
            ...postData,
          }
        );

        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log(
        "Something Went wrong",
        error?.response?.data?.response?.error
      );
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default PatientMedicalInformation;
