import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import React from "react";

const ForgotPasswordForm = ({ setCreds, creds }) => {
  return (
    <div className="w-100 d-flex flex-column ">
      <h4 className="forgot-password-title mb-12 ">Forgot password?</h4>
      <p className="forgot-password-desc mb-24">
        No worries, we’ll send you reset instructions.
      </p>

      <TextFieldFormik
        label="Email"
        placeholder="Enter Your Email"
        wrapperClassName="w-100 mb-24"
        type="email"
        values={creds?.user?.email}
        handleChange={(e) => setCreds({ user: { email: e.target.value } })}
      />
    </div>
  );
};

export default ForgotPasswordForm;
