export const questionInfo = [
  {
    id: 505,
    title: "check_in_patient_name",
    placeholder: "Enter Patient Name",
    label: "Patient Name",
    wrapperClassName: "w-32",
    tabIndex: 1,
    firstInput: true,
    disabled: true,
    options: [],
  },
  {
    id: 506,
    title: "check_in_therapist_name",
    placeholder: "Enter Therapist Name",
    label: "Therapist Name",
    wrapperClassName: "w-32",
    options: [],
    disabled: true,
    tabIndex: 2,

    // disabled: true,
  },
  {
    id: 507,
    title: "check_in_date",
    placeholder: "",
    label: "Check In Date",
    wrapperClassName: "w-32",
    fieldType: "text",
    tabIndex: 3,
    formater: 10,
    disabled: true,

    // disabled: true,
    options: [],
  },
  {
    id: 508,
    title: "check_in_time",
    placeholder: "",
    label: "Check In Time",
    wrapperClassName: "w-32",
    options: [],
    tabIndex: 4,
    formater: 7,
    disabled: true,

    fieldType: "text",
  },
  {
    id: 509,
    title: "check_in_location",
    placeholder: "Enter Location",
    label: "Location",
    tabIndex: 5,
    wrapperClassName: "w-32",
    disabled: true,
    formater: 9,
    options: [],
  },
  {
    id: 520,
    placeholder: "",
    label: "How did the patient sleep last night?",
    title: "check_in_patient_sleep",
    wrapperClassName: "w-100",
    options: [
      {
        id: 558,
        tabIndex: 6,
        title: "Minimally",
        key: "minimally",
      },
      {
        id: 559,
        tabIndex: 7,
        title: "Normal",
        key: "normal",
      },
      {
        id: 560,
        tabIndex: 8,
        title: "Extremely Well",
        key: "extremely_well",
      },
    ],
  },
  {
    id: 521,
    placeholder: "",
    label: "How did the patient eat?",
    title: "patient_eat",
    wrapperClassName: "w-100",
    options: [
      {
        id: 464,
        tabIndex: 9,
        title: "Minimally",
        key: "minimally",
      },
      {
        id: 465,
        tabIndex: 10,
        title: "Normal",
        key: "normal",
      },
      {
        id: 466,
        tabIndex: 11,
        title: "Extremely Well",
        key: "extremely_well",
      },
    ],
  },
  {
    id: 522,
    placeholder: "",
    label: "How was the patient's mood/how is he feeling?",
    title: "patient_mood",
    wrapperClassName: "w-100",
    options: [
      {
        id: 467,
        tabIndex: 12,
        title: "Minimally",
        key: "minimally",
      },
      {
        id: 468,
        tabIndex: 13,
        title: "Normal",
        key: "normal",
      },
      {
        id: 469,
        tabIndex: 14,
        title: "Extremely Well",
        key: "extremely_well",
      },
    ],
  },
  {
    id: 522,
    placeholder: "",
    label: "How was his behavior?",
    title: "patient_beahviour",
    wrapperClassName: "w-100",
    options: [
      {
        id: 470,
        tabIndex: 15,
        title: "Minimally",
        key: "minimally",
      },
      {
        id: 471,
        tabIndex: 16,
        title: "Normal",
        key: "normal",
      },
      {
        id: 472,
        tabIndex: 17,
        title: "Extremely Well",
        key: "extremely_well",
      },
    ],
  },
  {
    id: 523,
    title: "check_in_patient_behaviour_addit_text",
    placeholder: "Enter Patient Behaviour Additional text",
    label: "Patient Behaviour Additional text",
    wrapperClassName: "w-32",
    tabIndex: 18,
    options: [],
  },
  {
    id: 524,
    title: "check_in_patient_disease_last_day",
    placeholder: "Enter Patient Disease Last 24h",
    label:
      "Did the patient have a fever, cough, or congestion in the past 24h?",
    wrapperClassName: "m-w-i",
    tabIndex: 19,
    options: [],
  },
  {
    id: 525,
    title: "check_in_challenging_beh_in_school",
    placeholder: "Enter Challenging Behaviours in School / Home",
    label:
      "Did The Patient Display Problem or Challenging Behaviours at School / Home?",
    wrapperClassName: "m-w-i",
    tabIndex: 20,
    options: [],
  },
  {
    id: 526,
    title: "check_in_patient_marks",
    placeholder: "Enter Patient Marks",
    label:
      "Does the child have any physical marks/ bruises? If yes how did it happen? (e.g., at school, at home, at the park)",
    wrapperClassName: "m-w-i",
    tabIndex: 21,
    options: [],
  },
  {
    id: 527,
    title: "check_in_patient_work_break_schedule",
    placeholder: "List Patient Work Break Schedule",
    label:
      "Please list work/break schedule ( e.g., 10 min work/5 min independent play)",
    wrapperClassName: "m-w-i",
    tabIndex: 22,
    options: [],
  },
  {
    id: 528,
    title: "check_in_patient_worked_goal_today",
    placeholder: "Enter Patient Worked Goal Today",
    label:
      "What goals are you working on today? please list 5 types of material you will use to teach each goal",
    wrapperClassName: "m-w-i",
    tabIndex: 23,
    options: [],
  },
];
