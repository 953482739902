import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
// import sc from "./ProgressBar.module.scss";
const ProgressBarComponent = ({
  progress,
  label,
  reverse,
  progressLabel,
  GoalProgress,
  GoalStatus,
}) => {
  let percentage = 0;
  let statusClass = "";
  if (GoalStatus == "in_progress") {
    percentage = 25;
    statusClass = "in-progress-status";
  } else if (GoalStatus == "mastered") {
    percentage = 100;
    statusClass = "mastered-status";
  } else if (GoalStatus == "perfected_once") {
    percentage = 25;
    statusClass = "mastered-status";
  } else if (GoalStatus == "perfected_twice") {
    percentage = 50;
    statusClass = "mastered-status";
  } else if (GoalStatus == "perfected_thrice") {
    percentage = 75;
    statusClass = "mastered-status";
  }

  return (
    <div className="lm-progress-bar-dashboard d-flex flex-column gap-14">
      <div className="d-flex justify-content-between">
        <div>
          {
            <span className="f-15 f-w-600 lh-20 clr-dark-grey">
              {GoalProgress ? (
                <div className="d-flex gap-4 ">
                  {/* <span className="f-13 lh-18 f-w-600 clr-d-blue">{`${progress}%`}</span> */}
                  <span
                    className={`f-13 lh-18 f-w-600 clr-d-blue ${statusClass}`}
                    style={{ textTransform: "capitalize" }}
                  >
                    {GoalStatus?.replace(/_/g, " ")}
                  </span>
                </div>
              ) : (
                label
              )}
            </span>
          }
          {}
        </div>
        {progressLabel && (
          <span className="f-15 f-w-600 lh-20 clr-dark-grey">
            {progress ?? 0}
          </span>
        )}
      </div>
      <ProgressBar
        className={`${reverse && "justify-content-end"} ${statusClass}`}
        now={percentage}
      />
    </div>
  );
};

export default ProgressBarComponent;
