import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";

const HomeAndFamily = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 108,
      title: "family_parent_marital_status",
      placeholder: "",
      label: "Parents Marital Status",
      wrapperClassName: "radio-4",
      options: [
        {
          id: 64,
          title: "Married",
          key: "married",
          firstInput: true,
        },
        {
          id: 65,
          title: "Civil Union",
          key: "civil_union",
        },
        {
          id: 66,
          title: "Remarried",
          key: "remarried",
        },
        {
          id: 67,
          title: "Divorced",
          key: "divorced",
        },
        {
          id: 68,
          title: "Separated",
          key: "separated",
        },
        {
          id: 69,
          title: "Widowed",
          key: "widowed",
        },
        {
          id: 70,
          title: "Single",
          key: "single",
        },
        {
          id: 71,
          title: "Cohabitants",
          key: "cohabitants",
        },
      ],

      gridView: true,
    },
    {
      title: "divorced_legal_custody",
      label: "If divorced who has legal custody?",
      options: [
        {
          id: 72,
          title: "Mother",

          key: "mother",
        },
        {
          id: 73,
          title: "Father",

          key: "father",
        },
      ],
      descType: "divorced",
    },
    {
      title: "divorced_full_or_joint",
      label: "Is it full or joint custody?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 74,
          title: "Full",

          key: "full",
        },
        {
          id: 75,
          title: "Joint",

          key: "joint",
        },
      ],
      descType: "divorced",
    },
    {
      id: 109,
      title: "family_home_daily_routine",
      placeholder: "Details...",
      label: "What is the daily routine and structure in the home?",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 110,
      title: "family_home_type",
      placeholder: "Details...",
      label: "What is the type of home does the child live in?",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 111,
      title: "family_financial_stressores",
      placeholder: "",
      label: "Are there any financial stressors for the family?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 72,
          title: "Yes",
          key: "yes",
        },
        {
          id: 73,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 112,
      title: "family_financial_stressores_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 113,
      title: "family_conflict",
      placeholder: "",
      label: "Are there any significant family conflicts?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 74,
          title: "Yes",
          key: "yes",
        },
        {
          id: 75,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 114,
      title: "family_conflict_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 115,
      title: "family_guardianship_agreement",
      placeholder: "",
      label: "Are there any guardianship or visitation agreements?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 76,
          title: "Yes",
          key: "yes",
        },
        {
          id: 77,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 116,
      title: "family_patient_risk",
      placeholder: "",
      label:
        "Is the patient at risk for being placed outside of the home (ex. foster care, residential treatment, inpatient treatment, etc)?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 78,
          title: "Yes",
          key: "yes",
        },
        {
          id: 79,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 117,
      title: "family_patient_risk_desc",
      placeholder: "Enter details..",
      label: "If so, what is the timeline for this placement?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 118,
      title: "home_family_sblings",
      placeholder: "w-100",
      label: "Are there Siblings?",
      wrapperClassName: "m-w-100",
      options: [
        {
          id: 80,
          title: "Yes",
          key: "yes",
        },
        {
          id: 81,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 119,
      title: "home_family_siblings_name",
      placeholder: "Complete Name",
      label: "Name",
      wrapperClassName: "w-4",
      options: [],
      first: true,
    },
    {
      id: 120,
      title: "home_family_siblings_age",
      placeholder: "Age",
      label: "Age",
      wrapperClassName: "w-8",
      fieldType: "number",
      // age: true,
      options: [],
    },
    {
      id: 121,
      title: "home_family_siblings_relationship",
      placeholder: "Relationship",
      label: "Relationship",
      wrapperClassName: "w-4",
      options: [],
      
    },
    {
      id: 122,
      title: "home_family_siblings_school",
      placeholder: "School Name",
      label: "School",
      wrapperClassName: "w-4",
      options: [],
    },
    {
      id: 123,
      title: "home_family_siblings_grade",
      placeholder: "0-12",
      label: "Grade",
      wrapperClassName: "w-8",
      fieldType: "number",
      options: [],
      formater: 5,
      last: true,
    },
    {
      id: 124,
      title: "family_siblings_special_need_desc",
      placeholder: "Details...",
      label:
        "Indicate and describe if any of the siblings have special needs, diagnoses, or concerns",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 125,
      title: "family_siblings_need_intrest",
      placeholder: "",
      label: "Interested in seeking services for siblings with special needs?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 82,
          title: "Yes",
          key: "yes",
        },
        {
          id: 83,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 126,
      title: "family_history_relationship",
      placeholder: "",
      label: "Current Living Situation and Family History/Relationships",
      wrapperClassName: "",
      options: [],
    },
    {
      id: 127,
      title: "family_relationship_person_name",
      placeholder: "Enter Name",
      label: "Name",
      wrapperClassName: "w-3",
      options: [],
      first: true,
    },
    {
      id: 128,
      title: "family_relationship_person_age",
      placeholder: "Age",
      label: "Age",
      age: true,
      wrapperClassName: "w-3",
      fieldType: "number",
      options: [],
    },
    {
      id: 129,
      title: "family_relationship_person_relation",
      placeholder: "Relationship to the Child",
      label: "Relationship to the Child",
      wrapperClassName: "w-3",
      options: [],
      last: true,
    },
    {
      id: 130,
      title: "family_child_strengths",
      placeholder: "Type here.",
      label: "Child Strengths",
      wrapperClassName: "m-w-i",
      options: [],
    },
    {
      id: 131,
      title: "family_child_weaknesses",
      placeholder: "Type here.",
      label: "Child Weaknesses",
      wrapperClassName: "m-w-i",
      options: [],
    },
    {
      id: 132,
      title: "family_child_extracurriculars",
      placeholder: "Type here.",
      label: "Extracurricular Activities",
      wrapperClassName: "m-w-i",
      options: [],
    },
    {
      id: 133,
      title: "family_child_religious_involvment",
      placeholder: "Type here.",
      label: "Religious Involvement",
      wrapperClassName: "m-w-i",
      options: [],
    },
    {
      id: 134,
      title: "family_child_family_involvement",
      placeholder: "Type here.",
      label: "Extended Family Involvement",
      wrapperClassName: "m-w-i",
      options: [],
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/home-family/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get("api/v1/sections/home-family");
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);

        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/home-family/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={false}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default HomeAndFamily;
