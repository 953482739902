import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import React from "react";
import FormCheckBox from "../../components/form-controls/input/FormCheck/FormCheckBox";

const ClientForm = ({ setCreds, creds }) => {
  return (
    <div className="w-100 d-flex flex-column gap-20">
      <div className="d-flex flex-column gap-12 mb-12">
        <h3 className="f-30 lh-38 f-w-600 clr-dark-gray-bold">Sign In</h3>
        <p className="f-16 lh-24 f-w-400 clr-l-grey">
          Welcome 👋 Please enter your details
        </p>
      </div>
      <TextFieldFormik
        label="Username/Email"
        placeholder="Your Email"
        wrapperClassName="w-100"
        type="email"
        handleChange={(e) => {
          setCreds({
            user: { ...creds?.user, email: e.target.value },
          });
        }}
      />
      <TextFieldFormik
        label="Password"
        wrapperClassName="w-100"
        type="password"
        handleChange={(e) =>
          setCreds({
            user: { ...creds?.user, password: e.target.value },
          })
        }
      />
    </div>
  );
};

export default ClientForm;
