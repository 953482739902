import React from "react";

const InnerHeader = ({ headerTitle, StepNo, subStep, totalSteps }) => {
  return (
    <div className="d-flex flex-column gap-10 onboarding-forms-section-inner-header">
      <div className="onboarding-form-header">
        <h3 className="onboarding-form-header-title">{headerTitle}</h3>
        <div className="onboarding-form-header-breadcrumb">
          {StepNo > 0 && StepNo < 10 && (
            <ul>
              Step {StepNo}
              <li>/{totalSteps}</li>
            </ul>
          )}
        </div>
      </div>
      <h3 className="onboarding-form-header-heading">{subStep} </h3>
    </div>
  );
};

export default InnerHeader;
