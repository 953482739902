import { useEffect, useState } from "react";
import httpClient from "../../../services/axios";
import LoaderSpinner from "../../general/loader/loader.spinner";
import CommonFormPage from "../CommonFormPage";
import { createNotification } from "../../../helpers/createNotifications";
import { logout } from "../../../store/user/userSlice";

const PsychologicalHistory = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 135,
      title: "psychological_history_symptoms",
      placeholder: "",
      label:
        "Indicate below if there is a history of the following in your immediate family or in either biological parents extended family.",
      wrapperClassName: "w-100",
      checkBox: true,
      options: [
        {
          id: 84,
          title: "Autism Spectrum Disorders",
          key: "autism_spectrum_disorders",
          firstInput: true,
          tabIndex: 1,
        },
        {
          id: 85,
          title: "Behavior Problems in School",
          key: "behaviour_problem",
          tabIndex: 2,
        },
        {
          id: 86,
          title: "Learning Problems/Disabilities",
          key: "learning_problem",
          tabIndex: 3,
        },
        {
          id: 87,
          title: "Anxiety Disorders (e.g., OCD, etc.)",
          key: "anxiety_disorder",
          tabIndex: 4,
        },
        {
          id: 88,
          title: "ADD/ADHD-Attention Problems",
          key: "attention_problem",
          tabIndex: 5,
        },
        {
          id: 89,
          title: "Intellectual Disability",
          key: "intellectual_disability",
          tabIndex: 6,
        },
        {
          id: 90,
          title: "Clinical Depression",
          key: "clinical_depression",
          tabIndex: 7,
        },
        {
          id: 91,
          title: "Psychosis/Schizophrenia",
          key: "psychosis_schizophrenia",
          tabIndex: 8,
        },
        {
          id: 92,
          title: "Bipolar Disorder",
          key: "bipolar_disorde",
          tabIndex: 9,
        },
        {
          id: 93,
          title: "Substance Abuse/Dependence",
          key: "substance_abuse",
          tabIndex: 10,
        },
        {
          id: 94,
          title: "Other",
          key: "other",
          tabIndex: 11,
        },
        {
          id: 95,
          title: "None",
          key: "none",
          tabIndex: 12,
        },
      ],
      gridView: true,
    },
    {
      id: 136,
      title: "psychological_history_symptoms_desc",
      placeholder: "Details...",
      label:
        "If any, please indicate who in the family currently has or has had these diagnoses:",
      wrapperClassName: "",
      options: [],
      checkBox: true,
      inputType: true,
    },
    {
      id: 137,
      title: "psychological_history_outside_evaluation",
      placeholder: "",
      label:
        "Has your child/adolescent had an outside psychological or psychiatric evaluation?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 96,
          title: "Yes",
          key: "yes",
        },
        {
          id: 97,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 138,
      title: "psychological_history_child_hospitalized",
      placeholder: "",
      label: "Has your child ever been hospitalised for psychiatric condition?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 98,
          title: "Yes",
          key: "yes",
        },
        {
          id: 99,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 139,
      title: "psychological_history_psychological_trauma",
      placeholder: "",
      label:
        "Is there a history of abuse or neglect? Any sexual or domestic violence? Has there been any trauma (including psychological trauma)?",
      wrapperClassName: "w-100",
      options: [
        {
          id: 100,
          title: "Yes",
          key: "yes",
        },
        {
          id: 101,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 140,
      title: "psychological_history_psychological_trauma_desc",
      placeholder: "Details...",
      label: "If yes, Please Explain",
      wrapperClassName: "m-w-i",
      options: [],
      inputType: true,
    },
    {
      id: 141,
      title: "psychological_history_helpful_understanding",
      placeholder: "Write here...",
      label:
        "Please provide with any other information on psychological history that would be helpful in understanding your child",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/psychological-history-section/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/psychological-history-section"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);

        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/psychological-history-section/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default PsychologicalHistory;
