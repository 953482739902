import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";
import LoaderSpinner from "../../../general/loader/loader.spinner";

const SocialSkills = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 220,
      title: "play_well",
      placeholder: "",
      label: "Does your child play well with others?",
      wrapperClassName: "",
      options: [
        {
          id: 198,
          title: "Yes",
          key: "yes",
          firstInput: true,
        },
        {
          id: 199,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 221,
      title: "play_well_explaination",
      placeholder: "Explanation...",
      label: "Please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 222,
      title: "toy_play",
      placeholder: "",
      label: "Does your child play appropriately with toys?",
      wrapperClassName: "",
      options: [
        {
          id: 200,
          title: "Yes",
          key: "yes",
        },
        {
          id: 201,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 223,
      title: "toy_play_explaination",
      placeholder: "Explanation...",
      label: "Please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 224,
      title: "eye_contact",
      placeholder: "",
      label: "Does your child make eye contact?",
      wrapperClassName: "",
      options: [
        {
          id: 202,
          title: "Yes",
          key: "yes",
        },
        {
          id: 203,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 225,
      title: "eye_contact_explaination",
      placeholder: "Explanation...",
      label: "Please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 226,
      title: "child_interest",
      placeholder: "",
      label: "Does your child show interest in others?",
      wrapperClassName: "",
      options: [
        {
          id: 204,
          title: "Yes",
          key: "yes",
        },
        {
          id: 205,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 227,
      title: "child_interest_explaination",
      placeholder: "Explanation...",
      label: "Please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 228,
      title: "child_follow_direction",
      placeholder: "",
      label: "Does your child follow directions when given to him/her?",
      wrapperClassName: "",
      options: [
        {
          id: 206,
          title: "Yes",
          key: "yes",
        },
        {
          id: 207,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 229,
      title: "child_follow_direction_explaination",
      placeholder: "Explanation...",
      label: "Please explain",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];
  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/social-skills/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get("api/v1/sections/social-skills");
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/social-skills/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default SocialSkills;
