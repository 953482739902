import ImageView from "../components/Image/ImageView";
import {
  addNotesIcon,
  archiveIcon,
  crossRed,
  forwardToInboxIcon,
  tickGreen,
} from "./constants";

export const TableDataAllSessions = [
  {
    key: Math.random(),
    Age: 9,
    Name: "Emily Joe",
    name: (
      <div className="client-info-data">
        <img
          className=""
          alt="client-profile"
          src="/src/assets/images/tkxel_logo.png"
        ></img>
        <span>Emily Joe</span>
      </div>
    ),
    DateJoined: "20/3/2023",
    Unitsleft: 40,
    // GoalsAssigned: "Accepting No",
    Archive: (
      <span className="cursor-pointer">
        <ImageView className={"img-fluid"} src={archiveIcon}></ImageView>
      </span>
    ),
  },
];
// export const TableDataPendingRequests = ({
//   data,
//   setDeleteModal,
//   setShowApproveModal,
//   setShowTextEditor,
//   setShowMailSent,
// }) => {
//   const tableData = data.map((row) => {
//     return {
//       id: row.id,
//       key: row.id,
//       Age: row.Age,
//       Name: row.Name,
//       name: (
//         <div
//           onClick={() => {
//             setdashboardView("Client");
//           }}
//           className="client-info-data"
//         >
//           <img
//             className=""
//             alt="client-profile"
//             src={row.image || "/src/assets/images/tkxel_logo.png"}
//           ></img>
//           <span>{row.Name}</span>
//         </div>
//       ),
//       DateJoined: row.DateJoined,
//       Actions: (
//         <div className="d-flex gap-16 align-items-center">
//           <span
//             onClick={() => {
//               setDeleteModal(true);
//             }}
//             className="actions-tag actions-tag-red  cursor-pointer"
//           >
//             {crossRed}
//           </span>
//           <span
//             onClick={() => {
//               setShowApproveModal(true);
//             }}
//             className="actions-tag actions-tag-green  cursor-pointer"
//           >
//             {tickGreen}
//           </span>
//           <span className="actions-tag actions-tag-yellow  cursor-pointer">
//             {addNotesIcon}
//           </span>
//           <span
//             className="actions-tag actions-tag-orange  cursor-pointer"
//             onClick={() => setShowMailSent(true)}
//           >
//             {forwardToInboxIcon}
//           </span>
//         </div>
//       ),
//       //   onClick: () => {
//       //     setdashboardView("Client");
//       //     console.log("Row Clicked");
//       //   },
//     };
//   });
//   return tableData;
// };
export const TableDataClient = {
  users: [
    {
      id: "asdasd1",
      key: "emily_joe or id above1",
      Age: 9,
      full_name: "Jmily Joe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdasd2",
      key: "emily_joe or id above2",
      Age: 9,
      full_name: "Hmily Foe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdasd3",
      key: "emily_joe or id above3",
      Age: 9,
      full_name: "Tmily hoe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdasd4",
      key: "emily_joe or id above4",
      Age: 9,
      full_name: "Rmily koe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdas5",
      key: "emily_joe or id above5",
      Age: 9,
      full_name: "Emily loe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdasd6",
      key: "emily_joe or id above6",
      Age: 9,
      full_name: "Amily poe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 40,
      archiveStatus: false,
    },
    {
      id: "asdasd7",
      key: "emily_joe or id abov7",
      Age: 12,
      full_name: "hmily poe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 90,
      archiveStatus: false,
    },
    {
      id: "asdasd8",
      key: "emily_joe or id abov8",
      Age: 9,
      full_name: "Amily poe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 90,
      archiveStatus: false,
    },
    {
      id: "asdasd9",
      key: "emily_joe or id abov9",
      Age: 1,
      full_name: "Amily poe",
      image: "string",
      DateJoined: "20/3/2023",
      Unitsleft: 50,
      archiveStatus: false,
    },
  ],
  meta: {
    total_pages: 20,
  },
};
// export const TableDataPendingRequests = [
//   {
//     id: "asdasd",
//     key: "emily_joe or id above",
//     Age: 9,
//     Name: "Emily Joe",
//     image: "string",
//     DateJoined: "20/3/2023",
//   },
// ];
export const TableDataArchive = [
  {
    id: "asdasd",
    key: "emily_joe or id above",
    Age: 9,
    Name: "Emily Joe",
    image: "string",
    DateJoined: "20/3/2023",
    archiveStatus: false,
  },
];
export const timeSlotsStart = [
  {
    value: "6:00 AM",
    key: "06:00",
  },
  {
    value: "6:15 AM",
    key: "06:15",
  },
  {
    value: "6:30 AM",
    key: "06:30",
  },
  {
    value: "6:45 AM",
    key: "06:45",
  },
  {
    value: "7:00 AM",
    key: "07:00",
  },
  {
    value: "7:15 AM",
    key: "07:15",
  },
  {
    value: "7:30 AM",
    key: "07:30",
  },
  {
    value: "7:45 AM",
    key: "07:45",
  },
  {
    value: "8:00 AM",
    key: "08:00",
  },
  {
    value: "8:15 AM",
    key: "08:15",
  },
  {
    value: "8:30 AM",
    key: "08:30",
  },
  {
    value: "8:45 AM",
    key: "08:45",
  },
  {
    value: "9:00 AM",
    key: "09:00",
  },
  {
    value: "9:15 AM",
    key: "09:15",
  },
  {
    value: "9:30 AM",
    key: "09:30",
  },
  {
    value: "9:45 AM",
    key: "09:45",
  },
  {
    value: "10:00 AM",
    key: "10:00",
  },
  {
    value: "10:15 AM",
    key: "10:15",
  },
  {
    value: "10:30 AM",
    key: "10:30",
  },
  {
    value: "10:45 AM",
    key: "10:45",
  },
  {
    value: "11:00 AM",
    key: "11:00",
  },
  {
    value: "11:15 AM",
    key: "11:15",
  },
  {
    value: "11:30 AM",
    key: "11:30",
  },
  {
    value: "11:45 AM",
    key: "11:45",
  },
  {
    value: "12:00 PM",
    key: "12:00",
  },
  {
    value: "12:15 PM",
    key: "12:15",
  },
  {
    value: "12:30 PM",
    key: "12:30",
  },
  {
    value: "12:45 PM",
    key: "12:45",
  },
  {
    value: "01:00 PM",
    key: "13:00",
  },
  {
    value: "01:15 PM",
    key: "13:15",
  },
  {
    value: "01:30 PM",
    key: "13:30",
  },
  {
    value: "01:45 PM",
    key: "13:45",
  },
  {
    value: "02:00 PM",
    key: "14:00",
  },
  {
    value: "02:15 PM",
    key: "14:15",
  },
  {
    value: "02:30 PM",
    key: "14:30",
  },
  {
    value: "02:45 PM",
    key: "14:45",
  },
  {
    value: "03:00 PM",
    key: "15:00",
  },
  {
    value: "03:15 PM",
    key: "15:15",
  },
  {
    value: "03:30 PM",
    key: "15:30",
  },
  {
    value: "03:45 PM",
    key: "15:45",
  },
  {
    value: "04:00 PM",
    key: "16:00",
  },
  {
    value: "04:15 PM",
    key: "16:15",
  },
  {
    value: "04:30 PM",
    key: "16:30",
  },
  {
    value: "04:45 PM",
    key: "16:45",
  },
  {
    value: "05:00 PM",
    key: "17:00",
  },
  {
    value: "05:15 PM",
    key: "17:15",
  },
  {
    value: "05:30 PM",
    key: "17:30",
  },
  {
    value: "05:45 PM",
    key: "17:45",
  },
  {
    value: "06:00 PM",
    key: "18:00",
  },
  {
    value: "06:15 PM",
    key: "18:15",
  },
  {
    value: "06:30 PM",
    key: "18:30",
  },
  {
    value: "06:45 PM",
    key: "18:45",
  },
  {
    value: "07:00 PM",
    key: "19:00",
  },
  {
    value: "07:15 PM",
    key: "19:15",
  },
  {
    value: "07:30 PM",
    key: "19:30",
  },
  {
    value: "07:45 PM",
    key: "19:45",
  },
  {
    value: "08:00 PM",
    key: "20:00",
  },
  {
    value: "08:15 PM",
    key: "20:15",
  },
  {
    value: "08:30 PM",
    key: "20:30",
  },
  {
    value: "08:45 PM",
    key: "20:45",
  },
  {
    value: "09:00 PM",
    key: "21:00",
  },
  {
    value: "09:15 PM",
    key: "21:15",
  },
  {
    value: "09:30 PM",
    key: "21:30",
  },
  {
    value: "09:45 PM",
    key: "21:45",
  },
];
export const timeSlotsEnd = [
  {
    value: "6:15 AM",
    key: "06:15",
  },
  {
    value: "6:30 AM",
    key: "06:30",
  },
  {
    value: "6:45 AM",
    key: "06:45",
  },
  {
    value: "7:00 AM",
    key: "07:00",
  },
  {
    value: "7:15 AM",
    key: "07:15",
  },
  {
    value: "7:30 AM",
    key: "07:30",
  },
  {
    value: "7:45 AM",
    key: "07:45",
  },
  {
    value: "8:00 AM",
    key: "08:00",
  },
  {
    value: "8:15 AM",
    key: "08:15",
  },
  {
    value: "8:30 AM",
    key: "08:30",
  },
  {
    value: "8:45 AM",
    key: "08:45",
  },
  {
    value: "9:00 AM",
    key: "09:00",
  },
  {
    value: "9:15 AM",
    key: "09:15",
  },
  {
    value: "9:30 AM",
    key: "09:30",
  },
  {
    value: "9:45 AM",
    key: "09:45",
  },
  {
    value: "10:00 AM",
    key: "10:00",
  },
  {
    value: "10:15 AM",
    key: "10:15",
  },
  {
    value: "10:30 AM",
    key: "10:30",
  },
  {
    value: "10:45 AM",
    key: "10:45",
  },
  {
    value: "11:00 AM",
    key: "11:00",
  },
  {
    value: "11:15 AM",
    key: "11:15",
  },
  {
    value: "11:30 AM",
    key: "11:30",
  },
  {
    value: "11:45 AM",
    key: "11:45",
  },
  {
    value: "12:00 PM",
    key: "12:00",
  },
  {
    value: "12:15 PM",
    key: "12:15",
  },
  {
    value: "12:30 PM",
    key: "12:30",
  },
  {
    value: "12:45 PM",
    key: "12:45",
  },
  {
    value: "01:00 PM",
    key: "13:00",
  },
  {
    value: "01:15 PM",
    key: "13:15",
  },
  {
    value: "01:30 PM",
    key: "13:30",
  },
  {
    value: "01:45 PM",
    key: "13:45",
  },
  {
    value: "02:00 PM",
    key: "14:00",
  },
  {
    value: "02:15 PM",
    key: "14:15",
  },
  {
    value: "02:30 PM",
    key: "14:30",
  },
  {
    value: "02:45 PM",
    key: "14:45",
  },
  {
    value: "03:00 PM",
    key: "15:00",
  },
  {
    value: "03:15 PM",
    key: "15:15",
  },
  {
    value: "03:30 PM",
    key: "15:30",
  },
  {
    value: "03:45 PM",
    key: "15:45",
  },
  {
    value: "04:00 PM",
    key: "16:00",
  },
  {
    value: "04:15 PM",
    key: "16:15",
  },
  {
    value: "04:30 PM",
    key: "16:30",
  },
  {
    value: "04:45 PM",
    key: "16:45",
  },
  {
    value: "05:00 PM",
    key: "17:00",
  },
  {
    value: "05:15 PM",
    key: "17:15",
  },
  {
    value: "05:30 PM",
    key: "17:30",
  },
  {
    value: "05:45 PM",
    key: "17:45",
  },
  {
    value: "06:00 PM",
    key: "18:00",
  },
  {
    value: "06:15 PM",
    key: "18:15",
  },
  {
    value: "06:30 PM",
    key: "18:30",
  },
  {
    value: "06:45 PM",
    key: "18:45",
  },
  {
    value: "07:00 PM",
    key: "19:00",
  },
  {
    value: "07:15 PM",
    key: "19:15",
  },
  {
    value: "07:30 PM",
    key: "19:30",
  },
  {
    value: "07:45 PM",
    key: "19:45",
  },
  {
    value: "08:00 PM",
    key: "20:00",
  },
  {
    value: "08:15 PM",
    key: "20:15",
  },
  {
    value: "08:30 PM",
    key: "20:30",
  },
  {
    value: "08:45 PM",
    key: "20:45",
  },
  {
    value: "09:00 PM",
    key: "21:00",
  },
  {
    value: "09:15 PM",
    key: "21:15",
  },
  {
    value: "09:30 PM",
    key: "21:30",
  },
  {
    value: "09:45 PM",
    key: "21:45",
  },
  {
    value: "10:00 PM",
    key: "22:00",
  },
];
export const timeSlots = [
  {
    value: "6:00 AM",
    key: "06:00",
  },
  {
    value: "6:15 AM",
    key: "06:15",
  },
  {
    value: "6:30 AM",
    key: "06:30",
  },
  {
    value: "6:45 AM",
    key: "06:45",
  },
  {
    value: "7:00 AM",
    key: "07:00",
  },
  {
    value: "7:15 AM",
    key: "07:15",
  },
  {
    value: "7:30 AM",
    key: "07:30",
  },
  {
    value: "7:45 AM",
    key: "07:45",
  },
  {
    value: "8:00 AM",
    key: "08:00",
  },
  {
    value: "8:15 AM",
    key: "08:15",
  },
  {
    value: "8:30 AM",
    key: "08:30",
  },
  {
    value: "8:45 AM",
    key: "08:45",
  },
  {
    value: "9:00 AM",
    key: "09:00",
  },
  {
    value: "9:15 AM",
    key: "09:15",
  },
  {
    value: "9:30 AM",
    key: "09:30",
  },
  {
    value: "9:45 AM",
    key: "09:45",
  },
  {
    value: "10:00 AM",
    key: "10:00",
  },
  {
    value: "10:15 AM",
    key: "10:15",
  },
  {
    value: "10:30 AM",
    key: "10:30",
  },
  {
    value: "10:45 AM",
    key: "10:45",
  },
  {
    value: "11:00 AM",
    key: "11:00",
  },
  {
    value: "11:15 AM",
    key: "11:15",
  },
  {
    value: "11:30 AM",
    key: "11:30",
  },
  {
    value: "11:45 AM",
    key: "11:45",
  },
  {
    value: "12:00 PM",
    key: "12:00",
  },
  {
    value: "12:15 PM",
    key: "12:15",
  },
  {
    value: "12:30 PM",
    key: "12:30",
  },
  {
    value: "12:45 PM",
    key: "12:45",
  },
  {
    value: "01:00 PM",
    key: "13:00",
  },
  {
    value: "01:15 PM",
    key: "13:15",
  },
  {
    value: "01:30 PM",
    key: "13:30",
  },
  {
    value: "01:45 PM",
    key: "13:45",
  },
  {
    value: "02:00 PM",
    key: "14:00",
  },
  {
    value: "02:15 PM",
    key: "14:15",
  },
  {
    value: "02:30 PM",
    key: "14:30",
  },
  {
    value: "02:45 PM",
    key: "14:45",
  },
  {
    value: "03:00 PM",
    key: "15:00",
  },
  {
    value: "03:15 PM",
    key: "15:15",
  },
  {
    value: "03:30 PM",
    key: "15:30",
  },
  {
    value: "03:45 PM",
    key: "15:45",
  },
  {
    value: "04:00 PM",
    key: "16:00",
  },
  {
    value: "04:15 PM",
    key: "16:15",
  },
  {
    value: "04:30 PM",
    key: "16:30",
  },
  {
    value: "04:45 PM",
    key: "16:45",
  },
  {
    value: "05:00 PM",
    key: "17:00",
  },
  {
    value: "05:15 PM",
    key: "17:15",
  },
  {
    value: "05:30 PM",
    key: "17:30",
  },
  {
    value: "05:45 PM",
    key: "17:45",
  },
  {
    value: "06:00 PM",
    key: "18:00",
  },
  {
    value: "06:15 PM",
    key: "18:15",
  },
  {
    value: "06:30 PM",
    key: "18:30",
  },
  {
    value: "06:45 PM",
    key: "18:45",
  },
  {
    value: "07:00 PM",
    key: "19:00",
  },
  {
    value: "07:15 PM",
    key: "19:15",
  },
  {
    value: "07:30 PM",
    key: "19:30",
  },
  {
    value: "07:45 PM",
    key: "19:45",
  },
  {
    value: "08:00 PM",
    key: "20:00",
  },
  {
    value: "08:15 PM",
    key: "20:15",
  },
  {
    value: "08:30 PM",
    key: "20:30",
  },
  {
    value: "08:45 PM",
    key: "20:45",
  },
  {
    value: "09:00 PM",
    key: "21:00",
  },
  {
    value: "09:15 PM",
    key: "21:15",
  },
  {
    value: "09:30 PM",
    key: "21:30",
  },
  {
    value: "09:45 PM",
    key: "21:45",
  },
  {
    value: "10:00 PM",
    key: "22:00",
  },
];
