import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import React from "react";
import FormCheckBox from "../../components/form-controls/input/FormCheck/FormCheckBox";
import { useNavigate } from "react-router-dom";

const ClinicalDirectorForm = ({
  setCreds,
  creds,
  setRemember,
  remember,
  emailError,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-100 d-flex flex-column gap-20">
      <TextFieldFormik
        label="Username/Email"
        placeholder="Your Email"
        wrapperClassName={`w-100 ${emailError && "border-red"}`}
        type="email"
        handleChange={(e) => {
          setCreds({
            user: {
              ...creds?.user,
              email: e.target.value,
            },
          });
        }}
        values={creds?.user?.email}
      />
      <TextFieldFormik
        label="Password"
        wrapperClassName="w-100"
        type="password"
        handleChange={(e) => {
          setCreds({
            user: {
              ...creds?.user,
              password: e.target.value,
            },
          });
        }}
        values={creds?.user?.password}
      />
      <div className="d-flex justify-content-between align-items-center">
        <FormCheckBox
          onClick={(e) => {
            setRemember(e.target.checked);
          }}
          id={"rememberMeCheck"}
          label={"Remember Me"}
          checked={remember}
        />
        <p
          className="inter_regular text-sm-regular clr-orange-pri"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate("/forgotpassword", { state: { tab: "director" } })
          }
        >
          Forgot Password?
        </p>
      </div>
    </div>
  );
};

export default ClinicalDirectorForm;
