export const learnnMeForm = {
  id: 1,
  title: "Learn Me Form",
  order_no: null,
  meta: {
    key: "learn_me_form",
  },
  sections: [
    {
      id: 1,
      title: "Basic Information",
      parent_section_id: null,
      meta: {
        key: "basic_information",
      },
      sub_sections: [
        {
          id: 2,
          title: "General",
          parent_section_id: 1,
          meta: {
            key: "general",
          },
          questions: [
            {
              id: 1,
              title: "general_name",
              question_type: "text_input",
              meta: {
                key: "general_name",
              },
              options: [],
              answers: [
                {
                  id: 33,
                  statement:
                    "This is the one of the biggest question that we need to answer",
                  option_id: null,
                  meta: {},
                },
              ],
            },
            {
              id: 2,
              title: "general_child_relationship",
              question_type: "radio",
              meta: {
                key: "general_child_relationship",
              },
              options: [
                {
                  id: 1,
                  title: "parent",
                  meta: {
                    key: "parent",
                  },
                },
                {
                  id: 2,
                  title: "legal_guardian_family_member",
                  meta: {
                    key: "legal_guardian_family_member",
                  },
                },
                {
                  id: 3,
                  title: "grand_parent",
                  meta: {
                    key: "grand_parent",
                  },
                },
                {
                  id: 4,
                  title: "legal_guardian_non_family_member",
                  meta: {
                    key: "legal_guardian_non_family_member",
                  },
                },
                {
                  id: 5,
                  title: "other_provider",
                  meta: {
                    key: "other_provider",
                  },
                },
              ],
              answers: [
                {
                  id: 37,
                  statement: null,
                  option_id: 4,
                  meta: {
                    key: "legal_guardian_non_family_member",
                  },
                },
              ],
            },
            {
              id: 3,
              title: "gerneral_agency_source",
              question_type: "radio",
              meta: {
                key: "gerneral_agency_source",
              },
              options: [
                {
                  id: 64,
                  title: "friend",
                  meta: {
                    key: "friend",
                  },
                },
                {
                  id: 74,
                  title: "digital_ads",
                  meta: {
                    key: "digital_ads",
                  },
                },
                {
                  id: 84,
                  title: "clinician",
                  meta: {
                    key: "clinician",
                  },
                },
                {
                  id: 94,
                  title: "Other",
                  meta: {
                    key: "other",
                  },
                },
              ],
              answers: [
                {
                  option_id: 64,
                },
              ],
            },
            {
              id: 4,
              title: "general_referal_reason",
              question_type: "text_area",
              meta: {
                key: "general_referal_reason",
              },
              options: [],
              answers: [],
            },
            {
              id: 5,
              title: "general_therapy_recieved",
              question_type: "radio_with_description",
              meta: {
                key: "general_therapy_recieved",
              },
              options: [
                {
                  id: 10,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 11,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [
                {
                  id: 1,
                  statement: null,
                  option_id: 10,
                  meta: {},
                },
              ],
            },
            {
              id: 6,
              title: "general_therapy_recieved_desc",
              question_type: "text_area",
              meta: {
                key: "general_therapy_recieved_desc",
                parent_question_id: 5,
              },
              options: [],
              answers: [],
            },
            {
              id: 7,
              title: "general_initial_goals",
              question_type: "text_area",
              meta: {
                key: "general_initial_goals",
              },
              options: [],
              answers: [],
            },
            {
              id: 8,
              title: "general_requested_services",
              question_type: "radio",
              meta: {
                key: "general_requested_services",
              },
              options: [
                {
                  id: 12,
                  title: "ABA in the Clinic",
                  meta: {
                    key: "aba_in_the_clinic",
                  },
                },
                {
                  id: 13,
                  title: "ABA in Home - Telhealth Consultation",
                  meta: {
                    key: "aba_in_home",
                  },
                },
              ],
              answers: [
                {
                  id: 1,
                  statement: null,
                  option_id: 12,
                  meta: {},
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "Insurance Information",
          parent_section_id: 1,
          meta: {
            key: "insurance_information",
          },
          questions: [
            {
              id: 9,
              title: "insurance_funding_source",
              question_type: "radio",
              meta: {
                key: "insurance_funding_source",
              },
              options: [
                {
                  id: 14,
                  title: "Insurance",
                  meta: {},
                },
                {
                  id: 15,
                  title: "Self-Pay",
                  meta: {},
                },
                {
                  id: 16,
                  title: "Other",
                  meta: {},
                },
              ],
              answers: [],
            },
            {
              id: 10,
              title: "insurances_names",
              question_type: "custom",
              meta: {
                key: "insurances_names",
              },
              options: [],
              answers: [],
            },
            {
              id: 11,
              title: "insurance_name_input",
              question_type: "custom_with_text",
              meta: {
                key: "insurance_name_input",
                parent_question_id: 10,
              },
              options: [],
              answers: [
                {
                  statement: "22",
                  meta: {
                    batch_no: 1,
                  },
                },
                {
                  statement: "222",
                  meta: {
                    batch_no: 2,
                  },
                },
              ],
            },
            {
              id: 12,
              title: "insurance_policy_number",
              question_type: "custom_with_text",
              meta: {
                key: "insurance_policy_number",
                parent_question_id: 10,
              },
              options: [],
              answers: [
                {
                  statement: "22",
                  meta: {
                    batch_no: 1,
                  },
                },
                {
                  statement: "232",
                  meta: {
                    batch_no: 2,
                  },
                },
              ],
            },
            {
              id: 13,
              title: "insurance_subscriber_name",
              question_type: "custom_with_text",
              meta: {
                key: "insurance_subscriber_name",
                parent_question_id: 10,
              },
              options: [],
              answers: [
                {
                  statement: "22",
                  meta: {
                    batch_no: 1,
                  },
                },
                {
                  statement: "223",
                  meta: {
                    batch_no: 2,
                  },
                },
              ],
            },
            {
              id: 14,
              title: "insurance_subscriber_dob",
              question_type: "custom_with_text",
              meta: {
                key: "insurance_subscriber_dob",
                parent_question_id: 10,
              },
              options: [],
              answers: [
                {
                  statement: "22",
                  meta: {
                    batch_no: 1,
                  },
                },
                {
                  statement: "232",
                  meta: {
                    batch_no: 2,
                  },
                },
              ],
            },
            {
              id: 15,
              title: "medical_insurance_no",
              question_type: "custom_with_text",
              meta: {
                key: "medical_insurance_no",
                parent_question_id: 10,
              },
              options: [],
              answers: [
                {
                  statement: "22",
                  meta: {
                    batch_no: 1,
                  },
                },
                {
                  statement: "33",
                  meta: {
                    batch_no: 2,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 4,
          title: "Patient Information",
          parent_section_id: 1,
          meta: {
            key: "patient_information",
          },
          questions: [
            {
              id: 16,
              title: "patient_info_child_name",
              question_type: null,
              meta: {
                key: "patient_info_child_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 17,
              title: "patient_info_child_first_name",
              question_type: "text_input",
              meta: {
                key: "patient_info_child_first_name",
                parent_question_id: 16,
              },
              options: [],
              answers: [],
            },
            {
              id: 18,
              title: "patient_info_child_middle_name",
              question_type: "text_input",
              meta: {
                key: "patient_info_child_middle_name",
                parent_question_id: 16,
              },
              options: [],
              answers: [],
            },
            {
              id: 19,
              title: "patient_info_child_last_name",
              question_type: "text_input",
              meta: {
                key: "patient_info_child_last_name",
                parent_question_id: 16,
              },
              options: [],
              answers: [],
            },
            {
              id: 20,
              title: "patient_info_gender",
              question_type: "radio",
              meta: {
                key: "patient_info_gender",
              },
              options: [
                {
                  id: 17,
                  title: "Male",
                  meta: {
                    key: "male",
                  },
                },
                {
                  id: 18,
                  title: "Female",
                  meta: {
                    key: "female",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 21,
              title: "patient_info_dob",
              question_type: "text_input",
              meta: {
                key: "patient_info_dob",
              },
              options: [],
              answers: [],
            },
            {
              id: 22,
              title: "patient_info_phone",
              question_type: "text_input",
              meta: {
                key: "patient_info_phone",
              },
              options: [],
              answers: [],
            },
            {
              id: 23,
              title: "patient_info_address",
              question_type: "text_area",
              meta: {
                key: "patient_info_address",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 5,
          title: "Parent/Guardian Information",
          parent_section_id: 1,
          meta: {
            key: "parent/guardian_information",
          },
          questions: [
            {
              id: 24,
              title: "parent_guardian1_name",
              question_type: "text_input",
              meta: {
                key: "parent_guardian1_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 25,
              title: "parent_guardian1_relationship",
              question_type: "text_input",
              meta: {
                key: "parent_guardian1_relationship",
              },
              options: [],
              answers: [],
            },
            {
              id: 26,
              title: "parent_guardian1_phone",
              question_type: "text_input",
              meta: {
                key: "parent_guardian1_phone",
              },
              options: [],
              answers: [],
            },
            {
              id: 27,
              title: "parent_guardian1_email",
              question_type: "text_input",
              meta: {
                key: "parent_guardian1_email",
              },
              options: [],
              answers: [],
            },
            {
              id: 28,
              title: "parent_guardian1_employer",
              question_type: "text_input",
              meta: {
                key: "parent_guardian1_employer",
              },
              options: [],
              answers: [],
            },
            {
              id: 29,
              title: "parent_guardian2_name",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 30,
              title: "parent_guardian2_relationship",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_relationship",
              },
              options: [],
              answers: [],
            },
            {
              id: 31,
              title: "parent_guardian2_phone",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_phone",
              },
              options: [],
              answers: [],
            },
            {
              id: 32,
              title: "parent_guardian2_email",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_email",
              },
              options: [],
              answers: [],
            },
            {
              id: 33,
              title: "parent_guardian2_employer",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_employer",
              },
              options: [],
              answers: [],
            },
            {
              id: 34,
              title: "parent_guardian2_address",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_address",
              },
              options: [],
              answers: [],
            },
            {
              id: 35,
              title: "parent_guardian2_home_telephone",
              question_type: "text_input",
              meta: {
                key: "parent_guardian2_home_telephone",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      title: "Medical History",
      parent_section_id: null,
      meta: {
        key: "medical_history",
      },
      sub_sections: [
        {
          id: 7,
          title: "Health Care Providers",
          parent_section_id: 6,
          meta: {
            key: "health_care_providers",
          },
          questions: [
            {
              id: 36,
              title: "med_hsitory_physician_name",
              question_type: "text_input",
              meta: {
                key: "med_hsitory_physician_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 37,
              title: "med_hsitory_physician_address",
              question_type: "text_input",
              meta: {
                key: "med_hsitory_physician_address",
              },
              options: [],
              answers: [],
            },
            {
              id: 38,
              title: "med_hsitory_physician_number",
              question_type: "text_input",
              meta: {
                key: "med_hsitory_physician_number",
              },
              options: [],
              answers: [],
            },
            {
              id: 39,
              title: "med_hsitory_physician_fax",
              question_type: "text_input",
              meta: {
                key: "med_hsitory_physician_fax",
              },
              options: [],
              answers: [],
            },
            {
              id: 40,
              title: "pediatrician_name",
              question_type: "text_input",
              meta: {
                key: "pediatrician_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 41,
              title: "pediatrician_address",
              question_type: "text_input",
              meta: {
                key: "pediatrician_address",
              },
              options: [],
              answers: [],
            },
            {
              id: 42,
              title: "pediatrician_phone",
              question_type: "text_input",
              meta: {
                key: "pediatrician_phone",
              },
              options: [],
              answers: [],
            },
            {
              id: 43,
              title: "pediatrician_fax",
              question_type: "text_input",
              meta: {
                key: "pediatrician_fax",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 8,
          title: "Patient Medical Information",
          parent_section_id: 6,
          meta: {
            key: "patient_medical_information",
          },
          questions: [
            {
              id: 44,
              title: "medical_info_child_height",
              question_type: "text_input",
              meta: {
                key: "medical_info_child_height",
              },
              options: [],
              answers: [],
            },
            {
              id: 45,
              title: "medical_info_child_weight",
              question_type: "text_input",
              meta: {
                key: "medical_info_child_weight",
              },
              options: [],
              answers: [],
            },
            {
              id: 46,
              title: "medical_info_dominant_hand",
              question_type: "radio",
              meta: {
                key: "medical_info_dominant_hand",
              },
              options: [
                {
                  id: 19,
                  title: "Left",
                  meta: {
                    key: "left",
                  },
                },
                {
                  id: 20,
                  title: "Right",
                  meta: {
                    key: "right",
                  },
                },
                {
                  id: 21,
                  title: "No Preference",
                  meta: {
                    key: "no_preference",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 47,
              title: "medical_info_infectious_disease",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_infectious_disease",
              },
              options: [
                {
                  id: 22,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 23,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 48,
              title: "medical_info_infectious_disease_desc",
              question_type: "text_area",
              meta: {
                key: "medical_info_infectious_disease_desc",
                parent_question_id: 47,
              },
              options: [],
              answers: [],
            },
            {
              id: 49,
              title: "medical_conditions",
              question_type: "custom",
              meta: {
                key: "medical_conditions",
              },
              options: [],
              answers: [],
            },
            {
              id: 50,
              title: "medical_conditions_name",
              question_type: "custom_with_text",
              meta: {
                key: "medical_conditions_name",
                parent_question_id: 49,
              },
              options: [],
              answers: [],
            },
            {
              id: 51,
              title: "medical_conditions_treating_clinician",
              question_type: "custom_with_text",
              meta: {
                key: "medical_conditions_treating_clinician",
                parent_question_id: 49,
              },
              options: [],
              answers: [],
            },
            {
              id: 52,
              title: "medical_conditions_therapeutic_interventions",
              question_type: "custom_with_text",
              meta: {
                key: "medical_conditions_therapeutic_interventions",
                parent_question_id: 49,
              },
              options: [],
              answers: [],
            },
            {
              id: 53,
              title: "medical_conditions_date",
              question_type: "custom_with_text",
              meta: {
                key: "medical_conditions_date",
                parent_question_id: 49,
              },
              options: [],
              answers: [],
            },
            {
              id: 54,
              title: "medical_info_past_medical_problem",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_past_medical_problem",
              },
              options: [
                {
                  id: 24,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 25,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 55,
              title: "medical_info_past_medical_problem_desc",
              question_type: "text_area",
              meta: {
                key: "medical_info_past_medical_problem_desc",
                parent_question_id: 54,
              },
              options: [],
              answers: [],
            },
            {
              id: 56,
              title: "medical_info_previous_hospitalizations",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_previous_hospitalizations",
              },
              options: [
                {
                  id: 26,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 27,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 57,
              title: "medical_info_previous_hospitalizations_desc",
              question_type: "text_area",
              meta: {
                key: "medical_info_previous_hospitalizations_desc",
                parent_question_id: 56,
              },
              options: [],
              answers: [],
            },
            {
              id: 58,
              title: "medical_info_physical_complaints",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_physical_complaints",
              },
              options: [
                {
                  id: 28,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 29,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 59,
              title: "medical_info_physical_complaints_desc",
              question_type: "text_area",
              meta: {
                key: "medical_info_physical_complaints_desc",
                parent_question_id: 58,
              },
              options: [],
              answers: [],
            },
            {
              id: 60,
              title: "medical_info_vision_problem",
              question_type: "radio",
              meta: {
                key: "medical_info_vision_problem",
              },
              options: [
                {
                  id: 30,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 31,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 61,
              title: "medical_info_hearing_problem",
              question_type: "radio",
              meta: {
                key: "medical_info_hearing_problem",
              },
              options: [
                {
                  id: 32,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 33,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 62,
              title: "medical_info_history_seizures",
              question_type: "radio",
              meta: {
                key: "medical_info_history_seizures",
              },
              options: [
                {
                  id: 34,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 35,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 63,
              title: "medical_info_physical_activ_restriction",
              question_type: "radio",
              meta: {
                key: "medical_info_physical_activ_restriction",
              },
              options: [
                {
                  id: 36,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 37,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 64,
              title: "medical_info_allergies",
              question_type: "radio",
              meta: {
                key: "medical_info_allergies",
              },
              options: [
                {
                  id: 38,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 39,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 65,
              title: "medical_info_current_medications",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_current_medications",
              },
              options: [
                {
                  id: 40,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 41,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 66,
              title: "medical_info_medication_name",
              question_type: "text_input",
              meta: {
                key: "medical_info_medication_name",
                parent_question_id: 65,
              },
              options: [],
              answers: [],
            },
            {
              id: 67,
              title: "medical_info_medication_amount",
              question_type: "text_input",
              meta: {
                key: "medical_info_medication_amount",
                parent_question_id: 65,
              },
              options: [],
              answers: [],
            },
            {
              id: 68,
              title: "medical_info_medication_how_often",
              question_type: "text_input",
              meta: {
                key: "medical_info_medication_how_often",
                parent_question_id: 65,
              },
              options: [],
              answers: [],
            },
            {
              id: 69,
              title: "medical_info_medication_when_taken",
              question_type: "text_input",
              meta: {
                key: "medical_info_medication_when_taken",
                parent_question_id: 65,
              },
              options: [],
              answers: [],
            },
            {
              id: 70,
              title: "medical_info_current_diagnosis",
              question_type: "radio_with_description",
              meta: {
                key: "medical_info_current_diagnosis",
              },
              options: [
                {
                  id: 42,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 43,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 71,
              title: "medical_info_current_diag",
              question_type: "text_input",
              meta: {
                key: "medical_info_current_diag",
                parent_question_id: 70,
              },
              options: [],
              answers: [],
            },
            {
              id: 72,
              title: "medical_info_current_diag_physician",
              question_type: "text_input",
              meta: {
                key: "medical_info_current_diag_physician",
                parent_question_id: 70,
              },
              options: [],
              answers: [],
            },
            {
              id: 73,
              title: "medical_info_current_diag_date",
              question_type: "text_input",
              meta: {
                key: "medical_info_current_diag_date",
                parent_question_id: 70,
              },
              options: [],
              answers: [],
            },
            {
              id: 74,
              title: "medical_info_current_diag_code",
              question_type: "text_input",
              meta: {
                key: "medical_info_current_diag_code",
                parent_question_id: 70,
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 9,
      title: "Therapy Providers",
      parent_section_id: null,
      meta: {
        key: "therapy_providers",
      },
      sub_sections: [
        {
          id: 10,
          title: "Therapy Providers Section",
          parent_section_id: 9,
          meta: {
            key: "therapy_providers_section",
          },
          questions: [
            {
              id: 75,
              title: "current_therapy_services",
              question_type: "radio",
              meta: {
                key: "current_therapy_services",
              },
              options: [
                {
                  id: 44,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 45,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 76,
              title: "current_therapy_provider_address",
              question_type: "text_input",
              meta: {
                key: "current_therapy_provider_address",
                parent_question_id: 75,
              },
              options: [],
              answers: [],
            },
            {
              id: 77,
              title: "current_therapy_provider_phone",
              question_type: "text_input",
              meta: {
                key: "current_therapy_provider_phone",
                parent_question_id: 75,
              },
              options: [],
              answers: [],
            },
            {
              id: 78,
              title: "current_therapy_provider_email",
              question_type: "text_input",
              meta: {
                key: "current_therapy_provider_email",
                parent_question_id: 75,
              },
              options: [],
              answers: [],
            },
            {
              id: 79,
              title: "current_therapy_provider_name",
              question_type: "text_input",
              meta: {
                key: "current_therapy_provider_name",
                parent_question_id: 75,
              },
              options: [],
              answers: [],
            },
            {
              id: 80,
              title: "current_therapy_provider_no_of_visits",
              question_type: "text_input",
              meta: {
                key: "current_therapy_provider_no_of_visits",
                parent_question_id: 75,
              },
              options: [],
              answers: [],
            },
            {
              id: 81,
              title: "prev_therapy_services",
              question_type: "radio",
              meta: {
                key: "prev_therapy_services",
              },
              options: [
                {
                  id: 46,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 47,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 82,
              title: "prev_therapy_provider_address",
              question_type: "text_input",
              meta: {
                key: "prev_therapy_provider_address",
                parent_question_id: 81,
              },
              options: [],
              answers: [],
            },
            {
              id: 83,
              title: "prev_therapy_provider_phone",
              question_type: "text_input",
              meta: {
                key: "prev_therapy_provider_phone",
                parent_question_id: 81,
              },
              options: [],
              answers: [],
            },
            {
              id: 84,
              title: "prev_therapy_provider_email",
              question_type: "text_input",
              meta: {
                key: "prev_therapy_provider_email",
                parent_question_id: 81,
              },
              options: [],
              answers: [],
            },
            {
              id: 85,
              title: "prev_therapy_provider_name",
              question_type: "text_input",
              meta: {
                key: "prev_therapy_provider_name",
                parent_question_id: 81,
              },
              options: [],
              answers: [],
            },
            {
              id: 86,
              title: "prev_therapy_provider_no_of_visits",
              question_type: "text_input",
              meta: {
                key: "prev_therapy_provider_no_of_visits",
                parent_question_id: 81,
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 11,
      title: "Education",
      parent_section_id: null,
      meta: {
        key: "education",
      },
      sub_sections: [
        {
          id: 12,
          title: "Institute & Services",
          parent_section_id: 11,
          meta: {
            key: "institute_&_services",
          },
          questions: [
            {
              id: 87,
              title: "institue_school_list",
              question_type: "custom",
              meta: {
                key: "institue_school_list",
              },
              options: [],
              answers: [],
            },
            {
              id: 88,
              title: "institue_school_name",
              question_type: "custom_with_text",
              meta: {
                key: "institue_school_name",
                parent_question_id: 87,
              },
              options: [],
              answers: [],
            },
            {
              id: 89,
              title: "institue_school_system",
              question_type: "custom_with_text",
              meta: {
                key: "institue_school_system",
                parent_question_id: 87,
              },
              options: [],
              answers: [],
            },
            {
              id: 90,
              title: "institue_school_year",
              question_type: "custom_with_text",
              meta: {
                key: "institue_school_year",
                parent_question_id: 87,
              },
              options: [],
              answers: [],
            },
            {
              id: 91,
              title: "institue_school_grade",
              question_type: "custom_with_text",
              meta: {
                key: "institue_school_grade",
                parent_question_id: 87,
              },
              options: [],
              answers: [],
            },
            {
              id: 92,
              title: "institue_education_services",
              question_type: "custom_with_text",
              meta: {
                key: "institue_education_services",
                parent_question_id: 87,
              },
              options: [],
              answers: [],
            },
            {
              id: 93,
              title: "instiute_special_service_classified",
              question_type: "radio",
              meta: {
                key: "instiute_special_service_classified",
              },
              options: [
                {
                  id: 48,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 49,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 94,
              title: "institute_education_plan",
              question_type: "radio",
              meta: {
                key: "institute_education_plan?",
              },
              options: [
                {
                  id: 50,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 51,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 95,
              title: "institue_child_aide",
              question_type: "radio",
              meta: {
                key: "institue_child_aide",
              },
              options: [
                {
                  id: 52,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 53,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 96,
              title: "institute_speech_occupational_services",
              question_type: "radio_with_description",
              meta: {
                key: "institute_speech_occupational_services",
              },
              options: [
                {
                  id: 54,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 55,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 97,
              title: "institute_speech_occupational_service_desc",
              question_type: "text_input",
              meta: {
                key: "institue_school_name",
                parent_question_id: 96,
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 13,
          title: "Current Performance",
          parent_section_id: 11,
          meta: {
            key: "current_performance",
          },
          questions: [
            {
              id: 98,
              title: "performance_child_grade",
              question_type: "text_area",
              meta: {
                key: "performance_child_grade",
              },
              options: [],
              answers: [],
            },
            {
              id: 99,
              title: "performance_expelled_suspended",
              question_type: "radio_with_description",
              meta: {
                key: "performance_expelled_suspended",
              },
              options: [
                {
                  id: 56,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 57,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 100,
              title: "performance_expelled_suspended_desc",
              question_type: "text_area",
              meta: {
                key: "performance_expelled_suspended_desc",
                parent_question_id: 99,
              },
              options: [],
              answers: [],
            },
            {
              id: 101,
              title: "performance_acad_beh_func",
              question_type: "radio_with_description",
              meta: {
                key: "performance_acad_beh_func",
              },
              options: [
                {
                  id: 58,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 59,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 102,
              title: "performance_acad_beh_func_desc",
              question_type: "text_area",
              meta: {
                key: "performance_acad_beh_func_desc",
                parent_question_id: 101,
              },
              options: [],
              answers: [],
            },
            {
              id: 103,
              title: "performance_peer_relationship",
              question_type: "text_area",
              meta: {
                key: "performance_peer_relationship",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 14,
      title: "Family Background",
      parent_section_id: null,
      meta: {
        key: "family_background",
      },
      sub_sections: [
        {
          id: 15,
          title: "Caregiver Involvement",
          parent_section_id: 14,
          meta: {
            key: "caregiver_involvement",
          },
          questions: [
            {
              id: 104,
              title: "caregiver_parent_job_requirment",
              question_type: "radio_with_description",
              meta: {
                key: "caregiver_parent_job_requirment",
              },
              options: [
                {
                  id: 60,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 61,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 105,
              title: "caregiver_parent_job_requirment_desc",
              question_type: "text_area",
              meta: {
                key: "caregiver_parent_job_requirment_desc",
                parent_question_id: 104,
              },
              options: [],
              answers: [],
            },
            {
              id: 106,
              title: "caregiver_parental_participation",
              question_type: "radio",
              meta: {
                key: "caregiver_parental_participation",
              },
              options: [
                {
                  id: 62,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 63,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 107,
              title: "caregiver_adult_presence",
              question_type: "text_area",
              meta: {
                key: "caregiver_adult_presence",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 16,
          title: "Home & Family",
          parent_section_id: 14,
          meta: {
            key: "home_&_family",
          },
          questions: [
            {
              id: 108,
              title: "family_parent_marital_status",
              question_type: "radio",
              meta: {
                key: "family_parent_marital_status",
              },
              options: [
                {
                  id: 64,
                  title: "Married",
                  meta: {
                    key: "married",
                  },
                },
                {
                  id: 65,
                  title: "Civil Union",
                  meta: {
                    key: "civil_union",
                  },
                },
                {
                  id: 66,
                  title: "Remarried",
                  meta: {
                    key: "remarried",
                  },
                },
                {
                  id: 67,
                  title: "Divorced",
                  meta: {
                    key: "divorced",
                  },
                },
                {
                  id: 68,
                  title: "Separated",
                  meta: {
                    key: "separated",
                  },
                },
                {
                  id: 69,
                  title: "Widowed",
                  meta: {
                    key: "widowed",
                  },
                },
                {
                  id: 70,
                  title: "Single",
                  meta: {
                    key: "single",
                  },
                },
                {
                  id: 71,
                  title: "Cohabitants",
                  meta: {
                    key: "cohabitants",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 109,
              title: "family_home_daily_routine",
              question_type: "text_area",
              meta: {
                key: "family_home_daily_routine",
              },
              options: [],
              answers: [],
            },
            {
              id: 110,
              title: "family_home_type",
              question_type: "text_area",
              meta: {
                key: "family_home_type",
              },
              options: [],
              answers: [],
            },
            {
              id: 111,
              title: "family_financial_stressores",
              question_type: "radio_with_description",
              meta: {
                key: "family_financial_stressores",
              },
              options: [
                {
                  id: 72,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 73,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 112,
              title: "family_financial_stressores_desc",
              question_type: "text_area",
              meta: {
                key: "family_financial_stressores_desc",
                parent_question_id: 111,
              },
              options: [],
              answers: [],
            },
            {
              id: 113,
              title: "family_conflict",
              question_type: "radio_with_description",
              meta: {},
              options: [
                {
                  id: 74,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 75,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 114,
              title: "family_conflict_desc",
              question_type: "text_area",
              meta: {
                key: "family_conflict_desc",
                parent_question_id: 113,
              },
              options: [],
              answers: [],
            },
            {
              id: 115,
              title: "family_guardianship_agreement",
              question_type: "radio",
              meta: {
                key: "family_guardianship_agreement",
              },
              options: [
                {
                  id: 76,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 77,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 116,
              title: "family_patient_risk",
              question_type: "radio",
              meta: {},
              options: [
                {
                  id: 78,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 79,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 117,
              title: "family_patient_risk_desc",
              question_type: "text_area",
              meta: {
                key: "family_patient_risk_desc",
                parent_question_id: 116,
              },
              options: [],
              answers: [],
            },
            {
              id: 118,
              title: "home_family_sblings",
              question_type: "radio_with_description",
              meta: {
                key: "home_family_sblings",
              },
              options: [
                {
                  id: 80,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 81,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 119,
              title: "home_family_siblings_name",
              question_type: "text_input",
              meta: {
                key: "home_family_siblings_name",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 120,
              title: "home_family_siblings_age",
              question_type: "text_input",
              meta: {
                key: "home_family_siblings_age",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 121,
              title: "home_family_siblings_relationship",
              question_type: "text_input",
              meta: {
                key: "home_family_siblings_relationship",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 122,
              title: "home_family_siblings_school",
              question_type: "text_input",
              meta: {
                key: "home_family_siblings_school",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 123,
              title: "home_family_siblings_grade",
              question_type: "text_input",
              meta: {
                key: "home_family_siblings_grade",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 124,
              title: "family_siblings_special_need_desc",
              question_type: "text_area",
              meta: {
                key: "family_siblings_special_need_desc",
                parent_question_id: 118,
              },
              options: [],
              answers: [],
            },
            {
              id: 125,
              title: "family_siblings_need_intrest",
              question_type: "radio",
              meta: {
                key: "family_siblings_need_intrest",
                parent_question_id: 118,
              },
              options: [
                {
                  id: 82,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 83,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 126,
              title: "family_history_relationship",
              question_type: "custom",
              meta: {
                key: "family_history_relationship",
              },
              options: [],
              answers: [],
            },
            {
              id: 127,
              title: "family_relationship_person_name",
              question_type: "custom_with_text",
              meta: {
                key: "family_relationship_person_name",
                parent_question_id: 126,
              },
              options: [],
              answers: [],
            },
            {
              id: 128,
              title: "family_relationship_person_age",
              question_type: "custom_with_text",
              meta: {
                key: "family_relationship_person_age",
                parent_question_id: 126,
              },
              options: [],
              answers: [],
            },
            {
              id: 129,
              title: "family_relationship_person_relation",
              question_type: "custom_with_text",
              meta: {
                key: "family_relationship_person_relation",
                parent_question_id: 126,
              },
              options: [],
              answers: [],
            },
            {
              id: 130,
              title: "family_child_strengths",
              question_type: "text_input",
              meta: {
                key: "family_child_strengths",
              },
              options: [],
              answers: [],
            },
            {
              id: 131,
              title: "family_child_weaknesses",
              question_type: "text_input",
              meta: {
                key: "family_child_weaknesses",
              },
              options: [],
              answers: [],
            },
            {
              id: 132,
              title: "family_child_extracurriculars",
              question_type: "text_input",
              meta: {
                key: "family_child_extracurriculars",
              },
              options: [],
              answers: [],
            },
            {
              id: 133,
              title: "family_child_religious_involvment",
              question_type: "text_input",
              meta: {
                key: "family_child_religious_involvment",
              },
              options: [],
              answers: [],
            },
            {
              id: 134,
              title: "family_child_family_involvement",
              question_type: "text_input",
              meta: {
                key: "family_child_family_involvement",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 17,
      title: "Psychological History",
      parent_section_id: null,
      meta: {
        key: "psychological_history",
      },
      sub_sections: [
        {
          id: 18,
          title: "Psychological History Section",
          parent_section_id: 17,
          meta: {
            key: "psychological_history_section",
          },
          questions: [
            {
              id: 135,
              title: "psychological_history_symptoms",
              question_type: "radio_with_description",
              meta: {
                key: "psychological_history_symptoms",
              },
              options: [
                {
                  id: 84,
                  title: "autism_spectrum_disorders",
                  meta: {
                    key: "autism_spectrum_disorders",
                  },
                },
                {
                  id: 85,
                  title: "behaviour_problem",
                  meta: {
                    key: "behaviour_problem",
                  },
                },
                {
                  id: 86,
                  title: "learning_problem",
                  meta: {
                    key: "learning_problem",
                  },
                },
                {
                  id: 87,
                  title: "anxiety_disorder",
                  meta: {
                    key: "anxiety_disorder",
                  },
                },
                {
                  id: 88,
                  title: "attention_problem",
                  meta: {
                    key: "attention_problem",
                  },
                },
                {
                  id: 89,
                  title: "intellectual_disability",
                  meta: {
                    key: "intellectual_disability",
                  },
                },
                {
                  id: 90,
                  title: "clinical_depression",
                  meta: {
                    key: "clinical_depression",
                  },
                },
                {
                  id: 91,
                  title: "psychosis_schizophrenia",
                  meta: {
                    key: "psychosis_schizophrenia",
                  },
                },
                {
                  id: 92,
                  title: "bipolar_disorde",
                  meta: {
                    key: "bipolar_disorde",
                  },
                },
                {
                  id: 93,
                  title: "substance_abuse",
                  meta: {
                    key: "substance_abuse",
                  },
                },
                {
                  id: 94,
                  title: "Other",
                  meta: {
                    key: "other",
                  },
                },
                {
                  id: 95,
                  title: "None",
                  meta: {
                    key: "none",
                  },
                },
              ],
              answers: [
                {
                  id: 135,
                  statement: null,
                  option_id: 95,
                  meta: {
                    key: "none",
                  },
                },
              ],
            },
            {
              id: 136,
              title: "psychological_history_symptoms_desc",
              question_type: "text_area",
              meta: {
                key: "psychological_history_symptoms_desc",
                parent_question_id: 135,
              },
              options: [],
              answers: [],
            },
            {
              id: 137,
              title: "psychological_history_outside_evaluation",
              question_type: "radio",
              meta: {
                key: "psychological_history_outside_evaluation",
              },
              options: [
                {
                  id: 96,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 97,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 138,
              title: "psychological_history_child_hospitalized",
              question_type: "radio",
              meta: {
                key: "psychological_history_child_hospitalized",
              },
              options: [
                {
                  id: 98,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 99,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 139,
              title: "psychological_history_psychological_trauma",
              question_type: "radio_with_description",
              meta: {
                key: "psychological_history_psychological_trauma",
              },
              options: [
                {
                  id: 100,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 101,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 140,
              title: "psychological_history_psychological_trauma_desc",
              question_type: "text_area",
              meta: {
                key: "psychological_history_psychological_trauma_desc",
                parent_question_id: 139,
              },
              options: [],
              answers: [],
            },
            {
              id: 141,
              title: "psychological_history_helpful_understanding",
              question_type: "text_area",
              meta: {
                key: "psychological_history_helpful_understanding",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 19,
      title: "Birth & Development",
      parent_section_id: null,
      meta: {
        key: "birth_&_development",
      },
      sub_sections: [
        {
          id: 20,
          title: "Birth Information",
          parent_section_id: 19,
          meta: {
            key: "birth_information",
          },
          questions: [
            {
              id: 142,
              title: "birth_info_mother_parental_care",
              question_type: "radio",
              meta: {
                key: "birth_info_mother_parental_care",
              },
              options: [
                {
                  id: 102,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 103,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 143,
              title: "birth_info_pregnancy_complication",
              question_type: "radio_with_description",
              meta: {
                key: "birth_info_pregnancy_complication",
              },
              options: [
                {
                  id: 104,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 105,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 144,
              title: "birth_info_pregnancy_complication_desc",
              question_type: "text_area",
              meta: {
                key: "birth_info_pregnancy_complication_desc",
                parent_question_id: 143,
              },
              options: [],
              answers: [],
            },
            {
              id: 145,
              title: "birth_info_term",
              question_type: "radio_with_description",
              meta: {
                key: "birth_info_term",
              },
              options: [
                {
                  id: 106,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 107,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 146,
              title: "birth_info_term_desc",
              question_type: "text_area",
              meta: {
                key: "birth_info_term_desc",
                parent_question_id: 145,
              },
              options: [],
              answers: [],
            },
            {
              id: 147,
              title: "birth_info_delivery_question_type",
              question_type: "radio",
              meta: {
                key: "birth_info_delivery_question_type",
              },
              options: [
                {
                  id: 108,
                  title: "Spontaneous",
                  meta: {
                    key: "spontaneous",
                  },
                },
                {
                  id: 109,
                  title: "Induced Vaginal",
                  meta: {
                    key: "induced_vaginal",
                  },
                },
                {
                  id: 110,
                  title: "C-Section",
                  meta: {
                    key: "c-section",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 148,
              title: "birth_info_delivery_complications",
              question_type: "radio_with_description",
              meta: {
                key: "birth_info_delivery_complications",
              },
              options: [
                {
                  id: 111,
                  title: "yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 112,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 149,
              title: "birth_info_delivery_complications_desc",
              question_type: "text_area",
              meta: {
                key: "birth_info_delivery_complications_desc",
                parent_question_id: 148,
              },
              options: [],
              answers: [],
            },
            {
              id: 150,
              title: "birth_info_child_lbs",
              question_type: "text_input",
              meta: {
                key: "birth_info_child_lbs",
              },
              options: [],
              answers: [],
            },
            {
              id: 151,
              title: "birth_info_child_oz",
              question_type: "text_input",
              meta: {
                key: "birth_info_child_oz",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 21,
          title: "Development Background",
          parent_section_id: 19,
          meta: {
            key: "development_background",
          },
          questions: [
            {
              id: 152,
              title: "dev_background_birth_condition",
              question_type: "radio_with_description",
              meta: {
                key: "dev_background_birth_condition",
              },
              options: [
                {
                  id: 113,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 114,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 153,
              title: "dev_background_birth_condition_desc",
              question_type: "text_area",
              meta: {
                key: "dev_background_birth_condition_desc",
                parent_question_id: 152,
              },
              options: [],
              answers: [],
            },
            {
              id: 154,
              title: "dev_background_birth_milestone",
              question_type: "radio_with_description",
              meta: {
                key: "dev_background_birth_milestone",
              },
              options: [
                {
                  id: 115,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 116,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 155,
              title: "dev_background_birth_milestone_desc",
              question_type: "text_area",
              meta: {
                key: "dev_background_birth_milestone_desc",
                parent_question_id: 154,
              },
              options: [],
              answers: [],
            },
            {
              id: 156,
              title: "dev_background_development_disability",
              question_type: "radio_with_description",
              meta: {
                key: "dev_background_development_disability",
              },
              options: [
                {
                  id: 117,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 118,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 157,
              title: "dev_background_development_disability_desc",
              question_type: "text_area",
              meta: {
                key: "dev_background_development_disability_desc",
                parent_question_id: 156,
              },
              options: [],
              answers: [],
            },
            {
              id: 158,
              title: "dev_background_crawling_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_crawling_age",
              },
              options: [],
              answers: [],
            },
            {
              id: 159,
              title: "dev_background_sitting_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_sitting_age",
              },
              options: [],
              answers: [],
            },
            {
              id: 160,
              title: "dev_background_walking_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_walking_age",
              },
              options: [],
              answers: [],
            },
            {
              id: 161,
              title: "dev_background_sleeping_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_sleeping_age",
              },
              options: [],
              answers: [],
            },
            {
              id: 162,
              title: "dev_background_eating_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_eating_age",
              },
              options: [],
              answers: [],
            },
            {
              id: 163,
              title: "dev_background_talking_age",
              question_type: "text_input",
              meta: {
                key: "dev_background_talking_age",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 22,
      title: "Current Behaviours",
      parent_section_id: null,
      meta: {
        key: "current_behaviours",
      },
      sub_sections: [
        {
          id: 23,
          title: "General Behaviour",
          parent_section_id: 22,
          meta: {
            key: "general_behaviour",
          },
          questions: [
            {
              id: 176,
              title: "gen_behaviour_fall_risk",
              question_type: "radio",
              meta: {
                key: "gen_behaviour_fall_risk",
              },
              options: [
                {
                  id: 150,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 151,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 164,
              title: "gen_beh_agression",
              question_type: "optional",
              meta: {
                key: "gen_beh_agression",
              },
              options: [
                {
                  id: 119,
                  title: "hitting",
                  meta: {
                    key: "gen_beh_agression_hitting",
                  },
                },
                {
                  id: 120,
                  title: "spitting",
                  meta: {
                    key: "gen_beh_agression_spitting",
                  },
                },
                {
                  id: 121,
                  title: "kicking",
                  meta: {
                    key: "gen_beh_agression_kicking",
                  },
                },
                {
                  id: 122,
                  title: "head_butting",
                  meta: {
                    key: "gen_beh_agression_head_butting",
                  },
                },
                {
                  id: 123,
                  title: "pulling_teath",
                  meta: {
                    key: "gen_beh_agression_pulling_teath",
                  },
                },
                {
                  id: 124,
                  title: "biting",
                  meta: {
                    key: "gen_beh_agression_biting",
                  },
                },
                {
                  id: 125,
                  title: "cutting_buring",
                  meta: {
                    key: "gen_beh_agression_biting",
                  },
                },
                {
                  id: 126,
                  title: "pinching",
                  meta: {
                    key: "gen_beh_agression_pinching",
                  },
                },
                {
                  id: 127,
                  title: "scratching",
                  meta: {
                    key: "gen_beh_agression_scratching",
                  },
                },
                {
                  id: 128,
                  title: "other",
                  meta: {
                    key: "gen_beh_agression_other",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 165,
              title: "gen_beh_agression_desc",
              question_type: "text_area",
              meta: {
                key: "gen_beh_agression_desc",
                parent_question_id: 164,
              },
              options: [],
              answers: [],
            },
            {
              id: 166,
              title: "gen_beh_self_injorious",
              question_type: "optional",
              meta: {
                key: "gen_beh_self_injorious",
              },
              options: [
                {
                  id: 129,
                  title: "hitting_self",
                  meta: {
                    key: "gen_beh_self_injorious_hitting",
                  },
                },
                {
                  id: 130,
                  title: "kicking_self",
                  meta: {
                    key: "gen_beh_self_injorious_kicking",
                  },
                },
                {
                  id: 131,
                  title: "biting_self",
                  meta: {
                    key: "gen_beh_self_injorious_biting",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 167,
              title: "gen_beh_self_injorious_desc",
              question_type: "text_area",
              meta: {
                key: "gen_beh_self_injorious_desc",
                parent_question_id: 166,
              },
              options: [],
              answers: [],
            },
            {
              id: 168,
              title: "gen_beh_property_destruction",
              question_type: "optional",
              meta: {
                key: "gen_beh_property_destruction",
              },
              options: [],
              answers: [],
            },
            {
              id: 169,
              title: "gen_beh_property_destruction_desc",
              question_type: "text_area",
              meta: {
                key: "gen_beh_property_destruction_desc",
                parent_question_id: 168,
              },
              options: [],
              answers: [],
            },
            {
              id: 170,
              title: "gen_beh_sexualize_behaviour",
              question_type: "optional",
              meta: {
                key: "gen_beh_sexualize_behaviour",
              },
              options: [],
              answers: [],
            },
            {
              id: 171,
              title: "gen_beh_sexualize_behaviour_desc",
              question_type: "text_area",
              meta: {
                key: "gen_beh_sexualize_behaviour_desc",
                parent_question_id: 170,
              },
              options: [],
              answers: [],
            },
            {
              id: 172,
              title: "gen_beh_multi_problems_list",
              question_type: "optional",
              meta: {
                key: "gen_beh_multi_problems_list",
              },
              options: [
                {
                  id: 132,
                  title: "self_urinating_defacating",
                  meta: {
                    key: "gen_beh_problems_self_urinating",
                  },
                },
                {
                  id: 133,
                  title: "fecal_smearing",
                  meta: {
                    key: "gen_beh_problems_fecal_smearing",
                  },
                },
                {
                  id: 134,
                  title: "bedwetting",
                  meta: {
                    key: "gen_beh_problems_bedwetting",
                  },
                },
                {
                  id: 135,
                  title: "difficulty_in_toileting",
                  meta: {
                    key: "gen_beh_problems_difficulty_in_toileting",
                  },
                },
                {
                  id: 136,
                  title: "vocalization",
                  meta: {
                    key: "gen_beh_problems_vocalization",
                  },
                },
                {
                  id: 137,
                  title: "problem_with_eating",
                  meta: {
                    key: "gen_beh_problems_with_eating",
                  },
                },
                {
                  id: 138,
                  title: "tantrums",
                  meta: {
                    key: "gen_beh_problems_tantrums",
                  },
                },
                {
                  id: 139,
                  title: "screaming",
                  meta: {
                    key: "gen_beh_problems_screaming",
                  },
                },
                {
                  id: 140,
                  title: "repetative_behaviour",
                  meta: {
                    key: "gen_beh_problems_repetative_behaviour",
                  },
                },
                {
                  id: 141,
                  title: "rectal_digging",
                  meta: {
                    key: "gen_beh_problems_rectal_digging",
                  },
                },
                {
                  id: 142,
                  title: "setting_fire",
                  meta: {
                    key: "gen_beh_problems_setting_fire",
                  },
                },
                {
                  id: 143,
                  title: "sadness_depression",
                  meta: {
                    key: "gen_beh_problems_sadness_depression",
                  },
                },
                {
                  id: 144,
                  title: "difficulty_concentrating",
                  meta: {
                    key: "gen_beh_problems_difficulty_concentrating",
                  },
                },
                {
                  id: 145,
                  title: "hallucinations",
                  meta: {
                    key: "gen_beh_problems_hallucinations",
                  },
                },
                {
                  id: 146,
                  title: "dellusions",
                  meta: {
                    key: "gen_beh_problems_dellusions",
                  },
                },
                {
                  id: 147,
                  title: "other",
                  meta: {
                    key: "gen_beh_problems_other",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 173,
              title: "gen_beh_multi_problems_list_desc",
              question_type: "text_area",
              meta: {
                key: "gen_beh_multi_problems_list_desc",
                parent_question_id: 172,
              },
              options: [],
              answers: [],
            },
            {
              id: 174,
              title: "gen_behaviour_full_day_supervision",
              question_type: "radio_with_description",
              meta: {
                key: "gen_behaviour_full_day_supervision",
              },
              options: [
                {
                  id: 148,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 149,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 175,
              title: "gen_behaviour_full_day_supervision_desc",
              question_type: "text_area",
              meta: {
                key: "gen_behaviour_full_day_supervision_desc",
                parent_question_id: 174,
              },
              options: [],
              answers: [],
            },
            {
              id: 177,
              title: "gen_behaviour_patient_night_awake",
              question_type: "radio_with_description",
              meta: {
                key: "gen_behaviour_patient_night_awake",
              },
              options: [
                {
                  id: 152,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 153,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 178,
              title: "gen_behaviour_patient_night_awake_desc",
              question_type: "text_area",
              meta: {
                key: "gen_behaviour_patient_night_awake_desc",
                parent_question_id: 177,
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 24,
          title: "Suicide & Self Harm",
          parent_section_id: 22,
          meta: {
            key: "suicide_&_self_harm",
          },
          questions: [
            {
              id: 179,
              title: "suicide_ideations",
              question_type: "radio_with_description",
              meta: {
                key: "suicide_ideations",
              },
              options: [
                {
                  id: 154,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 155,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 180,
              title: "suicide_ideations_desc",
              question_type: "text_area",
              meta: {
                key: "suicide_ideations_desc",
                parent_question_id: 179,
              },
              options: [],
              answers: [],
            },
            {
              id: 181,
              title: "suicide_attempted",
              question_type: "radio_with_description",
              meta: {
                key: "suicide_attempted",
              },
              options: [
                {
                  id: 156,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 157,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 182,
              title: "suicide_attempted_desc",
              question_type: "text_area",
              meta: {
                key: "suicide_attempted_desc",
                parent_question_id: 181,
              },
              options: [],
              answers: [],
            },
            {
              id: 183,
              title: "homocidal_ideation",
              question_type: "radio_with_description",
              meta: {
                key: "homocidal_ideation",
              },
              options: [
                {
                  id: 158,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 159,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 184,
              title: "homocidal_ideation_desc",
              question_type: "text_area",
              meta: {
                key: "homocidal_ideation_desc",
                parent_question_id: 183,
              },
              options: [],
              answers: [],
            },
            {
              id: 185,
              title: "child_mood_range",
              question_type: "radio_with_description",
              meta: {
                key: "child_mood_range",
              },
              options: [
                {
                  id: 160,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 161,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 186,
              title: "child_mood_range_desc",
              question_type: "text_area",
              meta: {
                key: "child_mood_range_desc",
                parent_question_id: 185,
              },
              options: [],
              answers: [],
            },
            {
              id: 187,
              title: "intellectual_impairments",
              question_type: "radio_with_description",
              meta: {
                key: "intellectual_impairments",
              },
              options: [
                {
                  id: 162,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 163,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 188,
              title: "intellectual_impairments_desc",
              question_type: "text_area",
              meta: {
                key: "intellectual_impairments_desc",
                parent_question_id: 187,
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 25,
          title: "Sensory Integeration",
          parent_section_id: 22,
          meta: {
            key: "sensory_integeration",
          },
          questions: [
            {
              id: 189,
              title: "sensory_int_child_hard_hearing",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_hard_hearing",
              },
              options: [
                {
                  id: 164,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 165,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 190,
              title: "sensory_int_child_hard_hearing_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_hard_hearing_desc",
                parent_question_id: 189,
              },
              options: [],
              answers: [],
            },
            {
              id: 191,
              title: "sensory_int_child_hypersensitive",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_hypersensitive",
              },
              options: [
                {
                  id: 166,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 167,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 192,
              title: "sensory_int_child_hypersensitive_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_hypersensitive_desc",
                parent_question_id: 191,
              },
              options: [],
              answers: [],
            },
            {
              id: 193,
              title: "sensory_int_child_inital_feeling",
              question_type: "radio",
              meta: {
                key: "sensory_int_child_inital_feeling",
              },
              options: [
                {
                  id: 168,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 169,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 194,
              title: "sensory_int_child_affection",
              question_type: "radio",
              meta: {
                key: "sensory_int_child_affection",
              },
              options: [
                {
                  id: 170,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 171,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 195,
              title: "sensory_int_child_rythmic_activity",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_rythmic_activity",
              },
              options: [
                {
                  id: 172,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 173,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 196,
              title: "sensory_int_child_rythmic_activity_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_rythmic_activity_desc",
                parent_question_id: 195,
              },
              options: [],
              answers: [],
            },
            {
              id: 197,
              title: "sensory_int_child_walkthrough",
              question_type: "radio",
              meta: {
                key: "sensory_int_child_walkthrough",
              },
              options: [
                {
                  id: 174,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 175,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 198,
              title: "sensory_int_child_oddities",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_oddities",
              },
              options: [
                {
                  id: 176,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 177,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 199,
              title: "sensory_int_child_oddities_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_oddities_desc",
                parent_question_id: 198,
              },
              options: [],
              answers: [],
            },
            {
              id: 200,
              title: "sensory_int_child_food_craving",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_food_craving",
              },
              options: [
                {
                  id: 178,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 179,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 201,
              title: "sensory_int_child_food_craving_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_food_craving_desc",
                parent_question_id: 200,
              },
              options: [],
              answers: [],
            },
            {
              id: 202,
              title: "sensory_int_child_food_intake",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_child_food_intake",
              },
              options: [
                {
                  id: 180,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 181,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 203,
              title: "sensory_int_child_food_intake_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_food_intake_desc",
                parent_question_id: 202,
              },
              options: [],
              answers: [],
            },
            {
              id: 204,
              title: "sensory_int_child_playing_skill",
              question_type: "text_area",
              meta: {
                key: "sensory_int_child_playing_skill",
              },
              options: [],
              answers: [],
            },
            {
              id: 205,
              title: "sensory_int_reaction_on_interuption",
              question_type: "text_area",
              meta: {
                key: "sensory_int_reaction_on_interuption",
              },
              options: [],
              answers: [],
            },
            {
              id: 206,
              title: "sensory_int_ritual_engagment",
              question_type: "radio_with_description",
              meta: {
                key: "sensory_int_ritual_engagment",
              },
              options: [
                {
                  id: 182,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 183,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 207,
              title: "sensory_int_ritual_engagment_desc",
              question_type: "text_area",
              meta: {
                key: "sensory_int_ritual_engagment_desc",
                parent_question_id: 206,
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 26,
          title: "Communication",
          parent_section_id: 22,
          meta: {
            key: "communication",
          },
          questions: [
            {
              id: 208,
              title: "comm_talk_pattern",
              question_type: "radio_with_description",
              meta: {
                key: "comm_talk_pattern",
              },
              options: [
                {
                  id: 184,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 185,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 209,
              title: "comm_talk_pattern_desc",
              question_type: "text_area",
              meta: {
                key: "comm_talk_pattern_desc",
                parent_question_id: 208,
              },
              options: [],
              answers: [],
            },
            {
              id: 210,
              title: "comm_immitate_phrase",
              question_type: "radio",
              meta: {
                key: "comm_immitate_phrase",
              },
              options: [
                {
                  id: 186,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 187,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 211,
              title: "comm_vocabulary_proportion",
              question_type: "radio",
              meta: {
                key: "comm_vocabulary_proportion",
              },
              options: [
                {
                  id: 188,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 189,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 212,
              title: "comm_use_of_yes",
              question_type: "radio_with_description",
              meta: {
                key: "comm_use_of_yes",
              },
              options: [
                {
                  id: 190,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 191,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 213,
              title: "comm_use_of_yes_desc",
              question_type: "text_area",
              meta: {
                key: "comm_use_of_yes_desc",
                parent_question_id: 212,
              },
              options: [],
              answers: [],
            },
            {
              id: 214,
              title: "comm_use_of_no",
              question_type: "radio_with_description",
              meta: {
                key: "comm_use_of_no",
              },
              options: [
                {
                  id: 192,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 193,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 215,
              title: "comm_use_of_no_desc",
              question_type: "text_area",
              meta: {
                key: "comm_use_of_no_desc",
                parent_question_id: 214,
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 27,
          title: "Self Care",
          parent_section_id: 22,
          meta: {
            key: "self_care",
          },
          questions: [
            {
              id: 216,
              title: "self_care_able_to_dress",
              question_type: "radio",
              meta: {
                key: "self_care_able_to_dress",
              },
              options: [
                {
                  id: 194,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 195,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 217,
              title: "self_care_able_to_dress_explaination",
              question_type: "text_area",
              meta: {
                key: "self_care_able_to_dress_explaination",
              },
              options: [],
              answers: [],
            },
            {
              id: 218,
              title: "self_care_feed",
              question_type: "radio",
              meta: {
                key: "self_care_feed",
              },
              options: [
                {
                  id: 196,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 197,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 219,
              title: "self_care_feed_explaination",
              question_type: "text_area",
              meta: {
                key: "self_care_feed_explaination",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 28,
          title: "Social Skills",
          parent_section_id: 22,
          meta: {
            key: "social_skills",
          },
          questions: [
            {
              id: 220,
              title: "play_well",
              question_type: "radio",
              meta: {
                key: "play_well",
              },
              options: [
                {
                  id: 198,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 199,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 221,
              title: "play_well_explaination",
              question_type: "text_area",
              meta: {
                key: "play_well_explaination",
              },
              options: [],
              answers: [],
            },
            {
              id: 222,
              title: "toy_play",
              question_type: "radio",
              meta: {
                key: "toy_play",
              },
              options: [
                {
                  id: 200,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 201,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 223,
              title: "toy_play_explaination",
              question_type: "text_area",
              meta: {
                key: "toy_play_explaination",
              },
              options: [],
              answers: [],
            },
            {
              id: 224,
              title: "eye_contact",
              question_type: "radio",
              meta: {
                key: "eye_contact",
              },
              options: [
                {
                  id: 202,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 203,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 225,
              title: "eye_contact_explaination",
              question_type: "text_area",
              meta: {
                key: "eye_contact_explaination",
              },
              options: [],
              answers: [],
            },
            {
              id: 226,
              title: "child_interest",
              question_type: "radio",
              meta: {
                key: "child_interest",
              },
              options: [
                {
                  id: 204,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 205,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 227,
              title: "child_interest_explaination",
              question_type: "text_area",
              meta: {
                key: "child_interest_explaination",
              },
              options: [],
              answers: [],
            },
            {
              id: 228,
              title: "child_follow_direction",
              question_type: "radio",
              meta: {
                key: "child_follow_direction",
              },
              options: [
                {
                  id: 206,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 207,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 229,
              title: "child_follow_direction_explaination",
              question_type: "text_area",
              meta: {
                key: "child_follow_direction_explaination",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 29,
          title: "Drug & Alcohol Profile",
          parent_section_id: 22,
          meta: {
            key: "drug_&_alcohol_profile",
          },
          questions: [
            {
              id: 230,
              title: "drug_substance_abuse_problem",
              question_type: "radio",
              meta: {
                key: "drug_substance_abuse_problem",
              },
              options: [
                {
                  id: 208,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 209,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 231,
              title: "drug_substance_abuse_issue",
              question_type: "radio_with_description",
              meta: {
                key: "drug_substance_abuse_issue",
              },
              options: [
                {
                  id: 210,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 211,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 232,
              title: "drug_substance_abuse_drug_list",
              question_type: "text_area",
              meta: {
                key: "drug_substance_abuse_issue_desc",
                parent_question_id: 231,
              },
              options: [],
              answers: [],
            },
            {
              id: 233,
              title: "drug_substance_abuse_issue2_desc",
              question_type: "text_area",
              meta: {
                key: "drug_substance_abuse_issue2_desc",
                parent_question_id: 231,
              },
              options: [],
              answers: [],
            },
            {
              id: 234,
              title: "drug_substance_often_used",
              question_type: "text_area",
              meta: {
                key: "drug_substance_often_used",
              },
              options: [],
              answers: [],
            },
          ],
        },
        {
          id: 30,
          title: "Legal Status",
          parent_section_id: 22,
          meta: {
            key: "legal_status",
          },
          questions: [
            {
              id: 235,
              title: "legal_issues_past_current",
              question_type: "radio_with_description",
              meta: {
                key: "legal_issues_past_current",
              },
              options: [
                {
                  id: 212,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 213,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 236,
              title: "legal_issues_past_current_desc",
              question_type: "text_area",
              meta: {
                key: "legal_issues_past_current_desc",
                parent_question_id: 235,
              },
              options: [],
              answers: [],
            },
            {
              id: 237,
              title: "legal_issues_court_date",
              question_type: "radio_with_description",
              meta: {
                key: "legal_issues_court_date",
              },
              options: [
                {
                  id: 214,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 215,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 238,
              title: "legal_issues_court_date_desc",
              question_type: "text_area",
              meta: {
                key: "legal_issues_court_date_desc",
                parent_question_id: 237,
              },
              options: [],
              answers: [],
            },
            {
              id: 239,
              title: "legal_issues_child_on_probation",
              question_type: "radio",
              meta: {
                key: "legal_issues_child_on_probation",
              },
              options: [
                {
                  id: 216,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 217,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 240,
              title: "legal_issues_past_convictions",
              question_type: "radio",
              meta: {
                key: "legal_issues_past_convictions",
              },
              options: [
                {
                  id: 218,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 219,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 241,
              title: "legal_issues_probation_violations",
              question_type: "radio",
              meta: {
                key: "legal_issues_probation_violations",
              },
              options: [
                {
                  id: 220,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 221,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 242,
              title: "legal_issues_child_goals_list",
              question_type: "text_area",
              meta: {
                key: "legal_issues_child_goals_list",
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
    {
      id: 31,
      title: "Discipline Information",
      parent_section_id: null,
      meta: {
        key: "discipline_information",
      },
      sub_sections: [
        {
          id: 32,
          title: "Discipline Information Section",
          parent_section_id: 31,
          meta: {
            key: "discipline_information_section",
          },
          questions: [
            {
              id: 243,
              title: "disc_info_guard1_name",
              question_type: "text_area",
              meta: {
                key: "disc_info_guard1_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 244,
              title: "disc_info_parent_guard1_relation",
              question_type: "text_area",
              meta: {
                key: "disc_info_parent_guard1_relation",
              },
              options: [],
              answers: [],
            },
            {
              id: 245,
              title: "disc_info_guard2_name",
              question_type: "text_area",
              meta: {
                key: "disc_info_guard2_name",
              },
              options: [],
              answers: [],
            },
            {
              id: 246,
              title: "disc_info_parent_guard2_relation",
              question_type: "text_area",
              meta: {
                key: "disc_info_parent_guard2_relation",
              },
              options: [],
              answers: [],
            },
            {
              id: 247,
              title: "disc_info_disc_methods",
              question_type: "radio_with_description",
              meta: {
                key: "disc_info_disc_methods",
              },
              options: [
                {
                  id: 222,
                  title: "timeout",
                  meta: {
                    key: "timeout",
                  },
                },
                {
                  id: 223,
                  title: "assigning_chores",
                  meta: {
                    key: "assigning_chores",
                  },
                },
                {
                  id: 224,
                  title: "physical_punishment",
                  meta: {
                    key: "physical_punishment",
                  },
                },
                {
                  id: 225,
                  title: "isolation",
                  meta: {
                    key: "isolation",
                  },
                },
                {
                  id: 226,
                  title: "Other",
                  meta: {
                    key: "other",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 248,
              title: "disc_info_disc_methods_desc",
              question_type: "text_area",
              meta: {
                key: "disc_info_disc_methods_desc",
                parent_question_id: 247,
              },
              options: [],
              answers: [],
            },
            {
              id: 249,
              title: "disc_info_cultural_beliefs",
              question_type: "radio_with_description",
              meta: {
                key: "disc_info_cultural_beliefs",
              },
              options: [
                {
                  id: 227,
                  title: "Yes",
                  meta: {
                    key: "yes",
                  },
                },
                {
                  id: 228,
                  title: "No",
                  meta: {
                    key: "no",
                  },
                },
              ],
              answers: [],
            },
            {
              id: 250,
              title: "disc_info_cultural_beliefs_desc",
              question_type: "text_area",
              meta: {
                key: "disc_info_cultural_beliefs_desc",
                parent_question_id: 249,
              },
              options: [],
              answers: [],
            },
          ],
        },
      ],
    },
  ],
};

export const formData = learnnMeForm.sections.map((item) => {
  return {
    id: 1,
    title: item.title,
    parent_section_id: null,
    meta: {
      key: item.meta.key,
    },
    sub_sections: item.sub_sections.map((subItem) => {
      return {
        sectionTitle: subItem.title,
        meta: {
          key: subItem.meta.key,
        },
        questions: subItem.questions.map((question) => {
          switch (question.question_type) {
            case "text_input":
              return {
                id: question.id,
                title: question.title,
                placeholder: "",
                label: "",
                type: question.question_type,
                meta: {
                  key: question.meta.key,
                },
                options: [],
                answers: question.answers.map((answer) => answer),
              };
            case "radio":
              return {
                id: question.id,

                title: question.title,
                placeholder: "",
                label: "",
                type: question.question_type,
                options: question.options.map((option) => {
                  return {
                    option_id: option.id,
                    label: "",
                    title: option.title,
                    meta: {
                      key: option.meta.key,
                    },
                  };
                }),

                answers: question.answers.map((answer) => answer),
              };
            case "text_area":
              return {
                id: question.id,

                title: question.title,
                placeholder: "",
                label: "",
                meta: {
                  key: question.meta.key,
                  parent_question_id: question.meta.parent_question_id,
                },
                type: question.question_type,
                options: [],
                answers: question.answers.map((answer) => answer),
              };
            case "custom":
              return {
                id: question.id,

                title: question.title,
                placeholder: "",
                label: "",
                meta: {
                  key: question.meta.key,
                },
                type: question.question_type,
                options: [],
                answers: question.answers.map((answer) => answer),
              };
            case "number":
              return {
                id: question.id,

                title: question.title,
                placeholder: "",
                label: "",
                meta: {
                  key: question.meta.key,
                },
                type: question.question_type,
                options: [],
                answers: [
                  {
                    statement: "",
                  },
                ],
              };
            case "radio_with_description":
              return {
                id: question.id,

                title: question.title,
                placeholder: "",
                label: "",
                type: question.question_type,
                meta: {
                  key: question.meta.key,
                },
                options: question.options.map((option) => {
                  return {
                    option_id: option.id,
                    label: "",
                    title: option.title,
                    meta: {
                      key: option.meta.key,
                    },
                  };
                }),

                answers: question.answers.map((answer) => answer),
              };
            case "optional":
              return {
                id: question.id,
                title: question.title,
                placeholder: "",
                label: "",
                type: question.question_type,
                meta: {
                  key: question.meta.key,
                },
                options: question.options.map((option) => {
                  return {
                    id: option.id,
                    label: "",
                    title: option.title,
                    meta: {
                      key: option.meta.key,
                    },
                  };
                }),

                answers: question.answers.map((answer) => answer),
              };
          }
        }),
      };
    }),
  };
});
