// const questionInfoGeneric = learnnMeForm.sections[0].sub_sections[2].questions.map(
//   (obj) => {
//     return {
//       id: obj.id,
//       title: obj.title,
//       placeholder: "",
//       label: "",
//       wrapperClassName: "",
//       options: obj.options?.map((option) => {
//         return { id: option.id, title: "", key: option.title };
//       }),

//       gridView: true,
//     };
//   }
// );
// console.log(questionInfoGeneric);
export const autoFormatPhoneNumber = (phoneNumberString) => {
  try {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/);
    // let intlCode = match[1] ? "+1 " : "";
    return [
      // intlCode,
      match[2] ? "(" : "",
      match[2],
      match[3] ? ") " : "",
      match[3],
      match[4] ? "-" : "",
      match[4],
    ].join("");
  } catch (err) {
    return "";
  }
};

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-zA-Z0-9_%+-]+(?:\.[a-zA-Z0-9_%+-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@((([a-zA-Z][a-zA-Z]{0,63}[a-zA-Z0-9]{0,63}\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email) => {
  return emailRegex.test(email);
};
export const validateName = (name) => {
  const fullNameRegex = /^[a-zA-Z\s'.,-]+$/;
  const isValid = fullNameRegex.test(name);

  if (isValid) {
    return true;
  } else {
    return false;
  }
};
export const NumberFormat = (input, template) => {
  try {
    let j = 0;
    let plaintext = "";
    let countj = 0;
    while (j < template.length) {
      if (countj > input.length - 1) {
        template = template.substring(0, j);
        break;
      }

      if (template[j] == input[countj]) {
        j++;
        countj++;
        continue;
      }

      if (template[j] == "x") {
        if (!isNaN(parseInt(input[countj]))) {
          // Check if the input is a number
          template =
            template.substring(0, j) +
            input[countj] +
            template.substring(j + 1);
          plaintext = plaintext + input[countj];
          countj++; // Move to the next character in the input
        } else {
          return ""; // Return an empty string if input is not a number
        }
      }
      j++;
    }

    return template;
  } catch {
    return "";
  }
};

// export const getID = (questionTitle, optionTitle, option) => {
//   const requiredQuestion = GeneralResponse.questions.find((question) => {
//     return question.title === questionTitle;
//   });
//   if (!option) {
//     return {
//       questionID: requiredQuestion.id,
//       parentID: requiredQuestion?.meta?.parent_question_id,
//     };
//   } else {
//     const requiredOption = requiredQuestion.options.find((option) => {
//       return option.title === optionTitle;
//     });

//     return {
//       questionID: requiredQuestion.id,
//       parentID: requiredQuestion?.meta?.parent_question_id,
//       optionID: requiredOption.id,
//     };
//   }
// };

// export const getQuestionObject = (questionTitle) => {
//   return GeneralResponse.questions.find((question) => {
//     question.title === questionTitle;
//   });
// };

// console.log(getID("general_therapy_recieved_desc", "", false));
// console.log(getQuestionObject("general_therapy_recieved_desc"));
