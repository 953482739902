import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ClinicalDirectorForm from "./ClinicalDirectorForm";
import TechnicianForm from "./TechnicianForm";
import ForgotPasswordForm from "../../pages/ForgotPassword/ForgotPasswordForm";
const UserTypeTabs = ({
  setCreds,
  creds,
  setTab,
  setRemember,
  remember,
  forgotPassword,
  tab,
  emailError,
}) => {
  return (
    <Tabs
      defaultActiveKey={tab ?? "director"}
      id=""
      className="w-100 login-form-tabs"
      fill
      onClick={(e) =>
        setTab(
          e.target.innerText === "Clinicial Director"
            ? "director"
            : "technician"
        )
      }
    >
      <Tab eventKey="director" title="Clinicial Director">
        {!forgotPassword ? (
          <ClinicalDirectorForm
            setCreds={setCreds}
            creds={creds}
            setRemember={setRemember}
            emailError={emailError}
            remember={remember}
          ></ClinicalDirectorForm>
        ) : (
          <ForgotPasswordForm setCreds={setCreds} creds={creds} />
        )}
      </Tab>
      <Tab eventKey="technician" title="Technician">
        {!forgotPassword ? (
          <TechnicianForm
            setCreds={setCreds}
            creds={creds}
            setRemember={setRemember}
            remember={remember}
            emailError={emailError}
          ></TechnicianForm>
        ) : (
          <ForgotPasswordForm setCreds={setCreds} creds={creds} />
        )}
      </Tab>
    </Tabs>
  );
};

export default UserTypeTabs;
