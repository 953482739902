import { useEffect, useState } from "react";
import { Form, Formik, FormikProvider } from "formik";
import { TextFieldFormik } from "../../form-controls/input/text.field.formik";
import RadioButtonsFormSection from "../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import RadioButtons from "../../form-controls/input/RadioButtonsSection/RadioButtons";
// import InnerFooter from "./InnerFooter";
import { createNotification } from "../../../helpers/createNotifications";

import {
  NumberFormat,
  validateEmail,
  validateName,
} from "../../../helpers/utils";
import { useNavigate, useParams } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { getUsers } from "../../../services/apis/userService";
import { GetCurrentUser } from "../../../utils/utilAuth";
import LoaderSpinner from "../../general/loader/loader.spinner";
import httpClient from "../../../services/axios";
import dayjs from "dayjs";
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc"; // Import the utc plugin
// import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
// dayjs.extend(utc); // Extend dayjs with the utc plugin
// dayjs.extend(timezone); // Extend dayjs with the timezone plugin
// import moment from "moment";

const CommonDailySheetDetail = ({
  data,
  questionInfo,
  prev,
  next,
  halfWidth,
  postData,
  last,
  idCheck,
  savedSignature,
  savedSignatureTwo,
  setdashboardView,
  setSignatureDisable,
  applyCalculations,
  serviceId,
  utcSavedDates
}) => {
  const { id, dataSheetId } = useParams();
  const user = GetCurrentUser();
  const serviceNoteApproved = data?.service_note_approved;
  const serviceNoteStatus = data?.service_note_status;
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";
  const [mappingData, setMappingData] = useState([...data?.questions]);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errorsLength, setErrorsLength] = useState(null);
  const [endTime, setEndTime] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const router = useNavigate();
  const [customData, setCustomData] = useState([
    ...data?.questions?.filter(
      (item) => item?.question_type === "custom_with_text"
    ),
  ]);

  const ABC_BEHAVIOUR = [
    { value: "Cries", key: "cries" },
    { value: "Screams", key: "scream" },
    { value: "Hits", key: "hits" },
    { value: "Throw object", key: "throw_object" },
    { value: "Other", key: "behaviour_other" },
    { value: "Told no", key: "asked_no" },
    { value: "Ignored", key: "ignored" },
    { value: "Given what they wanted", key: "given_wanted" },
    { value: "Redirect to alternate behaviour", key: "redirect_alt_behaviour" },
    { value: "Other", key: "consequence_other" },
    { value: "Was Told 'no'", key: "told_no" },
    { value: "Was asked to do something", key: "ask_something" },
    { value: "Attention given to others", key: "attention_to_other" },
    { value: "Transition", key: "transition" },
    { value: "Other", key: "antecedent_other" },
  ];

  let isSubmitting = false;
  let isFormatSubmit = true;
  const formatValue = (value, formatNumber = null) => {
    switch (formatNumber) {
      case 1:
        return NumberFormat(value, "xxxx-xxxx-xxxx");
      case 2:
        return NumberFormat(value, "(xxx) xxx-xxxx");
      case 5:
        return value;
      case 8:
        if (value) {
          const val = ABC_BEHAVIOUR.find((obj) => obj?.key === value)?.value;
          if (val) {
            return val;
          } else {
            return value;
          }
        }
      default:
        return value;
    }
  };
  // const convertToFile = (signature) => {
  //   const contentType = "image/jpeg";
  //   const base64 = signature.split(",")[1];
  //   const binaryString = atob(base64);
  //   const byteArray = new Uint8Array(binaryString.length);
  //   for (let i = 0; i < binaryString.length; i++) {
  //     byteArray[i] = binaryString.charCodeAt(i);
  //   }
  //   const signatureBlob = new Blob([byteArray], { type: contentType });
  //   const formData = new FormData();
  //   formData.append("file", signatureBlob, "signature.jpeg");

  //   let signatureFile;
  //   for (const entry of formData.entries()) {
  //     signatureFile = entry[1];
  //   }
  //   return signatureFile;
  // };
  // const convertToFormData = (signature, fieldName, name) => {
  //   const contentType = "image/jpeg";
  //   const base64 = signature.split(",")[1];
  //   const binaryString = atob(base64);
  //   const byteArray = new Uint8Array(binaryString.length);
  //   for (let i = 0; i < binaryString.length; i++) {
  //     byteArray[i] = binaryString.charCodeAt(i);
  //   }
  //   const signatureBlob = new Blob([byteArray], { type: contentType });
  //   const formData = new FormData();

  //   formData.append(fieldName, signatureBlob, `${name}.jpeg`);
  //   let signatureFile;

  //   for (const entry of formData.entries()) {
  //     signatureFile = entry[1];
  //   }
  //   return signatureFile;
  // };
  function fileToObject(file) {
    const { name, type, size } = file;
    const fileObject = {
      name,
      type,
      size,
    };

    return fileObject;
  }

  const convertToFormData = (base64String, contentType, filename) => {
    const encodedData = base64String?.split(",")[1];
    const byteCharacters = atob(encodedData);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);

    const blob = new Blob([byteArray], { type: contentType });

    const file = new File([blob], filename, { type: contentType });

    return file;
  };

  useEffect(() => {
    const questions = mappingData;
    if (questions.find((item) => item?.question_type === "custom")) {
      const customWithTextObject = {
        batch_no: 1,
        question_type: "custom_with_text",
        customObjects: customData,
      };
      const newData = questions?.filter(
        (item) => item?.question_type !== "custom_with_text"
      );
      const customWithTextObjects = [];
      const parentIndices = {};
      const parentObjects = [];
      const visitedParents = new Set();

      customData.forEach((customItem) => {
        const parentID = customItem?.meta?.parent_question_id;

        if (!visitedParents.has(parentID)) {
          parentObjects.push(customItem);
          visitedParents.add(parentID);
        }
      });
      const noAnswers = [];
      customData.forEach((customItem) => {
        if (customItem?.answers?.length) {
          parentObjects.forEach((parentItem, parentIndex) => {
            const parentID = customItem?.meta?.parent_question_id;
            parentItem.answers.forEach((answer, index) => {
              const batchNo = answer?.meta?.batch_no;
              if (!parentIndices.hasOwnProperty(parentID + index)) {
                if (
                  parentItem.answers[index] &&
                  parentID === parentItem?.meta?.parent_question_id
                ) {
                  parentIndices[parentID + index] =
                    customWithTextObjects.length;
                  customWithTextObjects.push({
                    batch_no: batchNo,
                    question_type: "custom_with_text",
                    customObjects: [],
                    parent_id: parentID,
                  });
                }
              }

              if (parentItem.meta?.parent_question_id === parentID) {
                customWithTextObjects[
                  parentIndices[parentID + index]
                ]?.customObjects.push(customItem);
              }
            });
          });
        } else {
          if (!noAnswers.includes(customItem)) {
            noAnswers.push(customItem);
          }
        }
      });
      if (noAnswers?.length) {
        noAnswers.forEach((customItem) => {
          const parentID = customItem?.meta?.parent_question_id;
          if (!parentIndices.hasOwnProperty(parentID)) {
            parentIndices[parentID] = customWithTextObjects.length;
            customWithTextObjects.push({
              batch_no: 1,
              question_type: "custom_with_text",
              customObjects: [],
              parent_id: parentID,
            });
          }
          customWithTextObjects[parentIndices[parentID]].customObjects.push(
            customItem
          );
        });
      }

      const tempData = newData.flatMap((item) => {
        if (
          item?.question_type === "custom" ||
          item?.meta.key === "medical_info_current_medications" ||
          item?.meta.key === "medical_info_current_diagnosis" ||
          item?.meta.key === "home_family_sblings"
        ) {
          if (customData[0]?.answers?.length) {
            return [
              item,
              ...customWithTextObjects.filter(
                (object) => object.parent_id === item.id
              ),
            ];
          } else {
            const parentID = item.id;
            const parentIndex = parentIndices[parentID];
            const customWithTextObject = customWithTextObjects[parentIndex];
            return [item, customWithTextObject];
          }
        } else {
          return item;
        }
      });
      const finalData = tempData.filter((data) => {
        if (data?.customObjects) {
          if (data?.customObjects?.length) {
            return data;
          } else {
            return;
          }
        } else {
          return data;
        }
      });

      finalData.sort((a, b) => {
        if ("parent_id" in a && "parent_id" in b) {
          if (a.parent_id < b.parent_id) {
            return -1;
          }
          if (a.parent_id > b.parent_id) {
            return 1;
          }
        }
        if (a.batch_no < b.batch_no) {
          return -1;
        }
        if (a.batch_no > b.batch_no) {
          return 1;
        }
        return 0;
      });
      setMappingData([...finalData]);
    }
  }, [data]);
  const duplicateCustomObject = (parentID, batch_no) => {
    const customWithParent = mappingData?.reduce((lastMatch, item) => {
      if (item?.parent_id === parentID) {
        return item;
      }
      return lastMatch;
    }, null);
    const newCustomWithParent = { ...customWithParent };
    const index = mappingData.indexOf(customWithParent);
    newCustomWithParent.batch_no = batch_no + 1;
    const newData = [...mappingData];
    newData.splice(index + 1, 0, newCustomWithParent);
    setMappingData([...newData]);
  };
  const removeObjectByBatchNo = (item, values, setValues, errors) => {
    const updatedValues = values.section.questions_attributes.map(
      (question) => {
        const answerAttribute = question?.answers_attributes?.map((answer) => {
          if (
            answer?.meta?.batch_no === item?.batch_no &&
            item.customObjects.some((i) => i?.id === question?.id)
          ) {
            return { ...answer, _destroy: true };
          } else {
            return answer;
          }
        });

        if (question) {
          return {
            ...question,
            answers_attributes: answerAttribute,
          };
        }
      }
    );
    setValues({
      ...values,
      section: {
        ...values.section,
        questions_attributes: updatedValues,
      },
    });
    const toBeRemovedItem = mappingData.filter((object) => {
      if (object !== item) {
        return object;
      }
    });
    setMappingData([...toBeRemovedItem]);
    return toBeRemovedItem;
    // }
  };
  const navigate = useNavigate();
  function startsWithHttp(inputString) {
    return typeof inputString === "string" && inputString.startsWith("http");
  }
  const handleSendBack = async () => {
    if (dataSheetId) {
      //localhost:3000/api/v1/data_sheets/30/send_data_sheet_feedback

      try {
        const res = await httpClient.get(
          `/api/v1/data_sheets/${dataSheetId}/send_data_sheet_feedback`
        );
        if (res.data) {
          createNotification(
            "success",
            "Feedback has been send to relevant technician"
          );
          setdashboardView("dashboard");
          navigate("dashboard");
        }
        return true;
      } catch (error) {
        createNotification("error", error?.response?.data?.response?.message);
        console.log("Something Went wrong", error.message);
        return false;
      }
    }
  };
  const handleApprove = async () => {
    if (dataSheetId) {
      try {
        const res = await httpClient.post(
          `api/v1/data_sheets/${dataSheetId}/approve_service_note`
        );
        if (res.data) {
          createNotification("success", "Service note has been approved");
          setdashboardView("dashboard");
          navigate("dashboard");
        }
        return true;
      } catch (error) {
        console.log(error);
        createNotification("error", error?.response?.data?.response?.message);

        console.log("Something Went wrong", error.message);
        return false;
      }
    }
  };

  useEffect(() => {
    setSignatureDisable(dirty && errorsLength === 0);
  }, [errorsLength, dirty]);
  return (
    <FormikProvider>
      <Formik
        validateOnMount
        initialValues={{
          section: {
            questions_attributes: data?.questions?.map((question, index) => {
              if (question.question_type !== "custom") {
                const answer = question?.options?.length
                  ? question.answers.length
                    ? question.answers.map((answer) => {
                        return {
                          id: answer?.id,
                          option_id: answer.option_id,
                          meta: {},
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                        };
                      })
                    : [
                        {
                          id: question?.answers[0]?.id,
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                          option_id: question?.answers[0]?.option_id,
                        },
                      ]
                  : question?.answers?.length
                  ? question.answers.map((answer) => {
                      return {
                        id: answer?.id,
                        statement: answer?.statement,
                        meta: {
                          batch_no: answer?.meta?.batch_no,
                          // parent_id: answer?.meta?.parent_id,
                        },
                        user_section_mapping_id: data?.user_section_mapping_id,
                        _destroy: answer._destroy || false,
                      };
                    })
                  : [
                      {
                        id: question?.answers[0]?.id,
                        statement: question?.answers[0]?.statement,
                        user_section_mapping_id: data?.user_section_mapping_id,
                      },
                    ];
                return {
                  id: question.id,
                  answers_attributes: answer,
                };
              } else {
                return;
              }
            }),
          },
        }}
        onSubmit={async (values) => {
          setLoading(true);
          // console.log(savedSignature, savedSignatureTwo);

          // let newSavedSignature = savedSignature
          //   ? convertToFormData(
          //       savedSignature,
          //       "image/png",
          //       "consultant-signature"
          //     )
          //   : "";
          // let newSavedSignatureTwo = savedSignatureTwo
          //   ? convertToFormData(
          //       savedSignatureTwo,
          //       "image/png",
          //       "guardian-signature"
          //     )
          //   : "";

          if (savedSignature && savedSignatureTwo) {
            if (
              !values.section.questions_attributes[1].answers_attributes[0]
                .statement
            ) {
              values.section.questions_attributes[1].answers_attributes[0].statement =
                "-";
            }
            if (
              !values.section.questions_attributes[4].answers_attributes[0]
                .statement
            ) {
              values.section.questions_attributes[4].answers_attributes[0].statement =
                "-";
            }
            if (
              !values.section.questions_attributes[23].answers_attributes[0]
                .statement
            ) {
              values.section.questions_attributes[23].answers_attributes[0].statement =
                "-";
            }
            try {
              const submitted = await postData({
                // service_note: true,
                resource: "service_note",
                appointment_id: id,
                consultant_signature_image: startsWithHttp(savedSignature)
                  ? savedSignature
                  : convertToFormData(
                      savedSignature,
                      "image/png",
                      "consultant-signature"
                    ),
                guardian_signature_image: startsWithHttp(savedSignatureTwo)
                  ? savedSignatureTwo
                  : convertToFormData(
                      savedSignatureTwo,
                      "image/png",
                      "guardian-signature"
                    ),
                section: {
                  questions_attributes: values.section.questions_attributes,
                },
              });
              if (submitted) {
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              console.log(error);
            }
          } else {
            setLoading(false);

            return;
          }
          setLoading(false);
        }}
        // enableReinitialize
        validate={(values) => {
          const errors = {};
          // if (isSubmitting) {
          values.section.questions_attributes.map((item, index) => {
            if (item?.answers_attributes?.length === 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    console.log("ErrorsAdd-0", index);
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    if (!answer?.meta?.containsError)
                      if (
                        (index > 10 && index < 14) ||
                        // index === 20 ||
                        // index === 22 ||
                        index === 4 ||
                        index === 1 ||
                        (answer?.statement === 0 && index > 33 && index < 40)
                      ) {
                        delete errors[item.id];
                      } else {
                        console.log("ErrorsAdd-1", index);
                        errors[item.id] = "This field is required";
                      }
                  }
                }
              }
            } else if (item?.answers_attributes?.length > 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    console.log("ErrorsAdd-2", index);
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    console.log("ErrorsAdd-3", index);
                    errors[item.id] = "This field is required";
                  }
                }
              }
              item?.answers_attributes.map((_answer, answerIndex) => {
                if (
                  ([36, 37, 38].includes(index) && answer.statement === "0") ||
                  ([34, 35].includes(index) && _answer.statement === 0)
                ) {
                  console.log("***Deleting and Returning***", index);
                  delete errors[item.id + `-${1}`];
                  return; // Skip this iteration and move to the next one
                }
                if (
                  !_answer?.statement &&
                  !_answer?.option_id &&
                  !_answer?._destroy
                ) {
                  console.log("ErrorsAdd-4", index);
                  answerIndex > 0 &&
                    (errors[item.id + `-${_answer?.meta?.batch_no}`] =
                      "This field is required");
                }
              });
            }
            return errors;
          });
          // if (isSubmitting && isFormatSubmit) {
          //   return errors;
          // }
          console.log(errors);
          setErrorsLength(Object.keys(errors).length);
          return errors;
          // }
        }}
      >
        {({ handleSubmit, errors, values, handleChange, setValues, dirty }) => {
          setDirty(dirty);
          if (applyCalculations && !enableSaveBtn) {
            function convertToAMPM(utcDateString) {
              const utcDate = new Date(utcDateString);

              const hours = utcDate.getHours();
              const minutes = utcDate.getMinutes();
              let hours12 = hours % 12;
              hours12 = hours12 === 0 ? 12 : hours12;
              const period = hours < 12 ? "AM" : "PM";
              const formattedTime = `${hours12}:${minutes
                .toString()
                .padStart(2, "0")} ${period}`;

              return formattedTime;
            }
            const initialDateTime = new Date(
              values.section.questions_attributes[10].answers_attributes[0].statement.split(
                "Z"
              )[0]
            );

            const currentDate = new Date();
            const timeDifference = currentDate - initialDateTime;
            const millisecondsInSecond = 1000;
            const millisecondsInMinute = millisecondsInSecond * 60;
            const millisecondsInHour = millisecondsInMinute * 60;
            const millisecondsInDay = millisecondsInHour * 24;

            const hours = Math.floor(
              (timeDifference % millisecondsInDay) / millisecondsInHour
            );
            const minutes = Math.ceil(
              (timeDifference % millisecondsInHour) / millisecondsInMinute
            );

            const currentTime = currentDate.toISOString();

            setTotalTime(`${hours}h, ${minutes}m`);
            setEndTime(convertToAMPM(currentTime));
            setEnableSaveBtn(true);
          }
          const handleForamtion = (value, formater, id, batch_no) => {
            if (value || value === 0) {
              switch (formater) {
                case 2: {
                  const formatedValue = NumberFormat(value, "(xxx) xxx-xxxx");
                  if (formatedValue && formatedValue.length === 14) {
                    const parsedPhoneNumber = parsePhoneNumberFromString(
                      formatedValue,
                      "US"
                    );
                    const isValid =
                      parsedPhoneNumber && parsedPhoneNumber.isValid();
                    if (!isValid) {
                      errors[id] = "Please enter valid number";
                    } else delete errors[id];
                  } else if (
                    formatedValue &&
                    formatedValue.length > 1 &&
                    formatedValue.length < 14
                  ) {
                    errors[id] = "Please enter complete value";
                  } else {
                    delete errors[id];
                  }
                  return formatedValue;
                }
                case 3: {
                  const formatedValue = validateEmail(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "Please enter email with correct format";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 4: {
                  const formatedValue = validateName(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "You have entered wrong value";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 5: {
                  if (value <= 0 || value > 12) {
                    errors[id] = "Value must be from 0 to 12";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 6: {
                  if (value === 0) {
                    errors[id] = "Enter valid value";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 7: {
                  if (value) {
                    if (value.includes("AM") || value.includes("PM")) {
                      return value;
                    }
                    function convertToAMPM(utcDateString) {
                      const utcDate = new Date(utcDateString);

                      let hours, minutes;
                      if (utcSavedDates) {
                        hours = utcDate.getUTCHours();
                        minutes = utcDate.getUTCMinutes();
                      } else {
                        hours = utcDate.getHours();
                        minutes = utcDate.getMinutes();
                      }

                      let hours12 = hours % 12;
                      hours12 = hours12 === 0 ? 12 : hours12;
                      const period = hours < 12 ? "AM" : "PM";
                      const formattedTime = `${hours12}:${minutes
                        .toString()
                        .padStart(2, "0")} ${period}`;

                      return formattedTime;
                    }
                    return convertToAMPM(value);
                  }

                  return null;
                }
                case 9: {
                  if (value) {
                    const val = [
                      { key: "in_home", value: "Home" },
                      { key: "in_clinic", value: "In Clinic" },
                    ].find((obj) => obj?.key === value)?.value;
                    if (val) {
                      return val;
                    } else {
                      return value;
                    }
                  }
                }
                case 10: {
                  if (value) {
                    const dateObject = new Date(value);
                    const month = String(dateObject.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const day = String(dateObject.getDate()).padStart(2, "0");
                    const year = dateObject.getFullYear();
                    const formattedDate = `${month}/${day}/${year}`;

                    return formattedDate;
                  }

                  return value;
                }
                case 11: {
                  function capitalizeFirstLetter(str) {
                    return str.charAt(0).toUpperCase() + str.slice(1);
                  }
                  if (value) {
                    let capitalizedString = capitalizeFirstLetter(value);
                    return capitalizedString;
                  }
                }
                default:
                  return value;
              }
            }
          };

          const transformedMappingData = mappingData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.batch_no === value.batch_no &&
                  t.id === value.id &&
                  t.parent_id === value.parent_id
              )
          );
          return (
            <Form>
              <div
                className={`d-flex gap-16 flex-wrap ${
                  halfWidth ? "max-w-input-of" : "w-100"
                }`}
              >
                {transformedMappingData?.map((item) => {
                  let currentItem = questionInfo.find(
                    (i) => i?.title === item?.title
                  );
                  switch (item?.question_type) {
                    case "text_input":
                      const index =
                        values.section.questions_attributes.findIndex(
                          (index) => index?.id === item?.id
                        );
                      if (
                        !values.section.questions_attributes[11]
                          .answers_attributes[0].statement
                      ) {
                        values.section.questions_attributes[11].answers_attributes[0].statement =
                          endTime;
                      }

                      if (
                        !values.section.questions_attributes[20]
                          .answers_attributes[0].statement
                      ) {
                        values.section.questions_attributes[20].answers_attributes[0].statement =
                          endTime;
                      }
                      if (
                        !values.section.questions_attributes[12]
                          .answers_attributes[0].statement
                      ) {
                        values.section.questions_attributes[12].answers_attributes[0].statement =
                          totalTime;
                      }

                      const isCustom = data?.questions.find(
                        (question) =>
                          question?.id === item?.meta?.parent_question_id
                      );
                      let currentOptionalParent;

                      if (isCustom?.question_type === "optional") {
                        currentOptionalParent =
                          values.section.questions_attributes?.find(
                            (question) => question?.id === isCustom?.id
                          );
                        const index =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id === item?.id
                          );
                        const containsItem =
                          values.section.questions_attributes.find(
                            (question) => question?.id === item?.id
                          );
                        if (
                          containsItem &&
                          currentOptionalParent?.answers_attributes[0]?.meta
                            ?.containsError
                        ) {
                          containsItem?.answers_attributes?.map((answer) => {
                            answer._destroy = true;
                            answer.meta = {
                              containsError: true,
                            };
                          });
                        }
                        {
                          /* if (
                          containsItem &&
                          currentOptionalParent?.answers_attributes[0]?.meta
                            ?.containsError
                        ) {
                          values.section.questions_attributes.splice(index, 1);
                        } */
                        }
                      }

                      if (
                        item?.meta.parent_question_id &&
                        isCustom?.question_type === "radio_with_description"
                      ) {
                        const parentAnswer =
                          values.section.questions_attributes.find(
                            (index) =>
                              index?.id == item?.meta.parent_question_id
                          )?.answers_attributes[0]?.option_id;
                        const descType = currentItem?.descType;
                        const yesOptionId = data.questions
                          .find(
                            (question) =>
                              question.id === item?.meta.parent_question_id
                          )
                          .options.find(
                            (option) =>
                              option.meta.key === (descType ? descType : "yes")
                          )?.id;
                        const compare = parentAnswer == yesOptionId;

                        if (!compare && !!item.meta.parent_question_id) {
                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (containsItem) {
                            values.section.questions_attributes.splice(
                              index,
                              1
                            );
                          }
                        } else if (compare && !!item.meta.parent_question_id) {
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (!containsItem) {
                            values.section.questions_attributes.push({
                              id: item?.id,
                              answers_attributes: [
                                {
                                  statement: "",
                                  user_section_mapping_id:
                                    data?.user_section_mapping_id,
                                },
                              ],
                            });
                          }
                        }

                        return (
                          compare && (
                            <>
                              {currentItem?.message && (
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "#000",
                                    width: "100%",
                                  }}
                                >
                                  {currentItem?.message}
                                </span>
                              )}
                              <TextFieldFormik
                                disabled={
                                  user.role === "director" ||
                                  currentItem?.disabled
                                    ? true
                                    : false
                                }
                                key={currentItem.id}
                                placeholder={currentItem.placeholder}
                                label={currentItem.label}
                                type={
                                  currentItem?.fieldType
                                    ? currentItem.fieldType
                                    : currentItem?.inputType
                                    ? "textarea"
                                    : "text"
                                }
                                pattern={currentItem?.pattern}
                                wrapperClassName={
                                  currentItem.wrapperClassName
                                    ? currentItem.wrapperClassName
                                    : "max-w-input-of w-1 00"
                                }
                                tabIndex={1}
                                name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                handleChange={handleChange}
                                // handleChange={(e) => {
                                //   if (currentItem?.fieldType === "time") {
                                //     debugger
                                //     setValues((prevValues) => {
                                //       const localTime = dayjs?.utc(e).local();
                                //       console.log(e);
                                //       const newTime = localTime?.format(
                                //         "YYYY-MM-DD HH:mm:ss"
                                //       );
                                //       const { section } = prevValues;
                                //       const { questions_attributes } = section;

                                //       const indexToSet = index;

                                //       const updatedQuestions =
                                //         questions_attributes.map(
                                //           (question, idx) => {
                                //             if (idx === indexToSet) {
                                //               const updatedAnswers =
                                //                 question.answers_attributes.map(
                                //                   (answer, ansIdx) => {
                                //                     if (ansIdx === 0) {
                                //                       return {
                                //                         ...answer,
                                //                         statement: newTime,
                                //                       };
                                //                     }
                                //                     return answer;
                                //                   }
                                //                 );

                                //               return {
                                //                 ...question,
                                //                 answers_attributes:
                                //                   updatedAnswers,
                                //               };
                                //             }

                                //             return question;
                                //           }
                                //         );

                                //       return {
                                //         ...prevValues,
                                //         section: {
                                //           ...section,
                                //           questions_attributes:
                                //             updatedQuestions,
                                //         },
                                //       };
                                //     });
                                //   } else {
                                //     handleChange(e);
                                //   }
                                // }}
                                values={
                                  values?.section?.questions_attributes[index]
                                    ?.answers_attributes[0]?.statement
                                }
                                errors={errors[item?.id]}
                                birthDate={
                                  currentItem.birthDate && currentItem.birthDate
                                }
                              />
                            </>
                          )
                        );
                      } else {
                        return (
                          <>
                            {currentItem?.message && (
                              <div
                                style={{
                                  fontWeight: 600,
                                  fontSize: "20px",
                                  color: "#000",
                                  width: "100%",
                                }}
                              >
                                {currentItem?.message}
                              </div>
                            )}
                            {currentItem?.twoCol && (
                              <div className="two-col-wrapper">
                                <span className="two-col-heading">
                                  {currentItem?.heading}
                                </span>
                                <div className="two-col-titles">
                                  <span>{currentItem?.colOneTitle}</span>
                                  <span>{currentItem?.colTwoTitle}</span>
                                </div>
                              </div>
                            )}
                            {currentItem?.topLabel && (
                              <label
                                className={`lm-input-form-input-label form-label }`}
                              >
                                {currentItem?.topLabel}
                              </label>
                            )}

                            {!currentOptionalParent?.answers_attributes[0]?.meta
                              ?.containsError && (
                              <TextFieldFormik
                                disabled={
                                  user.role === "director" ||
                                  currentItem?.disabled
                                    ? true
                                    : false || serviceNoteApproved
                                }
                                key={currentItem?.id}
                                placeholder={currentItem?.placeholder}
                                label={currentItem?.label}
                                type={
                                  currentItem?.fieldType
                                    ? currentItem.fieldType
                                    : currentItem?.inputType
                                    ? "textarea"
                                    : "text"
                                }
                                pattern={currentItem?.pattern}
                                wrapperClassName={
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }
                                tabIndex={1}
                                birthDate={
                                  currentItem?.birthDate &&
                                  currentItem?.birthDate
                                }
                                name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                handleChange={handleChange}
                                // handleChange={(e) => {
                                //   if (currentItem.fieldType === "time") {
                                //     const localTime = dayjs?.utc(e).local();

                                //     const newTime = localTime?.format(
                                //       "YYYY-MM-DD HH:mm:ss"
                                //     );
                                //     const dateToSave =
                                //       newTime === "Invalid Date"
                                //         ? null
                                //         : newTime;
                                //     // const dateToSave = e ? e.toDate() : null;
                                //     setValues((prevValues) => {
                                //       const { section } = prevValues;
                                //       const { questions_attributes } = section;

                                //       const indexToSet = index;

                                //       const updatedQuestions =
                                //         questions_attributes.map(
                                //           (question, idx) => {
                                //             if (idx === indexToSet) {
                                //               const updatedAnswers =
                                //                 question.answers_attributes.map(
                                //                   (answer, ansIdx) => {
                                //                     if (ansIdx === 0) {
                                //                       return {
                                //                         ...answer,
                                //                         statement: dateToSave,
                                //                       };
                                //                     }
                                //                     return answer;
                                //                   }
                                //                 );

                                //               return {
                                //                 ...question,
                                //                 answers_attributes:
                                //                   updatedAnswers,
                                //               };
                                //             }

                                //             return question;
                                //           }
                                //         );

                                //       return {
                                //         ...prevValues,
                                //         section: {
                                //           ...section,
                                //           questions_attributes:
                                //             updatedQuestions,
                                //         },
                                //       };
                                //     });
                                //   } else {
                                //     handleChange(e);
                                //   }
                                // }}
                                values={handleForamtion(
                                  values.section.questions_attributes[
                                    index
                                  ]?.answers_attributes.find(
                                    ({ _destroy }) => !_destroy
                                  )?.statement,
                                  currentItem?.formater,
                                  item?.id
                                )}
                                errors={errors[item?.id]}
                              />
                            )}
                          </>
                        );
                      }
                    case "custom_with_text":
                      const lastBatchNoObjects = {};
                      let showAddButton = 0;
                      mappingData.forEach((currentObject) => {
                        if (
                          currentObject.question_type === "custom_with_text" &&
                          currentObject.parent_id &&
                          (!lastBatchNoObjects[currentObject.parent_id] ||
                            currentObject.batch_no >
                              lastBatchNoObjects[currentObject.parent_id]
                                .batch_no)
                        ) {
                          lastBatchNoObjects[currentObject.parent_id] =
                            currentObject;
                        }
                      });

                      mappingData.map((obj) => {
                        if (obj.parent_id === item.parent_id) {
                          showAddButton = showAddButton + 1;
                        }
                      });
                      const currentBatch = item?.batch_no;
                      const renderedCustomObjects = item?.customObjects?.map(
                        (object) => {
                          let currentItem = questionInfo.find(
                            (i) => i?.title === object.title
                          );
                          let customIndex;
                          if (object?.meta.parent_question_id) {
                            customIndex =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === object.id
                              );

                            const parentAnswer =
                              values.section.questions_attributes.find(
                                (index) =>
                                  index?.id == object.meta.parent_question_id
                              )?.answers_attributes[0]?.option_id;
                            const descType = currentItem?.descType;
                            const yesOptionId = data.questions
                              .find(
                                (question) =>
                                  question.id === object.meta.parent_question_id
                              )
                              ?.options.find(
                                (option) =>
                                  option.meta.key ==
                                  (descType ? descType : "yes")
                              )?.id;
                            const compare = parentAnswer == yesOptionId;

                            if (!compare && !!object.meta.parent_question_id) {
                              const index =
                                values.section.questions_attributes.findIndex(
                                  (index) => index?.id === object?.id
                                );
                              const containsItem =
                                values.section.questions_attributes.some(
                                  (question) => question?.id === object?.id
                                );

                              if (containsItem) {
                                values.section.questions_attributes.splice(
                                  index,
                                  1
                                );
                              }
                              const tempData = mappingData;
                              const mappingIndex = tempData.findIndex(
                                (data) =>
                                  data?.batch_no === item?.batch_no &&
                                  data?.parent_id === item?.parent_id
                              );
                              const containsMappingItem = tempData.some(
                                (data) =>
                                  data?.batch_no === item?.batch_no &&
                                  data?.parent_id === item?.parent_id
                              );
                              if (
                                containsMappingItem &&
                                mappingIndex &&
                                item?.batch_no > 1
                              ) {
                                tempData.splice(mappingIndex, 1);
                              }
                            } else if (
                              compare &&
                              !!object.meta.parent_question_id
                            ) {
                              const containsItem =
                                values.section.questions_attributes.some(
                                  (question) => question?.id === object?.id
                                );

                              if (!containsItem) {
                                values.section.questions_attributes.push({
                                  id: object?.id,
                                  answers_attributes: object?.answers?.length
                                    ? object?.answers?.map((answer) => {
                                        return {
                                          statement: answer?.statement,
                                          user_section_mapping_id:
                                            data?.user_section_mapping_id,
                                          meta: answer?.meta,
                                          id: answer?.id,
                                          _destroy:
                                            answer?.meta?.batch_no === 1
                                              ? false
                                              : true,
                                        };
                                      })
                                    : [
                                        {
                                          statement: "",
                                          user_section_mapping_id:
                                            data?.user_section_mapping_id,
                                        },
                                      ],
                                });
                              }
                            }

                            return (
                              compare && (
                                <>
                                  <TextFieldFormik
                                    birthDate={
                                      currentItem.birthDate &&
                                      currentItem.birthDate
                                    }
                                    disabled={
                                      user.role === "director" ||
                                      currentItem.disabled
                                        ? true
                                        : false
                                    }
                                    key={object?.id + `-${item?.batch_no}`}
                                    batchNo={item?.batch_no}
                                    placeholder={currentItem?.placeholder}
                                    errors={
                                      errors[object?.id + `-${item?.batch_no}`]
                                    }
                                    label={currentItem?.label}
                                    type={
                                      currentItem?.fieldType
                                        ? currentItem.fieldType
                                        : currentItem?.inputType
                                        ? "textarea"
                                        : "text"
                                    }
                                    pattern={currentItem?.pattern}
                                    wrapperClassName={
                                      currentItem?.wrapperClassName
                                        ? currentItem?.wrapperClassName
                                        : "max-w-input-of w-100"
                                    }
                                    handleChange={(e) => {
                                      const thisBatch = mappingData?.filter(
                                        (obj) =>
                                          obj?.parent_id === item?.parent_id
                                      );

                                      const batchIndex = thisBatch?.findIndex(
                                        (index) =>
                                          index?.batch_no === item?.batch_no
                                      );
                                      if (currentItem.formater) {
                                        e.target.value = formatValue(
                                          e.target.value,
                                          currentItem.formater
                                        );

                                        if (
                                          currentItem.formater != 5 &&
                                          currentItem.formater != 2
                                        ) {
                                          if (e.target.value.length < 14) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Please enter complete value";
                                            isFormatSubmit = false;
                                          } else {
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        } else if (currentItem.formater === 2) {
                                          if (e.target.value.length < 14) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Please enter complete value";
                                            isFormatSubmit = false;
                                          } else if (
                                            e.target.value.length === 14
                                          ) {
                                            const parsedPhoneNumber =
                                              parsePhoneNumberFromString(
                                                e.target.value,
                                                "US"
                                              );
                                            const isValid =
                                              parsedPhoneNumber &&
                                              parsedPhoneNumber.isValid();
                                            if (!isValid) {
                                              errors[
                                                object?.id +
                                                  `-${item?.batch_no}`
                                              ] = "Please enter valid number";
                                              isFormatSubmit = false;
                                            }
                                          } else {
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        } else if (currentItem.formater == 5) {
                                          if (
                                            e.target.value > 12 ||
                                            e.target.value <= 0
                                          ) {
                                            errors[
                                              object?.id + `-${item?.batch_no}`
                                            ] = "Value must be from 0 to 12";
                                            isFormatSubmit = false;
                                          } else {
                                            delete errors[
                                              object?.id + `-${item?.batch_no}`
                                            ];
                                            isFormatSubmit = true;
                                          }
                                        }
                                      } else {
                                        if (e.target.value) {
                                          delete errors[
                                            object?.id + `-${item?.batch_no}`
                                          ];
                                        } else {
                                          errors[
                                            object?.id + `-${item?.batch_no}`
                                          ] = "This field is required";
                                        }
                                      }

                                      const answer =
                                        values.section.questions_attributes[
                                          customIndex
                                        ].answers_attributes;
                                      const valueIndex = answer.findIndex(
                                        (index) =>
                                          index?.meta?.batch_no ===
                                          item?.batch_no
                                      );
                                      const batchAnswer = answer[batchIndex];
                                      if (batchIndex === 0) {
                                        batchAnswer.statement = e.target.value;
                                        batchAnswer.meta = {
                                          batch_no: item?.batch_no,
                                          parent_id: item?.parent_id,
                                        };
                                      } else {
                                        if (valueIndex) {
                                          answer[valueIndex].statement =
                                            e.target.value;
                                          answer[valueIndex].meta = {
                                            batch_no: item?.batch_no,
                                            parent_id: item?.parent_id,
                                          };
                                          answer[
                                            valueIndex
                                          ].user_section_mapping_id =
                                            data?.user_section_mapping_id;
                                        } else {
                                          answer.push({
                                            statement: e.target.value,
                                            meta: {
                                              batch_no: item?.batch_no,
                                              parent_id: item?.parent_id,
                                            },
                                            user_section_mapping_id:
                                              data?.user_section_mapping_id,
                                          });
                                        }
                                      }
                                    }}
                                    defaultValue={formatValue(
                                      values.section.questions_attributes[
                                        customIndex
                                      ]?.answers_attributes[
                                        values.section.questions_attributes[
                                          customIndex
                                        ]?.answers_attributes?.findIndex(
                                          (index) =>
                                            index?.meta?.batch_no ===
                                              item?.batch_no && !index._destroy
                                        )
                                      ]?.statement,
                                      currentItem.formater
                                    )}
                                  />

                                  {currentItem?.last &&
                                    item?.batch_no ===
                                      lastBatchNoObjects[item?.parent_id]
                                        ?.batch_no &&
                                    !idCheck &&
                                    !dataSheetId &&
                                    showAddButton < 5 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          duplicateCustomObject(
                                            item?.parent_id,
                                            item?.batch_no
                                          );

                                          const updatedQuestions =
                                            values.section.questions_attributes.map(
                                              (question) => {
                                                const currentCheck =
                                                  data?.questions?.some(
                                                    (newQuestion) => {
                                                      return (
                                                        question?.id ===
                                                          newQuestion.id &&
                                                        item?.parent_id ===
                                                          newQuestion?.meta
                                                            ?.parent_question_id &&
                                                        newQuestion?.question_type ===
                                                          "custom_with_text"
                                                      );
                                                    }
                                                  );
                                                const answerCheck =
                                                  question?.answers_attributes?.find(
                                                    (answer) =>
                                                      answer?.meta?.batch_no ===
                                                      item?.batch_no + 1
                                                  );
                                                if (answerCheck) {
                                                  answerCheck._destroy = false;
                                                }
                                                return currentCheck &&
                                                  !answerCheck
                                                  ? {
                                                      ...question,
                                                      answers_attributes: [
                                                        ...question.answers_attributes,
                                                        {
                                                          meta: {
                                                            parent_id:
                                                              item?.parent_id,
                                                            batch_no:
                                                              item?.batch_no +
                                                              1,
                                                          },
                                                          statement: "",
                                                        },
                                                      ],
                                                    }
                                                  : question;
                                              }
                                            );
                                          setValues({
                                            ...values,
                                            section: {
                                              ...values.section,
                                              questions_attributes:
                                                updatedQuestions,
                                            },
                                          });
                                        }}
                                        className="add-custom-button"
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9 0V17.0573"
                                            stroke="black"
                                          />
                                          <path
                                            d="M17.5273 8.5293L0.470009 8.5293"
                                            stroke="black"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                </>
                              )
                            );
                          } else {
                            return (
                              <>
                                {currentItem?.twoCol && (
                                  <div className="two-col-wrapper">
                                    <span className="two-col-heading">
                                      {currentItem?.heading}
                                    </span>
                                    <div className="two-col-titles">
                                      <span>{currentItem?.colOneTitle}</span>
                                      <span>{currentItem?.colTwoTitle}</span>
                                    </div>
                                  </div>
                                )}
                                <TextFieldFormik
                                  birthDate={
                                    currentItem.birthDate &&
                                    currentItem.birthDate
                                  }
                                  disabled={
                                    user.role === "director" ? true : false
                                  }
                                  // eslint-disable-next-line no-unused-expressions

                                  key={item?.batch_no.id}
                                  batchNo={item?.batch_no}
                                  // eslint-disable-next-line no-unused-expressions

                                  placeholder={currentItem?.placeholder}
                                  errors={errors[object?.id]}
                                  label={currentItem?.label}
                                  // eslint-disable-next-line no-unused-expressions

                                  tyep={
                                    currentItem?.fieldType
                                      ? currentItem.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem?.wrapperClassName
                                      ? currentItem?.wrapperClassName
                                      : "max-w-input-of w-100"
                                  }
                                  tabIndex={1}
                                  // eslint-disable-next-line no-unused-expressions
                                  name={`section.questions_attributes[${customIndex}]?.answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  // eslint-disable-next-line no-unused-expressions
                                  values={
                                    values?.section.questions_attributes[
                                      customIndex
                                    ]?.answers_attributes[item?.batch_no - 1]
                                      ? values.section.questions_attributes[
                                          customIndex
                                        ].answers_attributes[item?.batch_no - 1]
                                          .statement
                                      : ""
                                  }
                                />
                              </>
                            );
                          }
                        }
                      );
                      return <>{renderedCustomObjects}</>;

                    case "radio":
                      const indexRadio =
                        values?.section?.questions_attributes?.findIndex(
                          (index) => index?.id == item.id
                        );
                      const isCustomType = data.questions.find(
                        (question) =>
                          question.id == item.meta.parent_question_id
                      );

                      if (
                        item.meta.parent_question_id &&
                        isCustomType.question_type == "radio_with_description"
                      ) {
                        const parentAnswer =
                          values.section.questions_attributes.find(
                            (index) => index.id == item.meta.parent_question_id
                          ).answers_attributes[0]?.option_id;

                        const yesOptionId = data.questions
                          .find(
                            (question) =>
                              question.id === item.meta.parent_question_id
                          )
                          ?.options.find(
                            (option) =>
                              option?.meta?.key ===
                              (currentItem?.descType
                                ? currentItem?.descType
                                : "yes")
                          )?.id;
                        const compare = parentAnswer == yesOptionId;
                        if (!compare && !!item.meta.parent_question_id) {
                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (containsItem) {
                            values.section.questions_attributes.splice(
                              index,
                              1
                            );
                          }
                        } else if (compare && !!item.meta.parent_question_id) {
                          const containsItem =
                            values.section.questions_attributes.some(
                              (question) => question?.id === item?.id
                            );

                          if (!containsItem) {
                            values.section.questions_attributes.push({
                              id: item?.id,
                              answers_attributes: [
                                {
                                  statement: "",
                                  user_section_mapping_id:
                                    data?.user_section_mapping_id,
                                },
                              ],
                            });
                          }
                        }
                        return (
                          compare && (
                            <RadioButtonsFormSection
                              required
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem?.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director" ? true : false
                                      }
                                      id={currentOption?.id}
                                      name={`section.questions_attributes[${indexRadio}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section.questions_attributes[
                                          indexRadio
                                        ]?.answers_attributes?.length &&
                                        currentOption?.id ==
                                          values?.section.questions_attributes[
                                            indexRadio
                                          ]?.answers_attributes[0]?.option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          )
                        );
                      }

                    case "radio_with_description":
                      const indexRadioWD =
                        values.section.questions_attributes.findIndex(
                          (index) => index?.id == item.id
                        );
                      if (!currentItem?.checkBox) {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            key={item?.id}
                            RadioButtons={currentItem?.options?.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                return (
                                  <RadioButtons
                                    disabled={
                                      user.role === "director"
                                        ? true
                                        : false || serviceNoteApproved
                                    }
                                    id={currentOption?.id}
                                    name={`section.questions_attributes[${indexRadioWD}].answers_attributes[0].option_id`}
                                    onChange={handleChange}
                                    values={currentOption?.id}
                                    Label={content.title}
                                    tabIndex={content.tabIndex}
                                    checked={
                                      values?.section.questions_attributes[
                                        indexRadioWD
                                      ]?.answers_attributes?.length &&
                                      currentOption?.id ==
                                        values?.section.questions_attributes[
                                          indexRadioWD
                                        ]?.answers_attributes[0].option_id
                                    }
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      } else {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            key={item?.id}
                            RadioButtons={currentItem?.options.map(
                              (content) => {
                                const currentOption = item.options.find(
                                  (option) => option.meta.key == content.key
                                );
                                const answers =
                                  values.section.questions_attributes[
                                    indexRadioWD
                                  ].answers_attributes;
                                let answer = answers.find(
                                  (answer) =>
                                    answer.option_id == currentOption?.id
                                );
                                const isChecked = answer && !answer._destroy;
                                return (
                                  <RadioButtons
                                    disabled={
                                      user.role === "director" ? true : false
                                    }
                                    id={currentOption?.id}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (
                                          answers[0].option_id === undefined
                                        ) {
                                          answers[0].option_id = parseInt(
                                            e.target.value
                                          );
                                        } else {
                                          if (answer) {
                                            answer._destroy = false;
                                          } else {
                                            answer = {
                                              option_id: parseInt(
                                                e.target.value
                                              ),
                                              user_section_mapping_id:
                                                data.user_section_mapping_id,
                                            };
                                            answers.push(answer);
                                          }
                                        }
                                      } else {
                                        if (answer) {
                                          answer._destroy = true;
                                        }
                                      }
                                    }}
                                    values={currentOption?.id}
                                    Label={content.title}
                                    tabIndex={content.tabIndex}
                                    checkBox={currentItem.checkBox}
                                    defaultChecked={isChecked}
                                  />
                                );
                              }
                            )}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem.wrapperClassName
                                ? currentItem.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      }

                    case "checkbox":
                      const indexCheckbox =
                        values.section.questions_attributes.findIndex(
                          (index) => index.id == item.id
                        );
                      if (!currentItem.checkBox) {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            key={item?.id}
                            RadioButtons={currentItem.options.map((content) => {
                              const currentOption = item.options.find(
                                (option) => option.meta.key == content.key
                              );
                              return (
                                <RadioButtons
                                  disabled={
                                    user.role === "director" ? true : false
                                  }
                                  id={currentOption?.id}
                                  name={`section.questions_attributes[${indexCheckbox}].answers_attributes[0].option_id`}
                                  onChange={handleChange}
                                  values={currentOption?.id}
                                  Label={content.title}
                                  tabIndex={content.tabIndex}
                                  checked={
                                    values?.section.questions_attributes[
                                      indexCheckbox
                                    ].answers_attributes.length &&
                                    currentOption.id ==
                                      values?.section.questions_attributes[
                                        indexCheckbox
                                      ].answers_attributes[0].option_id
                                  }
                                />
                              );
                            })}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem.wrapperClassName
                                ? currentItem.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      } else {
                        return (
                          <RadioButtonsFormSection
                            errors={errors[item?.id]}
                            key={item?.id}
                            RadioButtons={currentItem.options.map((content) => {
                              const currentOption = item.options.find(
                                (option) => option.meta.key == content.key
                              );
                              const answers =
                                values.section.questions_attributes[
                                  indexCheckbox
                                ].answers_attributes;
                              let answer = answers.find(
                                (answer) =>
                                  answer.option_id == currentOption?.id
                              );
                              const isChecked = answer && !answer._destroy;
                              return (
                                <RadioButtons
                                  disabled={
                                    user.role === "director"
                                      ? true
                                      : false || serviceNoteApproved
                                  }
                                  id={currentOption?.id}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      if (
                                        answers[0].option_id === undefined ||
                                        answers[0].option_id === null
                                      ) {
                                        answers[0].option_id = parseInt(
                                          e.target.value
                                        );
                                        answers[0]._destroy = false;
                                      } else {
                                        if (answer) {
                                          answer._destroy = false;
                                        } else {
                                          answer = {
                                            option_id: parseInt(e.target.value),
                                            user_section_mapping_id:
                                              data.user_section_mapping_id,
                                            _destroy: false,
                                          };
                                          answers.push(answer);
                                        }
                                      }
                                    } else {
                                      if (answer) {
                                        answer._destroy = true;
                                      }
                                    }
                                    if (
                                      answers.every(
                                        (answer) =>
                                          answer.option_id && answer._destroy
                                      )
                                    ) {
                                      errors[item?.id] =
                                        "This field is required";
                                    } else {
                                      delete errors[item?.id];
                                    }
                                  }}
                                  values={currentOption?.id}
                                  Label={content.title}
                                  // tagCheckbox={true}
                                  tabIndex={content.tabIndex}
                                  checkBox={currentItem?.checkBox}
                                  defaultChecked={isChecked}
                                />
                              );
                            })}
                            label={currentItem?.label}
                            wrapperClassName={
                              currentItem?.wrapperClassName
                                ? currentItem?.wrapperClassName
                                : "max-w-input-of w-100"
                            }
                            GridView={currentItem?.gridView}
                          />
                        );
                      }

                    case "custom":
                      return (
                        <h4 className="w-100 custom-heading">
                          {currentItem?.label}
                        </h4>
                      );
                    default:
                      return true;
                  }
                })}
                {user.role === "director" && serviceNoteStatus === "pending" ? (
                  <div className="dashboard-footer">
                    <button
                      type="button"
                      className="send-back-btn"
                      onClick={() => handleSendBack()}
                    >
                      Send Back
                    </button>
                    <button
                      type="button"
                      className="approve-btn"
                      onClick={() => handleApprove()}
                      disabled={loading}
                    >
                      {loading ? <LoaderSpinner color="white" /> : "Approve"}
                    </button>
                  </div>
                ) : (
                  <div
                    className="dashboard-footer"
                    style={{
                      width: !serviceId ? "100%" : "calc(100% - 362px)",
                    }}
                  >
                    <button
                      type="submit"
                      className="approve-btn"
                      onClick={() => {
                        isSubmitting = true;
                        if (savedSignature && savedSignatureTwo) {
                          handleSubmit(values);
                        } else {
                          createNotification("error", "Signature Required");
                        }
                      }}
                      disabled={
                        loading || !savedSignatureTwo || serviceNoteApproved
                      }
                    >
                      {loading ? <LoaderSpinner color="white" /> : saveBtnText}
                    </button>
                  </div>
                )}
                {/* {!id && (
                  <InnerFooter
                    prevFormHandler={prev}
                    loading={loading}
                    next={next}
                    last={last ? last : null}
                    nextFormHandler={() => {
                      isSubmitting = true;
                      handleSubmit(values);
                    }}
                    saveDraft={() => handleSaveDraft(values, errors)}
                  />
                )} */}
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormikProvider>
  );
};

export default CommonDailySheetDetail;
