import React, { useState, useRef, useEffect } from "react";
// import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button } from "antd";
import httpClient from "../../services/axios";
let index = 0;
const SearchDropdown = ({
  setInsuranceProvider,
  dropdownList,
  insuranceProvider,
}) => {
  const [items, setItems] = useState(dropdownList);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setItems([...items, { title: name || `New item ${index++}`, id: "" }]);
    setName("");
  };

  return (
    <Select
      showSearch
      placeholder="custom dropdown render"
      className="ant-search-dropdown-wrapper"
      value={insuranceProvider}
      onSelect={(e, obj) => setInsuranceProvider(e, obj)}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <Space
            style={{
              padding: "0 8px 4px",
            }}
          >
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onMouseDown={(e) => {
                e.stopPropagation();
                inputRef.current.focus();
                // e.preventDefault();
              }}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log(e);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addItem(e);
              }}
            >
              Add item
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({
        label: item.title,
        value: item.id || "",
      }))}
    />
  );
};
export default SearchDropdown;
