import React, { Fragment, useEffect, useState } from "react";
import MultiStepperForm from "./components/multiStepperForm/multiStepperForm";
// import Navbar from "./components/Menu/nav";
import Navbar from "./components/menu/nav";
import AppRoutes from "./routes";
import { useSelector } from "react-redux";
import DashboardLogin from "./Containers/Dashboard Login/DashboardLogin";
import Dashboard from "./Containers/Dashboard/Dashboard";
import ClientLogin from "./Containers/Dashboard Login/ClientLogin";
import "react-calendar/dist/Calendar.css";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

// function App() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const currentUser = useSelector((state) =>
//     state.user ? state.user.currentUser : null
//   );
//   const loggingStatus = useSelector((state) =>
//     state.user ? state.user.loggedIn : false
//   );

//   console.log(currentUser, "user");

//   return (
//     <>
//       {isLoggedIn ? (
//         <Dashboard />
//       ) : (
//         <DashboardLogin
//           loggedInHandler={() => {
//             setIsLoggedIn(true);
//           }}
//         />
//       )}
//       {/* <MultiStepperForm></MultiStepperForm> */}
//       {/* <ClientLogin></ClientLogin> */}
//     </>
//   );
// }
function App() {
  const currentUser = useSelector((state) =>
    state.user ? state.user.currentUser : null
  );
  const loggingStatus = useSelector((state) =>
    state.user ? state.user.loggedIn : false
  );
  const [showOverlay, setShowOverlay] = useState(false);
  // ##TODOS As we disabled copy paste on each text field so below code may not affective but we will test it by disabling it
  const handleKeyUp = (e) => {
    if (
      (e.ctrlKey || e.metaKey) &&
      (e.key === "c" || e.key === "x" || e.key === "v")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyUp);
    };
  }, []);
  return (
    <Fragment>
      <AppRoutes
        currentUser={currentUser}
        loggingStatus={loggingStatus}
        // role={currentUser?.role}
      />
    </Fragment>
  );
}

export default App;
