import { Pagination } from "antd";
import React from "react";
import { Accordion } from "react-bootstrap";
import {
  ABC_BEHAVIOUR,
  ABC_BEHAVIOUR_ANTECEDENT,
  ABC_BEHAVIOUR_CONSEQUENCE,
  ABC_BEHAVIOUR_FUNCTION,
} from "./AddAbc";

const ViewAbc = ({ abcData, setCurrentPage, currentPage, totalCount }) => {
  if (!abcData?.length) {
    return (
      <div
        className="compilance-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div class="css-dev-only-do-not-override-1sn0ohr ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                <ellipse
                  fill="#f5f5f5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fill-rule="nonzero" stroke="#d9d9d9">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#fafafa"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="ant-empty-description">No data</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="accordian-wrapper">
        <Accordion
          defaultActiveKey="0"
          // flush
          className="custom-accordian-data-sheet"
        >
          {abcData &&
            !!abcData?.length &&
            abcData?.map((row, index) => {
              return (
                <Accordion.Item eventKey={String(index)}>
                  <Accordion.Header className="fs-14 f-w-600 clr-dark-grey">
                    {row.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="behaviour-name-1">
                      <div className="border-right">
                        <p className="f-w-600 clr-yellowish-clr pb-4">
                          Intensity
                        </p>
                        <span className="clr-yellowish-clr">
                          {row.intensity}
                        </span>
                      </div>
                      <div className="border-right">
                        <p className="f-w-600 clr-yellowish-clr pb-4">
                          Duartion
                        </p>
                        <span className="clr-yellowish-clr">
                          {row.spent_time}
                        </span>
                      </div>
                      <div className="border-right">
                        <p className="f-w-600 pb-4">Antecedent</p>
                        <span>
                          {row.other_statements[0]?.antecedent_statement.length
                            ? row.other_statements[0]?.antecedent_statement
                            : ABC_BEHAVIOUR_ANTECEDENT.find((val) => {
                                return val.key === row.antecedent;
                              })?.value}
                        </span>
                      </div>
                      <div className="border-right">
                        <p className="f-w-600 pb-4 Antecedent">Behaviour</p>
                        <span>
                          {row.other_statements[0]?.behavioural_statement.length
                            ? row.other_statements[0]?.behavioural_statement
                            : ABC_BEHAVIOUR.find((val) => {
                                return val.key === row.behaviour;
                              })?.value}
                        </span>
                      </div>
                      <div className="border-right">
                        <p className="f-w-600 pb-4 Antecedent">Consequence</p>
                        <span>
                          {row.other_statements[0]?.consequence_statement.length
                            ? row.other_statements[0]?.consequence_statement
                            : ABC_BEHAVIOUR_CONSEQUENCE.find((val) => {
                                return val.key === row.consequence;
                              })?.value}
                        </span>
                      </div>
                      <div className="border-right">
                        <p className="f-w-600 pb-4 Antecedent">Function</p>
                        <span>
                          {row.other_statements[0]?.function_statement.length
                            ? row.other_statements[0]?.function_statement
                            : ABC_BEHAVIOUR_FUNCTION.find((val) => {
                                return val.key === row.function;
                              })?.value}
                        </span>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          {totalCount > 5 && (
            <Pagination
              // defaultCurrent={currentPage}
              total={totalCount}
              pageSize={5}
              onChange={(page) => setCurrentPage && setCurrentPage(page)}
            />
          )}
        </Accordion>
      </div>
    );
  }
};

export default ViewAbc;
