import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../store/user/userSlice";

export function IsLogin() {
  const isLogin = useSelector((state) =>
    state.user.loggedIn ? state.user.loggedIn : false
  );
  return isLogin;
}

export function GetCurrentUser() {
  const currentUser = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser : null
  );
  return currentUser;
}
export function GetUserProfile() {
  const currentUser = useSelector((state) =>
    state.user.userProfile ? state.user.userProfile : null
  );
  return currentUser;
}

export const loginSuccess = (dispatch, user) => {

  dispatch(
    setCurrentUser({
      role: user?.role,
      token: user.token,
      slug: user?.slug,
      name: user?.full_name,
    })
  );
  if (user.role === "director") {
    window.open("/dashboard", "_self");
  } else {
    window.open("/techdashboard", "_self");
  }
};
