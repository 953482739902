import React, { useEffect, useRef, useState } from "react";
import TableView from "../../components/TableViews/TableView";
import ClientsListing from "./ClientsListing";
import Sc from "./Dashboard.module.scss";
import { Breadcrumb } from "antd";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../constants/constants";
import DashboardMiniSidebar from "../../components/DashboardMiniSidebar/DashboardMiniSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { useParams } from "react-router-dom/dist";
import { logout } from "../../store/user/userSlice";
import httpClientTwo from "../../services/fetch";
import LoaderSpinner from "../../components/general/loader/loader.spinner";
const goalsStats = [
  {
    id: Math.random(),
    Title: "Total",
    Count: 18,
  },
  {
    id: Math.random(),
    Title: "Mastered",
    Count: "04",
  },
];
const ClientDocuments = ({
  Panel,
  View,
  RightSidebar,
  setdashboardView,
  ClientSessions,
  assignGoal,
  InProgressGoals,
  viewGoals,
  tech,
  tabOf,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const propsReceived = location.state;

  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setIsLoading] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [currentView, setCurrentView] = useState();
  const [sideBarData, setSideBarData] = useState();
  const [searchText, setSearchText] = useState();
  const [stats, setStats] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [smLoading, setSmLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        setIsLoading(true);
        const getTableData = async ({ role, page, search, status }) => {
          // let newStatus = "";
          // switch (status) {
          //   case "archived":
          //     newStatus = "archive";
          //     break;
          //   case "unarchived":
          //     newStatus = "active";
          //     break;
          //   case "pending_request":
          //     newStatus = "";
          // }
          const queryParams = {
            q: search ?? "",
            page: page,
            per_page: 10,
          };
          const sessionParam = {
            role: role,
            q: search ?? "",
            page: page,
            per_page: 10,
            status: status,
          };
          const queryString = new URLSearchParams(queryParams).toString();
          // setTableData(TableDataClient);
          try {
            const response = await httpClient.get(
              `api/v1/users/${id}/user_attached_docs`
            );

            setTableData(response.data);
            setIsLoading(false);
            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              setIsLoading(false);
              localStorage.removeItem(`persist:root`);
              window.open("/dashboardlogin", "_self");
              logout();
              return;
            } else if (
              error.message ===
              "Cannot read properties of null (reading 'user')"
            ) {
              window.open("/dashboardlogin", "_self");
            }
            setIsLoading(false);
            createNotification("error", error?.response?.data?.response.error);
            console.log("Something Went wrong", error);
          }
          setIsLoading(false);
        };

        getTableData({
          page: currentPage,
          // role: tabOf,
          search: searchText,
          status: currentView,
        });
      },
      searchText ? 1000 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [
    currentPage,
    searchText,
    currentView,
    actionDone,
    View,
    propsReceived,
    selectedFile,
  ]);
  // useEffect(() => {
  //   if (View === "ViewGoals") {
  //   } else if (View === "AssignGoal") {
  //   }
  // }, [View]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5000000) {
      createNotification("error", "File size should be less the 5MB");
      return;
    }
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      createNotification("error", "Add PDFs only");
    }
    // setSelectedFile(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleDeleteDoc = async (docId) => {
    try {
      const response = await httpClient.delete(
        `/api/v1/users/${id}/destroy_document?doc_id=${docId}`
      );
      if (response?.data) {
        createNotification("success", "Document Deleted");
        setActionDone(!actionDone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async () => {
    setSmLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("doc", selectedFile);
      try {
        const response = await httpClientTwo.post(
          `/api/v1/users/${id}/attach_pdf `,
          formData
        );
        if (response?.data) {
          setSmLoading(false);

          createNotification("success", "Document has been added successfully");
          setSelectedFile(null);
          // setImageFile(null);
          // setFetchData(!fetchData);
          // navigate("/");
          // setdashboardView("panel");
          // setLoading(false);
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error?.response?.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          // setLoading(false);
          setSmLoading(false);

          return;
        }
        createNotification("error", error?.response?.data?.response?.message);
        //   setLoading(false);
        console.log("Something Went wrong", error?.message);
        return false;
      }
      setSmLoading(false);
    }
  };

  useEffect(() => {
    setSearchText("");
  }, [View]);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  const navigate = useNavigate();
  return (
    <section className="w-100">
      <section
        className={`${Sc.ClientDailySession} d-flex flex-column gap-21 w-100`}
        style={{ overflowX: "auto" }}
      >
        <div className={` ${Sc.ProfileContainer__LeftSidebar_Header}`}>
          <div
            className="lm-nav-arrow"
            onClick={() => {
              if (assignGoal && !tech) {
                setdashboardView("ViewGoals");
                navigate(-1);
                return;
              } else if (tech) {
                navigate(-1);
                setdashboardView("yourclients");
                return;
              } else if (InProgressGoals) {
                navigate(`/technicians/${propsReceived.userId}`);
                setdashboardView("Technician");
                return;
              } else {
                navigate(-1);
                setdashboardView("Client");
                return;
              }
            }}
          >
            {arrowLeftPurple}
          </div>
          <Breadcrumb
            separator={arrowRightBreadcrumb}
            items={[
              {
                title: "Clients",
              },
              {
                title: "Client Detail",
              },
              {
                title: "Documents",
              },
              ...(assignGoal ? [{ title: "Assign Goals" }] : []),
            ]}
          />
        </div>
        <div className="d-flex gap-30 w-100">
          <div className="d-flex flex-column gap-24 w-100 bg-clr-bg-pri-light InnerWrapper">
            <div
              className={` d-flex justify-content-between align-items-center ps-30 pt-27 pe-30`}
            >
              <h4 className="f-24 f-w-700 lh-28 clr-dark-grey">
                Client Documents
              </h4>
              {/* <TextFieldFormik
                wrapperClassName={`Search-Input-Wrapper w-100`}
                type="search"
                values={searchText}
                placeholder="Search"
                handleChange={(e) => setSearchText(e.target.value)}
              /> */}
              <div className="file-upload-container">
                {selectedFile ? (
                  <div>
                    <span className="selected-name">{selectedFile.name}</span>
                    {!smLoading ? (
                      <>
                        <button
                          className="remove-btn"
                          onClick={handleRemoveFile}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_2815_24795"
                              //   style="mask-type:alpha"
                              maskUnits="userSpaceOnUse"
                              x="6"
                              y="6"
                              width="20"
                              height="20"
                            >
                              <rect
                                x="6"
                                y="6"
                                width="20"
                                height="20"
                                fill="#D9D9D9"
                              />
                            </mask>
                            <g mask="url(#mask0_2815_24795)">
                              <path
                                d="M11.832 23.5C11.3737 23.5 10.9813 23.3368 10.6549 23.0104C10.3286 22.684 10.1654 22.2917 10.1654 21.8333V11H9.33203V9.33333H13.4987V8.5H18.4987V9.33333H22.6654V11H21.832V21.8333C21.832 22.2917 21.6688 22.684 21.3424 23.0104C21.0161 23.3368 20.6237 23.5 20.1654 23.5H11.832ZM20.1654 11H11.832V21.8333H20.1654V11ZM13.4987 20.1667H15.1654V12.6667H13.4987V20.1667ZM16.832 20.1667H18.4987V12.6667H16.832V20.1667Z"
                                fill="#EF4747"
                              />
                            </g>
                          </svg>
                        </button>
                        <button onClick={handleFileUpload}>
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_2313_21418"
                              //   style="mask-type:alpha"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="25"
                              height="25"
                            >
                              <rect width="25" height="25" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_2313_21418)">
                              <path
                                d="M9.16699 15.8332V10.8332H4.16699V9.1665H9.16699V4.1665H10.8337V9.1665H15.8337V10.8332H10.8337V15.8332H9.16699Z"
                                fill="#F6891F"
                              />
                            </g>
                          </svg>
                        </button>
                      </>
                    ) : (
                      <LoaderSpinner size={15} top={17} color="white" />
                    )}
                  </div>
                ) : (
                  <>
                    Add Document
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="file-upload-input"
                      onChange={handleFileChange}
                    />
                  </>
                )}
              </div>
            </div>
            {/* <TableView View={"DailySessionsClient"} Pagination={true}></TableView> */}

            <TableView
              View={"ClientDocuments"}
              setCurrentPage={setCurrentPage}
              tableData={tableData?.user_docs}
              metaData={tableData?.meta}
              setActionDone={setActionDone}
              handleDeleteDoc={handleDeleteDoc}
              currentPage={currentPage}
              actionDone={actionDone}
              userId={propsReceived?.userId}
              Pagination={true}
            />
          </div>
          {(View === "ViewGoals" ||
            View === "AssignGoal" ||
            View === "InProgressGoals") && (
            <DashboardMiniSidebar
              goalsStats={goalsStats}
              stats={stats}
              assignGoal={assignGoal}
              setdashboardView={setdashboardView}
              tech={tech}
              InProgressGoals={InProgressGoals}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default ClientDocuments;
