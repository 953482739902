import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import LoaderSpinner from "../../../general/loader/loader.spinner";
import CommonFormPage from "../../CommonFormPage";
import { logout } from "../../../../store/user/userSlice";
import httpClient from "../../../../services/axios";

const CurrentPerformance = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 98,
      title: "performance_child_grade",
      placeholder: "Details...",
      label: "How are the child’s grades?",
      wrapperClassName: "",
      options: [],
      inputType: true,
      firstInput: true,
    },
    {
      id: 99,
      title: "performance_expelled_suspended",
      placeholder: "",
      label: "Has the child ever been suspended or expelled from school?",
      wrapperClassName: "",
      options: [
        {
          id: 56,
          title: "Yes",
          key: "yes",
        },
        {
          id: 57,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 100,
      title: "performance_expelled_suspended_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 101,
      title: "performance_acad_beh_func",
      placeholder: "",
      label:
        "Has the child had any changes in academic or behavioral functioning at school in the last 30 days?",
      wrapperClassName: "",
      options: [
        {
          id: 58,
          title: "Yes",
          key: "yes",
        },
        {
          id: 59,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 102,
      title: "performance_acad_beh_func_desc",
      placeholder: "Details...",
      label: "If yes, when and why?",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
    {
      id: 103,
      title: "performance_peer_relationship",
      placeholder: "Details...",
      label:
        "Has the child been able to form any peer relationships at school? Please explain:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/current-performance/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/current-performance"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);

        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/current-performance/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            next={next}
            prev={prev}
            halfWidth={true}
            postData={postData}
            id={id}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};

export default CurrentPerformance;
