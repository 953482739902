import { Select } from "antd";
import { TextFieldFormik } from "../../../../components/form-controls/input/text.field.formik";
import {
  //   ChartData,
  //   GoalsDurationOptions,
  //   GoalsOptions,
  arrowDownBlack,
} from "../../../../constants/constants";
import Styles from "../../Dashboard.module.scss";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import httpClient from "../../../../services/axios";

const GoalsDurationOptions = [
  { value: "", label: "Last 6 Months" },
  { value: "last_month", label: "Last Month" },
  { value: "this_week", label: "This Week" },
];

const ClientDetailChart = ({
  data,
  option,
  duration,
  goalsDuration,
  userId,
  technician,
}) => {
  const [goalReference, setGoalReference] = useState([]);
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [firstItem, setFirstItem] = useState();

  useEffect(() => {
    const getGoalReference = async () => {
      try {
        const response = await httpClient.get(
          `/api/v1/goals/client_goals?user_id=${userId}&state=${'un_archived'}`
        );
        if (response?.data) {
          option(response?.data?.goals[0]?.reference_no);
          setFirstItem(response?.data?.goals[0]?.reference_no);
          setGoalReference(response?.data?.goals);
          setFilteredGoals(response?.data?.goals);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!technician) {
      getGoalReference();
    }
  }, [userId]);

  const [chartData, setChartData] = useState();

  useEffect(() => {
    setChartData(data);
  }, [data]);

  const chartInfo = {
    series: [
      {
        name: "Goals Achieved",
        type: "column",
        data: chartData && Object.values(chartData).map((item) => item),
      },
      {
        name: "Sessions Done",
        type: "line",
        data: chartData && Object.values(chartData).map((item) => item),
      },
    ],
    chart: {
      fontFamily: "OpenSans Regular",
      height: 240,
      width: 240,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1, 2],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
      style: {
        colors: ["#0000"],
      },
    },
    labels: chartData && Object.keys(chartData).map((item) => item),
    xaxis: {
      type: "month",
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
    },

    colors: ["rgba(102, 45, 145, 0.15)", "rgba(246, 137, 31, 1)"],

    legend: {
      show: false,
    },
  };
  const handleSearchReference = (e) => {
    e.preventDefault();

    const searchValue = e.target.value.toLowerCase();
    const tempGoals = goalReference?.filter((item) =>
      item?.reference_no.toLowerCase().includes(searchValue)
    );
    setFilteredGoals(tempGoals);
  };

  return (
    <div className={Styles.ProfileContainer__ClientPanel_Card}>
      <div className={Styles.ProfileContainer__ClientPanel_CardHeader}>
        {/* <h3 className="f-18 f-w-700 lh-25 clr-dark-grey">{goalsDuration === "this_week" ? "Weekly" : "Monthly" } Progress</h3> */}
        <h3 className="f-18 f-w-700 lh-25 clr-dark-grey">Progress</h3>
        <div className="d-flex gap-8">
          <Select
            suffixIcon={arrowDownBlack}
            defaultValue={GoalsDurationOptions[2].label}
            style={{ width: 130, height: 38 }}
            onChange={(e) => duration(e)}
            options={GoalsDurationOptions}
            className={"DashbaordCardSelect"}
            popupClassName="DashbaordCardSelect"
          />
          {firstItem && (
            <Select
              suffixIcon={arrowDownBlack}
              defaultValue={firstItem}
              style={{ width: 120, height: 38 }}
              onChange={(e) => option(e)}
              options={
                goalReference &&
                filteredGoals?.map((item) => {
                  return {
                    value: item?.reference_no,
                    label: item?.reference_no,
                  };
                })
              }
              className={"DashbaordCardSelect"}
              popupClassName="DashbaordCardSelect"
              dropdownRender={(menu) => {
                return (
                  <>
                    <div className="d-flex flex-column">
                      {/* <Search></Search> */}
                      <TextFieldFormik
                        wrapperClassName={`Search-Input-Wrapper`}
                        type="search"
                        placeholder="Search"
                        handleChange={handleSearchReference}
                      />
                      {menu}
                    </div>
                  </>
                );
              }}
            />
          )}
        </div>
      </div>
      <div style={{ minHeight: "300px" }}>
        {chartData && Object.values(chartData)?.length ? (
          <Chart
            options={chartInfo}
            series={chartInfo.series}
            type="bar"
            width="100%"
            height={"100%"}
            style={{ minWidth: "100%" }}
            menu={false}
            legends={false}
          />
        ) : (
          <div
            class="css-dev-only-do-not-override-w8mnev ant-empty ant-empty-normal"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginBlock: 0,
            }}
          >
            <div class="ant-empty-image">
              <svg
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse
                    fill="#f5f5f5"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  ></ellipse>
                  <g fill-rule="nonzero" stroke="#d9d9d9">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#fafafa"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <div class="ant-empty-description">No Goals</div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ClientDetailChart;
