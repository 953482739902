import React, { useEffect, useState } from "react";
import TableView from "../../components/TableViews/TableView";
import ClientsListing from "./ClientsListing";
import Sc from "./Dashboard.module.scss";
import { Breadcrumb } from "antd";
import { TextFieldFormik } from "../../components/form-controls/input/text.field.formik";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../constants/constants";
import DashboardMiniSidebar from "../../components/DashboardMiniSidebar/DashboardMiniSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { useParams } from "react-router-dom/dist";
import { logout } from "../../store/user/userSlice";
import TabsView from "../../components/TabView/TabsView";
const goalsStats = [
  {
    id: Math.random(),
    Title: "Total",
    Count: 18,
  },
  {
    id: Math.random(),
    Title: "Mastered",
    Count: "04",
  },
];
const ClientDailySessions = ({
  Panel,
  View,
  RightSidebar,
  setdashboardView,
  ClientSessions,
  assignGoal,
  InProgressGoals,
  viewGoals,
  tech,
  tabOf,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const propsReceived = location.state;

  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setIsLoading] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [currentView, setCurrentView] = useState();
  const [sideBarData, setSideBarData] = useState();
  const [searchText, setSearchText] = useState();
  const [viewArchivedGoals, setViewArchivedGoals] = useState();
  const [stats, setStats] = useState();
  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        setIsLoading(true);
        const getTableData = async ({ role, page, search, status }) => {
          const queryParams = {
            q: search ?? "",
            page: page,
            per_page: 10,
          };
          const queryString = new URLSearchParams(queryParams).toString();
          try {
            let response;
            if (View === "ViewGoals" || View === 'ArchivedGoals') {
              const state = View === "ViewGoals" ? "un_archived" : "archived"; 
              response = await httpClient.get(
                `/api/v1/goals/client_goals?user_id=${propsReceived?.userId || id}&state=${state}&${queryString}`
              );
            } else if (View === "AssignGoal") {
              response = await httpClient.get(`/api/v1/goals?${queryString}`);
            } else if (View === "InProgressGoals") {
              response = await httpClient.get(
                `/api/v1/goals/technician_in_progress_goals?${queryString}&user_id=${propsReceived.userId}`
              );
            }
            setTableData(response.data);
            setIsLoading(false);
            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              setIsLoading(false);
              localStorage.removeItem(`persist:root`);
              window.open("/dashboardlogin", "_self");
              logout();
              return;
            } else if (
              error.message ===
              "Cannot read properties of null (reading 'user')"
            ) {
              window.open("/dashboardlogin", "_self");
            }
            setIsLoading(false);
            createNotification("error", error?.response?.data?.response.error);
            console.log("Something Went wrong", error);
          }
          setIsLoading(false);
        };
        const getStatsGoals = async () => {
          try {
            let response = await httpClient.get(
              `api/v1/goals/user_goals_stats?user_id=${
                propsReceived?.userId ?? id
              }&role=${tabOf}`
            );

            setStats(response?.data?.goals_stats);
            return true;
          } catch (error) {
            if (
              error?.response?.data === "Signature has expired" ||
              error?.response?.status === 401
            ) {
              createNotification(
                "error",
                "Please login again your session has been expired"
              );
              localStorage.removeItem(`persist:root`);
              window.open("/dashboardlogin", "_self");
              logout();
              return;
            } else if (
              error.message ===
              "Cannot read properties of null (reading 'user')"
            ) {
              window.open("/dashboardlogin", "_self");
            }
            setIsLoading(false);
            createNotification("error", error?.response?.data?.response.error);
            console.log("Something Went wrong", error);
          }
        };
        getTableData({
          page: currentPage,
          // role: tabOf,
          search: searchText,
          status: currentView,
        });
        getStatsGoals();
      },
      searchText ? 1000 : 0
    );
    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, searchText, currentView, actionDone, View, propsReceived]);

  const getActiveTab = (tab) => {
    setdashboardView(tab);
  };

  useEffect(() => {
    setSearchText("");
  }, [View]);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  const navigate = useNavigate();
  return (
    <section className="w-100">
      <section
        className={`${Sc.ClientDailySession} d-flex flex-column gap-21 w-100`}
        style={{ overflowX: "auto" }}
      >
        <div className={` ${Sc.ProfileContainer__LeftSidebar_Header}`}>
          <div
            className="lm-nav-arrow"
            onClick={() => {
              if (assignGoal && !tech) {
                setdashboardView("ViewGoals");
                navigate(-1);
                return;
              } else if (tech) {
                navigate(-1);
                setdashboardView("yourclients");
                return;
              } else if (InProgressGoals) {
                navigate(`/technicians/${propsReceived.userId}`);
                setdashboardView("Technician");
                return;
              } else {
                navigate(-1);
                setdashboardView("Client");
                return;
              }
            }}
          >
            {arrowLeftPurple}
          </div>
          {tech ? (
            <Breadcrumb
              separator={arrowRightBreadcrumb}
              items={[
                {
                  title: "Your Clients",
                },

                {
                  title: "View Goals",
                },
              ]}
            />
          ) : InProgressGoals ? (
            <Breadcrumb
              separator={arrowRightBreadcrumb}
              items={[
                {
                  title: "Technicians",
                },
                {
                  title: "Technician Profile",
                },
                {
                  title: "In Progress Goals",
                },
              ]}
            />
          ) : (
            <Breadcrumb
              separator={arrowRightBreadcrumb}
              items={[
                {
                  title: "Clients",
                },
                {
                  title: "Client Detail",
                },
                {
                  title: "View Goals",
                },
                ...(assignGoal ? [{ title: "Assign Goals" }] : []),
              ]}
            />
          )}
        </div>
        <div className="d-flex gap-30 w-100">
          <div className="d-flex flex-column gap-24 w-100 bg-clr-bg-pri-light InnerWrapper">
            <div
              className={` d-flex justify-content-between align-items-center ps-30 pt-27 pe-30`}
            >
              <h4 className="f-24 f-w-700 lh-28 clr-dark-grey">
                {/* {View === "ViewGoals" ? "Assigned Goals " : ""} */}
                {(View === "ViewGoals" ||
                  View === "ArchivedGoals") &&
                  !tech && (
                    <TabsView
                      activeDefault={View}
                      TabsData={[
                        {
                          id: Math.random(),
                          title: "Assigned Goals",
                          key: "ViewGoals",
                        },
                        {
                          id: Math.random(),
                          title: "Archived Goals",
                          key: "ArchivedGoals",
                        },
                      ]}
                      getActiveTab={getActiveTab}
                      Container={true}
                      setIsLoading={setIsLoading}
                      setSearchText={setSearchText}
                      setCurrentView={setCurrentView}
                    />
                  )}

                {View === "AssignGoal" ? "Assign New Goal" : ""}
                {View === "InProgressGoals" && "In Progress Goals"}
              </h4>
              <TextFieldFormik
                wrapperClassName={`Search-Input-Wrapper w-100`}
                type="search"
                values={searchText}
                placeholder="Search"
                handleChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            {View === "ViewGoals" && tech && (
              <TableView
                View={"ViewGoalsTech"}
                setCurrentPage={setCurrentPage}
                tableData={tableData?.goals}
                metaData={tableData?.meta}
                setActionDone={setActionDone}
                currentPage={currentPage}
                actionDone={actionDone}
                userId={propsReceived?.userId}
                Pagination={true}
                loading={loading}
              />
            )}

            {View === "ViewGoals" && !tech && ( //it will also run if tech is not sent from the parent component
              <TableView
                View={"ViewGoals"}
                setCurrentPage={setCurrentPage}
                tableData={tableData?.goals}
                metaData={tableData?.meta}
                setActionDone={setActionDone}
                currentPage={currentPage}
                actionDone={actionDone}
                userId={propsReceived?.userId}
                Pagination={true}
                loading={loading}
                archived={true}
              />
            )}
            {View === "ArchivedGoals" && !tech && ( //it will also run if tech is not sent from the parent component
              <TableView
                View={"ArchivedGoals"}
                setCurrentPage={setCurrentPage}
                tableData={tableData?.goals}
                metaData={tableData?.meta}
                setActionDone={setActionDone}
                currentPage={currentPage}
                actionDone={actionDone}
                userId={propsReceived?.userId}
                Pagination={true}
                loading={loading}
                archived={true}
              />
            )}
            {View === "AssignGoal" && (
              <TableView
                View={"AssignGoal"}
                setCurrentPage={setCurrentPage}
                Pagination={true}
                currentPage={currentPage}
                tableData={tableData?.goals}
                metaData={tableData?.meta}
                userId={propsReceived?.userId}
                loading={loading}
              />
            )}
            {View === "InProgressGoals" && (
              <TableView
                View={"InProgressGoals"}
                setCurrentPage={setCurrentPage}
                Pagination={true}
                currentPage={currentPage}
                tableData={tableData?.goals}
                metaData={tableData?.meta}
                userId={propsReceived?.userId}
                loading={loading}
              />
            )}
            {View === "DailySessionView" && (
              <TableView View={"DailySessionView"} Pagination={true} />
            )}
          </div>
          {(View === "ViewGoals" ||
            View === "AssignGoal" ||
            View === "InProgressGoals") && (
            <DashboardMiniSidebar
              goalsStats={goalsStats}
              stats={stats}
              assignGoal={assignGoal}
              setdashboardView={setdashboardView}
              tech={tech}
              action={actionDone}
              setAction={setActionDone}
              InProgressGoals={InProgressGoals}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default ClientDailySessions;
