import React, { useEffect, useState } from "react";
import Styles from "./DashboardLogin.module.scss";
import lmauthfeatured from "../../assets/images/lm-auth-featured.svg";
import lmLogo from "../../assets/images/learn-me-logo-colored.svg";
import ImageView from "../../components/Image/ImageView";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ClientForm from "./ClientForm";
import { login } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";
import { setCurrentUser } from "../../store/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../helpers/utils";

const ClientLogin = ({ loggedInHandler }) => {
  const [creds, setCreds] = useState();
  const [disable, setDisbale] = useState(true);
  const dispatch = useDispatch();

  const loginSuccess = (username) => {
    dispatch(
      setCurrentUser({
        // name: username,
        role: username?.role,
        token: username.token,
      })
    );
  };
  const handleSignIn = async (data) => {
    const loggedUser = await login({
      user: {
        email: data.user.email,
        password: data.user.password,
        role: "client",
      },
    });
    if (loggedUser?.data?.token) {
      // createNotification("success", "Your are logged in");
      loginSuccess(loggedUser.data);
      setTimeout(() => {
        window.open("clientForm/onboardingform ", "_self");
      }, 500);
    } else {
      createNotification("error", "Invalid credentials");
    }
  };
  useEffect(() => {
    if (
      creds?.user?.email?.length &&
      emailRegex?.test(creds?.user?.email) &&
      creds.user?.password?.length
    ) {
      setDisbale(false);
    } else setDisbale(true);
  }, [creds]);
  return (
    <section className={Styles.DashboardLogin}>
      <div className={Styles.DashboardLogin__FormSection}>
        <div className={Styles.DashboardLogin__FormSectionWrapper}>
          <a href="#">
            <ImageView src={lmLogo} className="img-fluid mb-24" />
          </a>
          <Form
            className={`w-100 d-flex flex-column ${Styles.DashboardLogin__FormSectionWrapper_Client}`}
          >
            <div className="w-100 d-flex flex-column gap-70">
              <ClientForm setCreds={setCreds} creds={creds}></ClientForm>
            </div>
            <Button
              disabled={disable}
              onClick={() => {
                handleSignIn(creds);
              }}
              className="w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600"
            >
              Sign In
            </Button>
          </Form>
        </div>
      </div>
      <div className={Styles.DashboardLogin__ImageSection}>
        <section className={Styles.DashboardLogin__ImageSection_Wrapper}>
          <ImageView src={lmauthfeatured} className="img-fluid" />
        </section>
      </div>
    </section>
  );
};

export default ClientLogin;
