import React, { useEffect, useState } from "react";
import Styles from "../../Containers/Dashboard/Dashboard.module.scss";
import { mailReadIcon, mailUnreadIcon } from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
const Notification = ({
  note,
  timeAgo,
  title,
  read,
  id,
  actionDone,
  setActionDone,
}) => {
  const [isRead, setIsRead] = useState(read);
  const words = note.split("_"); // Split the input into an array of words
  const capitalizedWords = words.map(
    (word) => word.charAt(0)?.toUpperCase() + word.slice(1)
  );
  const noteResult = capitalizedWords.join(" ");
  useEffect(() => {
    setIsRead(read);
  }, [read]);
  const currentTime = new Date(); // Current time
  const previousTime = new Date(timeAgo); // Provided timestamp

  const timeDifference = currentTime.getTime() - previousTime.getTime(); // Time difference in milliseconds

  let timeResult;

  if (timeDifference < 1000 * 60) {
    timeResult = `${Math.abs(Math.floor(timeDifference / 1000))} seconds ago`;
  } else if (timeDifference < 1000 * 60 * 60) {
    timeResult = `${Math.abs(
      Math.floor(timeDifference / (1000 * 60))
    )} minutes ago`;
  } else if (timeDifference < 1000 * 60 * 60 * 24) {
    timeResult = `${Math.abs(
      Math.floor(timeDifference / (1000 * 60 * 60))
    )} hours ago`;
  } else if (timeDifference < 1000 * 60 * 60 * 24 * 7) {
    timeResult = `${Math.abs(
      Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    )} days ago`;
  } else {
    timeResult = `${Math.floor(
      timeDifference / (1000 * 60 * 60 * 24 * 7)
    )} weeks ago`;
  }

  const handleReadNotification = async (id, read) => {
    try {
      if (!read) {
        const res = await httpClient.put(`/api/v1/notifications/${id}`);
        if (res.data) {
          // setActionDone(!actionDone);
        }
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error?.response?.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      createNotification("error", error?.response?.data?.response.error);
      return false;
    }
  };
  return (
    <>
      <li
        onClick={() => {
          {
            handleReadNotification(id, read);
            setIsRead(true);
          }
        }}
        className={`${Styles.Dashboard__Notifications_Notification} ${
          !isRead && Styles.Dashboard__Notifications_Notification_Unread
        }`}
      >
        <div className="d-flex flex-column gap-24">
          <div className="d-flex justify-content-between">
            <span className={`f-18 lh-25 f-w-600 clr-dark-grey `}>
              {noteResult}
            </span>
            <span className="f-14 lh-19 f-w-600 clr-b-grey">{timeResult}</span>
          </div>
          <div
            className={`d-flex justify-content-between ${Styles.Dashboard__Notifications_Notification_TitleWrapper}`}
          >
            <span className={`f-16 lh-22 f-w-400 clr-dark-grey `}>
              {title}{" "}
            </span>
            <span style={{ minWidth: "20px" }}>
              {isRead ? mailReadIcon : mailUnreadIcon}
            </span>
          </div>
        </div>
      </li>
      <hr className="notification-divider"></hr>
    </>
  );
};

export default Notification;
