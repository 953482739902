import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableView from "../../components/TableViews/TableView";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import { GetCurrentUser, GetUserProfile } from "../../utils/utilAuth";
import PendingApproval from "./PendingApproval/PendingApproval";
import "./TechnicianDahaboard.scss";
import TodaysSession from "./TodaysSession/TodaysSession";
import fetchFormattedDateTime from "../../utils/datetimeUtlis";

const TechnicianDahaboard = ({ setPanelViewHandler }) => {
  const navigate = useNavigate();
  const user = GetCurrentUser();
  const profile = GetUserProfile();
  const [techDashboardData, setTechDashboardData] = useState();
  const currentDate = new Date();
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const weekday = currentDate.toLocaleDateString("en-US", { weekday: "long" });
  const createDataSheet = async (appId, slug) => {
    const currentDateTime =  fetchFormattedDateTime();
    try {
      const res = await httpClient.post(
        `/api/v1/data_sheets?appointment_id=${appId}`, {
          current_datetime: currentDateTime
        }
      );
      if (res.data) {
        const { data } = res;
        const { data_sheet_id, service_note_id } = data;
        // eslint-disable-next-line no-unused-expressions
        navigate(`/techdashboard/checkin/${data_sheet_id}/${slug}/${appId}`, {
          state: { techDashboard: true },
        }),
          setPanelViewHandler("checkin");
      }
      return res.data;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      return false;
    }
  };
  const createGoalsObjectives = async (appId, slug) => {
    try {
      const res = await httpClient.post(
        `/api/v1/data_sheets?appointment_id=${appId.id}`
      );
      if (res.data) {
        const { data } = res;
        const { data_sheet_id, service_note_id } = data;
        // eslint-disable-next-line no-unused-expressions
        navigate(
          `/techdashboard/goalsObjective/${data_sheet_id}/${appId.slug}`,
          {
            state: { techDashboard: true },
          }
        ),
          setPanelViewHandler("goalsObjective");
      }
      return res.data;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/dashboardlogin ", "_self");
        return;
      }
      console.log(error);
      return false;
    }
  };
  useEffect(() => {
    const formatedDateTime  = fetchFormattedDateTime();
    const getDashboardData = async () => {
      try {
        const res = await httpClient.get(
          `api/v1/users/technician_dashboard_details?id=${user?.slug}&date=${formatedDateTime}`
        );
        if (res.data) {
          setTechDashboardData(res?.data?.technician_dashboard_data);
        }
        return res.data;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          window.open("/dashboardlogin ", "_self");
          return;
        }
        console.log(error);
        // createNotification("error", error?.response?.data?.response.error);
        return false;
      }
    };
    if (user.slug) getDashboardData();
  }, [user.slug]);
  return (
    <>
      <section className="Technician-main-wrapper">
        {techDashboardData ? (
          <>
            <div className="TechnicianHeading">
              <div className="Technicianname">
                <h2>Hello {profile ? profile?.full_name : user?.name}</h2>
              </div>
              <div className="day-date">
                <p>{weekday}</p>
                <span>{formattedDate}</span>
              </div>
            </div>
            {!!techDashboardData?.today_sessions?.length ? (
              <TodaysSession
                sessionData={techDashboardData?.today_sessions}
                handleButtonClick={createDataSheet}
                handleStartSession={createGoalsObjectives}
              />
            ) : (
              <div className="no-more-session">
                No more assigned appointments for today
              </div>
            )}
            {!!techDashboardData?.awaiting_approval_sessions?.length && (
              <PendingApproval
                pendingApprovalData={
                  techDashboardData?.awaiting_approval_sessions
                }
                setPanelViewHandler={setPanelViewHandler}
              />
            )}
            {/* <div className="no-more-assigned">
          <p className="no-more-assigned-text">
            No More Assigned Appointments For Today
          </p>
        </div> */}
            <div className="Pending-Approval-Sessions-outertable mb-30">
              <div className="Pending-Approval-Sessions-outer">
                <div className="Pending-Approval-Sessions-heading mb-8">
                  <h3 className="Pending-Approval-Sessions-title">
                    Sessions Completed This Week
                  </h3>
                  <div className="Pending-Approval-Sessions-linkouter">
                    {!!techDashboardData?.current_week_completed_sessions
                      ?.length && (
                      <a
                        className="Pending-Approval-Sessions-link"
                        onClick={() => {
                          navigate("/techdashboard/sessions");
                          setPanelViewHandler("sessionCompleted");
                        }}
                      >
                        View all
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="tech-table-view-wrapper">
                <TableView
                  View={"TechCompletedSession"}
                  tableData={techDashboardData?.current_week_completed_sessions}
                />
              </div>
            </div>
          </>
        ) : (
          <Spin
            // size="40"
            // top={"50%"}
            // left={"50%"}
            // width={"100%"}
            // color="#090834"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        )}
      </section>
    </>
  );
};
export default TechnicianDahaboard;
