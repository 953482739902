import { useEffect, useState } from "react";
import CommonFormPage from "../CommonFormPage";
import { createNotification } from "../../../helpers/createNotifications";
import LoaderSpinner from "../../general/loader/loader.spinner";
import httpClient from "../../../services/axios";
import { logout } from "../../../store/user/userSlice";

const DisciplineInformation = ({ data, next, prev, id, setAction, action }) => {
  const questionInfo = [
    {
      id: 243,
      title: "disc_info_guard1_name",
      placeholder: "Complete Name",
      label: "Parent/Guardian 1:",
      wrapperClassName: "w-48",
      options: [],
      formater: 4,
      firstInput: true,
    },
    {
      id: 244,
      title: "disc_info_parent_guard1_relation",
      placeholder: "Relation",
      label: "Relationship to Child",
      wrapperClassName: "w-48",
      options: [],
    },
    {
      id: 245,
      title: "disc_info_guard2_name",
      placeholder: "Complete Name",
      label: "Parent/Guardian 2:",
      wrapperClassName: "w-48",
      options: [],
      formater: 4,
    },
    {
      id: 246,
      title: "disc_info_parent_guard2_relation",
      placeholder: "Relation",
      label: "Relationship to Child",
      wrapperClassName: "w-48",
      options: [],
    },
    {
      id: 247,
      title: "disc_info_disc_methods",
      placeholder: "",
      label: "What is typically used for disciplining your child/adolescent",
      wrapperClassName: "",
      checkBox: true,
      options: [
        {
          id: 222,
          title: "Timeout",
          key: "timeout",
        },
        {
          id: 223,
          title: "Assigning chores",
          key: "assigning_chores",
        },
        {
          id: 224,
          title: "Physical Punishment",
          key: "physical_punishment",
        },
        {
          id: 225,
          title: "Isolation",
          key: "isolation",
        },
        {
          id: 226,
          title: "Other (Please Specify)",
          key: "other",
        },
      ],
      gridView: true,
    },
    {
      id: 248,
      title: "disc_info_disc_methods_desc",
      placeholder: "Complete Name",
      label: "",
      wrapperClassName: "",
      options: [],
    },
    {
      id: 249,
      title: "disc_info_cultural_beliefs",
      placeholder: "",
      label:
        "Are there any spiritual/Cultural beliefs or values that you think may impact how you provide discipline or behavioral supports to your child?",
      wrapperClassName: "",
      options: [
        {
          id: 227,
          title: "Yes",
          key: "yes",
        },
        {
          id: 228,
          title: "No",
          key: "no",
        },
      ],
    },
    {
      id: 250,
      title: "disc_info_cultural_beliefs_desc",
      placeholder: "Details...",
      label: "If yes, please describe below:",
      wrapperClassName: "",
      options: [],
      inputType: true,
    },
  ];

  const [questionData, setQuestionData] = useState();
  useEffect(() => {
    const getQuestionData = async () => {
      try {
        let response;
        if (!questionData) {
          if (id) {
            response = await httpClient.get(
              `api/v1/sections/discipline-information-section/preview_client_section/${id}`
            );
          } else {
            response = await httpClient.get(
              "api/v1/sections/discipline-information-section"
            );
          }
          setQuestionData(response?.data);

          return response;
        }
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();
          if (id) {
            window.open("/dashboardlogin", "_self");
          } else {
            window.open("/clientFormlogin ", "_self");
          }
          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
      }
    };
    getQuestionData();
  }, [questionData, id]);
  const postData = async (postData) => {
    try {
      if (postData) {
        const res = await httpClient.post(
          "/api/v1/sections/discipline-information-section/update_or_create",
          {
            ...postData,
          }
        );
        setQuestionData(res.data);
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();
        window.open("/clientFormlogin ", "_self");
        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  {
    return (
      <>
        {questionData ? (
          <CommonFormPage
            data={questionData}
            questionInfo={questionInfo}
            prev={prev}
            halfWidth={true}
            postData={postData}
            next={next}
            last={true}
            setAction={setAction}
            action={action}
          />
        ) : (
          <LoaderSpinner
            tip="Loading"
            size="50"
            top={"50%"}
            left={"50%"}
            color="#090834"
          />
        )}
      </>
    );
  }
};
export default DisciplineInformation;
