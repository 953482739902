import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./Modals.scss";
const DeleteModal = (props) => {
  return (
    <Modal
      {...props}
      size={400}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <section className="d-flex flex-column gap-32 align-items-center">
          <div className="d-flex flex-column  gap-4 align-items-center">
            <h4 className="f-18 f-w-600 lh-28 clr-dark-gray-bold">
              Are You Sure?
            </h4>
            <p className="f-14 lh-20 f-w-400 clr-l-grey text-center">
              <div>Do you really want to delete the client session of </div>
              <span className="f-w-600 clr-sb-dark">“{props.clientname}”</span>.
            </p>
          </div>
          <div className="d-flex gap-12 w-100">
            <Button
              onClick={props.onHide}
              className={`w-100 lm-form-btn-modalbtn lm-form-btn-modalbtn-cancel f-16 f-w-600 `}
            >
              Cancel
            </Button>
            <Button
              className={`w-100 lm-form-btn-modalbtn lm-form-btn-modalbtn-UnArchive f-16 f-w-600 `}
              onClick={() => props.onHandleClick()}
            >
              Delete
              {}
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
