import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./ModalStyles.module.scss";
import thumbup from "../../assets/images/thumb-up.png";
import { logout } from "../../store/user/userSlice";
const StepCompletionModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`${styles.StepCompletionModal} ${
          props.stepCompleted !== 9 ? "" : styles.thankYouModal
        }`}
      >
        {props.stepCompleted !== 9 ? (
          <div className="d-flex align-items-center justify-content-start gap-24">
            <img src={thumbup}></img>
            <div
              className={`d-flex flex-column ${styles.StepCompletionModal__textSection}`}
            >
              <div className="d-flex flex-column">
                <h1 className={styles.StepCompletionModal__step_indicator}>
                  Step{" "}
                  <span
                    className={styles.StepCompletionModal__step_indicatorNumber}
                  >
                    {props.stepCompleted}/9
                  </span>
                </h1>
                <h1 className={styles.StepCompletionModal__step_indicator}>
                  Completed!
                </h1>
              </div>
              <span className={styles.StepCompletionModal__step_nextCaption}>
                {" "}
                Lets move on to {props.nextStepTitle}
              </span>
            </div>
          </div>
        ) : (
          <div
            className="thank-you-modal"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              alignItems: "center",
              width: "100%",
              padding: "0 0",
            }}
          >
            <h2
              className="heading"
              style={{
                fontWeight: 700,
                fontSize: "40px",
                lineHeight: "60.5px",
              }}
            >
              Thank You
            </h2>
            <p
              className="description"
              style={{
                fontWeight: 600,
                fontSize: "18px",
                textAlign: "center",
                maxWidth: "404px",
              }}
            >
              We will be getting back to you with suitable therapist soon
            </p>
            <button
              className="done-btn"
              onClick={() => {
                localStorage.removeItem(`persist:root`);

                logout();
                window.open("/clientFormlogin ", "_self");
              }}
              style={{
                width: "100%",
                maxWidth: "200px",
                padding: "12px 24px  12px 24px",
                borderRadius: "8px",
                color: "white",
                background: "#70BF44",
                border: "none",
                fontWeight: 600,
              }}
            >
              Done
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StepCompletionModal;
