import { Slider } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import StopwatchDisplay from "../Countdown/Countdown";
import RadioButtons from "../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";

export const ABC_BEHAVIOUR_FUNCTION = [
  { value: "Attention", key: "attention" },
  { value: "Escape", key: "escape" },
  { value: "Access", key: "access" },
  { value: "Sensory", key: "sensory" },
  { value: "Other", key: "function_other" },
];
export const ABC_BEHAVIOUR = [
  { value: "Cries", key: "cries" },
  { value: "Screams", key: "scream" },
  { value: "Hits", key: "hits" },
  { value: "Throw object", key: "throw_object" },
  { value: "Other", key: "behaviour_other" },
];
export const ABC_BEHAVIOUR_CONSEQUENCE = [
  { value: "Told no", key: "asked_no" },
  { value: "Ignored", key: "ignored" },
  { value: "Given what they wanted", key: "given_wanted" },
  { value: "Redirect to alternate behaviour", key: "redirect_alt_behaviour" },
  { value: "Other", key: "consequence_other" },
];
export const ABC_BEHAVIOUR_ANTECEDENT = [
  { value: "Was Told 'no'", key: "told_no" },
  { value: "Was asked to do something", key: "ask_something" },
  { value: "Attention given to others", key: "attention_to_other" },
  { value: "Transition", key: "transition" },
  { value: "Other", key: "antecedent_other" },
];

const AddAbc = ({
  setAction,
  action,
  serviceNoteApproved,
  isDataSheetLocked,
  intializeAutoSaveRequests,
  setIntializeAutoSaveRequests,
}) => {
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [timer, setTimer] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [reset, setReset] = useState(false);
  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };
  const [payload, setPayload] = useState({
    title: "",
    spent_time: "",
    intensity: 1,
    other_statements: [
      {
        function_statement: "",
        antecedent_statement: "",
        behavioural_statement: "",
        consequence_statement: "",
      },
    ],
  });

  useEffect(() => {
    setPayload({ ...payload, spent_time: timer });
  }, [timer]);

  const hanldeSave = async () => {
    try {
      if (payload) {
        setIsSaving(true);
        const res = await httpClient.post(
          "/api/v1/abc_behaviours/update_or_create",
          {
            data_sheet: {
              id: id,
              abc_behaviours_attributes: [{ ...payload }],
            },
          }
        );
        if (res.data) {
          setAction(!action);
          setReset(!reset);
          setPayload({
            title: "",
            spent_time: 0,
            intensity: 1,
            antecedent: "",
            other_statements: [
              {
                function_statement: "",
                antecedent_statement: "",
                behavioural_statement: "",
                consequence_statement: "",
              },
            ],
          });
          createNotification("success", "Abc Behaviour Saved");
        }
        setIsSaving(false);
        return true;
      }
    } catch (error) {
      setIsSaving(false);
      handleErrorResponse(error);
    }
  };

  const isPayloadCompleted = () => {
    const hasBasicFields =
      !!payload.title &&
      payload.antecedent &&
      payload.behaviour &&
      payload.consequence &&
      payload.function;

    if (!hasBasicFields) {
      return false;
    }

    const {
      function_statement,
      antecedent_statement,
      behavioural_statement,
      consequence_statement,
    } = payload.other_statements[0];

    const hasValidStatements =
      !(payload.antecedent === "antecedent_other" && !antecedent_statement) &&
      !(payload.behaviour === "behaviour_other" && !behavioural_statement) &&
      !(
        payload.consequence === "consequence_other" && !consequence_statement
      ) &&
      !(payload.function === "function_other" && !function_statement);

    return hasValidStatements;
  };

  const handleAutoSave = async () => {
    const payLoadCompleted = isPayloadCompleted();
    try {
      if (payLoadCompleted) {
        setIsSaving(true);
        const res = await httpClient.post(
          "/api/v1/abc_behaviours/update_or_create",
          {
            data_sheet: {
              id: id,
              abc_behaviours_attributes: [{ ...payload }],
            },
          }
        );
        if (res.data) {
          setAction(!action);
          setReset(!reset);
          setPayload({
            title: "",
            spent_time: 0,
            intensity: 1,
            antecedent: "",
            other_statements: [
              {
                function_statement: "",
                antecedent_statement: "",
                behavioural_statement: "",
                consequence_statement: "",
              },
            ],
          });
          createNotification("success", "Abc Behaviour Saved");
        }
        setIsSaving(false);
        return true;
      };
    } catch (error) {
      setIsSaving(false);
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (intializeAutoSaveRequests) {
      console.log("Saving ABC Behaviours");
      handleAutoSave();
      setIntializeAutoSaveRequests(false);
    };
  }, [intializeAutoSaveRequests]);

  return (
    <>
      <div className="accordian-wrapper">
        <TextFieldFormik
          label="Behaviour Title"
          placeholder="title"
          type="text"
          values={payload.title}
          handleChange={(e) =>
            setPayload({ ...payload, title: e.target.value })
          }
        />
        <RadioButtonsFormSection
          RadioButtons={ABC_BEHAVIOUR_ANTECEDENT.map((content) => {
            return (
              <RadioButtons
                id={content?.key}
                key={content?.key}
                forAbc={true}
                name={payload?.antecedent}
                onChange={(e) => {
                  const statment =
                    e.target.value === "antecedent_other"
                      ? payload.other_statements[0]?.antecedent_statement
                      : "";
                  setPayload({
                    ...payload,
                    antecedent: e.target.value,
                    other_statements: [
                      {
                        ...payload?.other_statements[0],
                        antecedent_statement: statment,
                      },
                    ],
                  });
                }}
                checked={payload?.antecedent === content.key}
                value={payload?.behaviour}
                Label={content.value}
                //   defaultChecked={item?.difficulty === content.key}
              />
            );
          })}
          wrapperClassName="abc-radio-btn"
          label={"Antecedent"}
        />
        <TextFieldFormik
          disabled={payload.antecedent === "antecedent_other" ? false : true}
          wrapperClassName="abc-text-input"
          placeholder="Enter Details"
          type="text"
          handleChange={(e) => {
            setPayload({
              ...payload,
              other_statements: [
                {
                  ...payload.other_statements[0],
                  antecedent_statement: e.target.value,
                },
              ],
            });
          }}
          values={payload?.other_statements[0]?.antecedent_statement}
        />
        <RadioButtonsFormSection
          RadioButtons={ABC_BEHAVIOUR.map((content) => {
            return (
              <RadioButtons
                id={content?.key}
                key={content?.key}
                forAbc={true}
                name={payload?.behaviour}
                onChange={(e) => {
                  const statment =
                    e.target.value === "behaviour_other"
                      ? payload.other_statements[0]?.behavioural_statement
                      : "";
                  setPayload({
                    ...payload,
                    behaviour: e.target.value,
                    other_statements: [
                      {
                        ...payload?.other_statements[0],
                        behavioural_statement: statment,
                      },
                    ],
                  });
                }}
                checked={payload?.behaviour === content.key}
                value={payload?.behaviour}
                Label={content.value}
              />
            );
          })}
          wrapperClassName="abc-radio-btn"
          label={"Behaviour"}
        />
        <TextFieldFormik
          disabled={payload.behaviour === "behaviour_other" ? false : true}
          wrapperClassName="abc-text-input"
          placeholder="Enter Details"
          type="text"
          handleChange={(e) => {
            setPayload({
              ...payload,
              other_statements: [
                {
                  ...payload.other_statements[0],
                  behavioural_statement: e.target.value,
                },
              ],
            });
          }}
          values={payload?.other_statements[0]?.behavioural_statement}
        />
        <RadioButtonsFormSection
          RadioButtons={ABC_BEHAVIOUR_CONSEQUENCE.map((content) => {
            return (
              <RadioButtons
                id={content?.key}
                key={content?.key}
                name={payload?.consequence}
                onChange={(e) => {
                  const statment =
                    e.target.value === "consequence_other"
                      ? payload?.other_statements[0]?.consequence_statement
                      : "";
                  setPayload({
                    ...payload,
                    consequence: e.target.value,
                    other_statements: [
                      {
                        ...payload?.other_statements[0],
                        consequence_statement: statment,
                      },
                    ],
                  });
                }}
                checked={payload?.consequence === content.key}
                value={payload?.consequence}
                Label={content.value}
                forAbc={true}

                //   defaultChecked={item?.difficulty === content.key}
              />
            );
          })}
          wrapperClassName="abc-radio-btn"
          label={"Consequences"}
        />
        <TextFieldFormik
          disabled={payload.consequence === "consequence_other" ? false : true}
          wrapperClassName="abc-text-input"
          placeholder="Enter Details"
          type="text"
          handleChange={(e) => {
            setPayload({
              ...payload,
              other_statements: [
                {
                  ...payload?.other_statements[0],
                  consequence_statement: e.target.value,
                },
              ],
            });
          }}
          values={payload?.other_statements[0]?.consequence_statement}
        />
        <RadioButtonsFormSection
          RadioButtons={ABC_BEHAVIOUR_FUNCTION.map((content) => {
            return (
              <RadioButtons
                id={content?.key}
                key={content?.key}
                onChange={(e) => {
                  const statment =
                    e.target.value === "function_other"
                      ? payload?.other_statements[0]?.function_statement
                      : "";
                  setPayload({
                    ...payload,
                    function: e.target.value,
                    other_statements: [
                      {
                        ...payload?.other_statements[0],
                        function_statement: statment,
                      },
                    ],
                  });
                }}
                checked={payload?.function === content.key}
                name={payload?.function}
                value={payload?.function}
                forAbc={true}
                Label={content.value}
                //   defaultChecked={item?.difficulty === content.key}
              />
            );
          })}
          wrapperClassName="abc-radio-btn"
          label={"Function"}
        />
        <TextFieldFormik
          type="text"
          disabled={payload?.function === "function_other" ? false : true}
          wrapperClassName="abc-text-input"
          placeholder="Enter Details"
          handleChange={(e) => {
            setPayload({
              ...payload,
              other_statements: [
                {
                  ...payload.other_statements[0],
                  function_statement: e.target.value,
                },
              ],
            });
          }}
          values={payload?.other_statements[0]?.function_statement}
        />

        <div className="mt-12">
          <p className="lm-input-form-input-label form-label">
            Time and Intensity
          </p>
          <div>
            <span> Timer</span>
            <StopwatchDisplay
              setTimer={setTimer}
              timer={timer}
              setDisabled={setDisabled}
              reset={reset}
            />
            <span>Intensity</span>
            <Slider
              marks={marks}
              step={1}
              defaultValue={1}
              min={1}
              style={{ margin: "0 16px" }}
              max={5}
              value={payload?.intensity}
              onChange={(val) => {
                setPayload({ ...payload, intensity: val });
              }}
            />
          </div>
        </div>
      </div>
      <div className="abc-footer-wrapper">
        <div
          className="objective-btns-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            gap: "12px",
          }}
        >
          <button
            onClick={() => {
              setReset(!reset);
              setPayload({
                title: "",
                spent_time: 0,
                intensity: 1,
                antecedent: "",
                other_statements: [
                  {
                    function_statement: "",
                    antecedent_statement: "",
                    behavioural_statement: "",
                    consequence_statement: "",
                  },
                ],
              });
            }}
            disabled={disabled || serviceNoteApproved || isDataSheetLocked}
            className="save-btn"
          >
            Reset
          </button>
          <button
            onClick={() => hanldeSave()}
            className="save-btn"
            disabled={
              disabled || serviceNoteApproved || isSaving || isDataSheetLocked
            }
          >
            {saveBtnText}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddAbc;
