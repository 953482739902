import React from "react";
import Form from "react-bootstrap/Form";
const FormCheckBox = ({ id, label, onClick, checked }) => {
  return (
    <Form.Check
      className={"FormCheck"}
      type={"checkbox"}
      id={id}
      label={label}
      checked={checked}
      onClick={onClick}
    />
  );
};

export default FormCheckBox;
