
import React from "react";
import Image from "react-bootstrap/Image";

const ImageView = ({ src, alt, className, rounded }) => {
  return (
    <Image
      role="img"
      src={src}
      alt={alt}
      className={className}
      rounded={rounded}
    />
  );
};

export default ImageView;
