import { Form, Formik, FormikProvider } from "formik";
import { useEffect, useRef, useState } from "react";
import RadioButtons from "../../form-controls/input/RadioButtonsSection/RadioButtons";
import RadioButtonsFormSection from "../../form-controls/input/RadioButtonsSection/RadioButtonsFormSection";
import { TextFieldFormik } from "../../form-controls/input/text.field.formik";
// import InnerFooter from "./InnerFooter";
import { createNotification } from "../../../helpers/createNotifications";

import dayjs from "dayjs";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  NumberFormat,
  validateEmail,
  validateName,
} from "../../../helpers/utils";
import httpClient from "../../../services/axios";
import { GetCurrentUser } from "../../../utils/utilAuth";
import LoaderSpinner from "../../general/loader/loader.spinner";

const CommonCheckOutForm = ({
  data,
  questionInfo,
  prev,
  next,
  halfWidth,
  postData,
  last,
  idCheck,
  savedSignature,
  savedSignatureTwo,
  setdashboardView,
  setSignatureDisable,
  checkedOutLocked,
  timeOver,
  formStatus,
  changeFormStatus,
}) => {
  const location = useLocation();
  const formSubmitButtonRef = useRef();
  const propsReceived = location.state;
  const { id, dataSheetId, appointId, serviceId } = useParams();
  const user = GetCurrentUser();
  const [mappingData, setMappingData] = useState([...data?.questions]);
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [errorsLength, setErrorsLength] = useState(null);
  const [customData, setCustomData] = useState([
    ...data?.questions?.filter(
      (item) => item?.question_type === "custom_with_text"
    ),
  ]);
  const serviceNoteApproved = data?.service_note_approved;
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);
  const ABC_BEHAVIOUR = [
    { value: "Cries", key: "cries" },
    { value: "Screams", key: "scream" },
    { value: "Hits", key: "hits" },
    { value: "Throw object", key: "throw_object" },
    { value: "Other", key: "behaviour_other" },
    { value: "Told no", key: "asked_no" },
    { value: "Ignored", key: "ignored" },
    { value: "Given what they wanted", key: "given_wanted" },
    { value: "Redirect to alternate behaviour", key: "redirect_alt_behaviour" },
    { value: "Other", key: "consequence_other" },
    { value: "Was Told 'no'", key: "told_no" },
    { value: "Was asked to do something", key: "ask_something" },
    { value: "Attention given to others", key: "attention_to_other" },
    { value: "Transition", key: "transition" },
    { value: "Other", key: "antecedent_other" },
  ];

  let isSubmitting = false;
  let isFormatSubmit = true;
  const formatValue = (value, formatNumber = null) => {
    switch (formatNumber) {
      case 1:
        return NumberFormat(value, "xxxx-xxxx-xxxx");
      case 2:
        return NumberFormat(value, "(xxx) xxx-xxxx");
      case 5:
        return value;
      case 8:
        if (value) {
          const val = ABC_BEHAVIOUR.find((obj) => obj?.key === value)?.value;
          if (val) {
            return val;
          } else {
            return value;
          }
        }
      default:
        return value;
    }
  };

  function fileToObject(file) {
    const { name, type, size } = file;
    const fileObject = {
      name,
      type,
      size,
    };

    return fileObject;
  }

  const convertToFormData = (base64String, contentType, filename) => {
    const encodedData = base64String?.split(",")[1];
    const byteCharacters = atob(encodedData);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);

    const blob = new Blob([byteArray], { type: contentType });

    const file = new File([blob], filename, { type: contentType });

    return file;
  };

  useEffect(() => {
    const questions = mappingData;
    if (questions.find((item) => item?.question_type === "custom")) {
      const customWithTextObject = {
        batch_no: 1,
        question_type: "custom_with_text",
        customObjects: customData,
      };
      const newData = questions?.filter(
        (item) => item?.question_type !== "custom_with_text"
      );
      const customWithTextObjects = [];
      const parentIndices = {};
      const parentObjects = [];
      const visitedParents = new Set();

      customData.forEach((customItem) => {
        const parentID = customItem?.meta?.parent_question_id;

        if (!visitedParents.has(parentID)) {
          parentObjects.push(customItem);
          visitedParents.add(parentID);
        }
      });
      const noAnswers = [];
      customData.forEach((customItem) => {
        if (customItem?.answers?.length) {
          parentObjects.forEach((parentItem, parentIndex) => {
            const parentID = customItem?.meta?.parent_question_id;
            parentItem.answers.forEach((answer, index) => {
              const batchNo = answer?.meta?.batch_no;
              if (!parentIndices.hasOwnProperty(parentID + index)) {
                if (
                  parentItem.answers[index] &&
                  parentID === parentItem?.meta?.parent_question_id
                ) {
                  parentIndices[parentID + index] =
                    customWithTextObjects.length;
                  customWithTextObjects.push({
                    batch_no: batchNo,
                    question_type: "custom_with_text",
                    customObjects: [],
                    parent_id: parentID,
                  });
                }
              }

              if (parentItem.meta?.parent_question_id === parentID) {
                customWithTextObjects[
                  parentIndices[parentID + index]
                ]?.customObjects.push(customItem);
              }
            });
          });
        } else {
          if (!noAnswers.includes(customItem)) {
            noAnswers.push(customItem);
          }
        }
      });
      if (noAnswers?.length) {
        noAnswers.forEach((customItem) => {
          const parentID = customItem?.meta?.parent_question_id;
          if (!parentIndices.hasOwnProperty(parentID)) {
            parentIndices[parentID] = customWithTextObjects.length;
            customWithTextObjects.push({
              batch_no: 1,
              question_type: "custom_with_text",
              customObjects: [],
              parent_id: parentID,
            });
          }
          customWithTextObjects[parentIndices[parentID]].customObjects.push(
            customItem
          );
        });
      }

      const tempData = newData.flatMap((item) => {
        if (
          item?.question_type === "custom" ||
          item?.meta.key === "medical_info_current_medications" ||
          item?.meta.key === "medical_info_current_diagnosis" ||
          item?.meta.key === "home_family_sblings"
        ) {
          if (customData[0]?.answers?.length) {
            return [
              item,
              ...customWithTextObjects.filter(
                (object) => object.parent_id === item.id
              ),
            ];
          } else {
            const parentID = item.id;
            const parentIndex = parentIndices[parentID];
            const customWithTextObject = customWithTextObjects[parentIndex];
            return [item, customWithTextObject];
          }
        } else {
          return item;
        }
      });
      const finalData = tempData.filter((data) => {
        if (data?.customObjects) {
          if (data?.customObjects?.length) {
            return data;
          } else {
            return;
          }
        } else {
          return data;
        }
      });

      finalData.sort((a, b) => {
        if ("parent_id" in a && "parent_id" in b) {
          if (a.parent_id < b.parent_id) {
            return -1;
          }
          if (a.parent_id > b.parent_id) {
            return 1;
          }
        }
        if (a.batch_no < b.batch_no) {
          return -1;
        }
        if (a.batch_no > b.batch_no) {
          return 1;
        }
        return 0;
      });
      setMappingData([...finalData]);
    }
  }, [data]);

  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const navigate = useNavigate();
  function startsWithHttp(inputString) {
    return typeof inputString === "string" && inputString.startsWith("http");
  }
  const generateSubmitRequest = async (values) => {
    setLoading(true);
    if (savedSignature) {
      try {
        const submitted = await postData({
          resource: "check_out_form",
          appointment_id: id,
          form_status: "completely_filled",
          therapist_signature_image: startsWithHttp(savedSignature)
            ? savedSignature
            : convertToFormData(
                savedSignature,
                "image/png",
                "therapist-signature"
              ),

          section: {
            questions_attributes: values.section.questions_attributes,
          },
        });
        if (submitted) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      return;
    }
  };

  const generatePartiallyFilledRequest = async (values) => {
    setLoading(true);
    try {
      const submitted = await postData({
        resource: "check_out_form",
        appointment_id: id,
        form_status: "partially_filled",

        section: {
          questions_attributes: values.section.questions_attributes,
        },
      });
      if (submitted) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSendBack = async () => {
    try {
      if (route?.includes("dashboardT")) {
        navigate(`/dashboardT/serviceNote/${id}/${serviceId}/${dataSheetId}`, {
          state: { ...propsReceived },
        });
      } else {
        navigate(`/dashboard/serviceNote/${id}/${serviceId}/${dataSheetId}`, {
          state: { ...propsReceived },
        });
      }
      setdashboardView("serviceNote");
      return true;
    } catch (error) {
      createNotification("error", error?.response?.data?.response?.message);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const handlenext = async () => {
    if (dataSheetId) {
      try {
        if (route?.includes("techdashboard")) {
          navigate(`/techdashboard/servicenote/${id}/${dataSheetId}`);
        } else {
          navigate(`/techdashboard`);
        }
        setdashboardView("serviceNote");
        return true;
      } catch (error) {
        createNotification("error", error?.response?.data?.response?.message);
        console.log("Something Went wrong", error.message);
        return false;
      }
    }
  };

  function filterQuestionsByIndexes(values) {
    if (timeOver) {
      const radioQuestionsIndexes = [4, 6, 8, 10];
      const eliminatedQuestionIndexes = [];
      radioQuestionsIndexes.forEach((index) => {
        const question = values.section.questions_attributes[index];

        if (!question.answers_attributes[0].option_id) {
          eliminatedQuestionIndexes.push(index);
        }
      });
      const filteredQuestions = values.section.questions_attributes.filter(
        (question, index) => !eliminatedQuestionIndexes.includes(index)
      );
      values.section.questions_attributes = filteredQuestions;
      console.log("eliminatedQuestionIndexes", eliminatedQuestionIndexes);
    }
  }

  useEffect(() => {
    setSignatureDisable(dirty && errorsLength === 0);
    console.log(dirty, errorsLength);
  }, [errorsLength, dirty]);

  useEffect(() => {
    const submitBtn = formSubmitButtonRef.current;
    if (submitBtn && timeOver) {
      console.log("Doing submission");
      submitBtn.disabled = false;
      submitBtn.click();
      submitBtn.disabled = true;
    }
  }, [timeOver]);

  const submitResults = async (values) => {
    filterQuestionsByIndexes(values);

    if (timeOver && !savedSignature) {
      generatePartiallyFilledRequest(values);
    } else {
      generateSubmitRequest(values);
    }
  };

  return (
    <FormikProvider>
      <Formik
        validateOnMount
        initialValues={{
          section: {
            questions_attributes: data?.questions?.map((question) => {
              if (question.question_type !== "custom") {
                const answer = question?.options?.length
                  ? question.answers.length
                    ? question.answers.map((answer) => {
                        return {
                          id: answer?.id,
                          option_id: answer.option_id,
                          meta: {},
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                        };
                      })
                    : [
                        {
                          id: question?.answers[0]?.id,
                          user_section_mapping_id:
                            data?.user_section_mapping_id,
                          option_id: question?.answers[0]?.option_id,
                        },
                      ]
                  : question?.answers?.length
                  ? question.answers.map((answer) => {
                      return {
                        id: answer?.id,
                        statement: answer.statement,
                        meta: {
                          batch_no: answer?.meta?.batch_no,
                          // parent_id: answer?.meta?.parent_id,
                        },
                        user_section_mapping_id: data?.user_section_mapping_id,
                        _destroy: answer._destroy || false,
                      };
                    })
                  : [
                      {
                        id: question?.answers[0]?.id,
                        statement: question?.answers[0]?.statement,
                        user_section_mapping_id: data?.user_section_mapping_id,
                      },
                    ];
                return {
                  id: question.id,
                  answers_attributes: answer,
                };
              } else {
                return;
              }
            }),
          },
        }}
        onSubmit={submitResults}
        // enableReinitialize
        validate={(values) => {
          const errors = {};
          values.section.questions_attributes.map((item, index) => {
            if (item?.answers_attributes?.length === 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    if (index < 12 || index > 27) {
                      //Here check box ques with parent quest b/w 12 to 27
                      const radioAdditionalTextQuestions = [5, 7, 9, 11];
                      if (radioAdditionalTextQuestions.includes(index)) {
                        delete errors[item.id];
                        if (
                          !values.section.questions_attributes[index]
                            .answers_attributes[0].statement
                        ) {
                          values.section.questions_attributes[
                            index
                          ].answers_attributes[0].statement = "-";
                        }
                      } else {
                        if (timeOver) {
                          const lastTextQuestionIndexes = [28, 29, 30, 31];
                          if (lastTextQuestionIndexes.includes(index)) {
                            delete errors[item.id];
                            if (
                              !values.section.questions_attributes[index]
                                .answers_attributes[0].statement
                            ) {
                              values.section.questions_attributes[
                                index
                              ].answers_attributes[0].statement = "-";
                            }
                          }
                        } else {
                          errors[item.id] = "This field is requireds";
                        }
                      }
                    } else {
                      if (answer?.meta?.checkedField) {
                        if (!answer?.statement && !answer?._destroy) {
                          if (timeOver) {
                            answer.statement = "-";
                          } else {
                            errors[item.id] = "This field is required";
                          }
                        } else {
                          delete errors[item.id];
                        }
                      }
                    }
                  }
                }
              }
            } else if (item?.answers_attributes?.length > 1) {
              const answer = item.answers_attributes[0];
              if (!answer?.statement && !answer?.option_id) {
                if (
                  !data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      (question.question_type === "custom" ||
                        question.question_type === null)
                  )
                ) {
                  const customWithText = data.questions.some(
                    (question) =>
                      question.id === item.id &&
                      question.question_type === "custom_with_text"
                  );
                  if (customWithText) {
                    errors[item.id + `-${1}`] = "This field is required";
                  } else {
                    errors[item.id] = "This field is required";
                  }
                }
              }
              item?.answers_attributes.map((answer, index) => {
                if (
                  !answer?.statement &&
                  !answer?.option_id &&
                  !answer?._destroy
                ) {
                  index > 0 &&
                    (errors[item.id + `-${answer?.meta?.batch_no}`] =
                      "This field is required");
                }
              });
            }
            return errors;
          });
          // if (isSubmitting && isFormatSubmit) {
          //   return errors;
          // }
          setErrorsLength(Object.keys(errors).length);
          return errors;
          // }
        }}
      >
        {({
          handleSubmit,
          errors,
          values,
          handleChange,
          setValues,
          isValid,
          dirty,
        }) => {
          setDirty(dirty);

          const handleForamtion = (value, formater, id, batch_no) => {
            if (value || value === 0) {
              switch (formater) {
                case 2: {
                  const formatedValue = NumberFormat(value, "(xxx) xxx-xxxx");
                  if (formatedValue && formatedValue.length === 14) {
                    const parsedPhoneNumber = parsePhoneNumberFromString(
                      formatedValue,
                      "US"
                    );
                    const isValid =
                      parsedPhoneNumber && parsedPhoneNumber.isValid();
                    if (!isValid) {
                      errors[id] = "Please enter valid number";
                    } else delete errors[id];
                  } else if (
                    formatedValue &&
                    formatedValue.length > 1 &&
                    formatedValue.length < 14
                  ) {
                    errors[id] = "Please enter complete value";
                  } else {
                    delete errors[id];
                  }
                  return formatedValue;
                }
                case 3: {
                  const formatedValue = validateEmail(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "Please enter email with correct format";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 4: {
                  const formatedValue = validateName(value);
                  if (!formatedValue && value.length > 0) {
                    errors[id] = "You have entered wrong value";
                  } else if (formatedValue) {
                    delete errors[id];
                  }
                  return value;
                }
                case 5: {
                  if (value <= 0 || value > 12) {
                    errors[id] = "Value must be from 0 to 12";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 6: {
                  if (value === 0) {
                    errors[id] = "Enter valid value";
                  } else {
                    delete errors[id];
                  }
                  return value;
                }
                case 7: {
                  if (value) {
                    function convertToAMPM(utcDateString) {
                      const utcDate = new Date(utcDateString);

                      const hours = utcDate.getHours();
                      const minutes = utcDate.getMinutes();
                      let hours12 = hours % 12;
                      hours12 = hours12 === 0 ? 12 : hours12;
                      const period = hours < 12 ? "AM" : "PM";
                      const formattedTime = `${hours12}:${minutes
                        .toString()
                        .padStart(2, "0")} ${period}`;

                      return formattedTime;
                    }
                    return convertToAMPM(value);
                  }

                  return null;
                }
                case 9: {
                  if (value) {
                    const val = [
                      { key: "in_home", value: "Home" },
                      { key: "in_clinic", value: "In Clinic" },
                    ].find((obj) => obj?.key === value)?.value;
                    if (val) {
                      return val;
                    } else {
                      return value;
                    }
                  }
                }
                case 10: {
                  if (value) {
                    const formatDate = (date) => {
                      const data = new Date(date);
                      if (data) {
                        const formattedDate = dayjs(data).format("MM-DD-YYYY");
                        return formattedDate;
                      }
                    };
                    return formatDate(value);
                  }
                }
                default:
                  return value;
              }
            }
          };

          const transformedMappingData = mappingData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.batch_no === value.batch_no &&
                  t.id === value.id &&
                  t.parent_id === value.parent_id
              )
          );
          return (
            <Form onSubmit={handleSubmit}>
              <fieldset
                disabled={
                  checkedOutLocked ||
                  (formStatus == "partially_filled" &&
                    user?.role == "technician")
                }
              >
                <div
                  className={`d-flex gap-16 flex-wrap ${
                    halfWidth ? "max-w-input-of" : "w-100"
                  }`}
                >
                  {transformedMappingData?.map((item) => {
                    let currentItem = questionInfo.find(
                      (i) => i?.title === item?.title
                    );
                    switch (item?.question_type) {
                      case "text_input":
                        const index =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id === item?.id
                          );
                        const isCustom = data?.questions.find(
                          (question) =>
                            question?.id === item?.meta?.parent_question_id
                        );

                        let currentOptionalParent;

                        if (isCustom?.question_type === "optional") {
                          currentOptionalParent =
                            values.section.questions_attributes?.find(
                              (question) => question?.id === isCustom?.id
                            );

                          const index =
                            values.section.questions_attributes.findIndex(
                              (index) => index?.id === item?.id
                            );
                          const containsItem =
                            values.section.questions_attributes.find(
                              (question) => question?.id === item?.id
                            );

                          containsItem.answers_attributes.map(
                            (item) => (item._destroy = true)
                          );

                          if (
                            containsItem &&
                            currentOptionalParent?.answers_attributes[0]?.meta
                              ?.optionalOut
                          ) {
                            if (
                              currentOptionalParent?.answers_attributes[0]?.meta
                                .checked
                            ) {
                              containsItem?.answers_attributes.map((item) => {
                                item._destroy = false;
                                item.meta = {
                                  checkedField: true,
                                };
                              });
                            } else {
                              containsItem?.answers_attributes.map((item) => {
                                item._destroy = true;
                                item.meta = {
                                  checkedField: true,
                                };
                              });
                            }
                          }
                        }

                        if (
                          item?.meta.parent_question_id &&
                          isCustom?.question_type === "radio_with_description"
                        ) {
                          const parentAnswer =
                            values.section.questions_attributes.find(
                              (index) =>
                                index?.id == item?.meta.parent_question_id
                            )?.answers_attributes[0]?.option_id;
                          const descType = currentItem?.descType;
                          const yesOptionId = data.questions
                            .find(
                              (question) =>
                                question.id === item?.meta.parent_question_id
                            )
                            .options.find(
                              (option) =>
                                option.meta.key ===
                                (descType ? descType : "yes")
                            )?.id;
                          const compare = parentAnswer == yesOptionId;

                          if (!compare && !!item.meta.parent_question_id) {
                            const index =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === item?.id
                              );
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (containsItem) {
                              values.section.questions_attributes.splice(
                                index,
                                1
                              );
                            }
                          } else if (
                            compare &&
                            !!item.meta.parent_question_id
                          ) {
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (!containsItem) {
                              values.section.questions_attributes.push({
                                id: item?.id,
                                answers_attributes: [
                                  {
                                    statement: "",
                                    user_section_mapping_id:
                                      data?.user_section_mapping_id,
                                  },
                                ],
                              });
                            }
                          }

                          return (
                            compare && (
                              <>
                                <TextFieldFormik
                                  disabled={id ? true : false}
                                  key={currentItem.id}
                                  placeholder={currentItem.placeholder}
                                  label={currentItem.label}
                                  type={
                                    currentItem?.fieldType
                                      ? currentItem.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  firstInputRef={
                                    currentItem?.firstInput
                                      ? firstInputRef
                                      : null
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem.wrapperClassName
                                      ? currentItem.wrapperClassName
                                      : "max-w-input-of w-1 00"
                                  }
                                  tabIndex={currentItem.tabIndex}
                                  name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  values={
                                    values?.section?.questions_attributes[index]
                                      ?.answers_attributes[0]?.statement
                                  }
                                  errors={errors[item?.id]}
                                  birthDate={
                                    currentItem.birthDate &&
                                    currentItem.birthDate
                                  }
                                />
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "#B1B1B1",
                                  }}
                                >
                                  {currentItem?.message
                                    ? currentItem?.message
                                    : ""}
                                </span>
                              </>
                            )
                          );
                        } else {
                          const isCustom = data?.questions.find(
                            (question) => question?.title === currentItem?.title
                          );

                          return (
                            <>
                              {currentItem?.twoCol && (
                                <div className="two-col-wrapper">
                                  <span className="two-col-heading">
                                    {currentItem?.heading}
                                  </span>
                                  <div className="two-col-titles">
                                    <span>{currentItem?.colOneTitle}</span>
                                    <span>{currentItem?.colTwoTitle}</span>
                                  </div>
                                </div>
                              )}
                              {currentItem?.topLabel && (
                                <label
                                  className={`lm-input-form-input-label form-label }`}
                                >
                                  {currentItem?.topLabel}
                                </label>
                              )}
                              {isCustom?.meta?.parent_question_id &&
                              currentOptionalParent?.answers_attributes[0]?.meta
                                ?.checked ? (
                                <TextFieldFormik
                                  disabled={
                                    user.role === "director" ||
                                    currentItem?.disabled
                                      ? true
                                      : false
                                  }
                                  key={currentItem?.id}
                                  placeholder={currentItem?.placeholder}
                                  label={currentItem?.label}
                                  firstInputRef={
                                    currentItem?.firstInput
                                      ? firstInputRef
                                      : null
                                  }
                                  type={
                                    currentItem?.fieldType
                                      ? currentItem.fieldType
                                      : currentItem?.inputType
                                      ? "textarea"
                                      : "text"
                                  }
                                  pattern={currentItem?.pattern}
                                  wrapperClassName={
                                    currentItem?.wrapperClassName
                                      ? currentItem?.wrapperClassName
                                      : "max-w-input-of w-100"
                                  }
                                  tabIndex={currentItem?.tabIndex}
                                  birthDate={
                                    currentItem?.birthDate &&
                                    currentItem?.birthDate
                                  }
                                  name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                  handleChange={handleChange}
                                  values={handleForamtion(
                                    values.section.questions_attributes[
                                      index
                                    ]?.answers_attributes.find(
                                      ({ _destroy }) => !_destroy
                                    )?.statement,
                                    currentItem?.formater,
                                    item?.id
                                  )}
                                  errors={errors[item?.id]}
                                />
                              ) : (
                                !isCustom?.meta?.parent_question_id && (
                                  <TextFieldFormik
                                    disabled={
                                      user.role === "director" ||
                                      currentItem?.disabled
                                        ? true
                                        : false || serviceNoteApproved
                                    }
                                    key={currentItem?.id}
                                    placeholder={currentItem?.placeholder}
                                    label={currentItem?.label}
                                    firstInputRef={
                                      currentItem?.firstInput
                                        ? firstInputRef
                                        : null
                                    }
                                    type={
                                      currentItem?.fieldType
                                        ? currentItem.fieldType
                                        : currentItem?.inputType
                                        ? "textarea"
                                        : "text"
                                    }
                                    pattern={currentItem?.pattern}
                                    wrapperClassName={
                                      currentItem?.wrapperClassName
                                        ? currentItem?.wrapperClassName
                                        : "max-w-input-of w-100"
                                    }
                                    tabIndex={currentItem?.tabIndex}
                                    birthDate={
                                      currentItem?.birthDate &&
                                      currentItem?.birthDate
                                    }
                                    name={`section.questions_attributes[${index}].answers_attributes[0].statement`}
                                    handleChange={handleChange}
                                    values={handleForamtion(
                                      values.section.questions_attributes[
                                        index
                                      ]?.answers_attributes.find(
                                        ({ _destroy }) => !_destroy
                                      )?.statement,
                                      currentItem?.formater,
                                      item?.id
                                    )}
                                    errors={errors[item?.id]}
                                  />
                                )
                              )}
                            </>
                          );
                        }
                      case "optional":
                        const indexOptional =
                          values.section.questions_attributes.findIndex(
                            (index) => index?.id == item?.id
                          );
                        const displayOptional = false;
                        const handleOptionalChange = (value) => {};
                        if (!item?.options?.length) {
                          delete errors[item?.id];
                        }
                        if (!currentItem?.checkBox) {
                          return (
                            <>
                              {currentItem?.firstOption && (
                                <label
                                  className={`lm-input-form-input-label form-label w-100 }`}
                                >
                                  {currentItem?.firstOptionLabel}
                                </label>
                              )}
                              {currentItem?.descriptionText
                                ? currentItem?.descriptionText
                                : ""}
                              <RadioButtonsFormSection
                                errors={errors[item?.id]}
                                values={values}
                                optionalError={errors}
                                optionalTypeOut={true}
                                optionalType={true}
                                item={item}
                                setValues={setValues}
                                onOptionalChange={handleOptionalChange}
                                userMapping={data?.user_section_mapping_id}
                                displayOptional={displayOptional}
                                RadioButtons={currentItem?.options.map(
                                  (content) => {
                                    const currentOption = item.options.find(
                                      (option) => option.meta.key == content.key
                                    );
                                    return (
                                      <RadioButtons
                                        disabled={
                                          user.role === "director" ||
                                          currentItem?.disabled
                                            ? true
                                            : false
                                        }
                                        id={currentOption?.id}
                                        key={currentOption?.id}
                                        name={`section.questions_attributes[${indexOptional}].answers_attributes[0].meta.checked`}
                                        onChange={handleChange}
                                        values={
                                          values?.section.questions_attributes[
                                            indexOptional
                                          ].answers_attributes.length &&
                                          currentOption.id ==
                                            values?.section
                                              .questions_attributes[
                                              indexOptional
                                            ].answers_attributes.length &&
                                          currentOption.id ==
                                            values?.section
                                              .questions_attributes[
                                              indexOptional
                                            ].answers_attributes[0].option_id
                                        }
                                        firstInputRef={
                                          content?.firstInput
                                            ? firstInputRef
                                            : null
                                        }
                                        Label={content.title}
                                        tabIndex={content.tabIndex}
                                        checked={
                                          values?.section.questions_attributes[
                                            indexOptional
                                          ].answers_attributes.length &&
                                          currentOption.id ==
                                            values?.section
                                              .questions_attributes[
                                              indexOptional
                                            ].answers_attributes.length &&
                                          currentOption.id ==
                                            values?.section
                                              .questions_attributes[
                                              indexOptional
                                            ].answers_attributes[0].option_id
                                        }
                                      />
                                    );
                                  }
                                )}
                                label={currentItem?.label}
                                wrapperClassName={`${
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }`}
                                GridView={currentItem?.gridView}
                              />
                            </>
                          );
                        } else {
                          return (
                            <>
                              {currentItem?.descriptionText ? (
                                <span
                                  style={{ fontWeight: 600, fontSize: "15px" }}
                                >
                                  {currentItem?.descriptionText}
                                </span>
                              ) : (
                                ""
                              )}
                              <RadioButtonsFormSection
                                errors={errors[item?.id]}
                                values={values}
                                optionalError={errors}
                                optionalTypeOut={true}
                                item={item}
                                setValues={setValues}
                                onOptionalChange={handleOptionalChange}
                                userMapping={data?.user_section_mapping_id}
                                displayOptional={displayOptional}
                                RadioButtons={currentItem.options.map(
                                  (content) => {
                                    const currentOption = item.options.find(
                                      (option) => option.meta.key == content.key
                                    );
                                    const answers =
                                      values.section.questions_attributes[
                                        indexOptional
                                      ]?.answers_attributes;
                                    let answer = answers?.find(
                                      (answer) =>
                                        answer.option_id == currentOption?.id
                                    );
                                    const isChecked =
                                      answer && !answer._destroy;
                                    return (
                                      <RadioButtons
                                        disabled={true}
                                        id={currentOption?.id}
                                        key={currentOption?.id}
                                        firstInputRef={
                                          content?.firstInput
                                            ? firstInputRef
                                            : null
                                        }
                                        onChange={(e) => {
                                          if (
                                            e.target.offsetParent.classList.contains(
                                              "checked"
                                            )
                                          ) {
                                            e.target.offsetParent.classList.remove(
                                              "checked"
                                            );
                                          } else {
                                            e.target.offsetParent.classList.add(
                                              "checked"
                                            );
                                          }

                                          if (e.target.checked) {
                                            if (
                                              answers[0].option_id === undefined
                                            ) {
                                              answers[0].option_id = parseInt(
                                                e.target.value
                                              );
                                            } else {
                                              if (answer) {
                                                answer._destroy = false;
                                              } else {
                                                answer = {
                                                  option_id: parseInt(
                                                    e.target.value
                                                  ),
                                                  user_section_mapping_id:
                                                    data.user_section_mapping_id,
                                                };
                                                answers.push(answer);
                                              }
                                            }
                                          } else {
                                            if (answer) {
                                              answer._destroy = true;
                                            }
                                          }
                                        }}
                                        values={currentOption?.id}
                                        Label={content.title}
                                        tagCheckbox={true}
                                        tabIndex={content.tabIndex}
                                        checkBox={currentItem?.checkBox}
                                        defaultChecked={isChecked}
                                      />
                                    );
                                  }
                                )}
                                label={currentItem?.label}
                                wrapperClassName={`${
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }`}
                                GridView={currentItem?.gridView}
                              />
                            </>
                          );
                        }

                      case "radio":
                        const indexRadio =
                          values?.section?.questions_attributes?.findIndex(
                            (index) => index?.id == item.id
                          );
                        const isCustomType = data.questions.find(
                          (question) =>
                            question.id == item.meta.parent_question_id
                        );

                        if (
                          item.meta.parent_question_id &&
                          isCustomType.question_type == "radio_with_description"
                        ) {
                          const parentAnswer =
                            values.section.questions_attributes.find(
                              (index) =>
                                index.id == item.meta.parent_question_id
                            ).answers_attributes[0]?.option_id;

                          const yesOptionId = data.questions
                            .find(
                              (question) =>
                                question.id === item.meta.parent_question_id
                            )
                            ?.options.find(
                              (option) =>
                                option?.meta?.key ===
                                (currentItem?.descType
                                  ? currentItem?.descType
                                  : "yes")
                            )?.id;
                          const compare = parentAnswer == yesOptionId;
                          if (!compare && !!item.meta.parent_question_id) {
                            const index =
                              values.section.questions_attributes.findIndex(
                                (index) => index?.id === item?.id
                              );
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (containsItem) {
                              values.section.questions_attributes.splice(
                                index,
                                1
                              );
                            }
                          } else if (
                            compare &&
                            !!item.meta.parent_question_id
                          ) {
                            const containsItem =
                              values.section.questions_attributes.some(
                                (question) => question?.id === item?.id
                              );

                            if (!containsItem) {
                              values.section.questions_attributes.push({
                                id: item?.id,
                                answers_attributes: [
                                  {
                                    statement: "",
                                    user_section_mapping_id:
                                      data?.user_section_mapping_id,
                                  },
                                ],
                              });
                            }
                          }
                          return (
                            compare && (
                              <RadioButtonsFormSection
                                required
                                errors={errors[item?.id]}
                                key={item?.id}
                                RadioButtons={currentItem?.options.map(
                                  (content) => {
                                    const currentOption = item.options.find(
                                      (option) => option.meta.key == content.key
                                    );
                                    return (
                                      <RadioButtons
                                        disabled={
                                          user.role === "director"
                                            ? true
                                            : false
                                        }
                                        firstInputRef={
                                          currentItem?.firstInput
                                            ? firstInputRef
                                            : null
                                        }
                                        id={currentOption?.id}
                                        name={`section.questions_attributes[${indexRadio}].answers_attributes[0].option_id`}
                                        onChange={handleChange}
                                        values={currentOption?.id}
                                        Label={content.title}
                                        tabIndex={content.tabIndex}
                                        checked={
                                          values?.section.questions_attributes[
                                            indexRadio
                                          ]?.answers_attributes?.length &&
                                          currentOption?.id ==
                                            values?.section
                                              .questions_attributes[indexRadio]
                                              ?.answers_attributes[0]?.option_id
                                        }
                                      />
                                    );
                                  }
                                )}
                                label={currentItem?.label}
                                wrapperClassName={
                                  currentItem?.wrapperClassName
                                    ? currentItem?.wrapperClassName
                                    : "max-w-input-of w-100"
                                }
                                GridView={currentItem?.gridView}
                              />
                            )
                          );
                        }

                      case "checkbox":
                        const indexCheckbox =
                          values.section.questions_attributes.findIndex(
                            (index) => index.id == item.id
                          );
                        if (!currentItem.checkBox) {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director"
                                          ? true
                                          : false || serviceNoteApproved
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      name={`section.questions_attributes[${indexCheckbox}].answers_attributes[0].option_id`}
                                      onChange={handleChange}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      tabIndex={content.tabIndex}
                                      checked={
                                        values?.section?.questions_attributes[
                                          indexCheckbox
                                        ]?.answers_attributes.length &&
                                        currentOption.id ==
                                          values?.section.questions_attributes[
                                            indexCheckbox
                                          ]?.answers_attributes[0]?.option_id
                                      }
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem.wrapperClassName
                                  ? currentItem.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        } else {
                          return (
                            <RadioButtonsFormSection
                              errors={errors[item?.id]}
                              key={item?.id}
                              RadioButtons={currentItem.options.map(
                                (content) => {
                                  const currentOption = item.options.find(
                                    (option) => option.meta.key == content.key
                                  );
                                  const answers =
                                    values.section.questions_attributes[
                                      indexCheckbox
                                    ].answers_attributes;
                                  let answer = answers.find(
                                    (answer) =>
                                      answer.option_id == currentOption?.id
                                  );
                                  const isChecked = answer && !answer._destroy;
                                  return (
                                    <RadioButtons
                                      disabled={
                                        user.role === "director" ? true : false
                                      }
                                      firstInputRef={
                                        currentItem?.firstInput
                                          ? firstInputRef
                                          : null
                                      }
                                      id={currentOption?.id}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          if (
                                            answers[0].option_id ===
                                              undefined ||
                                            answers[0].option_id === null
                                          ) {
                                            answers[0].option_id = parseInt(
                                              e.target.value
                                            );
                                            answers[0]._destroy = false;
                                          } else {
                                            if (answer) {
                                              answer._destroy = false;
                                            } else {
                                              answer = {
                                                option_id: parseInt(
                                                  e.target.value
                                                ),
                                                user_section_mapping_id:
                                                  data.user_section_mapping_id,
                                                _destroy: false,
                                              };
                                              answers.push(answer);
                                            }
                                          }
                                        } else {
                                          if (answer) {
                                            answer._destroy = true;
                                          }
                                        }
                                        if (
                                          answers.every(
                                            (answer) =>
                                              answer.option_id &&
                                              answer._destroy
                                          )
                                        ) {
                                          errors[item?.id] =
                                            "This field is required";
                                        } else {
                                          delete errors[item?.id];
                                        }
                                      }}
                                      values={currentOption?.id}
                                      Label={content.title}
                                      // tagCheckbox={true}
                                      tabIndex={content.tabIndex}
                                      checkBox={currentItem?.checkBox}
                                      defaultChecked={isChecked}
                                    />
                                  );
                                }
                              )}
                              label={currentItem?.label}
                              wrapperClassName={
                                currentItem?.wrapperClassName
                                  ? currentItem?.wrapperClassName
                                  : "max-w-input-of w-100"
                              }
                              GridView={currentItem?.gridView}
                            />
                          );
                        }

                      case "custom":
                        return (
                          <h4 className="w-100 custom-heading">
                            {currentItem?.label}
                          </h4>
                        );
                      default:
                        return true;
                    }
                  })}
                  {user.role === "director" ? (
                    <div className="dashboard-footer w-100">
                      {formStatus === "partially_filled" && (
                        <button
                          type="button"
                          className="send-back-btn"
                          onClick={() => {
                            changeFormStatus();
                          }}
                        >
                          {loading ? (
                            <LoaderSpinner color="white" />
                          ) : (
                            `Send back`
                          )}
                        </button>
                      )}
                      <button
                        type="button"
                        className="send-back-btn"
                        onClick={() => handleSendBack()}
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    <div className="dashboard-footer w-100">
                      <button
                        ref={formSubmitButtonRef}
                        type="submit"
                        className="approve-btn"
                        onClick={() => {
                          isSubmitting = true;
                          if (savedSignature) {
                            handleSubmit(values);
                          } else {
                            createNotification("error", "Signature Required");
                          }
                        }}
                        disabled={
                          loading || !savedSignature || serviceNoteApproved
                        }
                      >
                        {loading ? (
                          <LoaderSpinner color="white" />
                        ) : (
                          saveBtnText
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </fieldset>
              {user.role === "technician" &&
                ["partially_filled", "completely_filled"].includes(
                  formStatus
                ) && (
                  <div className="dashboard-footer w-100">
                    <button
                      type="button"
                      className="send-back-btn"
                      onClick={() => handlenext()}
                    >
                      Next
                    </button>
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
    </FormikProvider>
  );
};

export default CommonCheckOutForm;
