import React, { useEffect, useState } from "react";
import Styles from "../../Containers/Dashboard Login//DashboardLogin.module.scss";
import lmauthfeatured from "../../assets/images/lm-auth-featured.svg";
import lmLogo from "../../assets/images/learn-me-logo-colored.svg";
import ImageView from "../../components/Image/ImageView";

import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../store/user/userSlice";
import UserTypeTabs from "../../Containers/Dashboard Login/UserTypeTabs";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { updatePassword } from "../../services/apis/authService";
import { createNotification } from "../../helpers/createNotifications";
import NewPasswordForm from "./newPasswordForm";
const NewPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [disbale, setIsDisable] = useState(true);
  const [creds, setCreds] = useState();
  const navigate = useNavigate();

  const handleUpdatePassword = async (data) => {
    const updatePasswordRes = await updatePassword({
      user: { ...data?.user, reset_password_token: token },
    });
    if (updatePasswordRes?.data?.message) {
      createNotification("success", "Password Updated");
      navigate("/passwordresetsuccesfully");
    } else {

      createNotification("error", updatePasswordRes.response?.data?.error);
    }
  };

  useEffect(() => {
    if (
      creds?.user?.password?.length &&
      creds?.user?.password === creds?.user?.password_confirmation
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [creds]);
  return (
    <section className={Styles.DashboardLogin}>
      <div className={Styles.DashboardLogin__FormSection}>
        <div className={Styles.DashboardLogin__FormSectionWrapper}>
          <a href="#">
            <ImageView src={lmLogo} className="img-fluid" />
          </a>
          <Form className="w-100 d-flex flex-column">
            <div className="w-100 d-flex flex-column gap-70">
              <NewPasswordForm setCreds={setCreds} creds={creds} />
            </div>
            <Button
              disabled={disbale}
              className="w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600"
              onClick={() => handleUpdatePassword(creds)}
            >
              Update Password
            </Button>
          </Form>
        </div>
      </div>
      <div className={Styles.DashboardLogin__ImageSection}>
        <section className={Styles.DashboardLogin__ImageSection_Wrapper}>
          <ImageView src={lmauthfeatured} className="img-fluid" />
        </section>
      </div>
    </section>
  );
};

export default NewPassword;
