import Styles from "../../Dashboard.module.scss";
import technicianAvatar1 from "../../../../assets/images/technician-avatar-1.svg";
import technicianAvatar2 from "../../../../assets/images/technician-avatar-2.svg";
import ImageView from "../../../../components/Image/ImageView";
import DummyImage from "../../../../assets/images/client-profile-image.svg";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
  penOrangeIcon,
  ChartData,
} from "../../../../constants/constants";
import { useEffect, useState } from "react";
import { createNotification } from "../../../../helpers/createNotifications";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const TechnicianDetails = ({
  setProfileModalView,
  clientProfile,
  setdashboardView,
}) => {
  const navigate = useNavigate();

  const ClientData = [
    {
      id: Math.random(),
      key: "email",
      label: "Email",
    },
    {
      id: Math.random(),
      label: "Phone Number",
      key: "phone_no",
    },
    {
      id: Math.random(),
      label: "Date of Birth",
      key: "date_of_birth",
    },
    {
      id: Math.random(),
      label: "Home Address",
      key: "address",
    },
  ];
  const formatDate = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("MM-DD-YYYY");
      return formattedDate;
    }
  };
  const ClientDetailsData = clientProfile
    ? Object.entries(clientProfile)?.map(([key, value]) => {
        const currentLabel = ClientData?.find((data) => data?.key === key);
        if (currentLabel)
          return (
            <div
              key={key}
              className={
                Styles.ProfileContainer__LeftSidebar_Section_Information
              }
            >
              <span
                className={
                  Styles.ProfileContainer__LeftSidebar_Section_Information_label
                }
              >
                {currentLabel?.label}
              </span>
              <span
                className={`${
                  Styles.ProfileContainer__LeftSidebar_Section_Information_Info
                } ${
                  currentLabel?.label === "Email" ||
                  currentLabel?.label === "Phone Number"
                    ? "clr-orange-pri"
                    : currentLabel?.label === "Units Remaining"
                    ? "clr-grey-charchoal"
                    : "clr-dark-grey"
                }`}
              >
                {key === "insurance_status"
                  ? value === true
                    ? "Yes"
                    : "No"
                  : key === "date_of_birth"
                  ? formatDate(value)
                  : value}
              </span>
            </div>
          );
      })
    : [];
  const getLetter = (name) => {
    const words = name.split(" ").filter((word) => word.trim() !== "");
    let extractedLetters;
    if (words.length >= 1) {
      const firstLetterWord1 = words[0][0]?.toUpperCase();
      const firstLetterWord2 = words[1]?.[0]?.toUpperCase() || "";
      extractedLetters = firstLetterWord1 + firstLetterWord2;
    }
    return extractedLetters;
  };

  const ClientDetails = clientProfile
    ? Object.entries(clientProfile?.client_details)?.map(([key, value]) => {
        return (
          <div
            key={key}
            className={Styles.ProfileContainer__LeftSidebar_Section_Information}
          >
            <span
              className={
                Styles.ProfileContainer__LeftSidebar_Section_Information_label
              }
              style={{ textTransform: "capitalize" }}
            >
              {key}
            </span>
            {value && (
              <span
                className={`f-16 f-w-600 lh-18 clr-dark-grey ${Styles.ProfileContainer__TechnicianBadge}`}
              >
                <span
                  data-column-name="name"
                  className="rounded-avatar-letters rounded-avatar-xsm name-show"
                  alt="client-profile"
                  style={{ fontSize: "12px" }}
                >
                  {getLetter(value)}
                </span>
                {value}
              </span>
            )}
          </div>
        );
      })
    : [];
  // const ClientDetails = () => {
  //   clientProfile && (
  //     <div
  //       key={key}
  //       className={Styles.ProfileContainer__LeftSidebar_Section_Information}
  //     >
  //       <span
  //         className={
  //           Styles.ProfileContainer__LeftSidebar_Section_Information_label
  //         }
  //         style={{ textTransform: "capitalize" }}
  //       >
  //         {key}
  //       </span>
  //       <span
  //         className={`f-16 f-w-600 lh-18 clr-dark-grey ${Styles.ProfileContainer__TechnicianBadge}`}
  //       >
  //         <ImageView
  //           className={"rounded-avatar-xsm"}
  //           // src={info.TechnicicianAvatar}
  //         />
  //         {value}
  //       </span>
  //     </div>
  //   );
  // };

  return (
    <>
      {" "}
      <div className={Styles.ProfileContainer__LeftSidebar_Section}>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column">
              <h3 className="f-24 lh-24 f-w-700 clr-dark-grey">
                {clientProfile?.full_name}
              </h3>
            </div>
            <div className="d-flex flex-column">
              <span className="f-14 f-w-400 lh-14 clr-b-charcoal">
                Date Joined
              </span>
              <span className="f-16 f-w-600 lh-16 clr-b-charcoal mt-5">
                {" "}
                {clientProfile?.enrolled_date}
              </span>
            </div>
          </div>
          {clientProfile?.profile_image ? (
            <ImageView
              className={"rounded-avatar-lg "}
              src={clientProfile?.profile_image}
            />
          ) : (
            <span
              data-column-name="name"
              className="rounded-avatar-letters name-show"
              alt="client-profile"
              //   style={{ background: "purple" }}
              // src={row.image || "/src/assets/images/tkxel_logo.png"}
            >
              {getLetter(clientProfile.full_name)}
            </span>
          )}
        </div>
      </div>
      <div className={Styles.ProfileContainer__LeftSidebar_Section}>
        <section className="d-flex flex-column gap-24">
          <div className="d-flex gap-18 align-items-center">
            <h4 className="f-18 lh-20 f-w-700 clr-dark-grey">
              Technician Details
            </h4>
            <div
              onClick={() => {
                setProfileModalView(true);
              }}
              className="cursor-pointer"
            >
              {penOrangeIcon}
            </div>
          </div>
          {ClientDetailsData}
        </section>
        {/* <section className="d-flex flex-column gap-10">
          <h4 className="f-18  f-w-700 clr-dark-grey ">Units</h4>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-38">
              <p className="clr-b-charcoal">Units Remaining</p>
              <span className="f-w-600">74</span>
            </div>
            <div>
              <Button
                className="units-link-btn"
                onClick={() => {
                  navigate("/clients/units");
                  setdashboardView("Units");
                }}
              >
                {" "}
                View all
              </Button>
            </div>
          </div>
        </section> */}
      </div>
      <div
        className={`${Styles.ProfileContainer__LeftSidebar_Section} border-0 pb-0`}
      >
        <section className="d-flex flex-column gap-10 mb-12">
          <h4 className="f-18 lh-20 f-w-700 clr-dark-grey pb-6">Clients</h4>
          {ClientDetails}
        </section>
      </div>
    </>
  );
};

export default TechnicianDetails;
