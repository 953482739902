import React from "react";
import { DatePicker } from "antd";
import { datepickerCalenderIcon } from "../../../../constants/constants";
import dayjs from "dayjs";
import "./DatePicker.scss";

const DatePickerLM = ({
  label,
  onChange,
  WrapperClassName,
  placeholder,
  defaultValue,
  pastDate,
  futureDate,
  value,
  dateAfter,
}) => {
  const disabledDate = (current, pastDate, futureDate) => {
    const date = new Date(dateAfter);
    if (dateAfter) {
      const date = new Date(dateAfter);

      return current && current < date;
    }
    if (pastDate) {
      return current && current > dayjs();
    }
    if (futureDate) {
      return current && current < dayjs().startOf("day");
    } else return;
  };

  return (
    <div className={`d-flex flex-column gap-8 ${WrapperClassName}`}>
      {label && <label className="lm-input-form-input-label">{label}</label>}
      <DatePicker
        placeholder={placeholder}
        picker="date"
        suffixIcon={datepickerCalenderIcon}
        className="position-relative lm-date-picker"
        // dropdownClassName="lm-date-picker" Because Deprecated
        popupClassName="lm-date-picker"
        onChange={onChange}
        format={"MM-DD-YYYY"}
        disabledDate={(current) => disabledDate(current, pastDate, futureDate)}
        defaultValue={defaultValue && dayjs(defaultValue, "YYYY-MM-DD")}
        value={value && dayjs(value, "YYYY-MM-DD")}
        allowClear={false}
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
        onKeyDown={(event) => {
          if (
            (event.ctrlKey &&
              (event.key === "c" || event.key === "x" || event.key === "v")) ||
            (event.metaKey &&
              (event.key === "c" || event.key === "x" || event.key === "v"))
          ) {
            event.preventDefault();
          }
        }}
      />
    </div>
  );
};

export default DatePickerLM;
