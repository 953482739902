import React from "react";
import { greyClockIcon } from "../../constants/constants";
import ProgressBarComponent from "../ProgressBar/ProgressBarComponent";
import cardStyles from "./GoalsCard.module.scss";

const GoalCard = ({
  avatar,
  category,
  Description,
  Status,
  last_worked_date,
}) => {
  const workedGoalDate = (last_worked_date) => {
    const date = new Date(last_worked_date);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  return (
    <section className={cardStyles.GoalCard}>
      <div className={cardStyles.GoalCard__Header}>
        <span className={cardStyles.avatarImg}>{avatar}</span>
        <div className={`d-flex flex-column gap-5`}>
          <span className="f-14 lh-19 f-w-700 clr-black-pri">{category}</span>
          <div className="d-flex gap-6 align-items-center ">
            {greyClockIcon}
            <span className="f-13 lh-18 f-w-600 clr-d-color">
              {workedGoalDate(last_worked_date)}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 py-11 flex-wrap row-gap-12">
        <span className="f-14 lh-19 f-w-600 clr-d-blue">{Description}</span>
        <div className={cardStyles.GoalCard_Progress}>
          <ProgressBarComponent
            GoalProgress={true}
            GoalStatus={Status}
            reverse={false}
          />
        </div>
      </div>
    </section>
  );
};

export default GoalCard;
