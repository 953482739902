import { Input } from "antd";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../Containers/Appointment/custom-select";
import { greenPostive, redNegetive } from "../../constants/constants";
import { createNotification } from "../../helpers/createNotifications";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import TestingGoalsAlertModal from "../Modals/TestingGoalsAlertModal";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import LoaderSpinner from "../general/loader/loader.spinner";
import "./DailyDataSheetTabs.scss";

const AddObjectives = ({
  data,
  onlyObjective,
  setdashboardView,
  serviceNoteApproved,
  checkinFormCompleted,
  markAsCompleted,
  showAlertModal,
  isDataSheetLocked,
  intializeAutoSaveRequests,
  setIntializeAutoSaveRequests,
}) => {
  const saveBtnText = serviceNoteApproved ? "Approved" : "Save";
  const { id, userId } = useParams();
  const [mainObjective, setMainObjective] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [restrictedGoals, setRestrictedGoals] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [testingRequiredGoals, setTestingRequiredGoals] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentDefaultGoals = mainObjective || data;
    const testingRequired = currentDefaultGoals?.some(
      (row) => row.testing_required
    );
    setTestingRequiredGoals(testingRequired);
  }, [mainObjective]);

  useEffect(() => {
    if (intializeAutoSaveRequests) {
      console.log("Saving Goals & objectives");
      handleAutoSave();
      setIntializeAutoSaveRequests(false);
    }
  }, [intializeAutoSaveRequests]);

  const handleAutoSave = async () => {
    const inValidGoalsObjectives = {};
    const validMainObjective = [];

    mainObjective.forEach((goal) => {
      if (!!goal.goal_id) {
        const validObjectives = [];
        const inValidObjectives = [];

        goal.sub_objective_attributes.forEach((objective) => {
          if (!!objective.id || !!objective.statement) {
            validObjectives.push(objective);
          } else {
            inValidObjectives.push(objective);
          }
        });

        goal.sub_objective_attributes = validObjectives;

        if (inValidObjectives.length) {
          if (!inValidGoalsObjectives[goal.goal_id]) {
            inValidGoalsObjectives[goal.goal_id] = [];
          }
          inValidGoalsObjectives[goal.goal_id].push(...inValidObjectives);
        }
        validMainObjective.push(goal);
      }
    });

    try {
      if (validMainObjective?.length) {
        const res = await httpClient.post(
          `api/v1/objectives/update_or_create/`,
          {
            data_sheet: {
              id: id,
              objectives_attributes: validMainObjective,
            },
          }
        );
        if (res?.data) {
          const updatedGoalObjectives =
            res.data.data_sheet.objectives_attributes;

          updatedGoalObjectives.forEach((goal) => {
            if (!!inValidGoalsObjectives[goal.goal_id]) {
              goal.sub_objective_attributes =
                goal.sub_objective_attributes.concat(
                  inValidGoalsObjectives[goal.goal_id]
                );
            }
          });
          setMainObjective(updatedGoalObjectives);
        }
        return true;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const hanldeSave = async () => {
    try {
      if (mainObjective?.length) {
        const res = await httpClient.post(
          `api/v1/objectives/update_or_create/?calculate_progress=${
            onlyObjective ? false : true
          }`,
          {
            data_sheet: {
              id: id,
              objectives_attributes: mainObjective,
            },
          }
        );
        if (res?.data) {
          setMainObjective(res.data.data_sheet.objectives_attributes);
          if (!onlyObjective) {
            markAsCompleted("objectives");
          }
          createNotification("success", "Objective Saved");
          if (onlyObjective) {
            navigate("/techdashboard");
            setdashboardView("panel");
          }
        }
        return true;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetch_default_goals = async () => {
    setIsLoading(true);
    try {
      const res = await httpClient.get(
        `/api/v1/goals/previous_appointment_goals?data_sheet_id=${id}`
      );
      if (res?.data) {
        setRestrictedGoals(res.data.restricted_goals);
        if (res?.data?.previous_goals.length) {
          setMainObjective(res.data.previous_goals);
        } else {
          setMainObjective([
            {
              goal_id: "",
              nameId: Math.random(),
              sub_objective_attributes: [
                {
                  statement: "",
                  positive_outcome: "",
                  negative_outcome: "",
                  nameId: Math.random(),
                },
              ],
            },
          ]);
        }
      }
      setIsLoading(false);
      return true;
    } catch (error) {
      handleErrorResponse(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setMainObjective(data);
    } else {
      fetch_default_goals();
    }
  }, [data]);

  const [selectedGoals, setSelectedGoals] = useState();
  const [userGoals, setUserGoals] = useState();
  const [hidemore, setHideMore] = useState(true);

  useEffect(() => {
    let selectedGoals;
    if (mainObjective?.length) {
      selectedGoals = mainObjective.map((obj) => obj.goal_id);
    }
    setSelectedGoals(selectedGoals);
    if (userGoals <= selectedGoals?.length) {
      setHideMore(true);
    } else {
      setHideMore(false);
    }
  }, [mainObjective, userGoals]);

  const handleParentRemove = (index) => {
    const updatedMainObjective = [...mainObjective];
    updatedMainObjective.splice(index, 1);
    setMainObjective(updatedMainObjective);
  };

  const handleChildRemove = (parentIndex, childIndex) => {
    const updatedMainObjective = [...mainObjective];
    // Remove the child object from the parent's sub_objective_attributes array
    if (
      updatedMainObjective[parentIndex] &&
      updatedMainObjective[parentIndex].sub_objective_attributes
    ) {
      updatedMainObjective[parentIndex].sub_objective_attributes.splice(
        childIndex,
        1
      );
    }
    // Update the state with the modified array
    setMainObjective(updatedMainObjective);
  };

  const screenWidth = window.innerWidth;
  const checkAllObjective = () => {
    const resultArray = [];

    if (mainObjective) {
      for (const item of mainObjective) {
        const subObjValidityArray = []; // Array for sub-objective validity within the current item
        for (const subObj of item.sub_objective_attributes) {
          const subObjIsValid =
            subObj.positive_outcome !== "" &&
            subObj.negative_outcome !== "" &&
            parseFloat(subObj.positive_outcome) >= 0 &&
            parseFloat(subObj.negative_outcome) >= 0;

          subObjValidityArray.push(subObjIsValid);
        }

        resultArray.push(subObjValidityArray);
      }
    }

    return resultArray;
  };
  const allTrialsFilled = checkAllObjective()
    .flat(Infinity)
    .every((item) => item === true);

  const validNoOfGoals =
    mainObjective?.length &&
    mainObjective?.length >= restrictedGoals["restricted_goals"];

  const validNoOfObjectives =
    mainObjective?.length &&
    mainObjective.every(
      (obj) =>
        obj?.sub_objective_attributes.length >=
        restrictedGoals["restricted_objectives"]
    );

  const showRestrictedGoalsNote = Object.keys(restrictedGoals).length > 0;

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"50%"}
        left={"39%"}
        color="#f6891f"
      />
    );
  }
  return (
    <>
      {showRestrictedGoalsNote && (
        <div style={{ marginTop: "10px", marginBottom: "40px" }}>
          <h6 style={{ color: "red" }}>
            {`Note: Ensure there are ${restrictedGoals["restricted_goals"]} goals, each with a minimum of
          ${restrictedGoals["restricted_objectives"]} Objectives`}
          </h6>
        </div>
      )}

      <Accordion
        defaultActiveKey="0"
        flush
        className="custom-accordian-data-sheet"
      >
        {!!mainObjective?.length &&
          mainObjective.map((row, index) => {
            return (
              <>
                <Accordion.Item
                  eventKey={index}
                  style={{
                    position: "relative",
                    zIndex: `${100 + (mainObjective?.length - index + 1)}`,
                  }}
                >
                  <Accordion.Header className="fs-14 f-w-600 clr-dark-grey">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "12px",
                      }}
                    >
                      <p style={{ width: "90%" }}>
                        {" "}
                        Goal {index + 1}{" "}
                        {!row.immutable_goal && !row?.saved && index != 0 && (
                          <p
                            style={{
                              // width: "10%",
                              position: "absolute",
                              top: "12px",
                              right: "42px",
                              borderRadius: "5px",
                              background: "#FBF1F1",
                              color: "#EF4747",
                              padding: "4px 8px",
                              fontWeight: "600",
                            }}
                            onClick={() => handleParentRemove(index)}
                          >
                            Remove
                          </p>
                        )}
                        {row.immutable_goal &&
                          row.testing_required &&
                          showAlertModal && (
                            <p
                              style={{
                                // width: "10%",
                                position: "absolute",
                                top: "12px",
                                right: "42px",
                                borderRadius: "5px",
                                background: "#FBF1F1",
                                color: "#548e29",
                                padding: "4px 8px",
                                fontWeight: "600",
                              }}
                            >
                              Testing Required
                            </p>
                          )}
                      </p>

                      <CustomSelect
                        style={{ display: "flex" }}
                        objectives={true}
                        existingGoals={selectedGoals}
                        placeholder="Select Goal"
                        userId={userId}
                        setUserGoals={setUserGoals}
                        handleValue={(value) => {
                          const updatedMainObjective = [...mainObjective];
                          updatedMainObjective[index].goal_id = value;
                          setMainObjective(updatedMainObjective);
                        }}
                        fieldValue={row?.goal_id}
                        mainObjective={mainObjective}
                        isClickDisabled={row?.immutable_goal}
                        testingRequired={
                          row?.testing_required && showAlertModal
                        }
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {row?.sub_objective_attributes?.length &&
                      row?.sub_objective_attributes.map((subObj, miniIndex) => {
                        const percentileElement = (
                          <span
                            style={{
                              color: "green",
                              fontSize: screenWidth <= 1000 && "12px",
                            }}
                          >
                            {subObj?.percentile || "0"}%
                          </span>
                        );
                        const Plabel = (
                          <>Successful Trials {percentileElement}</>
                        );

                        return (
                          <>
                            <div
                              className="accordion-table "
                              key={`miniIndex ${miniIndex}`}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "16px",
                                marginBottom: "12px",
                                position: "relative",
                                paddingTop: `${
                                  !subObj.id && miniIndex != 0 && "52px"
                                }`,
                              }}
                            >
                              {!subObj.id && miniIndex != 0 && (
                                <p
                                  onClick={() =>
                                    handleChildRemove(index, miniIndex)
                                  }
                                  style={{
                                    // width: "10%",
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px",
                                    borderRadius: "5px",
                                    background: "#FBF1F1",
                                    color: "#EF4747",
                                    padding: "4px 8px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    marginTop: "12px",
                                  }}
                                >
                                  Remove
                                </p>
                              )}
                              <TextFieldFormik
                                placeholder={"Enter Title"}
                                type={"text"}
                                wrapperClassName={"w-80"}
                                tabIndex={1}
                                disabled={checkinFormCompleted && subObj?.id}
                                label={`Method-${miniIndex + 1}`}
                                name={`Method-${miniIndex + 1} ${
                                  subObj?.nameId || 0
                                }`}
                                // name={`Objective-`}
                                handleChange={(e) => {
                                  const updatedMainObjective = [
                                    ...mainObjective,
                                  ];
                                  updatedMainObjective[
                                    index
                                  ].sub_objective_attributes[
                                    miniIndex
                                  ].statement = e.target.value;
                                  setMainObjective(updatedMainObjective);
                                }}
                                values={subObj?.statement || ""}
                              />
                              {!onlyObjective && (
                                <div
                                  className="outcome-fields"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    gap: "20px",
                                  }}
                                >
                                  <div>
                                    <p>{Plabel}</p>
                                    {/* <TextFieldFormik
                                      placeholder={"0"}
                                      type={"number"}
                                      wrapperClassName={
                                        "max-w-input-of mobile-100"
                                      }
                                      tabIndex={1}
                                      label={Plabel}
                                      name={`Positive-Outcome-${
                                        miniIndex + 1
                                      } ${index + 1}`}
                                      handleChange={(e) => {
                                        if (e.target.value < 0) {
                                          return;
                                        }
                                        const updatedMainObjective = [
                                          ...mainObjective,
                                        ];
                                        updatedMainObjective[
                                          index
                                        ].sub_objective_attributes[
                                          miniIndex
                                        ].positive_outcome = e.target.value;
                                        setMainObjective(updatedMainObjective);
                                      }}
                                      values={subObj?.positive_outcome || ""}
                                    /> */}

                                    <Input
                                      className="counter-input-abc"
                                      style={{
                                        // maxWidth: "120px",
                                        marginTop: "6px",
                                        height: "42px",
                                      }}
                                      prefix={
                                        <span
                                          onClick={() => {
                                            if (
                                              Number(
                                                subObj?.positive_outcome
                                              ) == 0
                                            ) {
                                              return;
                                            } else {
                                              const updatedMainObjective = [
                                                ...mainObjective,
                                              ];
                                              updatedMainObjective[
                                                index
                                              ].sub_objective_attributes[
                                                miniIndex
                                              ].positive_outcome =
                                                subObj?.positive_outcome - 1;
                                              setMainObjective(
                                                updatedMainObjective
                                              );
                                            }
                                          }}
                                        >
                                          {redNegetive}
                                        </span>
                                      }
                                      suffix={
                                        <span
                                          onClick={() => {
                                            const updatedMainObjective = [
                                              ...mainObjective,
                                            ];
                                            updatedMainObjective[
                                              index
                                            ].sub_objective_attributes[
                                              miniIndex
                                            ].positive_outcome =
                                              // subObj?.positive_outcome + 1;
                                              Number(subObj?.positive_outcome) +
                                              1;
                                            setMainObjective(
                                              updatedMainObjective
                                            );
                                          }}
                                        >
                                          {greenPostive}
                                        </span>
                                      }
                                      type="number"
                                      min={0}
                                      onChange={(e) => {
                                        const trimmedValue =
                                          e.target.value.replace(/^0+/, "");
                                        const numericValue =
                                          parseInt(trimmedValue);
                                        const updatedMainObjective = [
                                          ...mainObjective,
                                        ];

                                        if (
                                          isNaN(numericValue) ||
                                          numericValue < 0
                                        ) {
                                          updatedMainObjective[
                                            index
                                          ].sub_objective_attributes[
                                            miniIndex
                                          ].positive_outcome = 0;
                                          setMainObjective(
                                            updatedMainObjective
                                          );
                                        } else {
                                          updatedMainObjective[
                                            index
                                          ].sub_objective_attributes[
                                            miniIndex
                                          ].positive_outcome = numericValue;
                                          setMainObjective(
                                            updatedMainObjective
                                          );
                                        }
                                      }}
                                      value={subObj?.positive_outcome}
                                    />
                                  </div>
                                  <div>
                                    <p> Failed Trails</p>
                                    <Input
                                      className="counter-input-abc"
                                      style={{
                                        // maxWidth: "120px",
                                        height: "42px",
                                        marginTop: "6px",
                                      }}
                                      prefix={
                                        <span
                                          onClick={() => {
                                            if (
                                              Number(
                                                subObj?.negative_outcome
                                              ) == 0
                                            ) {
                                              return;
                                            } else {
                                              const updatedMainObjective = [
                                                ...mainObjective,
                                              ];
                                              updatedMainObjective[
                                                index
                                              ].sub_objective_attributes[
                                                miniIndex
                                              ].negative_outcome =
                                                subObj?.negative_outcome - 1;
                                              setMainObjective(
                                                updatedMainObjective
                                              );
                                            }
                                          }}
                                        >
                                          {redNegetive}
                                        </span>
                                      }
                                      suffix={
                                        <span
                                          onClick={() => {
                                            const updatedMainObjective = [
                                              ...mainObjective,
                                            ];
                                            updatedMainObjective[
                                              index
                                            ].sub_objective_attributes[
                                              miniIndex
                                            ].negative_outcome =
                                              // subObj?.negative_outcome + 1;
                                              Number(subObj?.negative_outcome) +
                                              1;
                                            setMainObjective(
                                              updatedMainObjective
                                            );
                                          }}
                                        >
                                          {greenPostive}
                                        </span>
                                      }
                                      type="number"
                                      min={0}
                                      onChange={(e) => {
                                        const trimmedValue =
                                          e.target.value.replace(/^0+/, "");
                                        const numericValue =
                                          parseInt(trimmedValue);
                                        const updatedMainObjective = [
                                          ...mainObjective,
                                        ];

                                        if (
                                          isNaN(numericValue) ||
                                          numericValue < 0
                                        ) {
                                          updatedMainObjective[
                                            index
                                          ].sub_objective_attributes[
                                            miniIndex
                                          ].negative_outcome = 0;
                                          setMainObjective(
                                            updatedMainObjective
                                          );
                                        } else {
                                          updatedMainObjective[
                                            index
                                          ].sub_objective_attributes[
                                            miniIndex
                                          ].negative_outcome = numericValue;
                                          setMainObjective(
                                            updatedMainObjective
                                          );
                                        }
                                      }}
                                      value={subObj?.negative_outcome}
                                    />
                                    {/* <TextFieldFormik
                                      placeholder={"0"}
                                      type={"number"}
                                      wrapperClassName={
                                        "max-w-input-of mobile-100"
                                      }
                                      tabIndex={1}
                                      label={`Failed Trails`}
                                      name={`Negative-Outcome-${
                                        miniIndex + 1
                                      } ${index + 1}`}
                                      handleChange={(e) => {
                                        if (e.target.value < 0) {
                                          return;
                                        }
                                        const updatedMainObjective = [
                                          ...mainObjective,
                                        ];
                                        updatedMainObjective[
                                          index
                                        ].sub_objective_attributes[
                                          miniIndex
                                        ].negative_outcome = e.target.value;
                                        setMainObjective(updatedMainObjective);
                                      }}
                                      values={
                                        mainObjective[index]
                                          .sub_objective_attributes[miniIndex]
                                          .negative_outcome || ""
                                      }
                                    /> */}
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Total outcome:
                                      {(Number(subObj?.positive_outcome) || 0) +
                                        (Number(subObj?.negative_outcome) || 0)}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}

                    <button
                      onClick={() => {
                        const newMiniObjectiveIn = {
                          // Initialize the new mini objective with default values or empty strings
                          statement: "",
                          positive_outcome: "",
                          negative_outcome: "",
                          percentile: 0,
                          nameId: Math.random(),
                        };
                        const transformedMainObjective = mainObjective.map(
                          (mainObjectiveValue, objIndex) => {
                            if (objIndex === index) {
                              return {
                                ...mainObjectiveValue,
                                sub_objective_attributes: [
                                  ...mainObjectiveValue.sub_objective_attributes,
                                  {
                                    ...newMiniObjectiveIn,
                                  },
                                ],
                              };
                            }

                            return mainObjectiveValue;
                          }
                        );

                        setMainObjective(transformedMainObjective);
                      }}
                      className="add-objectives-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path d="M6 0V12" stroke="#ABABAB" />
                        <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
                      </svg>
                      Add Objective
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              </>
            );
          })}
      </Accordion>

      <div className="objective-btns-wrapper">
        {selectedGoals?.length && !hidemore && (
          <button
            onClick={() => {
              const newMiniObjectiveIn = {
                statement: "",
                positive_outcome: "",
                negative_outcome: "",
                percentile: 0,
                nameId: Math.random(),
              };
              const newMainObjective1 = {
                goal_id: "",
                sub_objective_attributes: [newMiniObjectiveIn],
              };
              setMainObjective([...mainObjective, newMainObjective1]);
            }}
            className="add-main-btn"
            style={{ pointer: "cursor" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path d="M6 0V12" stroke="#ABABAB" />
              <path d="M12 6L-1.78814e-07 6" stroke="#ABABAB" />
            </svg>
          </button>
        )}
        <button
          onClick={() => hanldeSave()}
          style={{ pointer: "cursor" }}
          className="save-btn"
          disabled={
            (onlyObjective && (!validNoOfObjectives || !validNoOfGoals)) ||
            (!allTrialsFilled && !onlyObjective) ||
            serviceNoteApproved ||
            isDataSheetLocked
          }
        >
          {saveBtnText}
        </button>
      </div>

      {showAlertModal && testingRequiredGoals && (
        <TestingGoalsAlertModal
          showModal={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        ></TestingGoalsAlertModal>
      )}
    </>
  );
};
export default AddObjectives;
