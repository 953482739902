import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./Modals.scss";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import DatePickerLM from "../form-controls/input/DatePicker/DatePickerLM";
import httpClient from "../../services/axios";
import { createNotification } from "../../helpers/createNotifications";
import { logout } from "../../store/user/userSlice";
const UpdatePasswordModal = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentPassword, setCurrenPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const handlePasswordChange = async () => {
    const payload = {
      password: newPassword,
      password_confirmation: confirmPassword,
      current_password: currentPassword,
    };
    if (newPassword === confirmPassword && newPassword !== undefined) {
      try {
        const response = await httpClient.post(
          `/api/v1/users/${props.slug}/update_password`,
          {
            user: payload,
          }
        );
        if (response?.data) {
          createNotification("success", "Password updated");
          props.setUpdateModalView(false);
        }
        return true;
      } catch (error) {
        if (
          error?.response?.data?.response.error === "Signature has expired" ||
          error.response.status === 401
        ) {
          createNotification(
            "error",
            "Please login again your session has been expired"
          );
          localStorage.removeItem(`persist:root`);

          logout();

          return;
        }
        createNotification("error", error?.response?.data?.response.error);
        console.log("Something Went wrong", error.message);
        return false;
      }
    } else {
      createNotification("error", "Password do not match");
    }
  };

  return (
    <Modal
      {...props}
      size={"md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Header className="align-items-start border-0  pb-12" closeButton>
          <Modal.Title className="d-flex flex-column">
            <h4 className="f-24 lh-25 f-w-700 clr-dark-grey">
              {" "}
              Create a new password
            </h4>
            <span className="f-14 lh-24 f-w-400">
              Please choose your new password
            </span>
          </Modal.Title>
        </Modal.Header>
        <section className="d-flex flex-column gap-30 pt-24 w-100">
          <TextFieldFormik
            placeholder="Password"
            label="Current Password"
            wrapperClassName="w-100"
            type="password"
            handleChange={(e) => setCurrenPassword(e.target.value)}
          />
          <TextFieldFormik
            placeholder="New Password"
            label="New Password"
            wrapperClassName="w-100"
            type="password"
            handleChange={(e) => setNewPassword(e.target.value)}
          />
          <TextFieldFormik
            placeholder="Confirm Password"
            label="Confirm Password"
            wrapperClassName="w-100"
            type="password"
            handleChange={(e) => setConfirmPassword(e.target.value)}
          />
          <p style={{ fontSize: "15px" }}>
            Password Must Contain:
            <ul
              style={{
                fontSize: "10px",
                listStyle: "circle",
                marginLeft: "20px",
              }}
            >
              <li>Atleast 1 capital letter</li>
              <li>Atleast 1 lower letter</li>
              <li>Atleast 1 specail character</li>
              <li>Atleast 1 number digit</li>
            </ul>
          </p>

          <div className="w-100 d-flex justify-content-end">
            <div className="d-flex gap-12 w-100">
              <Button
                onClick={props.onHide}
                className={`w-100  lm-form-btn-secondary-draft  lm-form-btn-secondary  f-16 f-w-600 `}
              >
                Cancel
              </Button>
              <Button
                className={`w-100 lm-form-btn-primary lm-form-btn  f-16 f-w-600 clr-white-clr `}
                onClick={handlePasswordChange}
              >
                Save
              </Button>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePasswordModal;
