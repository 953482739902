import React, { useState } from "react";
import {
  plusIconWhite,
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../constants/constants";
import Sc from "../../Containers/Dashboard/Dashboard.module.scss";
import { Button } from "react-bootstrap";
import GoalModal from "../Modals/GoalModal";
const DashboardMiniSidebar = ({
  goalsStats,
  setdashboardView,
  assignGoal,
  tech,
  stats,
  InProgressGoals,
  setAction,
  action,
}) => {
  const [AddGoalModalView, setAddGoalModalView] = useState(false);
  const [AssignGoalModalView, setAssignGoalModalView] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  return (
    <>
      {!tech ? (
        <section className={Sc.ClientListing__RightSidebar}>
          <div className={Sc.ClientListing__RightSidebar_board}>
            <div
              // key={goal.id}
              className="d-flex flex-column gap-2 mb-40 last-child"
            >
              <span className={`f-35 lh-40 f-w-700 clr-purple-bold`}>
                {stats?.total_goals_count || 0}
              </span>
              <span className="clr-dark-grey f-15 lh-20 f-w-600">
                Total Goals
              </span>
            </div>
            <div
              // key={goal.id}
              className="d-flex flex-column gap-2 mb-40 last-child"
            >
              <span className={`f-35 lh-40 f-w-700 clr-chip-green`}>
                {stats?.mastered_goals_count || 0}
              </span>
              <span className="clr-dark-grey f-15 lh-20 f-w-600">
                Masterted Goals
              </span>
            </div>
          </div>
          {assignGoal ? (
            <Button
              onClick={() => {
                setAddGoalModalView(true);
              }}
              className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
            >
              {plusIconWhite} Add Goal
            </Button>
          ) : InProgressGoals ? (
            ""
          ) : (
            <Button
              onClick={() => {
                // setAssignGoalModalView(true);
                setdashboardView("AssignGoal");
              }}
              className={`w-100 lm-form-btn lm-form-btn-primary f-16 f-w-600 ${Sc.ClientListing__RightSidebar_Button}`}
            >
              {plusIconWhite} Assign Goal
            </Button>
          )}
          <GoalModal
            AddGoal={true}
            AssignGoal={false}
            modalLoading={modalLoading}
            show={AddGoalModalView}
            setAction={setAction}
            action={action}
            // singleRowData={singleRowData}
            // userId={userId}
            setAddGoalModalView={setAddGoalModalView}
            onHide={() => {
              setAddGoalModalView(false);
            }}
          />
        </section>
      ) : (
        <section className={Sc.ClientListing__RightSidebar}>
          <div className={Sc.ClientListing__RightSidebar_board}>
            <div
              // key={goal.id}
              className="d-flex flex-column gap-2 last-child"
            >
              <span className={`f-35 lh-40 f-w-700 clr-purple-bold`}>
                {stats?.total_goals_count || 0}
              </span>
              <span className="clr-dark-grey f-15 lh-20 f-w-600">
                Total Goals
              </span>
            </div>
            <div
              // key={goal.id}
              className="d-flex flex-column gap-2 last-child"
            >
              <span className={`f-35 lh-40 f-w-700 clr-chip-green`}>
                {stats?.mastered_goals_count || 0}
              </span>
              <span className="clr-dark-grey f-15 lh-20 f-w-600">
                Masterted Goals
              </span>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DashboardMiniSidebar;
