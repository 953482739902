import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom/dist";
import { createNotification } from "../../helpers/createNotifications";
import httpClient from "../../services/axios";
import { logout } from "../../store/user/userSlice";
import styleClasses from "./DailyDataSheet.module.scss";
import DailyDataSheetTabs from "./DailyDataSheetTabs";
import RightSideBar from "./RightSideBar";
import { Button } from "react-bootstrap";
import { handleErrorResponse } from "../../helpers/responseHandler";
import { GetCurrentUser } from "../../utils/utilAuth";

const DailyDataSheet = ({ setdashboardView }) => {
  const user = GetCurrentUser();

  const { id, serviceId, userId } = useParams();
  const location = useLocation();
  const propsReceived = location.state;
  const [action, setAction] = useState(false);
  const [comments, setComments] = useState();
  const [isDataSheetLocked, setIsDataSheetLocked] = useState(false);

  // useEffect(() => {
  //   // setLoading(true);
  //   const getCommentsData = async () => {
  //     try {
  //       const allComments = await httpClient.get(
  //         `/api/v1/comments?data_sheet=${true}&resource_id=${id} `
  //       );
  //       if (allComments?.data) {
  //         setComments(allComments.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };
  //   if (id) getCommentsData();
  // }, [action]);

  const postComments = async (commentsData) => {
    try {
      const res2 = await httpClient.post(
        `/api/v1/comments?data_sheet=${true}&resource_id=${id} `,
        { ...commentsData }
      );
      if (res2?.data) {
        setComments(res2?.data);
        createNotification("success", "Comment Posted");
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };
  const putEditComments = async (commentsData, commentsId) => {
    try {
      const res2 = await httpClient.put(`/api/v1/comments/${commentsId} `, {
        ...commentsData,
      });
      if (res2?.data) {
        createNotification("success", "Comment Updated");
        setAction(!action);
      }
      return true;
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const requestUnlock = async () => {
    try {
      const response = await httpClient.post(
        `/api/v1/data_sheets/${id}/request_unlock`
      );

      if (response?.data) {
        createNotification(
          "success",
          response.data?.response?.message || "Request sent"
        );
      }
      return true;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return (
    <>
      <section className={styleClasses.MainDataSheetPage}>
        <div className={styleClasses.MainDataSheetPage__Content}>
          <div className={styleClasses.MainDataSheetPage__SheetHeader}>
            <div
              className={
                styleClasses.MainDataSheetPage__SheetHeader__HeadingRow
              }
            >
              <h3 className={styleClasses.MainDataSheetPage__DailydataHeading}>
                Daily Data Sheet
              </h3>
              {user.role === 'technician' && isDataSheetLocked && (
                <Button
                  className={
                    styleClasses.MainDataSheetPage__SheetHeader__HeadingRow__UnlockRequestButton
                  }
                  onClick={() => {
                    requestUnlock();
                  }}
                >
                  Request Unlock
                </Button>
              )}
            </div>
            <div className={styleClasses.MainDataSheetPage__clientName}>
              <p
                className="f-w-600"
                style={{ textTransform: "capitalize" }}
              ></p>
            </div>
          </div>
          <div className={styleClasses.MainDataSheetPage__MainTabs}>
            <DailyDataSheetTabs
              setdashboardView={setdashboardView}
              setIsDataSheetLocked={setIsDataSheetLocked}
              isDataSheetLocked={isDataSheetLocked}
            />
          </div>
        </div>

        {!!comments?.length && (
          <RightSideBar
            postComments={postComments}
            comments={comments}
            putEditComments={putEditComments}
            setComments={setComments}
            setAction={setAction}
            action={action}
          />
        )}
      </section>
    </>
  );
};

export default DailyDataSheet;
