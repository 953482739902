import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { crossbgWhite } from "../../../../constants/constants";

function RadioButtons(props) {
  const [isChecked, setIsChecked] = useState(false);
  const handleChecked = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !props.disabled) {
        setIsChecked(!isChecked);
        props.onChange(event);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isChecked, props]);
  return (
    <Form.Check
      className={`lm-check-input ${props.tagCheckbox && "tag-checkbox "} ${
        props.defaultChecked && "checked"
      } ${props.disabled && "disable-click-on-label"}`}
      type="radio"
      tabIndex={props.tabIndex}
      key={props.key}
    >
      <Form.Check.Input
        onChange={props.onChange}
        id={props.id}
        name={props.name}
        type={props.checkBox ? "checkbox" : "radio"}
        value={props.id}
        tabIndex={props.tabIndex}
        ref={props.firstInputRef ? props.firstInputRef : null}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
        // style={props.errors?{ borderColor:"red" }: {}}
      />
      {props.tagCheckbox ? (
        <Form.Check.Label
          onClick={handleChecked}
          // tabIndex={props.tabIndex}
          htmlFor={props.id}
          disabled={props.disabled}
        >
          {props.Label} {isChecked && crossbgWhite}
        </Form.Check.Label>
      ) : (
        <Form.Check.Label
          // tabIndex={props.tabIndex}
          htmlFor={props.id}
          disabled={props.disabled}
        >
          {props.Label}
        </Form.Check.Label>
      )}
    </Form.Check>
  );
}

export default RadioButtons;
