import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  loggedIn: false,
  form: "",
  section: "",
  role: "",
  userProfile: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      console.log("set currentUser");
      state.currentUser = payload;
      state.loggedIn = true;
      state.form = payload.from;
      state.section = payload.section;
      state.role = payload.role;
    },
    setUserProfile: (state, { payload }) => {
      state.userProfile = payload;
    },
    setLogin: (state, payload) => {
      console.log("set login");
      state.loggedIn = payload;
    },
    logout: (state) => {
      console.log("set logout");
      state.currentUser = null;
      state.loggedIn = false;
    },
  },
});

export const { setCurrentUser, setUserProfile, setLogin, logout } =
  userSlice.actions;
export default userSlice.reducer;
