import React, { useState } from "react";

const TabsView = ({
  TabsData,
  activeDefault,
  getActiveTab,
  Container,
  setIsLoading,
  setSearchText,
  setCurrentView,
  ClientSessions,
}) => {
  const [activeTab, setIsActiveTab] = useState(activeDefault);
  const activeClass = (id) => {
    if (id) {
      return id == activeTab ? "active" : "";
    }
  };
  const HandleActiveTab = (value) => {
    // console.log(e, "event calue");
    setIsActiveTab(value);
    getActiveTab(value);
    setCurrentView(value);
  };
  const screenWidth = window.innerWidth;
  return (
    <div
      className={`${
        Container ? "dashboard-tabs dashboard-tabs-container" : "dashboard-tabs"
      }`}
    >
      {TabsData.map((tab) => {
        return (
          <button
            key={tab.title}
            onClick={() => {
              HandleActiveTab(tab.key);
              setIsLoading?.(true);
              setSearchText("");
            }}
            style={{ marginTop: screenWidth <= 1000 && "7px" }}
            id={tab.title}
            className={`${activeClass(tab.key)} btn "`}
          >
            {tab.title}
          </button>
        );
      })}
    </div>
  );
};

export default TabsView;
