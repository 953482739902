import React, { useCallback, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { newMiniObjective } from "../../constants/constants";
import { handleErrorResponse } from "../../helpers/responseHandler";
import httpClient from "../../services/axios";
import { enableBeforeUnload } from "../../utils/sessionHelpers";
import { GetCurrentUser } from "../../utils/utilAuth";
import { TextFieldFormik } from "../form-controls/input/text.field.formik";
import AddAbc from "./AddAbc";
import AddAccNo from "./AddAccNo";
import AddCompliance from "./AddCompliance";
import AddMands from "./AddMands";
import AddObjectives from "./AddObjectives";
import "./DailyDataSheetTabs.scss";
import ViewAbc from "./ViewAbc";
import ViewAccNo from "./ViewAccNo";
import ViewCompliance from "./ViewCompliance";
import ViewMands from "./ViewMands";
import ViewObjectives from "./ViewObjectives";

const DailyDataSheetTabs = ({
  setdashboardView,
  setIsDataSheetLocked,
  isDataSheetLocked,
}) => {
  const [serviceNoteApproved, setServiceNoteApproved] = useState();
  const [checkinFormCompleted, setCheckinFormCompleted] = useState();
  const { params } = useMatch("*");
  const route = Object.values(params)[0];
  const { id, appointId, serviceId, userId } = useParams();
  const location = useLocation();
  const propsReceived = location.state;
  const navigate = useNavigate();
  const [abcView, setAbcView] = useState("add");
  const [abcData, setAbcdData] = useState();
  const [objectiveData, setObjectivesData] = useState([]);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const user = GetCurrentUser();
  const [notes, setNotes] = useState("");
  const [checkOutSubmitted, setCheckOutSubmitted] = useState(false);
  const [completedComponents, setCompletedComponents] = useState([]);
  const [dataSheetCompleted, setDataSheetCompleted] = useState(false);
  const [restrictedNoOfValues, setRestrictedNoOfValues] = useState({});
  const [intializeAutoSaveRequests, setIntializeAutoSaveRequests] =
    useState(false);

  const setDataSheetNotes = async ({ requireNavigation }) => {
    try {
      const res = await httpClient.put(
        `api/v1/data_sheets/${id}`,

        { note: notes }
      );
      const { data } = res || {};

      if (data && requireNavigation) {
        setNotes(data.note);
        if (serviceId) {
          if (route?.includes("yourClients")) {
            navigate(
              `/yourClients/checkout/${id}/${userId}/${appointId}/${serviceId}`,
              { state: { yourClient: true } }
            );
          } else {
            navigate(
              `/techdashboard/checkout/${id}/${userId}/${appointId}/${serviceId}`,
              {
                state: { ...propsReceived },
              }
            );
          }
        } else {
          navigate(`/techdashboard/checkout/${id}/${userId}/${appointId}`, {
            state: { ...propsReceived },
          });
        }
        setdashboardView("checkout");
      }
      // return true;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const saveCompleteDataSheet = useCallback(async () => {
    if (notes) {
      setDataSheetNotes({ requireNavigation: false });
    }
    setIntializeAutoSaveRequests(true);
  });

  useEffect(() => {
    if (!isDataSheetLocked && !serviceNoteApproved && !!restrictedNoOfValues['data_sheet_submission_time']) {
      const intervalId = setInterval(saveCompleteDataSheet, restrictedNoOfValues['data_sheet_submission_time']);
      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [saveCompleteDataSheet]);

  const markAsCompleted = (str) => {
    if (!completedComponents.includes(str)) {
      setCompletedComponents((prevCompletedComponents) => {
        if (!prevCompletedComponents.includes(str)) {
          return [...prevCompletedComponents, str];
        } else {
          return prevCompletedComponents;
        }
      });
    }
  };

  useEffect(() => {
    const allComponentsCompleted = [
      "compliances",
      "mands",
      "acceptingNo",
      "abcBehaviours",
      "objectives",
    ].every((component) => completedComponents.includes(component));
    setDataSheetCompleted(allComponentsCompleted);
  }, [completedComponents]);

  useEffect(() => {
    enableBeforeUnload(user);
  }, []);

  const newMainObjective = {
    goal_id: "",
    sub_objective_attributes: [newMiniObjective],
  };

  const getAbcData = useCallback(
    async (currentPage, restrictednosOfAbcs) => {
      try {
        const res = await httpClient.get(`api/v1/abc_behaviours`, {
          params: {
            data_sheet: { id: id },
            per_page: 5,
            page: currentPage || 1,
          },
        });
        const { data } = res || {};
        const { meta } = data || {};
        if (data?.data?.length) {
          const abc_behaviours = [...data?.data];
          if (abc_behaviours.length >= restrictednosOfAbcs) {
            markAsCompleted("abcBehaviours");
          }
          setAbcdData(abc_behaviours);
          setTotalCount(meta.total_count);
          setCurrentPage(meta.current_page);
        }
        // return true;
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    [id]
  );

  const isSuccessfulTrialsSaved = (objectives_attributes) => {
    return objectives_attributes.some((objective) => {
      return objective.sub_objective_attributes.some((subObjective) => {
        return (
          subObjective.positive_outcome !== null &&
          subObjective.negative_outcome !== null
        );
      });
    });
  };

  const getObjectiveData = useCallback(async () => {
    const getData = async () => {
      try {
        const res = await httpClient.get("api/v1/objectives", {
          params: { data_sheet: { id: id } },
        });
        const { data } = res || {};

        if (data) {
          const { data_sheet = {} } = data;
          const {
            objectives_attributes = [],
            service_note_approved,
            restricted_field_numbers,
            checkin_form_completed,
            is_data_sheet_locked,
          } = data_sheet;
          setIsDataSheetLocked(is_data_sheet_locked);
          setServiceNoteApproved(service_note_approved);
          setRestrictedNoOfValues(restricted_field_numbers);
          setCheckinFormCompleted(checkin_form_completed);
          if (objectives_attributes.length) {
            if (isSuccessfulTrialsSaved(objectives_attributes)) {
              markAsCompleted("objectives");
            }
            setObjectivesData(objectives_attributes);
          } else {
            {
              // eslint-disable-next-line no-unused-expressions
              user.role === "director"
                ? []
                : setObjectivesData([
                    {
                      goal_id: "",
                      sub_objective_attributes: [
                        {
                          statement: "",
                          positive_outcome: "",
                          negative_outcome: "",
                          nameId: Math.random(),
                        },
                      ],
                    },
                  ]);
            }
          }
        }
        // return true;
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (id) getData();
  }, [id]);

  const getDataSheetNotes = async () => {
    try {
      const res = await httpClient.get(`api/v1/data_sheets/${id}`);
      const { data } = res || {};

      if (data) {
        setNotes(data.note);
        setCheckOutSubmitted(data.check_out_submitted);
      }
      // return true;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    if (id && (route.includes("datasheet") || route.includes("dataSheet"))) {
      getObjectiveData();
      getDataSheetNotes();
    }
  }, [id]);

  useEffect(() => {
    const restrictedValuesPresent =
      Object.keys(restrictedNoOfValues).length > 0;
    if (
      restrictedValuesPresent &&
      id &&
      (route.includes("datasheet") || route.includes("dataSheet"))
    ) {
      const restrictednosOfAbcs = restrictedNoOfValues["no_of_abc_behaviours"];
      getAbcData(currentPage, restrictednosOfAbcs);
    }
  }, [restrictedNoOfValues, id, action, currentPage]);

  return (
    <div className="daily-data-sheet-tabs">
      <Tabs
        defaultActiveKey="home"
        className="custom-tabs-data-sheet"
        id="fill-tab-example"
        fill
      >
        <Tab eventKey="home" title="1. Objectives">
          {user?.role === "technician" && (
            <AddObjectives
              data={objectiveData}
              onlyObjective={false}
              serviceNoteApproved={serviceNoteApproved}
              checkinFormCompleted={checkinFormCompleted}
              markAsCompleted={markAsCompleted}
              showAlertModal={!checkOutSubmitted}
              isDataSheetLocked={isDataSheetLocked}
              intializeAutoSaveRequests={intializeAutoSaveRequests}
              setIntializeAutoSaveRequests={setIntializeAutoSaveRequests}
            />
          )}
          {user?.role === "director" && <ViewObjectives data={objectiveData} />}
        </Tab>
        <Tab eventKey="profile" title="2. Compliance">
          {user?.role === "director" && <ViewCompliance />}
          {user?.role === "technician" && (
            <AddCompliance
              serviceNoteApproved={serviceNoteApproved}
              restrictedFieldNumbers={restrictedNoOfValues["no_of_compliances"]}
              markAsCompleted={markAsCompleted}
              isDataSheetLocked={isDataSheetLocked}
              intializeAutoSaveRequests={intializeAutoSaveRequests}
              setIntializeAutoSaveRequests={setIntializeAutoSaveRequests}
            />
          )}
        </Tab>
        <Tab eventKey="longer-tab" title="3. ABC Behaviour" className="abc-tab">
          {user.role === "director" && (
            <ViewAbc
              abcData={abcData}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalCount={totalCount}
              isDataSheetLocked={isDataSheetLocked}
            />
          )}
          {user.role === "technician" && (
            <>
              <div style={{ marginTop: "10px" }}>
                <h6 style={{ color: "red" }}>
                  Note: You have to enter{" "}
                  {restrictedNoOfValues["no_of_abc_behaviours"]} Abc Behaviors
                  Minimum
                </h6>
              </div>
              <div className="btn-outer-wrapper">
                <div className="btn-outer">
                  <button
                    onClick={() => {
                      setAbcView("add");
                      setCurrentPage(1);
                    }}
                    className={`View-Behaviour-btn ${
                      abcView !== "add" && "View-Behaviour-btn-grey"
                    }`}
                  >
                    Add Behaviour
                  </button>
                </div>
                <div className="btn-outer">
                  <button
                    onClick={() => {
                      setAbcView("view");
                      setCurrentPage(1);
                    }}
                    style={{
                      color:
                        abcView === "view"
                          ? "#f6891f !important"
                          : "grey !important",
                    }}
                    className={`View-Behaviour-btn ${
                      abcView !== "view" && "View-Behaviour-btn-grey"
                    }`}
                  >
                    View Behaviour
                  </button>
                </div>
              </div>
              {abcView === "view" && (
                <ViewAbc
                  abcData={abcData}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  totalCount={totalCount}
                />
              )}
              {abcView === "add" && (
                <AddAbc
                  setAction={setAction}
                  action={action}
                  serviceNoteApproved={serviceNoteApproved}
                  markAsCompleted={markAsCompleted}
                  isDataSheetLocked={isDataSheetLocked}
                  intializeAutoSaveRequests={intializeAutoSaveRequests}
                  setIntializeAutoSaveRequests={setIntializeAutoSaveRequests}
                />
              )}
            </>
          )}
        </Tab>
        <Tab eventKey="Mands" title="4. Mands">
          {user?.role === "director" && <ViewMands />}
          {user?.role === "technician" && (
            <AddMands
              serviceNoteApproved={serviceNoteApproved}
              restrictedFieldNumbers={restrictedNoOfValues["no_of_mands"]}
              markAsCompleted={markAsCompleted}
              isDataSheetLocked={isDataSheetLocked}
              intializeAutoSaveRequests={intializeAutoSaveRequests}
              setIntializeAutoSaveRequests={setIntializeAutoSaveRequests}
            />
          )}
        </Tab>
        <Tab eventKey="Acc-no" title="5. Accepting No" className="abc-tab">
          {user.role === "director" && <ViewAccNo acceptNo={true} />}
          {user.role === "technician" && (
            <AddAccNo
              acceptNo={true}
              serviceNoteApproved={serviceNoteApproved}
              restrictedFieldNumbers={
                restrictedNoOfValues["no_of_accepting_nos"]
              }
              markAsCompleted={markAsCompleted}
              isDataSheetLocked={isDataSheetLocked}
              intializeAutoSaveRequests={intializeAutoSaveRequests}
              setIntializeAutoSaveRequests={setIntializeAutoSaveRequests}
            />
          )}
        </Tab>
        <Tab eventKey="contact" title="6. Notes">
          <div className="mt-28">
            <h4 className="f-15 f-w-600 ps-8">Session Notes</h4>
            <TextFieldFormik
              className={`${
                user.role === "director"
                  ? "note-description"
                  : "note-description-add"
              } mt-20`}
              disabled={
                user?.role === "director" ||
                serviceNoteApproved ||
                isDataSheetLocked
              }
              values={notes}
              handleChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <div
            className="d-flex justify-content-end mt-20"
            style={{
              cursor: "pointer",
              opacity: `${
                user.role === "technician" && notes && dataSheetCompleted
                  ? 1
                  : user.role === "director"
                  ? 1
                  : 0.5
              }`,
            }}
            onClick={() => {
              if (notes) {
                if (user.role === "director") {
                  if (route?.includes("dashboardT")) {
                    navigate(
                      `/dashboardT/checkout/${id}/${userId}/${appointId}/${serviceId}`,
                      {
                        state: { ...propsReceived },
                      }
                    );
                  } else {
                    navigate(
                      `/dashboard/checkout/${id}/${userId}/${appointId}/${serviceId}`,
                      {
                        state: { ...propsReceived },
                      }
                    );
                  }
                  setdashboardView("checkout");
                } else {
                  setDataSheetNotes({ requireNavigation: true });
                }
              }
            }}
          >
            <span className="notes-btn">Finish</span>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default DailyDataSheetTabs;
