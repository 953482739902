import { Breadcrumb, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styleClasses from "../DailyDataSheetDetail/DailyDataSheetDetail.module.scss";
// import { Image } from 'react-bootstrap/Image';
import { FaEraser, FaSave, FaSignature } from "react-icons/fa";
import { useMatch, useNavigate, useParams } from "react-router-dom/dist";
import Signature from "signature_pad";
import {
  arrowLeftPurple,
  arrowRightBreadcrumb,
} from "../../../constants/constants";
import { createNotification } from "../../../helpers/createNotifications";
import httpClient from "../../../services/axios";
import httpClientTwo from "../../../services/fetch";
import { logout } from "../../../store/user/userSlice";
import fetchFormattedDateTime from "../../../utils/datetimeUtlis";
import { GetCurrentUser } from "../../../utils/utilAuth";
import { questionInfo } from "./CheckOutFormConstant";
import CommonCheckOutForm from "./CommonCheckOutForm";
import FormCountDown from "../../Countdown/FormCountDown";
import { faStoreAltSlash } from "@fortawesome/free-solid-svg-icons";
import { enableBeforeUnload } from "../../../utils/sessionHelpers";

const CheckOutForm = ({ setdashboardView }) => {
  const user = GetCurrentUser();
  const { params } = useMatch("*");

  const route = Object.values(params)[0];

  const { id, serviceId, dataSheetId } = useParams();
  const [loading, setLoading] = useState();
  const [questionData, setQuestionData] = useState();
  const [signaturePad, setSignaturePad] = useState();
  const [savedSignature, setSavedSignature] = useState("");
  const [signaturePadTwo, setSignaturePadTwo] = useState();
  const [savedSignatureTwo, setSavedSignatureTwo] = useState("");
  const [actionTwo, setActionTwo] = useState(false);
  const navigate = useNavigate();
  const [newQuestion, setNewQuestion] = useState();
  const [preFilledData, setPreFilledData] = useState();
  const [signatureDisable, setSignatureDisable] = useState();
  const [checkedOutLocked, setCheckedOutLocked] = useState(false);
  const [FormStatus, setFormStatus] = useState();
  const [timeOver, setTimeOver] = useState(false);
  const [appointmentEndTime, setAppointmentEndTime] = useState();
  const [firstVisitTime, setFirstVisitTime] = useState(new Date());
  const [firstIntervalCompleted, setFirstIntervalCompleted] = useState(false);
  const [checkoutLockedTimes, setCheckoutLockedTimes] = useState({});

  let signatureRedoArray = [];

  useEffect(() => {
    enableBeforeUnload(user);
  }, []);

  const changeFormStatus = async () => {
    setLoading(true);
    try {
      const response = await httpClient.put(`/api/v1/appointment_forms/${id}`, {
        appointment_form: {
          status: "pending",
        },
        appointment_id: id,
        resource: "check_out_form",
      });
      if (response?.data) {
        setFormStatus("pending");
        createNotification("success", response.data?.response?.message);
      }
    } catch (error) {
      createNotification("error", error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const getServiceNoteData = async () => {
      const formatedDateTime = fetchFormattedDateTime();

      try {
        const response = await httpClient.get(
          `/api/v1/sections/check-out-section`,
          {
            params: {
              resource: "check_out_form",
              appointment_id: id,
              current_date_time: formatedDateTime,
            },
          }
        );
        if (response.data) {
          setQuestionData(response.data);
          setCheckedOutLocked(response.data?.checkout_locked);
          setFormStatus(response.data?.appointment_form_status);
          setAppointmentEndTime(response.data?.appointment_end_time);
          setCheckoutLockedTimes({
            submission_time: response.data?.check_out_submission_time * 60,
            unlock_time: response.data?.check_out_unlock_time ,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (id && route.includes("checkout")) {
      getServiceNoteData();
    }
  }, [id, appointmentEndTime]);

  useEffect(() => {
    setLoading(true);
    const getDataSheetData = async () => {
      try {
        // http://localhost:3000/api/v1/data_sheets/537/service_note_saved_answers
        const response = await httpClient.get(
          `/api/v1/data_sheets/${dataSheetId}/form_prefilled_answers?resource_type=check_out_form`
        );
        if (response.data) {
          setPreFilledData(response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (dataSheetId) {
      getDataSheetData();
    }
  }, [dataSheetId]);

  const createInterval = (delaySeconds) => {
    // console.log('checkoutLockedTimes', checkoutLockedTimes)
    const intervalId = setInterval(() => {
      const currentDateTime = new Date(fetchFormattedDateTime());
      // console.log('checkoutLockedTimes', checkoutLockedTimes["unlock_time"])
      if (currentDateTime.getMinutes() % 5 === 0) {
        const endTime = new Date(appointmentEndTime);
        const timeDifference = endTime - currentDateTime;
        if (timeDifference <= checkoutLockedTimes["unlock_time"] * 60 * 1000) {
          setCheckedOutLocked(false);
        }
      }
      setFirstIntervalCompleted(true);
    }, delaySeconds * 1000);
    return () => clearInterval(intervalId);
  };

  const calculateDelay = () => {
    if (!firstIntervalCompleted) {
      return 60 - firstVisitTime?.getSeconds();
    } else {
      return 60;
    }
  };

  useEffect(() => {
    const delayTime = calculateDelay();
    const cleanup = createInterval(delayTime);
    return cleanup;
  }, [firstIntervalCompleted, appointmentEndTime]);

  function startsWithHttp(inputString) {
    return typeof inputString === "string" && inputString.startsWith("http");
  }

  const postData = async (postData) => {
    const formData = new FormData();
    postData.current_datetime = fetchFormattedDateTime();
    if (!startsWithHttp(postData?.therapist_signature_image)) {
      formData.append(
        "therapist_signature_image",
        postData?.therapist_signature_image
      );
    }
    try {
      if (postData) {
        if (formData.get("therapist_signature_image")) {
          const res = await httpClientTwo.post(
            `/api/v1/sections/check-out-section/update_or_create?resource=${"check_out_form"}&appointment_id=${id}&image_only=${true}`,
            formData
          );
        }
        const res2 = await httpClient.post(
          `/api/v1/sections/check-out-section/update_or_create`,
          { ...postData }
        );
        if (res2.data) {
          setQuestionData(res2.data);
          createNotification("success", "Saved");
          // path="/techdashboard/servicenote/:id"

          setdashboardView("serviceNote");
          if (serviceId) {
            if (route?.includes("yourClients")) {
              navigate(
                `/yourClients/servicenote/${id}/${serviceId}/${dataSheetId}`,
                { state: { yourClient: true } }
              );
            } else {
              navigate(
                `/techdashboard/serviceNote/${id}/${serviceId}/${dataSheetId}`
              );
            }
          } else {
            navigate(`/techdashboard/servicenote/${id}/${dataSheetId}`);
          }
        }
        return true;
      }
    } catch (error) {
      if (
        error?.response?.data?.response.error === "Signature has expired" ||
        error.response.status === 401
      ) {
        createNotification(
          "error",
          "Please login again your session has been expired"
        );
        localStorage.removeItem(`persist:root`);

        logout();

        window.open("/clientFormlogin ", "_self");

        return;
      }
      createNotification("error", error?.response?.data?.response.error);
      console.log("Something Went wrong", error.message);
      return false;
    }
  };

  const readyPad = () => {
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector(".signature-canvas");
    if (wrapper) {
      canvas?.getContext("2d").scale(1, 1);
      let tempSignaturePad = new Signature(canvas, {
        maxWidth: 2,
        backgroundColor: "rgb(255, 255, 255)",
      });
      setSignaturePad(tempSignaturePad);
    }
  };
  const readyPadTwo = () => {
    let wrapper = document.getElementById("signature-pad-2");
    let canvas = wrapper?.querySelector(".signature-canvas-2");
    if (wrapper) {
      canvas?.getContext("2d").scale(1, 1);
      let tempSignaturePad = new Signature(canvas, {
        maxWidth: 2,
        backgroundColor: "rgb(255, 255, 255)",
      });
      setSignaturePadTwo(tempSignaturePad);
    }
  };

  useEffect(() => {
    setSavedSignature(questionData?.therapist_signature_image);
  }, [questionData]);
  const handleSave = () => {
    setSavedSignature(signaturePad.toDataURL("image/jpeg"));
  };
  const handleSaveTwo = () => {
    setSavedSignatureTwo(signaturePadTwo.toDataURL("image/jpg"));
  };

  const handleClear = () => {
    signaturePad.clear();
    setSavedSignature("");
  };

  const getFormattedStatus = (status) => {
    switch (status) {
      case "completely_filled":
        return "Completely Filled";
      case "partially_filled":
        return "Partially Filled";
      case "pending":
        return "Pending";
      case "initialized":
        return "Initialized";
      default:
        return status;
    }
  };

  const handleCompletion = () => {
    // Your logic to be executed on completion of 2 minutes
    setTimeOver(true);
    console.log("Countdown completed! Calling abc function.");
    // Call your abc function here
  };

  const handleClearTwo = () => {
    signaturePadTwo.clear();
    setSavedSignatureTwo("");
  };
  useEffect(() => {
    readyPad();
    readyPadTwo();
  }, []);

  const newData = {
    questions: [
      {
        title: "note_recepient_first_name",
        answers: [
          {
            statement: "CHECK",
          },
        ],
      },
    ],
  };
  useEffect(() => {
    if (preFilledData && questionData) {
      const newQuestionData = { ...questionData };
      preFilledData.questions.forEach((question) => {
        const serviceNoteQuestion = newQuestionData.questions.find(
          ({ title }) => title == question.title
        );
        if (serviceNoteQuestion) {
          let answers = (serviceNoteQuestion.answers || []).map((answer) => {
            answer._destroy = true;
            return answer;
          });
          answers = answers.concat(
            (question.answers || []).map((answer, index) => {
              answer.meta = answer.meta ?? {};
              answer.meta.batch_no = index + 1;
              answer.statement = answer.statement ?? "";
              return answer;
            })
          );
          serviceNoteQuestion.answers = answers;
        }
      });

      setNewQuestion({
        ...newQuestionData,
        questions: [...newQuestionData?.questions],
      });
    }
  }, [preFilledData, questionData]);

  return (
    <section className={styleClasses.MainDataSheetDetailPage}>
      <div
        className={`${styleClasses.MainDataSheetDetailPage__Content} ${styleClasses.checkinoutForm}`}
      >
        <div className={styleClasses.breadcrumbWrapper}>
          <div
            className="lm-nav-arrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            {arrowLeftPurple}
          </div>
          <Breadcrumb
            separator={arrowRightBreadcrumb}
            items={[
              {
                title: "Check Out",
              },
            ]}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="mt-20 f-24 f-w-700 ms-96 pb-40">
            Check Out Form{" "}
            <span
              style={{ fontSize: "12px", color: "green", marginLeft: "5px" }}
            >
              {getFormattedStatus(FormStatus)}
            </span>
          </h3>
          {user?.role === "technician" && (
            <>
              {checkedOutLocked && (
                <button
                  type="button"
                  className={styleClasses.checkedinLockedBtn}
                >
                  Locked
                </button>
              )}
              {!checkedOutLocked && FormStatus === "initialized" && (
                <div>
                  <FormCountDown
                    onCompletion={handleCompletion}
                    submissiontime={checkoutLockedTimes["submission_time"]}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className={styleClasses.breadcrumbWrapper__outerRecipent}>
          <>
            {newQuestion ? (
              <>
                <CommonCheckOutForm
                  data={newQuestion}
                  questionInfo={questionInfo}
                  postData={postData}
                  dataSheetId={dataSheetId}
                  savedSignature={savedSignature}
                  savedSignatureTwo={savedSignatureTwo}
                  actionTwo={actionTwo}
                  setActionTwo={setActionTwo}
                  setdashboardView={setdashboardView}
                  setSignatureDisable={setSignatureDisable}
                  checkedOutLocked={checkedOutLocked}
                  timeOver={timeOver}
                  formStatus={FormStatus}
                  changeFormStatus={changeFormStatus}
                />
              </>
            ) : (
              <Spin
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            )}
            <div className={styleClasses.signaturesWrapper}>
              <div
                className={styleClasses.firstSignature}
                style={{ position: "relative" }}
              >
                {((dataSheetId && user?.role === "director") ||
                  !signatureDisable) && (
                  <div className="signature-overlay"></div>
                )}

                <div
                  id="signature-pad"
                  className={`${!questionData && "signature-hidden"}`}
                >
                  <label>
                    Therapist Signature <FaSignature />
                  </label>
                  {savedSignature && (
                    <div
                      className={`saved-signature ${
                        savedSignature && "saved-signature-visible"
                      }`}
                    >
                      <img
                        className="signature-image"
                        alt="saved-signature"
                        src={savedSignature}
                      />
                    </div>
                  )}
                  <canvas className="signature-canvas"></canvas>
                  <div className="signature-btn-wrapper">
                    <button
                      onClick={handleSave}
                      disabled={
                        savedSignature ||
                        (dataSheetId && user?.role === "director")
                      }
                    >
                      <FaSave /> Save
                    </button>
                    <button
                      onClick={handleClear}
                      disabled={user?.role === "director"}
                    >
                      <FaEraser /> Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </section>
  );
};

export default CheckOutForm;
